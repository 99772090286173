import { FileWithPath } from "./index";

export const fileToBlob = async (file: FileWithPath[]) =>
  file[0] && new Blob([new Uint8Array(await file[0].arrayBuffer())], { type: file[0].type });

export const toBlob = (acceptedFiles: FileWithPath[]) => {
  return acceptedFiles.forEach((file) => {
    const reader = new FileReader();

    reader.onabort = () => console.log("file reading was aborted");
    reader.onerror = () => console.log("file reading has failed");
    reader.onload = () => {
      const blob = new Blob([new Uint8Array(reader.result as ArrayBuffer)], { type: file.type });
      console.log(blob);
    };
    reader.readAsArrayBuffer(file);
  });
};

export const toBase64 = (file: FileWithPath[]) =>
  new Promise((resolve, reject) => {
    if (file.length) {
      const reader = new FileReader();

      reader.readAsDataURL(file[0]);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    }
  });

export const toUnit8Array = (acceptedFiles: FileWithPath[]) => {
  return acceptedFiles.forEach((file) => {
    const reader = new FileReader();

    reader.onabort = () => console.log("file reading was aborted");
    reader.onerror = () => console.log("file reading has failed");
    reader.onload = () => {
      const bytes = new Uint8Array(reader.result as ArrayBuffer);
      console.log(bytes);
    };
    reader.readAsArrayBuffer(file);
  });
};

export const fileToUnit8Array = async (file: FileWithPath[]) => file[0] && new Uint8Array(await file[0].arrayBuffer());
