import translations from "translations";

export function getTranslatedCategoryEnumString(key: string | undefined | null): string {
  if (!key) return translations.globals.notifications.noDataAvailable;
  return JSON.parse(JSON.stringify(translations.enums.category))[key];
}

export function getTranslatedPrimaryEnergySourceString(key: string | undefined | null): string {
  if (!key) return translations.globals.notifications.noDataAvailable;
  return JSON.parse(JSON.stringify(translations.enums.primaryEnergySource))[key];
}

export function getTranslatedNetFeedInString(key: string | undefined | null): string {
  if (!key) return translations.globals.notifications.noDataAvailable;
  return JSON.parse(JSON.stringify(translations.enums.netFeedIn))[key];
}

export function getTranslatedTenantRoleString(key: string | undefined | null): string {
  if (!key) return translations.globals.notifications.noDataAvailable;
  return JSON.parse(JSON.stringify(translations.enums.tenantRole))[key];
}
export function getTranslatedSortString(key: string | undefined | null): string {
  if (!key) return translations.globals.notifications.noDataAvailable;
  return JSON.parse(JSON.stringify(translations.enums.sort))[key];
}

export function getTranslatedCertificateValidityStatusString(statusId: string | undefined | null): string {
  if (!statusId) return translations.globals.notifications.noDataAvailable;
  return JSON.parse(JSON.stringify(translations.entities.certificateValidityStatus))[statusId];
}

export function getTranslatedCertificateTypeString(certificateTypeId: string | undefined | null): string {
  if (!certificateTypeId) return translations.globals.notifications.noDataAvailable;
  return JSON.parse(JSON.stringify(translations.entities.certificateType))[certificateTypeId];
}
export function getTranslatedIssueTypeString(issueTypeId: string | undefined | null): string {
  if (!issueTypeId) return translations.globals.notifications.noDataAvailable;
  return JSON.parse(JSON.stringify(translations.pages.certificateValidation.issueTypes))[issueTypeId];
}

export function getTranslatedIssueTypeHistoryString(issueTypeId: string | undefined | null): string {
  if (!issueTypeId) return translations.globals.notifications.noDataAvailable;
  return JSON.parse(JSON.stringify(translations.pages.certificationManagement.certificateHistoryIssues))[issueTypeId];
}

export function getTranslatedRoleString(roleId: string | undefined | null): string {
  if (!roleId) return translations.globals.notifications.noDataAvailable;
  return JSON.parse(JSON.stringify(translations.entities.role.tenantRole))[roleId];
}

export function getTranslatedCertificateFileTypeNameString(certificateFileTypeId: string | undefined | null): string {
  if (!certificateFileTypeId) return translations.globals.notifications.noDataAvailable;
  return JSON.parse(JSON.stringify(translations.entities.certificateFileType.certificateFileTypeName))[
    certificateFileTypeId
  ];
}

export function getTranslatedAccessRequestStatusString(key: string | undefined | null): string {
  if (!key) return translations.globals.notifications.noDataAvailable;
  return JSON.parse(JSON.stringify(translations.enums.accessRequestStatus))[key];
}

export function getTenantRoleKeyFromTranslatedValue(
  translatedValue: string
): keyof typeof translations.enums.tenantRole | undefined {
  const translationMap = translations.enums.tenantRole;
  return (Object.keys(translationMap) as Array<keyof typeof translationMap>).find(
    (key) => translationMap[key] === translatedValue
  );
}
