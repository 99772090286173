import type * as Types from '../../types';

import client from "apollo/client";
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetCertificatesToValidateQueryVariables = Types.Exact<{
  skip?: Types.InputMaybe<Types.Scalars['Int']>;
  take?: Types.InputMaybe<Types.Scalars['Int']>;
  where?: Types.InputMaybe<Types.IssueFilterInput>;
  order?: Types.InputMaybe<Array<Types.IssueSortInput> | Types.IssueSortInput>;
}>;


export type GetCertificatesToValidateQuery = { __typename?: 'Query', issues?: { __typename?: 'IssueCollectionSegment', totalCount: number, items?: Array<{ __typename?: 'Issue', id: string, dateFrom?: string | null, issueDueDate?: string | null, createdAt: string, issueStatusId: string, issueTypeId: string, createdBy?: string | null, certificateId: string, issueType: { __typename?: 'IssueType', issueTypeName: string }, issueStatus: { __typename?: 'IssueStatus', issueStatusName: string }, certificate: { __typename?: 'Certificate', id: string, certificateIssueDate: string, certificateNumber: string, certificateHolderId: string, certificateAuthorityId: string, isImported: boolean, transformedBy?: string | null, transformedOn?: string | null, createdByTenant: string, certificateBridgeUnits: Array<{ __typename?: 'CertificateBridgeUnit', unitId: string }> } }> | null } | null };


        export type ExecGetCertificatesToValidateOptions = Omit<
        Apollo.QueryOptions<
        GetCertificatesToValidateQueryVariables, GetCertificatesToValidateQuery
        >,
        "query"
      >;
    export const execGetCertificatesToValidateQuery = (options: ExecGetCertificatesToValidateOptions) =>
    client.query< GetCertificatesToValidateQuery, GetCertificatesToValidateQueryVariables>({
      ...options,
      query: GetCertificatesToValidateDocument,
    });
    

export const GetCertificatesToValidateDocument = gql`
    query getCertificatesToValidate($skip: Int, $take: Int, $where: IssueFilterInput, $order: [IssueSortInput!]) {
  issues(skip: $skip, take: $take, where: $where, order: $order) {
    totalCount
    items {
      id
      dateFrom
      issueDueDate
      createdAt
      issueStatusId
      issueTypeId
      createdBy
      issueType {
        issueTypeName
      }
      issueStatus {
        issueStatusName
      }
      certificateId
      certificate {
        id
        certificateIssueDate
        certificateNumber
        certificateHolderId
        certificateAuthorityId
        createdByTenant: tenantId
        isImported
        transformedBy
        transformedOn
        certificateBridgeUnits {
          unitId
        }
      }
    }
  }
}
    `;

/**
 * __useGetCertificatesToValidateQuery__
 *
 * To run a query within a React component, call `useGetCertificatesToValidateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCertificatesToValidateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCertificatesToValidateQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      where: // value for 'where'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useGetCertificatesToValidateQuery(baseOptions?: Apollo.QueryHookOptions<GetCertificatesToValidateQuery, GetCertificatesToValidateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCertificatesToValidateQuery, GetCertificatesToValidateQueryVariables>(GetCertificatesToValidateDocument, options);
      }
export function useGetCertificatesToValidateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCertificatesToValidateQuery, GetCertificatesToValidateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCertificatesToValidateQuery, GetCertificatesToValidateQueryVariables>(GetCertificatesToValidateDocument, options);
        }
export type GetCertificatesToValidateQueryHookResult = ReturnType<typeof useGetCertificatesToValidateQuery>;
export type GetCertificatesToValidateLazyQueryHookResult = ReturnType<typeof useGetCertificatesToValidateLazyQuery>;
export type GetCertificatesToValidateQueryResult = Apollo.QueryResult<GetCertificatesToValidateQuery, GetCertificatesToValidateQueryVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
          export type ResultType<T> = Types.ResultType<T>;
          export type PaginatedResultType<T> = Types.PaginatedResultType<T>;
          export type NonMaybe<T> = Types.NonMaybe<T>;
          export type Maybe<T> = Types.Maybe<T>;