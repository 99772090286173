import ModalWithButtons from "components/molecules/Modal/ModalWithButtons";
import React, { useCallback, useState } from "react";
import translations from "translations";
import AddNewNormBridge, { AddNewNormBridgeProps } from "./AddNewNormBridge";

interface Props extends AddNewNormBridgeProps {
  setOpenModal: (value: boolean) => void;
  openModal: boolean;
  title: string;
  normBridgeIds: string[];
  addNormBridgeId: (normBridgeId: string) => void;
}

const CreateNormModal: React.FC<Props> = ({ setOpenModal, openModal, title, addNormBridgeId, normBridgeIds }) => {
  const handleClose = useCallback(() => {
    setNormId(undefined);
    setOpenModal(false);
  }, [setOpenModal]);
  const [normId, setNormId] = useState<string | undefined>();
  const save = useCallback(() => {
    if (!normId) return;
    addNormBridgeId(normId);
    handleClose();
  }, [addNormBridgeId, normId, handleClose]);

  const onClose = (event?: object, reason?: string) => {
    if (reason !== "backdropClick") {
      handleClose();
    }
  };
  return (
    <ModalWithButtons
      openModal={openModal}
      onClose={onClose}
      title={title}
      disabled={!normId}
      addButtonTitle={translations.globals.button.add}
      addButtonClick={save}
      children={<AddNewNormBridge addNormBridgeId={setNormId} normBridgeIds={normBridgeIds} />}
      width="715px"
    />
  );
};

export default CreateNormModal;
