import { userIsPlatformOperatorAtom } from "pages/UserProfile/recoil";
import * as React from "react";
import { useRecoilValue } from "recoil";
import TextField from "../TextField";
import Props from "./index.d";

const TextFieldForPlatformOperators: React.FC<Props> = (props) => {
  const userIsPlatformOperator = useRecoilValue(userIsPlatformOperatorAtom);
  const {
    title,

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    data,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    placeholder = "placeholder",
    disabled,
    testId,
    errors,
    isRequiredField,
    errorManager,
    className,
    isUnit,
    hideLabel,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ...rest
  } = props;

  const errorManagerRes = errorManager({
    errorJoiner: (errors) => {
      return errors.join(", ");
    },
  });

  return (
    <>
      <TextField
        aria-label={title}
        disabled={!userIsPlatformOperator}
        className={className}
        // showRemoveIcon={!isRequiredField}
        helperText={errorManagerRes.error && !isUnit ? errorManagerRes.helperText : ""}
        {...errorManagerRes}
        data={props.data}
        isRequiredField={props.isRequiredField}
        errorManager={props.errorManager}
        placeholder={props.placeholder}
        testId={props.testId}
        errors={props.errors}
        hideLabel={props.hideLabel}
        {...rest}
      />
    </>
  );
};

export default TextFieldForPlatformOperators;
