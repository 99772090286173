import React, { ReactElement, useEffect, useRef } from "react";
import { getFlatDataFromTree, FlatDataItem } from "@nosferatu500/react-sortable-tree";
import { diff } from "deep-object-diff";
import AsyncFileLoader from "./AsyncFileLoader";
import gql from "graphql-tag";
import { oc } from "ts-optchain";
import pickBy from "lodash/pickBy";
import {
  AnyProperties,
  Client,
  Localization,
  DataItem,
  ElementType,
  FormComponent,
  FormGeneratorProps,
  BeforeRemoteMuatation,
  StateEffects,
  Updaters,
  Tree,
  TreeElement,
  Type,
  QueryArray,
  ValidateForm,
  ValidateFormPropsInner,
  FormStateExtended,
  FocusedField,
  RemoteFieldSpecs,
  RemoteFieldSpecExtended,
} from "../formGen.model";
import { AfterChangeProps, QqlFormGenConfig, OnUpdateDataMiddleWareProps } from "../types";
import cleanParams from "./cleanParams";
import fieldState from "./fieldState";
import generateQeuery from "./generateQeuery";

import generateMutation from "./generateMutation";
import validateIsHidden, { Disabled, Hide, Validate, isDisabled, validateForm } from "./validate";
import Loader from "./loader";
import afterCreateOrDelete from "./afterCreateOrDelete";
import { upperFirst } from "lodash";
import Inspect from "./Inspect";
import { RecoilState, SetterOrUpdater, useResetRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import equal from "fast-deep-equal";
import { connectField, makeFormState } from "./formState";
import { getRecoilStore } from "recoil-toolkit";
import { getAddtitonalFieldNames } from "../utils/getAddtitonalFields";
import useComposeOverride from "./useCompomseOverrides";
import { ErrorResult, Errors, FormErrors, RuleResult, applyRules, Rules } from "../formValidation";
import errorManager from "../formValidation/errorManager";
import getErrorInformation from "../formValidation/getErrorInformation";
interface FlatDataItemFull extends FlatDataItem {
  treeIndex: number;
}

const noTriggerFieldsCore = ["step", "commit"];
const noTriggerFields = noTriggerFieldsCore.concat(["formErrors", "formErrorCount", "submit", "cancel"]);

const dataTypesMapping: { [key: string]: string } = {
  integer: "number",
  decimal: "number",
  string: "string",
  boolean: "boolean",
  date: "date",
  datetime: "datetime",
  time: "time",
  json: "json",
  uuid: "string",
  FKUuid: "string",
};

const RecoilConnector: React.FC<{
  stateAtom: RecoilState<FormStateExtended | undefined>;
  update: (dataIn: AnyProperties) => void;
  getData: () => DataItem | undefined;
}> = ({ stateAtom, update, getData }) => {
  const state = useRecoilValue(stateAtom);
  const resetStateAtom = useResetRecoilState(stateAtom);
  useEffect(() => {
    const data = getData();
    if (state && data) {
      const keys = Object.keys(state ?? {}).concat(Object.keys(data ?? {}));
      const newData = keys.reduce((next, key) => {
        const isSame = equal(data?.[key], state?.[key]);
        if (isSame || !Object.keys(state).includes(key)) return next;
        return { ...next, [key]: state?.[key] };
      }, {});
      if (Object.keys(newData).length) {
        update(newData);
      }
    }
  }, [state]);

  useEffect(() => {
    return () => {
      resetStateAtom();
    };
  }, [resetStateAtom]);
  return null;
};

const Base = (props: any) => {
  // console.log(props);
  return null;
};

const getFlatData = (treeData: Tree) => {
  return getFlatDataFromTree({
    treeData,
    getNodeKey: ({ node }) => node?.id ?? "",
    ignoreCollapsed: false,
  });
};
const BaseHiddenField = (props: any) => {
  // console.log(props.id, props.data.state);
  return <div>{props.data.state}</div>;
  return <div style={{ display: "none" }} />;
};
const fallback: ElementType = {
  uiType: "fallback",
  Component: Base,
};

interface Hiders {
  dataIds: string[];
  hidden: any;
  setHidden: (data: any) => void;
  id: string;
  Hide: Hide;
}

interface IsDisabled {
  dataIds: string[];
  disabled: any;
  setDisabled: (data: any) => void;
  id: string;
  Disabled: Disabled;
}
interface HasError {
  dataIds: string;
  errors: RuleResult[];
  setErrors: (data: any) => void;
  id: string;
}

class FormGenerator {
  public elements: ElementType[];
  public client: Client;
  public localization: Localization;
  public data: DataItem | undefined;
  public config: QqlFormGenConfig<{}>;
  public query = "";
  public propsCache: any = {};
  public clientFields: string[];
  public externalFields: string[];
  public fieldTypes: { [key: string]: Type } = {};
  public afterCreate?: (data: any) => void;
  public updateAfterCreateQueries?: QueryArray;
  public updateAfterDeleteQueries?: QueryArray;
  public fetchAllFieldsOnUpdate?: boolean = false;
  public fetchOnlyId?: boolean = false;
  public validateOnLoad?: boolean = false;
  public afterUpdate?: (data: any) => void;
  public afterDelete?: (data: any) => void;
  public onSubmitError?: (data: any) => void;
  public fieldsHadValue: string[] = [];
  public onCancel: () => void = () => {};
  public defaultValues: { [key: string]: any } = {
    step: 0,
  };
  public path = "";
  public updaters: Updaters[] = [];
  public bridgeSaves: {
    [key: string]: (id: string) => Promise<{
      adds: {
        id: string;
        outerId: string;
      }[];
      removes: {
        id: string;
        outerId: string;
      }[];
    }>;
  } = {};
  public hiders: Hiders[] = [];
  public isDisabled: IsDisabled[] = [];
  public errors: HasError[] = [];
  public validate: (data: any) => Validate = () => ({
    Validate: "Yes",
    Constraints_Validate: [],
  });
  public constraints: { id: string; rule: string }[] = [];
  public treeConfig: any;
  public params: any;
  public injectedValues?: any;
  public variables: any;
  public tree?: TreeElement;
  public flatTree: FlatDataItemFull[] = [];
  public afterChange?: (data: AfterChangeProps<any>) => void;
  public onUpdateDataMiddleWare?: (data: OnUpdateDataMiddleWareProps<any>) => any;
  public beforeRemoteMuatation?: BeforeRemoteMuatation;
  public onUpdateDataMiddleWareLocal?: (data: OnUpdateDataMiddleWareProps<any>) => any;
  public validateForm?: ValidateForm<any, any>;
  public validationRules?: Rules<any, any>;
  public validationTriggerFields?: Record<string, string[]>;
  public beforeRemoteMuatationLocal?: BeforeRemoteMuatation;
  public modifyProps?: { [key: string]: (val: any) => any };
  public formAtom?: RecoilState<FormStateExtended | undefined>;
  private recoilAccess?: [FormStateExtended | undefined, SetterOrUpdater<FormStateExtended | undefined>];
  public useHC11: boolean = false;
  public allFields: string[];
  public hiddenFields: string[] = [];
  public additionalFields: string[] = [];
  public remoteFieldSpecs: RemoteFieldSpecs = {};
  public callBackOnHiddenEmptyFields: {
    fields: string[];
    func: (allHiddenOrNot: boolean) => string;
    notHiddenOrEmpty: boolean;
  }[] = [];
  touchedFields: string[] = [];
  dirtyFields: string[] = [];
  focusedField: FocusedField = undefined;
  requiredFields: RemoteFieldSpecExtended[] = [];
  constructor({
    elements,
    client,
    config,
    clientFields,
    externalFields,
    afterChange,
    onUpdateDataMiddleWare,
    beforeRemoteMuatation,
    localization,
    useHC11,
    allFields,
    requiredFields,
  }: FormGeneratorProps) {
    this.elements = this.checkUnique(elements);
    this.client = client;
    this.data = { step: 0 };
    this.localization = localization;
    this.afterChange = afterChange;
    this.onUpdateDataMiddleWare = onUpdateDataMiddleWare;
    this.beforeRemoteMuatation = beforeRemoteMuatation;
    this.clientFields = clientFields;
    this.externalFields = externalFields;
    this.config = config;
    this.useHC11 = !!useHC11;
    this.allFields = allFields;
    this.requiredFields = requiredFields;
  }

  public saveData = (d: any) => {
    this.update(d);
  };
  public showData = () => this.data;
  public upDateCacheAfterMappingMutation = (
    cache: unknown
    // id: string,
    // operation: "add" | "remove"
  ) => {
    // const hello = cache.readQuery({
    //   query: gql(this.query),
    // });
    // cache.writeQuery({
    //   query: gql(this.query),
    //   data: {
    //     readConfigs:
    //       operation === "add"
    //         ? readConfigs.concat([{ id, __typename: "RAFGConfig" }])
    //         : readConfigs.filter(conf => conf.id !== id)
    //   }
    // });
    // console.log(hello);
  };

  public checkUnique = (elements: ElementType[]) => {
    if (process.env.NODE_ENV == "production") {
      return elements;
    }

    const uiTypesArray = elements.map((element) => element.uiType);
    if (uiTypesArray.length !== Array.from(new Set(uiTypesArray)).length) {
      throw Error(
        `FormGenerator Error: uiTypes of elements are not unique \n\t${uiTypesArray
          .map((name, index) => `${index} => ${name}`)
          .join("\n\t")}`
      );
    }

    return elements.concat([{ uiType: "BaseHiddenField", Component: BaseHiddenField }]);
  };
  public setTouchedFields = (fieldId: string) => {
    if (!this.touchedFields.includes(fieldId)) {
      this.touchedFields.push(fieldId);
    }
  };

  public getElement = ({ config: { uiType } }: TreeElement): ElementType => {
    const element = this.elements.find((element) => element.uiType === uiType);
    // if (!element)
    return element ? element : fallback;
  };

  public mutate = ({
    data: dataIn,
    fields,
    isDefaultData = false,
  }: {
    data: any;
    fields: string[];
    isDefaultData?: boolean;
  }) => {
    try {
      const { entity, operation } = this.treeConfig;

      if (!fields.length) return;
      if (operation === "update") {
        Object.values(this.bridgeSaves).map((saveBridge) => {
          saveBridge(dataIn.id);
        });
      }
      const { mutationString, mutationStringLocal, cacheUpdaters } = generateMutation({
        entity,
        operation,
        fields,
        clientFields: this.clientFields,
        externalFields: this.externalFields.concat(this.additionalFields),
        treeConfig: this.treeConfig,
        allFields: this.allFields,
        updateAfterCreateQueries: this.updateAfterCreateQueries,
        fetchAllFieldsOnUpdate: this.fetchAllFieldsOnUpdate,
        fetchOnlyId: this.fetchOnlyId,
      });
      // @todo fix if inputfields are fixed
      const { __typename, submit, commit, cancel, id, formErrors, virtualField, formErrorCount, step, ...data } =
          dataIn,
        newParams: { [key: string]: any } = {},
        newParamsLocal: { [key: string]: any } = {},
        requiredData = {};

      this.requiredFields.forEach(
        // @ts-ignore
        (field: string) => (requiredData[field] = this.data![field])
      );

      Object.entries(this.params).forEach(([key, value]: [string, any]) => {
        if (this.useHC11 && key === "id") {
          //@ts-ignore
          newParams[`${entity}Id`] = value;
        } else {
          //@ts-ignore
          newParams[key] = value;
        }
      });

      const dataRemote = pickBy(
          data,
          (val: any, key: string) =>
            !this.clientFields.includes(`${entity}.${key}`) &&
            !this.externalFields.includes(`${entity}.${key}`) &&
            !this.additionalFields.includes(key) &&
            !key.includes("Bridge") &&
            !key.startsWith("0raftValuePick")
        ),
        dataLocal = pickBy(
          data,
          (val: any, key: string) =>
            this.clientFields.includes(`${entity}.${key}`) &&
            !this.externalFields.includes(`${entity}.${key}`) &&
            !this.additionalFields.includes(key) &&
            !key.includes("Bridge")
        );

      const makeUpdate = (data: Object, mutationString: string, isLocal: boolean = false) => {
        const nonNulls: { [key: string]: any } = {},
          setNull: { [key: string]: true } = {};
        Object.entries(data).forEach(([key, val]: [string, any]) => {
          if (val !== null || isLocal) {
            nonNulls[key] = val;
          } else {
            if (operation === "update") setNull[key] = true;
          }
        });

        if (Object.keys(data).length) {
          return this.client.mutate({
            mutation: gql(mutationString),
            variables: {
              data: [
                operation === "delete"
                  ? this.params.id
                  : operation === "update"
                  ? { ...newParams, ...requiredData, ...nonNulls }
                  : { ...newParams, ...nonNulls },
              ],
              setNull: operation === "update" ? setNull : undefined,
            },
          });
        } else {
          return Promise.resolve({ data: { result: [{}] } });
        }
      };

      // console.log(variables);

      const cleandedData = this.beforeRemoteMuatation
        ? this.beforeRemoteMuatation({
            data: dataRemote,
            allData: this.data,
            fields: Object.keys(dataIn),
            path: this.path,
            client: this.client,
            injectedValues: this.injectedValues,
          })
        : dataRemote;
      const cleandedDataLocal = this.beforeRemoteMuatationLocal
        ? this.beforeRemoteMuatationLocal({
            data: cleandedData,
            allData: this.data,
            fields: Object.keys(dataIn),
            path: this.path,
            client: this.client,
            injectedValues: this.injectedValues,
          })
        : cleandedData;
      // console.log(cleandedData);
      Promise.all([makeUpdate(cleandedDataLocal, mutationString), makeUpdate(dataLocal, mutationStringLocal, true)])
        .then((results) => {
          return results.reduce(
            (all: any, result: any) => {
              if (!result.data.result) return all;
              return {
                data: {
                  result: [{ ...all.data.result[0], ...result.data.result[0] }],
                },
              };
            },
            { data: { result: [{}] } }
          );
        })
        .then((r) => {
          const { submit, commit, cancel, __typename, ...data } = this.data!,
            emptyData = {};
          Object.keys({ ...data, ...r.data.result[0] }).forEach((element) => {
            // @ts-ignore
            emptyData[element] = undefined;
          });
          const { id }: { id: string } = { ...data, ...r.data.result[0] };
          if (operation === "create") {
            Object.values(this.bridgeSaves).map((saveBridge) => saveBridge(id));
          }
          if (this.updateAfterCreateQueries) {
            afterCreateOrDelete({
              data: { ...data, ...r.data.result[0] },
              client: this.client,
              queries: this.updateAfterCreateQueries,
              type: "add",
              // cacheUpdaters,
              entity: this.treeConfig.entity,
            }).then(() => {
              if (this.afterCreate) {
                this.afterCreate({ ...data, ...r.data.result[0] });
                this.update({ ...emptyData }, { isFromApollo: true });
              }
            });
          } else {
            if (this.afterCreate) {
              this.afterCreate({ ...data, ...r.data.result[0] });
              this.update({ ...emptyData }, { isFromApollo: true });
            }
          }
          if (this.afterUpdate && !isDefaultData) {
            // console.log({ ...data, ...r.data.result[0] });
            this.afterUpdate({ ...data, ...r.data.result[0] });
          }
          if (this.updateAfterDeleteQueries) {
            afterCreateOrDelete({
              data: { ...data, ...r.data.result[0] },
              client: this.client,
              queries: this.updateAfterDeleteQueries,
              type: "add",
              // cacheUpdaters,
              entity: this.treeConfig.entity,
            }).then(() => {
              if (this.afterDelete) {
                this.afterDelete({ ...data, ...r.data.result[0] });
                this.update({ ...emptyData }, { isFromApollo: true });
              }
            });
          } else {
            if (this.afterDelete) {
              this.afterDelete({ ...data, ...r.data.result[0] });
              this.update({ ...emptyData }, { isFromApollo: true });
            }
          }
          if (submit) {
            //@ts-ignore
            this.update({ submit: false }, { isFromAfter: true });
          }
        })
        .catch((e) => {
          if (this.onSubmitError) {
            this.onSubmitError(dataIn);
          }
        });
      const entityUpperCase = entity.charAt(0).toUpperCase() + entity.slice(1);
      if (operation === "update")
        this.client.writeFragment({
          id: `${entityUpperCase}:${this.params.id}`,
          fragment: gql`
            fragment myTodo on ${entityUpperCase} {
              ${Object.keys(data)
                .filter((k) => !k.startsWith("__"))
                .join(", \n")}
              __typename
            }
          `,
          data: { ...data, __typename: `${entityUpperCase}` },
        });
    } catch (e) {
      if (this.onSubmitError) {
        this.onSubmitError(dataIn);
      }
    }
  };

  getUpdatedValues = (data: any, dataPre: any) => {
    if (!data) return {};
    if (!this.fieldTypes) return data;

    return Object.entries(data).reduce((newData: any, [key, value]: [string, any]) => {
      const realValue =
          (value || value === 0) && ["number", "integer", "decimal"].includes(this.fieldTypes[key]) ? +value : value,
        currentValue = oc(dataPre)[key]();

      if (realValue !== currentValue || key === "step") {
        newData[key] = realValue;
      }

      return newData;
    }, {});
    // console.log(data, nn);
  };

  public update = (
    dataIn: AnyProperties,
    {
      isFromApollo = false,
      isMappingData = false,
      isFromAfter = false,
    }: {
      isFromApollo?: boolean;
      isMappingData?: boolean;
      isFromAfter?: boolean;
    } = {}
  ) => {
    // if (!isFromApollo && !isMappingData && !isFromAfter) console.log(dataIn);
    if (dataIn.cancel) {
      this.onCancel();
      //@ts-ignore
      this.data.cancel = false;
      return;
    }

    const changedFields: string[] = [];
    // const data = this.getUpdatedValues(dataIn);

    const dataPre = this.getUpdatedValues(dataIn, {
      ...this.injectedValues,
      ...this.data,
    });
    const dataNew = this.onUpdateDataMiddleWare
      ? this.getUpdatedValues(
          this.onUpdateDataMiddleWare({
            path: this.path,
            query: this.query,
            variables: this.variables,
            data: { ...this.data, ...dataPre },
            dataBefore: { ...this.data },
            operation: this.treeConfig.operation,
            entity: this.treeConfig.entity,
            changedFields: Object.keys(dataPre),
            change: dataPre,
            origin: isFromApollo ? "store" : "user",
            client: this.client,
            flatTreeData: this.flatTree,
            hiddenFields: this.hiders.reduce<string[]>((hiddenFields, hider) => {
              if (hider.hidden) return hiddenFields.concat(hider.id);
              return hiddenFields;
            }, []),
          }),
          { ...this.data, ...dataPre }
        ) || {}
      : dataPre;

    const prevFieldChanges = Object.keys(dataNew).filter(
      (field) => !noTriggerFieldsCore.includes(field) && dataNew[field] !== this.data?.[field]
    );
    if (!prevFieldChanges.length) return;
    const dataNewLocal = this.onUpdateDataMiddleWareLocal
      ? this.getUpdatedValues(
          this.onUpdateDataMiddleWareLocal({
            path: this.path,
            query: this.query,
            variables: this.variables,
            data: { ...this.data, ...dataNew },
            dataBefore: { ...this.data },
            operation: this.treeConfig.operation,
            entity: this.treeConfig.entity,
            changedFields: Object.keys(dataNew),
            change: dataNew,
            origin: isFromApollo ? "store" : "user",
            client: this.client,
            flatTreeData: this.flatTree,
            hiddenFields: this.hiders.reduce<string[]>((hiddenFields, hider) => {
              if (hider.hidden) return hiddenFields.concat(hider.id);
              return hiddenFields;
            }, []),
          }),
          { ...this.data, ...dataNew }
        ) || {}
      : dataNew;

    const data = { ...dataPre, ...dataNewLocal };

    Object.entries(data).forEach(([name, value]) => {
      if (data[name] === this.data?.[name]) return;
      changedFields.push(name);
      this.updaters.forEach(({ dataId, stateEffects }) => {
        //  if (this.data && this.data.has && this.data[name] === name) {

        if (dataId === name) {
          if (stateEffects.state !== value) {
            console.log;
            stateEffects.setstate(value);
          }
        }
      });
    });
    // if (!changedFields.length) return;
    const dataBefore = { ...this.data };
    this.data = { ...this.data, ...data };
    const toValidateHidden: Hiders[] = [];
    const usedHiders: string[] = [];
    const toAddToIsDisabled: IsDisabled[] = [];
    const usedIsDisabled: string[] = [];
    changedFields.forEach((field) => {
      // console.log(changedFields, this.hiders);

      this.hiders.forEach((hider) => {
        if (hider.dataIds.includes(field) && !usedHiders.includes(hider.id)) {
          toValidateHidden.push(hider);
          usedHiders.push(hider.id);
        }
      });
      this.isDisabled.forEach((disabled) => {
        if (!disabled.dataIds.length) {
          return;
        }

        if (disabled.dataIds.includes(field) && !usedIsDisabled.includes(disabled.id)) {
          toAddToIsDisabled.push(disabled);
          usedIsDisabled.push(disabled.id);
        }
      });
    });
    toValidateHidden.forEach((validator) => {
      // console.log(validator);
      const toHide = validateIsHidden(validator.Hide, {
        ...this.data,
      });

      if (validator.hidden !== toHide) {
        if (!toHide) {
          this.hiddenFields = this.hiddenFields.filter((field) => field !== validator.id);
        } else {
          this.hiddenFields.push(validator.id);
        }
        // console.log(validator.id, toHide);
        validator.setHidden(toHide);
      }
    });

    toAddToIsDisabled.forEach((disabled) => {
      const toDisable = isDisabled(disabled.Disabled, {
        ...this.data,
      });
      // console.log(disabled.id);
      //  if (disabled.disabled !== toDisable) console.log(disabled.id, toDisable);
      if (disabled.disabled !== toDisable) {
        disabled.setDisabled(toDisable);
      }
    });

    if (this.afterChange && changedFields.length && !isFromAfter) {
      this.afterChange({
        path: this.path,
        update: (data: AnyProperties, isFromApollo = false, isMappingData = false, isFromAfter = true) => {
          // console.log(d);
          this.update(data, { isFromApollo, isMappingData, isFromAfter });
        },
        query: this.query,
        variables: this.variables,
        data: this.data,
        dataBefore,
        operation: this.treeConfig.operation,
        entity: this.treeConfig.entity,
        changedFields,
        change: data,
        origin: isFromApollo ? "store" : "user",
        client: this.client,
        flatTreeData: this.flatTree,
        hiddenFields: this.hiders.reduce<string[]>((hiddenFields, hider) => {
          if (hider.hidden) return hiddenFields.concat(hider.id);
          return hiddenFields;
        }, []),
      });
    }
    this.postUpdate({
      isFromApollo,
      isMappingData,
      isFromAfter,
      data,
      changedFields,
      dataBefore,
      dataIn: dataNewLocal,
    });

    if (this.recoilAccess?.[1] && changedFields.length && this.formAtom) {
      getRecoilStore().then((store) => {
        const recoilState = store
          .getSnapshot()
          .getPromise(this.formAtom!)
          .then((old) => {
            const newData = Object.keys(this.data ?? {}).reduce((next, key) => {
              const isSame = equal(this.data?.[key], old?.[key]);
              if (isSame) return next;

              return { ...next, [key]: this.data?.[key] };
            }, {});

            if (Object.keys(newData).length && this.recoilAccess) this.recoilAccess[1]({ ...old, ...newData });
          });
      });
    }
    if (this.data) this.data.commit = false;
  };

  getValidatableFields = async (validateOnLoad: boolean = false) => {
    const remoteFieldSpecs = this.remoteFieldSpecs;
    const fieldsToValidate = this.allFields.concat(this.additionalFields);
    const combinedErrors = this.validateForm
      ? ((await this.validateForm({
          data: this.data,
          allData: this.data,
          fieldsHadValue: this.fieldsHadValue as any,
          fields: Object.keys(this.data ?? {}),
          path: this.path,
          client: this.client,
          injectedValues: this.injectedValues,
          errors: {},
          changedFields: fieldsToValidate,
          fieldsToValidate: fieldsToValidate,
          applyRules,
          remoteFieldSpecs,
          isSubmit: true,
          config: this.config,
          language: this.localization.getLanguage(),
          isInitalVaildation: true,
        })) as Errors<AnyProperties>)
      : {};
    if (validateOnLoad) {
      this.updateFormErrors({ formErrors: combinedErrors, fieldsToValidate, isSubmit: true, toggleAll: true });
    }
    const { formErrorsNew, errorCount } = getErrorInformation({
      combinedErrors,
      isSubmit: false,
      formErrorsPre: this.data?.formErrors || {},
    });
    const formErrorsInital = { ...formErrorsNew, meta: { ...formErrorsNew.meta, isInitialValidation: true } };
    this.update({ formErrors: formErrorsInital, formErrorCount: errorCount });
  };

  getRemoteFieldSpecs = () => {
    const fullDataTypesMapping = { ...dataTypesMapping, ...this.config.dataTypesMapping };
    const currentLanguage = this.localization.getLanguage();
    const fieldDefinitions = this.flatTree.reduce<RemoteFieldSpecs>((fieldDefinitions, { node }) => {
      const { dataId, required, config } = node;

      if (dataId && Object.keys(dataTypesMapping).includes(config.dataType)) {
        fieldDefinitions[dataId] = {
          required: !!this.requiredFields.find((field) => field.name === dataId),
          dataType: fullDataTypesMapping[config.dataType],
          title: config?.title?.[currentLanguage] ?? "",
        };
      }

      return fieldDefinitions;
    }, {} as RemoteFieldSpecs);
    const remoteFieldSpecs = this.requiredFields.reduce<RemoteFieldSpecs>((remoteFieldSpecs, field) => {
      if (!remoteFieldSpecs[field.name]) {
        remoteFieldSpecs[field.name] = {
          required: true,
          dataType: field.dataType,
          title: field.title,
        };
      }
      return remoteFieldSpecs;
    }, fieldDefinitions);

    return remoteFieldSpecs;
  };

  public evaluateFormErrors = async ({
    dataIn,
    data,
    changedFields,
    fieldsToValidate,
  }: {
    data: AnyProperties;
    dataIn: AnyProperties;
    changedFields: string[];
    fieldsToValidate: string[];
  }) => {
    const formErrorsPre = data?.formErrors?.errors ?? {};
    const isSubmit =
      (Object.keys(data).includes("submit") && data.submit) ||
      (Object.keys(dataIn).includes("submit") && dataIn.submit);
    const isSubmitReset = Object.keys(dataIn).includes("submit") && !dataIn.submit;

    const allData = { ...this.data, ...data, ...dataIn };
    const dataf = { ...data, ...dataIn };

    if (isSubmitReset) return { errors: {} };
    const remoteFieldSpecs = this.remoteFieldSpecs;
    const formErrors = this.validateForm
      ? ((await this.validateForm({
          data: { ...data, ...dataIn },
          allData: { ...this.data, ...data, ...dataIn },
          fieldsHadValue: this.fieldsHadValue as any,
          fields: Object.keys(dataIn),
          path: this.path,
          client: this.client,
          injectedValues: this.injectedValues,
          errors: formErrorsPre as AnyProperties,
          changedFields,
          fieldsToValidate: isSubmit ? [...fieldsToValidate, "submit"] : fieldsToValidate,
          applyRules,
          remoteFieldSpecs,
          isSubmit,
          config: this.config,
          language: this.localization.getLanguage(),
          isInitalVaildation: false,
        })) as Errors<AnyProperties>)
      : {};
    this.updateFormErrors({ formErrors, fieldsToValidate, isSubmit });
    const cleanedErrors = Object.entries(formErrors).reduce((cleanedErrors, [key, value]) => {
      if (value?.messages?.length) {
        return { ...cleanedErrors, [key]: value };
      }
      return cleanedErrors;
    }, {} as Errors<AnyProperties>);
    return { errors: cleanedErrors };
  };

  updateFormErrors = async ({
    formErrors,
    fieldsToValidate,
    isSubmit,
    toggleAll = false,
  }: {
    formErrors: Errors<any>;
    fieldsToValidate: string[];
    isSubmit: boolean;
    toggleAll?: boolean;
  }) => {
    const formErrorsPre = this.data?.formErrors || {};
    const previousFormErrors = formErrorsPre?.errors || {};
    const errorUpdates = diff(previousFormErrors, formErrors);
    const fieldsWithErrorUpdates = toggleAll || isSubmit ? Object.keys(formErrors) : Object.keys(errorUpdates);
    const validFieldsWithErrorUpdates = fieldsWithErrorUpdates.filter(
      (field) => fieldsToValidate.includes(field) || isSubmit
    );
    const combinedErrors = { ...previousFormErrors, ...formErrors } as Errors<any>;
    this.errors.forEach((error) => {
      if (validFieldsWithErrorUpdates.includes(error.dataIds)) {
        const errorsForField = Object.entries(formErrors).reduce((errorsForField, [key, value]) => {
          if (key === error.dataIds) {
            const { messages, ...rest } = value ?? { messages: [] };
            return errorsForField.concat(messages);
          }
          return errorsForField;
        }, [] as RuleResult[]);

        error.setErrors(errorsForField);
      } else {
        if (error.errors.length) error.setErrors([]);
      }
      if (error.dataIds === "submit") {
        const allErrors = Object.values(combinedErrors).reduce((allErrors, error) => {
          return allErrors.concat(error?.messages ?? []);
        }, [] as RuleResult[]);
        error.setErrors(allErrors);
      }
    });
    const { formErrorsNew, errorCount } = getErrorInformation({
      combinedErrors,
      isSubmit,
      formErrorsPre: this.data?.formErrors || {},
    });

    this.update({ formErrors: formErrorsNew, formErrorCount: errorCount ?? 0 });
  };

  public postUpdate = async ({
    isFromApollo,
    isMappingData,
    isFromAfter,
    data,
    changedFields,
    dataBefore,
    dataIn,
  }: {
    isFromApollo: boolean;
    isMappingData: boolean;
    isFromAfter: boolean;
    data: AnyProperties;
    dataIn: AnyProperties;
    changedFields: string[];
    dataBefore: { [x: string]: any };
  }) => {
    const isSubmit = changedFields.includes("submit") && !dataIn.submit;
    const isSubmitReset = Object.keys(dataIn).includes("submit") && !data.submit;
    const formErrorsPre = data?.formErrors?.errors ?? {};
    if (isSubmitReset || isFromApollo) return;

    const changedFieldsClean = (isSubmit ? Object.keys({ ...dataBefore, ...data, ...dataIn }) : changedFields).filter(
      (field) => !noTriggerFields.includes(field)
    );
    const fieldsWithErrors = Object.entries(formErrorsPre).reduce<string[]>((fieldsWithErrors, [key, value]) => {
      //@ts-ignore
      if (value?.messages?.length) {
        return fieldsWithErrors.concat(key);
      }
      return fieldsWithErrors;
    }, []);

    const changedFieldsCombined = changedFieldsClean.length ? changedFieldsClean.concat(fieldsWithErrors) : [];
    const fieldsThatTriggerValidation = changedFieldsCombined.reduce((fieldsThatTriggerValidation, field) => {
      if (this.validationTriggerFields?.[field]) {
        return fieldsThatTriggerValidation.concat(this.validationTriggerFields[field]);
      }
      return fieldsThatTriggerValidation;
    }, changedFieldsCombined);

    const fieldsToValidate = fieldsThatTriggerValidation.filter((field) => {
      const existingErrorsForFieldLenght =
        formErrorsPre[field]?.messages.length || (dataBefore?.formErrors?.errors ?? {})[field]?.messages.length;
      if (isSubmit) return true;
      if (existingErrorsForFieldLenght) return true;
      if (!this.focusedField) return true;
      if (this.focusedField?.id === field && this.focusedField.hasErrorsPreFocus) {
        return true;
      }
      return false;
    });
    const formErrors = !!fieldsToValidate.length
      ? await this.evaluateFormErrors({
          data: dataBefore ?? {},
          dataIn: data,
          changedFields,
          fieldsToValidate,
        })
      : dataBefore.formErrors ?? {};

    this.fieldsHadValue = changedFields.reduce<string[]>((fieldsHadValue, field) => {
      const data = this.data ?? {};
      if (!this.fieldsHadValue.includes(field) && (data[field] || data[field] === 0)) {
        return fieldsHadValue.concat(field);
      }
      return fieldsHadValue;
    }, this.fieldsHadValue);

    if (Object.keys(formErrors?.errors ?? {}).length) {
      if (this.touchedFields.includes("submit")) this.update({ submit: false });
      return;
    }
    if (
      !!(
        (!isFromApollo &&
          !isMappingData &&
          !isFromAfter &&
          data.commit &&
          this.tree!.config.saveOnChange !== false &&
          changedFields.length &&
          this.treeConfig.operation === "update") ||
        data.submit
      )
    ) {
      const dataToWrite: Record<string, any> = {};
      if (data.submit) {
        Object.keys(dataBefore).forEach((field) => {
          // @ts-ignore
          dataToWrite[field] = dataBefore[field];
        });
      } else {
        changedFields.forEach((field) => {
          // @ts-ignore
          dataToWrite[field] = data[field];
        });
      }

      this.mutate({
        // data: data.id ? dataToWrite : { ...dataToWrite, id: data.id },
        data: dataToWrite,
        fields: changedFields,
      });
    }
  };

  public getForm({ path, tree, treeConfig }: { path: string; tree: TreeElement; treeConfig: any }): FormComponent {
    this.tree = tree;
    this.path = path;
    this.flatTree = getFlatData([tree]) as FlatDataItemFull[];
    this.additionalFields = getAddtitonalFieldNames({
      path: path,
      config: this.config,
    });
    this.treeConfig = treeConfig;
    this.query = generateQeuery(
      treeConfig,
      this.clientFields,
      this.externalFields.concat(this.additionalFields),
      this.useHC11
    );
    this.fieldTypes = treeConfig.usedFieldTypes;
    if (!tree.children) {
      return () => <div />;
    }

    const wholeTree = this.buildTree({ tree, treeConfig, disabled: false });

    return wholeTree;
  }

  private connectRecoil({ stateAtom }: { stateAtom: RecoilState<FormStateExtended | undefined> }) {
    const setState = useSetRecoilState(stateAtom);
    if (!this.recoilAccess) {
      this.recoilAccess = [undefined, setState];
    }
    return null;
  }

  public getComponentProps = (tree: TreeElement) => {
    const dataId = tree.dataId ? tree.dataId : undefined;
    // if (tree.config.uiTypeDisplay === "HiddenField") return {};
    const { config } = tree,
      {
        dataType,
        uiType,
        injectRaftValues,
        uiTypeDisplay,
        Hide,
        disabled: isDisabledIn = { disabled: "Inherit" },
        raftDefaultValue,
        ...params
      } = config;

    const isInitalHidden = this.injectedValues
      ? validateIsHidden(
          Hide || {
            Hide: "No",
            rulesFields: [],
          },
          {
            ...this.injectedValues,
            ...this.data,
          }
        )
      : uiType === "GFCFieldSet_Stepper" || Hide.Hide === "Yes";
    if (isInitalHidden && !this.hiddenFields.includes(tree.id)) {
      this.hiddenFields.push(tree.id);
    }
    const [hidden, setHidden] = React.useState(isInitalHidden);

    const hide = {
      hidden,
      setHidden,
      dataIds: Hide.rulesFields || [],
      id: tree.id,
      Hide,
    };

    const hidersIndex = this.hiders.findIndex((u) => u.id === tree.id);
    if (hidersIndex > -1) {
      this.hiders[hidersIndex] = hide;
    } else {
      this.hiders.push(hide);
    }

    const [disabled, setDisabled] = React.useState<boolean | undefined>(
      isDisabledIn.disabled === "Inherit"
        ? undefined
        : isDisabled(isDisabledIn, {
            ...this.injectedValues,
            ...this.data,
          })
    );

    const isDisabledObj: IsDisabled = {
      disabled,
      setDisabled,
      dataIds: isDisabledIn.rulesFields || [],
      id: tree.id,
      Disabled: isDisabledIn,
    };

    const isDisabledIndex = this.isDisabled.findIndex((u) => u.id === tree.id);
    if (isDisabledIndex > -1) {
      this.isDisabled[isDisabledIndex] = isDisabledObj;
    } else {
      this.isDisabled.push(isDisabledObj);
    }

    const cleanedParams = cleanParams(params, this.config, this.localization, this);
    const [errors, setErrors] = React.useState<string[]>([]);
    if (cleanedParams.validate && cleanedParams.validate.Validate !== "No") {
      this.validate = (data: any) =>
        validateForm(cleanParams(params, this.config, this.localization, this).validate, data);
    } else {
      const validate: HasError = {
        errors: [],
        setErrors,
        dataIds: dataId || "#nope",
        id: tree.id,
      };

      const validatorsIndex = this.errors.findIndex((u) => u.id === tree.id);
      if (validatorsIndex > -1) {
        this.errors[validatorsIndex] = validate;
      } else {
        this.errors.push(validate);
      }
    }
    const testObj =
      process.env.NODE_ENV !== "test" && process.env.REACT_APP_FOR_TEST !== "true"
        ? {}
        : {
            testId: `${this.treeConfig.operation}${upperFirst(this.treeConfig.entity)}_${dataId}_${uiType?.replace(
              /GFCField*_/i,
              ""
            )}`,
          };
    const props = {
      hidden,
      disabled,
      raftQuery: this.query,
      raftVariables: this.variables,
      raftId: tree.id,
      id: dataId,
      fieldId: dataId,
      errors,
      isRequiredField: !!tree.required,
      ...testObj,
      ...cleanedParams,
      formAtom: this.formAtom!,
      getFieldValue: connectField<FormStateExtended, string>(this.formAtom!),
      update: (val: any) => this.update(val),
    };
    // console.log(dataId, hidden);

    const propModifyName = (uiType ?? "").replace("GFCField_", "").replace("GFCFieldSet_", "").replace("GFCForm_", "");
    if (this.modifyProps && this.modifyProps.hasOwnProperty(propModifyName ?? "") && uiType) {
      const { Render, ...modified } = this.modifyProps[propModifyName](
        Object.freeze({ ...props, tree, getFormData: this.showData })
      );

      return {
        ...props,
        ...modified,
        modifiedProps: modified && Object.keys(modified).length,
        Render,
      };
    }
    return props;
  };

  private getFieldState = (tree: TreeElement) => {
    const getDefaultValue = (type?: string) => {
      switch (type) {
        case "bridge":
          return [];
        default:
          return undefined;
      }
    };
    const dataId = tree.dataId ? tree.dataId : undefined;
    const { config } = tree,
      {
        dataType,
        uiType,
        injectRaftValues,
        uiTypeDisplay,
        Hide,
        disabled: isDisabledIn = { disabled: "Inherit" },
        raftDefaultValue,
        ...params
      } = config;
    const isMapping = dataType === "bridge",
      valuePre = this.data && dataId && this.data[dataId] !== null ? this.data[dataId] : undefined;

    let value = valuePre ?? tree.config?.defaultValue ?? getDefaultValue(dataType);
    if (dataId) {
      const defaultValue = oc(tree).config.raftDefaultValue(oc(tree).config.defaultValue(this.defaultValues[dataId]));

      if (defaultValue !== null || defaultValue != undefined) {
        this.defaultValues[dataId] = defaultValue;
        if ((valuePre === null || valuePre === undefined) && defaultValue !== null && defaultValue !== undefined) {
          value = defaultValue;
        }
      }
    }
    const saveData = !dataId
      ? (v: any) => {}
      : (v: any) => {
          this.saveData(v);
        };
    const stateEffects = fieldState({
      valueIn: value,
      idPre: dataId || "dd",
      saveData,
      required: !!tree.required,
      delayCommitIn: params.valuePropagation?.delaypPropagate,
      commitOnBlur: params.valuePropagation?.propagateOnBlur,
      onDefocus: () => this.setTouchedFields(dataId || "dd"),
    });
    if (dataId && tree.config.dataType !== "bridge") {
      const updatersIndex = this.updaters.findIndex((u) => u.id === tree.id);
      if (updatersIndex > -1) {
        this.updaters[updatersIndex] = { dataId, stateEffects, id: tree.id };
      } else {
        this.updaters.push({ dataId, stateEffects, id: tree.id });
      }
      // console.log(dataId, this.updaters.length);

      if (isMapping) {
        return { state: [] };
      } else {
        return stateEffects;
      }
    }
  };

  public getData = (variables: any) => {
    this.variables = variables;
    if (["update", "read"].includes(this.treeConfig.operation)) {
      try {
        const { result } = this.client.readQuery({
          query: gql(this.query),
          variables,
        }) || { result: null };

        this.update({ ...result }, { isFromApollo: true });
      } catch (e) {
        // console.log("cache empty", e);
      }
      this.client
        .query({
          query: gql(this.query),
          variables,
          fetchPolicy: "network-only",
        })
        .then(
          (
            result = {
              loading: false,
              networkStatus: 7,
              // stale: true,
              data: {
                result: {},
              },
            }
          ) => {
            // console.log(result);
            const defaultData = Object.entries({
              ...this.defaultValues,
              ...variables,
            }).reduce((newDefaultValues: { [key: string]: any }, [key, value]) => {
              if (noTriggerFieldsCore.includes(key)) return newDefaultValues;
              if (
                //@ts-ignore
                (result.data.result[key] == null ||
                  //@ts-ignore
                  result.data.result[key] == undefined) &&
                value !== null &&
                value !== undefined
              ) {
                return { ...newDefaultValues, [key]: value };
              }
              return newDefaultValues;
            }, {});

            this.update({ ...result.data.result, ...defaultData }, { isFromApollo: true });

            if (Object.keys(defaultData).length) {
              this.mutate({
                data: { ...defaultData },
                fields: Object.keys(defaultData),
                isDefaultData: true,
              });
            }
          }
        )

        .catch((e: unknown) => console.log("err", e, this.query, variables));
    }
  };

  private getisFieldLiveValidated = (dataId: string = "") => {
    const validationRules = this.validationRules ?? {};
    const fieldValidatonCofigs = validationRules[dataId];
    if (!fieldValidatonCofigs) return false;
    if (
      fieldValidatonCofigs.some(
        (fieldValidatonCofig) => typeof fieldValidatonCofig === "object" && fieldValidatonCofig.liveValidation
      )
    )
      return true;
    return false;
  };

  private buildTree = (treeProps: {
    tree: TreeElement;
    calls?: number;
    treeConfig?: any;
    disabled?: boolean;
  }): React.ComponentType<any> => {
    const { tree, calls = 0, treeConfig = {}, disabled: disabledPre = false } = treeProps;

    const { Component, RenderChilds } = this.getElement(tree);

    if (tree.config.uiType === "HiddenField") {
      return () => null;
    }

    //  return () => null;

    if (!tree.children) {
      const LL = React.memo(
        () => {
          const {
            hidden,
            disabled: disabledInner,
            Render,
            overrides,
            modifiedProps,
            ...newPropsPre
          } = this.getComponentProps(tree);
          const setHasFocus = (hasFocus: boolean) => {
            if (hasFocus) {
              this.focusedField = {
                id: tree.dataId || tree.id,
                hasErrorsPreFocus: !!newPropsPre.errors.length || this.getisFieldLiveValidated(tree.dataId),
              };
            } else {
              this.focusedField = undefined;
              if (this.data && tree.dataId)
                this.evaluateFormErrors({
                  data: this.data,
                  dataIn: this.data,
                  changedFields: [tree.dataId],
                  fieldsToValidate: [tree.dataId],
                });
            }
          };
          const errorBuilder = errorManager({
            errors: newPropsPre.errors,
            setHasFocus,
          });
          const data = this.getFieldState(tree);
          const disabled = disabledInner !== undefined ? disabledInner : disabledPre;
          const newProps = { ...newPropsPre, data, disabled, errorManager: errorBuilder };

          const CompOverride = useComposeOverride({
            Render,
            Component,
            overrides,
            newProps: { ...newPropsPre, disabled, errorManager: errorBuilder },
          });

          if (!!hidden) {
            return null;
          }
          return (
            <>
              <Inspect
                tree={tree}
                treeConfig={this.treeConfig}
                isField
                customRender={!!Render}
                overrides={!!overrides}
                modifiedProps={modifiedProps}
                props={{
                  ...newProps,
                  Component: CompOverride,
                  disabled,
                }}
              />

              <CompOverride {...newProps} disabled={disabled} />
            </>
          );
        },
        () => true
      );
      return LL;
    }
    return React.memo(
      (props: {
        params: DataItem[] | undefined;
        injectedValues?: any;
        afterCreate?: (data: any) => void;
        afterUpdate?: (data: any) => void;
        onSubmitError?: (data: any) => void;
        onUpdateDataMiddleWare?: (data: OnUpdateDataMiddleWareProps<any>) => any;
        beforeRemoteMuatation?: BeforeRemoteMuatation;
        validateForm?: ValidateForm<any, any>;
        validationRules?: Rules<any, any>;
        validationTriggerFields?: Record<string, string[]>;
        afterDelete?: (data: any) => void;
        modifyProps?: { [key: string]: (val: any) => any };
        onCancel?: () => void;
        updateAfterCreateQueries?: QueryArray;
        updateAfterDeleteQueries?: QueryArray;
        fetchAllFieldsOnUpdate?: boolean;
        fetchOnlyId?: boolean;
        validateOnLoad?: boolean;
      }) => {
        if (calls === 0) {
          if (props.afterCreate) {
            this.afterCreate = props.afterCreate;
          }
          if (props.onCancel) {
            this.onCancel = props.onCancel;
          }
          if (props.afterUpdate) {
            this.afterUpdate = props.afterUpdate;
          }
          if (props.onUpdateDataMiddleWare) {
            this.onUpdateDataMiddleWareLocal = props.onUpdateDataMiddleWare;
          }
          if (props.beforeRemoteMuatation) {
            this.beforeRemoteMuatationLocal = props.beforeRemoteMuatation;
          }
          if (props.validateForm) {
            this.validateForm = props.validateForm;
          }
          if (props.validationRules) {
            this.validationRules = props.validationRules;
          }
          if (props.afterDelete) {
            this.afterDelete = props.afterDelete;
          }
          if (props.onSubmitError) {
            this.onSubmitError = props.onSubmitError;
          }
          if (props.modifyProps) {
            this.modifyProps = props.modifyProps;
          }
          if (props.updateAfterCreateQueries) {
            this.updateAfterCreateQueries = props.updateAfterCreateQueries;
          }
          if (props.updateAfterCreateQueries) {
            this.updateAfterCreateQueries = props.updateAfterCreateQueries;
          }
          if (props.fetchAllFieldsOnUpdate) {
            this.fetchAllFieldsOnUpdate = props.fetchAllFieldsOnUpdate;
          }
          if (props.fetchOnlyId) {
            this.fetchOnlyId = props.fetchOnlyId;
          }
          if (props.validateOnLoad) {
            this.validateOnLoad = props.validateOnLoad;
          }
          if (props.validationTriggerFields) {
            this.validationTriggerFields = props.validationTriggerFields;
          }
          this.remoteFieldSpecs = this.getRemoteFieldSpecs();
          this.params = props.params;
          this.injectedValues = props.injectedValues;
          this.data = { ...props.injectedValues, step: 0 };
          this.getData(props.params);
          //@ts-ignore
          this.formAtom = makeFormState(
            //@ts-ignore
            `${this.path.replace("tree/", "").replace(/\//g, ".")}.${
              //@ts-ignore
              this.path.includes("update") ? this.params.id : "current"
              // this.path.includes("update") ? id ?? this.params.id : "current"
            }`
          );
          if (this.validateOnLoad)
            setTimeout(() => {
              this.getValidatableFields(props.validateOnLoad).then((r) => {});
            }, 200);
          this.getValidatableFields(props.validateOnLoad).then((r) => {});
        }
        const {
          hidden,
          disabled: disabledInner,
          Render,
          modifiedProps,
          overrides,
          ...newProps
        } = this.getComponentProps(tree);

        // const { disabled: disabledInner, hidden, ...newProps } = this.getComponentProps(tree);
        // console.log(tree);
        // } = useGetComponentProps(tree, this);
        const disabled = disabledInner ? disabledInner : disabledPre;
        const Comp = useComposeOverride({ Render, Component, overrides, newProps: { ...newProps, disabled } });

        if (tree.config.uiType === "HiddenField") {
          return null;
        }
        if (!!hidden) {
          return (
            <>
              <Inspect
                tree={tree}
                treeConfig={this.treeConfig}
                showData={this.showData}
                customRender={!!Render}
                overrides={!!overrides}
                modifiedProps={modifiedProps}
                props={{
                  ...newProps,
                  Component: Comp,
                  disabled,
                }}
              />
              <div style={{ display: "none" }}>
                <Comp {...newProps} disabled={disabled}>
                  {tree.children &&
                    tree.children.map((child: any, index: number) => {
                      const ChildInner = this.buildTree({
                        tree: child,
                        calls: calls + 1,
                        treeConfig,
                        disabled,
                      });
                      return <ChildInner key={index} id={child.id} />;
                    })}
                </Comp>
              </div>
              {!calls && <Loader />}
            </>
          );
        }
        if (calls === 0 && !!this.formAtom) this.connectRecoil({ stateAtom: this.formAtom });
        return (
          <>
            {calls === 0 && !!this.formAtom && (
              <>
                {/* <DeFocusObserver onDeFocus={console.log} /> */}
                <RecoilConnector stateAtom={this.formAtom} update={this.update} getData={this.showData} />
              </>
            )}
            <Inspect
              tree={tree}
              treeConfig={this.treeConfig}
              showData={this.showData}
              customRender={!!Render}
              modifiedProps={modifiedProps}
              props={{
                ...newProps,
                Component: Comp,
                disabled,
              }}
            />
            <Comp {...newProps} disabled={disabled}>
              {tree.children &&
                tree.children.map((child: any, index: number) => {
                  const ChildInner = this.buildTree({
                    tree: child,
                    calls: calls + 1,
                    treeConfig,
                    disabled,
                  });
                  return <ChildInner key={index} includedFields={child.config.includedFields || []} tree={child} />;
                })}
            </Comp>
          </>
        );
      }
    );
  };
}

export default FormGenerator;
