import { getMsalUser } from "authentication";
import { hasScope } from "authorization";
import ServerSideSingleSelect from "components/templates/ServerSideSingleSelect";
import { useEffect, useState } from "react";
import useGetTenantOptions from "./useGetTenantOptions";
import { SelectItem } from "../MultiSelectFreeSolo";
import { useGetUsersLazyQuery } from "graphqlBase/userManagement/user/__generated__/getUsers";

interface TenantSelectFreeSoloProps {
  handleSaveTenant: (tenant: SelectItem | null) => void;
  pickDefaultFirst?: boolean;
  disabled?: boolean;
  label?: string;
  value?: string;
}

const TenantSelectFreeSolo: React.FC<TenantSelectFreeSoloProps> = ({
  pickDefaultFirst,
  disabled,
  handleSaveTenant,
  label,
  value,
}) => {
  const [selectedTenant, setSelectedTenant] = useState<SelectItem | null>(null);
  const { queryTenants, getSelectOptions, loading } = useGetTenantOptions(value);
  const [options, setOptions] = useState<SelectItem[] | undefined>();
  const user = getMsalUser();
  const [getUserQuery, { data: userData, loading: userLoading }] = useGetUsersLazyQuery({
    variables: { where: { email: { eq: (user?.idTokenClaims?.email as string) ?? "" } } },
  });

  const canSeeTenants = hasScope("User.Update.All");

  useEffect(() => {
    if (!canSeeTenants) getUserQuery();
    return () => setSelectedTenant(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getSelectOptions()
      .then((selectOptions) => {
        if (selectOptions) setOptions(selectOptions);
        if (!canSeeTenants) {
          const userTenant = {
            value: userData?.users?.items?.[0]?.tenant?.id ?? "",
            label: userData?.users?.items?.[0]?.tenant?.tenantName ?? "",
          };
          setSelectedTenant(userTenant);
          handleSaveTenant(userTenant);
        } else {
          const preSelected = selectOptions?.find((item) => item.value === value);
          if (!preSelected) return;
          setSelectedTenant(preSelected);
        }
      })
      .catch((error) => console.debug(error));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, canSeeTenants, userData]);

  useEffect(() => {
    if (pickDefaultFirst && !value && !!options && options.length) {
      setSelectedTenant(options[0]);
      handleSaveTenant(options[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options, value, pickDefaultFirst]);

  const fetchBySearchValueOptions = (searchValue?: string) => {
    queryTenants(searchValue ? { tenantName: { contains: searchValue } } : {})
      .then((result) => {
        if (!result?.length) return;
        setOptions(result);
      })
      .catch((error) => {
        console.debug(error);
      });
  };

  const handleSaveSelectedValue = (value: SelectItem | null) => {
    if (!value) {
      setSelectedTenant(null);
      handleSaveTenant(null);
    } else {
      setSelectedTenant(value);
      handleSaveTenant(value);
    }
  };

  return (
    <ServerSideSingleSelect
      options={options}
      fetchOptions={fetchBySearchValueOptions}
      loading={loading}
      label={label ?? ""}
      handleSaveSelectedValue={handleSaveSelectedValue}
      disabled={userLoading || !canSeeTenants || disabled || false}
      selected={selectedTenant}
    />
  );
};

export default TenantSelectFreeSolo;
