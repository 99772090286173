import React, { useState } from "react";
import translations from "translations";
import { StyledAddButton } from "../styles";
import { AddNewNormBridgeProps } from "./AddNewNormBridge";
import CreateNormModal from "./CreateNormModal";
interface Props extends AddNewNormBridgeProps {}

const CreateNorm: React.FC<Props> = (props) => {
  const [openModal, setOpenModal] = useState(false);

  const openNormModal = () => {
    setOpenModal(true);
  };

  return (
    <>
      <StyledAddButton onClick={openNormModal}>{translations.globals.button.addNorm}</StyledAddButton>
      <CreateNormModal {...props} openModal={openModal} setOpenModal={setOpenModal} title="Add Norm" />
    </>
  );
};

export default CreateNorm;
