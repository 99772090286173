import { nextLine } from "components/mlpaUiComponents/LegalNote/lib";

export default {
  accessPermissionManagement: "Zugangsberechtigungsverwaltung",
  table: {
    column: {
      certificateName: "Zertifikatsnummer",
      requestedOn: "Angefordert am",
      tenantName: "Organisation",
      tenantRole: "Rolle der Organisation",
      status: "Status",
      accessRequestedTo: "Angeforderter Zugriff auf",
      typeOfRequestedData: "Art der angeforderten Daten",
      approvedBy: "Genehmigt/Abgelehnt von",
    },
  },
  gridConnectionValues: "Netzanschlusswerte",
  openRequest: "offene Anfrage",
  buttons: {
    approveRequest: "Anfrage(n) genehmigen",
    denyRequest: "Anfrage(n) ablehnen",
  },
  modal: {
    approveTitle: "Anfrage(n) genehmigen",
    denyTitle: "Anfrage(n) ablehnen",
    approveBody: `Sind Sie sicher, dass Sie diese Zugriffsanfrage(n) genehmigen möchten?${nextLine()}Nach der Genehmigung erhalten die Benutzer des/der Mandanten Zugriff auf die Daten. Der Zugriff kann bei Bedarf jederzeit widerrufen werden.`,
    denyBody: `Sind Sie sicher, dass Sie diese Zugriffsanfrage(n) ablehnen möchten?${nextLine()}Der/die anfragende/n Benutzer/in wird/werden über Ihre Entscheidung per E-Mail informiert. Sie können bei Bedarf jederzeit einen neuen Zugriffsantrag stellen.`,
    confirmApprove: "Anfrage(n) genehmigen",
    confirmDeny: "Anfrage(n) ablehnen",
  },
  filters: {
    status: "Status",
    typeOfRequestedData: "Art der angeforderten Daten",
  },
};
