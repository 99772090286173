/* eslint-disable filenames/match-exported */
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { IconButton } from "@mui/material";
import * as React from "react";
import Props from "./index.d";
const CancelIcon: React.FC<Props> = (props) => {
  const {
    disabled,

    className,
    data: { setAndSave },
  } = props;

  return (
    <IconButton onClick={() => setAndSave(true)} disabled={disabled} className={className}>
      <CloseOutlinedIcon />
    </IconButton>
  );
};

export default CancelIcon;
