import * as React from "react";
import GFCFieldSet_UnitFieldWrapper from "./index.d";
import {
  StyledInputContainer,
  StyledTitle,
  StyledTitleBox,
  StyledTitleContainer,
  StyledUnitFieldWrapper,
} from "./styles";

interface UnitFieldWrapperProps extends GFCFieldSet_UnitFieldWrapper {
  children: React.ReactNode;
}

const UnitFieldWrapper: React.FC<UnitFieldWrapperProps> = (props) => {
  return (
    <StyledUnitFieldWrapper>
      {props.title && (
        <StyledTitleContainer>
          <StyledTitleBox>
            <StyledTitle>{props.title}</StyledTitle>
          </StyledTitleBox>
        </StyledTitleContainer>
      )}
      <StyledInputContainer>{props.children}</StyledInputContainer>
    </StyledUnitFieldWrapper>
  );
};

export default UnitFieldWrapper;
