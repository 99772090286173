import { getCertificateCreateFieldSelector } from "raft/CertificateForm";
import { useCallback, useEffect, useMemo } from "react";
import { SetterOrUpdater, useSetRecoilState } from "recoil";
import useGetItemtext from "../SoftwareBridgeList/useGetItemtext";

const buildSoftwSoftwareList =
  (getItemtext: (id: string) => string) =>
  (softwsoftwareBridgeIds: string[]): string => {
    const softwsoftwareNumbers = softwsoftwareBridgeIds
      .map((id) => {
        return getItemtext(id);
      })
      .join(",");
    return softwsoftwareNumbers;
  };

export const useManageSoftwSoftwarelist = (useBridgeArrayState: () => [string[], SetterOrUpdater<string[]>]) => {
  const __softwsoftwareListAtom = useMemo(
    () => getCertificateCreateFieldSelector({ formVersion: "active" })("__softwareVersion"),
    []
  );

  const setSoftwSoftwareList = useSetRecoilState(__softwsoftwareListAtom);
  const getItemtext = useGetItemtext();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getLables = useCallback(buildSoftwSoftwareList(getItemtext), [getItemtext, buildSoftwSoftwareList]);
  const [softwsoftwareBridgeIds] = useBridgeArrayState();

  useEffect(() => {
    setSoftwSoftwareList((prev) => {
      return getLables(softwsoftwareBridgeIds);
    });
  }, [softwsoftwareBridgeIds, getLables, setSoftwSoftwareList]);
};
