import type * as Types from '../../../types';

import client from "apollo/client";
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetTenantByIdQueryVariables = Types.Exact<{
  id: Types.Scalars['UUID'];
}>;


export type GetTenantByIdQuery = { __typename?: 'Query', tenant?: { __typename?: 'Tenant', tenantRole: Types.TenantRole } | null };


        export type ExecGetTenantByIdOptions = Omit<
        Apollo.QueryOptions<
        GetTenantByIdQueryVariables, GetTenantByIdQuery
        >,
        "query"
      >;
    export const execGetTenantByIdQuery = (options: ExecGetTenantByIdOptions) =>
    client.query< GetTenantByIdQuery, GetTenantByIdQueryVariables>({
      ...options,
      query: GetTenantByIdDocument,
    });
    

export const GetTenantByIdDocument = gql`
    query getTenantById($id: UUID!) {
  tenant(id: $id) {
    tenantRole
  }
}
    `;

/**
 * __useGetTenantByIdQuery__
 *
 * To run a query within a React component, call `useGetTenantByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTenantByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTenantByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTenantByIdQuery(baseOptions: Apollo.QueryHookOptions<GetTenantByIdQuery, GetTenantByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTenantByIdQuery, GetTenantByIdQueryVariables>(GetTenantByIdDocument, options);
      }
export function useGetTenantByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTenantByIdQuery, GetTenantByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTenantByIdQuery, GetTenantByIdQueryVariables>(GetTenantByIdDocument, options);
        }
export type GetTenantByIdQueryHookResult = ReturnType<typeof useGetTenantByIdQuery>;
export type GetTenantByIdLazyQueryHookResult = ReturnType<typeof useGetTenantByIdLazyQuery>;
export type GetTenantByIdQueryResult = Apollo.QueryResult<GetTenantByIdQuery, GetTenantByIdQueryVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
          export type ResultType<T> = Types.ResultType<T>;
          export type PaginatedResultType<T> = Types.PaginatedResultType<T>;
          export type NonMaybe<T> = Types.NonMaybe<T>;
          export type Maybe<T> = Types.Maybe<T>;