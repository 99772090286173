import Skeleton from "@mui/material/Skeleton";

export default () => {
  return (
    <Skeleton
      animation={false}
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "90%",
        height: "90%",
        margin: "5%",
      }}
    />
  );
};
