export default {
  columns: {
    createdAt: "Uploaded",
    createdBy: "Uploaded By",
    description: "Description",
    id: "ID",
    importedAt: "Imported At",
    importedBy: "Imported By",
    isDefaultRole: "is default role",
    isDeleted: "is deleted",
    modifiedAt: "Last Edit",
    modifiedBy: "Edited By",
    name: "Name",
  },
  name: "Role",
  plural: "Roles",
  tenantRole: {
    "d0e0b6a0-0b1a-4b9e-9b0e-1b8b8b8b8b8b": "Network Operator",
    "6cd73197-8f65-4a4c-980f-1d733fe00db4": "Manufacturer",
    "6cd73198-8f65-4a4c-980f-1d733fe00db4": "Certification Body",
    "2e2f2352-7a98-404c-9cae-26a71989a872": "Tenant Admin",
    "c73d5bc3-656a-4fdc-90f1-29655ad3c855": "Platform Operator",
    "0ebea863-d6ed-481a-bcd7-74f80366bc75": "Planner",
    "6e2f2352-7a98-404c-9cae-26a71989a872": "Access Request Manager",
  },
};
