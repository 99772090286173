import { styled } from "@mui/material";

const ClampedText = styled("span")({
  display: "-webkit-box",
  WebkitLineClamp: 1,
  WebkitBoxOrient: "vertical",
  overflow: "hidden",
  wordWrap: "break-word",
});

export default ClampedText;
