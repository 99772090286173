import type * as Types from '../../types';

import client from "apollo/client";
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetCertificateNormIssueDatesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetCertificateNormIssueDatesQuery = { __typename?: 'Query', certificateNormIssueDates?: { __typename?: 'CertificateNormIssueDateCollectionSegment', items?: Array<{ __typename?: 'CertificateNormIssueDate', id: string, certificateNormIssueDate: string, certificateNormBridges: Array<{ __typename?: 'CertificateNormBridge', id: string, certificateNormId: string, certificateNorm: { __typename?: 'CertificateNorm', id: string, certificateNormName: string } }> }> | null } | null };


        export type ExecGetCertificateNormIssueDatesOptions = Omit<
        Apollo.QueryOptions<
        GetCertificateNormIssueDatesQueryVariables, GetCertificateNormIssueDatesQuery
        >,
        "query"
      >;
    export const execGetCertificateNormIssueDatesQuery = (options: ExecGetCertificateNormIssueDatesOptions) =>
    client.query< GetCertificateNormIssueDatesQuery, GetCertificateNormIssueDatesQueryVariables>({
      ...options,
      query: GetCertificateNormIssueDatesDocument,
    });
    

export const GetCertificateNormIssueDatesDocument = gql`
    query getCertificateNormIssueDates {
  certificateNormIssueDates {
    items {
      id
      certificateNormIssueDate
      certificateNormBridges {
        id
        certificateNormId
        certificateNorm {
          id
          certificateNormName
        }
      }
    }
  }
}
    `;

/**
 * __useGetCertificateNormIssueDatesQuery__
 *
 * To run a query within a React component, call `useGetCertificateNormIssueDatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCertificateNormIssueDatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCertificateNormIssueDatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCertificateNormIssueDatesQuery(baseOptions?: Apollo.QueryHookOptions<GetCertificateNormIssueDatesQuery, GetCertificateNormIssueDatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCertificateNormIssueDatesQuery, GetCertificateNormIssueDatesQueryVariables>(GetCertificateNormIssueDatesDocument, options);
      }
export function useGetCertificateNormIssueDatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCertificateNormIssueDatesQuery, GetCertificateNormIssueDatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCertificateNormIssueDatesQuery, GetCertificateNormIssueDatesQueryVariables>(GetCertificateNormIssueDatesDocument, options);
        }
export type GetCertificateNormIssueDatesQueryHookResult = ReturnType<typeof useGetCertificateNormIssueDatesQuery>;
export type GetCertificateNormIssueDatesLazyQueryHookResult = ReturnType<typeof useGetCertificateNormIssueDatesLazyQuery>;
export type GetCertificateNormIssueDatesQueryResult = Apollo.QueryResult<GetCertificateNormIssueDatesQuery, GetCertificateNormIssueDatesQueryVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
          export type ResultType<T> = Types.ResultType<T>;
          export type PaginatedResultType<T> = Types.PaginatedResultType<T>;
          export type NonMaybe<T> = Types.NonMaybe<T>;
          export type Maybe<T> = Types.Maybe<T>;