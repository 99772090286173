import { Box, styled } from "@mui/material";
import ProcessOption from "./ProcessOption";
import CachedOutlinedIcon from "@mui/icons-material/CachedOutlined";
import ParkOutlineCertificate from "components/icons/iconComponents/ParkOutlineCertificate";
import { translations } from "translations";

export const CachedOutlinedSC = styled(CachedOutlinedIcon, {
  label: "CachedOutlined",
  shouldForwardProp(propName) {
    return propName !== "";
  },
})(({ theme }) => ({
  width: "2.5rem",
  height: "2.5rem",
  flexShrink: 0,
}));
export interface SelectProcessProps {
  setProcessOption: React.Dispatch<React.SetStateAction<number>>;
  processOption: number;
}

const SelectProcess: React.FC<SelectProcessProps> = ({ setProcessOption, processOption }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "2rem",
        flex: "1 0 0",
      }}
    >
      <ProcessOption
        active={processOption === 1}
        setProcessOption={() => setProcessOption(1)}
        icon={<ParkOutlineCertificate />}
        title={translations.pages.certificateUpload.certificateUploadProcess.createNew.title}
        description={translations.pages.certificateUpload.certificateUploadProcess.createNew.description}
      />
      <ProcessOption
        active={processOption === 2}
        setProcessOption={() => setProcessOption(2)}
        icon={<CachedOutlinedSC />}
        title={translations.pages.certificateUpload.certificateUploadProcess.replaceExisting.title}
        description={translations.pages.certificateUpload.certificateUploadProcess.replaceExisting.description}
      />
    </Box>
  );
};
export default SelectProcess;
