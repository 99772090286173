import * as React from "react";
import { ChangeEventHandler } from "react";
import Props from "./index.d";
import UnitWrapper from "components/formElements/fieldSets/UnitWrapper";
import { inputProps } from "./inputProps";
import { UnitFormErrorDisplay } from "components/formElements/customElements/UnitFormErrorDisplay";
import {
  StyledContentContainer,
  StyledTextfield,
} from "components/formElements/customElements/UnitFormErrorDisplay/styles";

const StringUnit: React.FC<Props> = (props) => {
  const {
    data: { state, setstate, onBlur, setNull },
    isRequiredField,
    errorManager,
  } = props;

  const change: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = (e) => {
    const value = e.target.value;

    if (value === null) {
      if (setNull) {
        setNull();
      } else {
        setstate("");
      }
    } else {
      if (typeof value === "string") setstate(value);
    }
  };

  const errorManagerRes = errorManager({
    errorJoiner: (errors) => errors.join(", "),
  });

  return (
    <UnitWrapper title={props.title ?? ""}>
      <StyledContentContainer>
        {UnitFormErrorDisplay(errorManagerRes)}
        <StyledTextfield
          onBlur={onBlur}
          value={state || ""}
          onChange={change}
          disabled={props.disabled}
          required={isRequiredField}
          InputProps={inputProps}
          {...errorManagerRes}
        />
      </StyledContentContainer>
    </UnitWrapper>
  );
};

export default StringUnit;
