import { Box, styled } from "@mui/material";
import ordinaryColors from "themes/ordinaryColors";
import { MUIStyledCommonProps } from "@mui/system";

export interface StyledContentProps {
  contentSx?: MUIStyledCommonProps["sx"];
}

export interface StyledTitleProps {
  titleSx?: MUIStyledCommonProps["sx"];
}

export interface StyledResetBoxProps {
  resetBoxSx?: MUIStyledCommonProps["sx"];
}

export const StyledContent = styled(Box)(({ contentSx }: StyledContentProps) => ({
  ...contentSx,
}));

export const StyledTitle = styled("div")(({ titleSx }: StyledTitleProps) => ({
  ...titleSx,
}));

export const StyledResetBox = styled(Box)(({ resetBoxSx }: StyledResetBoxProps) => ({
  ...resetBoxSx,
}));

export const contentSx: MUIStyledCommonProps["sx"] = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
};

export const titleSx: MUIStyledCommonProps["sx"] = {
  color: ordinaryColors.functionalBlack,
  fontFeatureSettings: "'clig' off, 'liga' off",
  fontSize: "20px",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "160% /* 32px */",
  letterSpacing: "0.15px",
};

export const resetBoxSx: MUIStyledCommonProps["sx"] = {
  whiteSpace: "nowrap",
  display: "flex",
  flexDirection: "row",
  gap: "24px",
  alignItems: "center",
};

export const contentBoxSx: MUIStyledCommonProps["sx"] = {
  display: "flex",
  flexDirection: "row",
  gap: "8px",
};

export const labelSx = ({ disabled }: { disabled?: boolean }): MUIStyledCommonProps["sx"] => ({
  color: disabled ? `1px solid ${ordinaryColors.functionalLightGrey}` : `1px solid ${ordinaryColors.primaryBrand}`,
  fontFeatureSettings: "'clig' off, 'liga' off",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "150% /* 24px */",
  letterSpacing: "0.15px",
  borderBottom: disabled
    ? `1px solid ${ordinaryColors.functionalLightGrey}`
    : `1px solid ${ordinaryColors.primaryBrand}`,
});

export const resetIconSx = ({ disabled }: { disabled?: boolean }): MUIStyledCommonProps["sx"] => ({
  color: !!disabled ? `1px solid ${ordinaryColors.functionalLightGrey}` : `1px solid ${ordinaryColors.primaryBrand}`,
});
