import * as React from "react";

import { Chip, styled } from "@mui/material";
import Props from "./index.d";

import { SetterOrUpdater } from "recoil";

const ChipWrapper = ({
  id,
  label,
  useRowSelected,
}: {
  id: string;
  label: string;
  className?: string | undefined;
  useRowSelected: (id: string) => [boolean, SetterOrUpdater<boolean>];
}) => {
  const [checked, setChecked] = useRowSelected(id);
  const handleClick = () => setChecked((checked) => !checked);
  return <Chip label={label} onClick={handleClick} sx={{ width: 150 }} color={checked ? "primary" : "secondary"} />;
};

const GenericBridgeChipsComponent: React.FC<Props> = (props) => {
  const { useBridge, className } = props;
  const { useRowSelected, items } = useBridge();
  return (
    <div>
      {items.list.map((elem) => (
        <ChipWrapper key={elem.id} {...elem} useRowSelected={useRowSelected} className={className} />
      ))}
    </div>
  );

  return null;
};
const GenericBridgeChips = styled(GenericBridgeChipsComponent)<Props>(() => ({
  display: "flex",
}));

export default GenericBridgeChips;
