import { Autocomplete, FormHelperText, TextField } from "@mui/material";
import * as React from "react";
import ordinaryColors from "themes/ordinaryColors";
import Props from "./index.d";

const GenericBridgeMultiSelect: React.FC<Props> = (props) => {
  const { useBridge, className, errorManager } = props;
  const { items, useBridgeArrayState } = useBridge();
  const [selctedPre, setSelected] = useBridgeArrayState();

  const selected = selctedPre.map((id) => {
    return {
      id,
      label: items.list.find((item) => item.id === id)?.label ?? "",
    };
  });

  const handleOnChange = (
    _: unknown,
    value: {
      id: string;
      label: string;
    }[]
  ) => {
    setSelected(value.map(({ id }) => id));
  };
  const errorManagerRes = errorManager({
    errorJoiner: (errors) => {
      return errors.join(", ");
    },
  });

  return (
    <>
      <Autocomplete
        className={className}
        multiple
        onChange={handleOnChange}
        id="tags-outlined"
        options={items.list.sort((a, b) => a.label.localeCompare(b.label))}
        value={selected}
        renderInput={(params) => (
          <TextField
            {...params}
            sx={{
              borderRadius: "0px",

              padding: "0px",
              "& .MuiOutlinedInput-root": {
                borderRadius: "0px",
                border: errorManagerRes.error ? "1px solid rgba(214, 40, 57, 1) " : "1px solid #9E9E9E66",
                backgroundColor: errorManagerRes.error ? "rgba(211, 47, 47, 0.02)" : "transparent",
                " .MuiOutlinedInput-notchedOutline": {
                  borderWidth: "0px",
                },
                "& input": {
                  height: "10px",
                  padding: "0px",
                  borderRadius: "0px",
                },
              },
            }}
          />
        )}
        isOptionEqualToValue={(option, value) => option.id === value.id}
      />
      {errorManagerRes.error && (
        <FormHelperText sx={{ color: ordinaryColors.error, marginLeft: "0px", fontSize: "12px" }}>
          {errorManagerRes.helperText}
        </FormHelperText>
      )}
    </>
  );
};

export default GenericBridgeMultiSelect;
