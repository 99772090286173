/* eslint-disable import/no-unresolved */
import { FC, useEffect, useState } from "react";
import { Box } from "@mui/material";
import Props from "./index.d";
import { FileWithPath, useDropzone } from "react-dropzone";
import FileDropZone from "./FileDropZone";
import FileLists from "./FileLists";
import { DataString } from "components/formElements/formTypes";
import { toBase64 } from "./FileUtils";

export type { FileWithPath };

const DropZone: FC<Props> = (props) => {
  const { title, formatsString, data } = props;
  const { setstate } = data as DataString;

  const {
    getRootProps,
    getInputProps,
    open: openDialog,
    acceptedFiles,
  } = useDropzone({
    accept: props.accept,
    noClick: true,
    noKeyboard: true,
    maxSize: 3000000,
    maxFiles: 1,
    useFsAccessApi: false,
  });
  const [acceptedFilesCount, setAcceptedFilesCount] = useState(0);

  const cleanUpBase64String = (base64String: string | undefined | null) => {
    if (!base64String) {
      return base64String;
    }
    return base64String?.split(",")[1];
  };

  useEffect(() => {
    if (acceptedFilesCount === 0) {
      setstate("");
    } else {
      toBase64(acceptedFiles).then((res) => setstate(cleanUpBase64String(res as string) ?? ""));
    }
  }, [acceptedFiles, acceptedFilesCount, setstate]);

  return (
    <Box
      sx={{
        minHeight: "122px",
      }}
    >
      <Box
        sx={{
          fontFeatureSettings: "'clig' off, 'liga' off",
          fontSize: "14px",
          fontStyle: "normal",
          fontWeight: 400,
          lineHeight: "143%",
          letterSpacing: "0.17px",
          marginBottom: "6px",
        }}
      >
        {title ? title : ""}
      </Box>
      {acceptedFilesCount === 0 && (
        <FileDropZone
          openDialog={openDialog}
          getRootProps={getRootProps}
          getInputProps={getInputProps}
          formatsString={formatsString}
        />
      )}
      <FileLists acceptedFiles={acceptedFiles} setAcceptedFilesCount={setAcceptedFilesCount} />
    </Box>
  );
};

export default DropZone;
