import CloseIcon from "@mui/icons-material/Close";
import { IconButton, Typography } from "@mui/material";
import { Box } from "@mui/system";
import * as React from "react";
import { titleFileStyle } from "../../CertificateFileUpload";
import useGetItemtext from "./useGetItemtext";

interface NormBridgeListProps {
  removeNorm: (normBridgeId: string) => void;
  normBridgeIds: string[];
}

const NormBridgeList: React.FC<NormBridgeListProps> = ({ removeNorm, normBridgeIds }) => {
  const getItemText = useGetItemtext();
  return (
    <Box>
      {normBridgeIds.map((normBridgeId) => (
        <Box key={normBridgeId}>
          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", paddingTop: "0.5rem" }}>
            <Typography sx={titleFileStyle}>{getItemText(normBridgeId)}</Typography>
            <IconButton onClick={() => removeNorm(normBridgeId)}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
      ))}
    </Box>
  );
};
export default NormBridgeList;
