import RefreshSharpIcon from "@mui/icons-material/RefreshSharp";
import { Button, Typography } from "@mui/material";
import React from "react";
import ordinaryColors from "themes/ordinaryColors";
interface ResetFilterButtonProps {
  title: string;
  resetFilters: () => void;
  onMouseDown?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  color?: string;
}

const ResetFilterButton: React.FC<ResetFilterButtonProps> = ({ title, resetFilters, onMouseDown, color }) => {
  return (
    <Button
      onMouseDown={onMouseDown}
      onClick={() => {
        resetFilters();
      }}
      startIcon={<RefreshSharpIcon sx={{ width: "24px", height: "24px" }} />}
      sx={{
        color: color ?? ordinaryColors.functionalBlack,
        "&:hover": {
          backgroundColor: "transparent",
        },
      }}
    >
      <Typography sx={{ whiteSpace: "nowrap", fontSize: "16px", color: color }}>{title}</Typography>
    </Button>
  );
};

export default ResetFilterButton;
