import * as React from "react";

import { Checkbox } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { SetterOrUpdater } from "recoil";
import Props from "./index.d";

const Checker = ({
  id,
  useRowSelected,
}: {
  id: string;

  className?: string | undefined;
  useRowSelected: (id: string) => [boolean, SetterOrUpdater<boolean>];
}) => {
  const [checked, setChecked] = useRowSelected(id);
  return <Checkbox key={id} checked={checked} onChange={() => setChecked((prev) => !prev)} />;
};

const GenericBridgeTable: React.FC<Props> = (props) => {
  const { useBridge, className } = props;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { save, useRowSelected, items } = useBridge();

  return (
    <>
      {items.list.map((elem) => (
        <Checker key={elem.id} {...elem} useRowSelected={useRowSelected} className={className} />
      ))}
      <TableContainer component={Paper} className={className}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              {items.table.titles.map((row) => (
                <TableCell key={row}>{row}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {items.table.rows.map((row) => (
              <TableRow key={row[0]} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                {row.map((cell, index) => (
                  <TableCell key={cell} component="th" scope="row">
                    {index === 0 ? <Checker key={cell} id={cell} useRowSelected={useRowSelected} /> : cell}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );

  return null;
};

export default GenericBridgeTable;
