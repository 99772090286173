import * as React from "react";
import Props from "./index.d";
import { MuiTelInput } from "mui-tel-input";

const PhoneInput: React.FC<Props> = (props) => {
  const {
    title,

    data: { state, setstate, onBlur, setNull },
    isRequiredField,
    className,
    errorManager,
  } = props;

  const change: (value: string) => void = (value) => {
    if (value === null) {
      if (setNull) {
        setNull();
      } else {
        setstate("");
      }
    } else {
      if (typeof value === "string") setstate(value);
    }
  };
  return (
    <>
      <MuiTelInput
        onBlur={onBlur}
        value={state || ""}
        defaultCountry="DE"
        onChange={change}
        disabled={props.disabled}
        label={title}
        className={className}
        required={isRequiredField}
        {...errorManager()}
        size="small"
      />
    </>
  );
};

export default PhoneInput;
