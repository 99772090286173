import { GridColDef, GridFilterInputValue, GridFilterItem, GridFilterOperator } from "@mui/x-data-grid-pro";
import { filterdefs } from "graphqlBase/__utils__/filterTypes";

const makeFilters = Object.entries(filterdefs).reduce<{
  [key: string]: GridFilterOperator[];
}>((filterOperators, [key, def]) => {
  const filterEntries: GridFilterOperator[] = def.map((def) => {
    return {
      label: def,
      value: def,
      getApplyFilterFn: (filterItem: GridFilterItem, column: GridColDef) => null,
      InputComponent: GridFilterInputValue,
      InputComponentProps: { type: key },
    };
  });
  return { ...filterOperators, [key]: filterEntries };
}, {});

export default makeFilters;
