import { getCertificateCreateFieldSelector } from "raft/CertificateForm";
import { getUnitCreateFieldSelector } from "raft/UnitForm";
import * as React from "react";
import { useMemo } from "react";
import { useRecoilValue } from "recoil";

import Props from "./index.d";

interface GFCFieldSet_FieldSetDiv extends Props {
  children: React.ReactNode;
}

const NormChecker: React.FC<GFCFieldSet_FieldSetDiv> = ({ children, norm, entity }) => {
  const __normListAtom = useMemo(() => getCertificateCreateFieldSelector({ formVersion: "active" })("__normList"), []);
  const __normListAtomUnits = useMemo(() => getUnitCreateFieldSelector({ formVersion: "active" })("__normList"), []);

  const __normList = useRecoilValue(__normListAtom);
  const __normListUnits = useRecoilValue(__normListAtomUnits);

  const CheckedChildren = useMemo(() => {
    const norms = (entity === "Certificate" ? __normList : __normListUnits) ?? "";

    const includesGreater10 = norms.includes("10") || norms.includes("20") || norms.includes("30");
    const selectedNormGreater10 = norm === "10" || norm === "20" || norm === "30";
    const includesIs5 = norms.includes("05");

    if (includesGreater10 && selectedNormGreater10) return <>{children}</>;
    if (norm === "05" && includesIs5) return <>{children}</>;
    return null;
  }, [__normList, __normListUnits, entity, norm, children]);

  return CheckedChildren;
};

export default NormChecker;
