import IconButton from "@mui/material/IconButton";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import React, { isValidElement } from "react";
import {
  GridRenderCellParams,
  gridDetailPanelExpandedRowsContentCacheSelector,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid-pro";

const DetailPanelToggle = (props: Pick<GridRenderCellParams, "id" | "value">) => {
  const { id, value: isExpanded } = props;
  const apiRef = useGridApiContext();

  const contentCache = useGridSelector(apiRef, gridDetailPanelExpandedRowsContentCacheSelector);

  const hasDetail = isValidElement(contentCache[id]);

  return (
    <IconButton
      sx={{
        marginTop: "-6px",
      }}
      size="small"
      tabIndex={-1}
      disabled={!hasDetail}
      aria-label={isExpanded ? "Close" : "Open"}
    >
      <ArrowDropDownIcon
        color="primary"
        sx={{
          transform: `rotateZ(${isExpanded ? 0 : -90}deg)`,
          transition: (theme) =>
            theme.transitions.create("transform", {
              duration: theme.transitions.duration.shortest,
            }),
        }}
      />
    </IconButton>
  );
};

export default DetailPanelToggle;
