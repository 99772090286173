import { Typography } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { Box, styled } from "@mui/system";
import { StyledInputBase } from "components/formElements/fields/ForeignKeySelector";
import { useGetsoftwareVersionsQuery } from "graphqlBase/SoftwareVersions/__generated__/getsoftwareVersions";
import * as React from "react";
import { useMemo, useState } from "react";

export interface AddNewSoftwareBridgeProps {
  addSoftwareBridgeId: (softwareBridgeId: string) => void;
  softwareBridgeIds: string[];
  softwareBridgeId?: string;
}

export const StyledCreateSoftwareSelects = styled(Box, { label: "StyledCreateSoftwareModal" })(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "32px",
  flex: "1 0 0",
  width: "100%",
}));

const AddNewSoftwareBridge: React.FC<AddNewSoftwareBridgeProps> = ({
  addSoftwareBridgeId,
  softwareBridgeId,
  softwareBridgeIds,
}) => {
  const { data: softwaresData } = useGetsoftwareVersionsQuery();
  const [softwareType, setSoftwareType] = useState<string | undefined>();

  const handleSoftwareChange = (event: SelectChangeEvent) => {
    setSoftwareType(event.target.value as string);
  };
  const handleSoftwareBridgeChange = (event: SelectChangeEvent) => {
    addSoftwareBridgeId(event.target.value as string);
  };

  const uniuqeSofwareTypes = useMemo(() => {
    return (softwaresData?.softwareVersions?.items ?? []).reduce<string[]>((acc, item) => {
      if (!acc.includes(item.softwareType)) {
        acc.push(item.softwareType);
      }
      return acc;
    }, []);
  }, [softwaresData?.softwareVersions?.items]);
  return (
    <StyledCreateSoftwareSelects>
      <FormControl fullWidth>
        <Typography sx={{ fontSize: "14px", marginBottom: "6px" }}>Software</Typography>

        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={softwareType}
          input={<StyledInputBase />}
          label="Age"
          onChange={handleSoftwareChange}
        >
          {uniuqeSofwareTypes
            ?.sort((a, b) => (a ?? "").localeCompare(b ?? ""))
            .map((sofwareTypeName) => (
              <MenuItem key={sofwareTypeName} value={sofwareTypeName}>
                {sofwareTypeName}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      <FormControl fullWidth>
        <Typography sx={{ fontSize: "14px", marginBottom: "6px" }}>Revision</Typography>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={softwareBridgeId}
          label="Age"
          input={<StyledInputBase />}
          onChange={handleSoftwareBridgeChange}
          disabled={!softwareType}
        >
          {(softwaresData?.softwareVersions?.items ?? [])
            .filter((softWareItem) => softWareItem.softwareType === softwareType)
            ?.sort((a, b) => a.version.localeCompare(b.version))
            .map((softWareItem) => (
              <MenuItem
                key={softWareItem.id}
                value={softWareItem.id}
                disabled={softwareBridgeIds.includes(softWareItem.id)}
              >
                {softWareItem.version}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </StyledCreateSoftwareSelects>
  );
};

export default AddNewSoftwareBridge;
