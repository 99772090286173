import { getCertificateCreateFieldSelector } from "raft/CertificateForm";
import * as React from "react";
import { useRecoilValue } from "recoil";

import Props from "./index.d";

interface GFCFieldSet_NoLayoutGroupExtended extends Props {
  children: React.ReactNode;
  modificationType: "new" | "edit";
}

const CheckModificationType: React.FC<GFCFieldSet_NoLayoutGroupExtended> = ({ children, modificationType }) => {
  const idAtom = React.useMemo(() => getCertificateCreateFieldSelector({ formVersion: "active" })("id"), []);
  const id = useRecoilValue(idAtom);
  const isProperModification = React.useMemo(() => {
    if (modificationType === "new" && id) return false;
    if (modificationType === "edit" && !id) return false;
    return true;
  }, [modificationType, id]);

  if (!isProperModification) return null;
  return <>{children}</>;
};

export default CheckModificationType;
