import React from "react";

interface IntspectProps {
  tree: any;
  treeConfig: any;
  isField?: boolean;
  showData?: () => any;
  customRender?: boolean;
  overrides?: boolean;
  modifiedProps: boolean;
  props: unknown
}

function useKeyPress(targetKey: string): boolean {
  const [keyPressed, setKeyPressed] = React.useState(false);

  function downHandler({ key }: { key: string }): void {
    if (key === targetKey) {
      setKeyPressed((val) => !val);
    }
  }

  React.useEffect(() => {
    window.addEventListener("keydown", downHandler);
    return () => {
      window.removeEventListener("keydown", downHandler);
    };
  }, []);
  return keyPressed;
}

const Inspect = (props: IntspectProps) => {
  if (process.env.NODE_ENV !== "development") return null;
  return <InspectInner {...props} />;
};

const InspectInner = ({ tree, treeConfig, isField = false, showData, customRender, modifiedProps, props, overrides }: IntspectProps) => {
  const inspect = useKeyPress("F9");
  const inspect2 = useKeyPress("F10");

  if (!inspect && !inspect2) return null;
  const handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    {
      showData && console.table({ __form__: treeConfig.form, ...showData() });
    }
  };
  const handleClick2 = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    {
      console.dir(props);
    }
  };
  const handleClickToOpen = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    fetch(`http://localhost:4000/link?file=${tree.config?.fullyQualifiedName}`)
  };
  return (
    <>
      {tree.config.uiType === "GFCForm_Base" && (
        <div>
          <b>
            <a
              onContextMenu={handleClick}
              style={{ fontSize: "1.5em" }}
              href={`${location.protocol}//${location.host}/configurator?form=${treeConfig.form}`}
              target="_blank"
            >
              {treeConfig.form}
            </a>
            &nbsp;&nbsp;
          </b>
        </div>
      )}
      {(isField || inspect2) && (
        <div
          style={{
            fontSize: "1.5em",

            zIndex: 20000,
          }}
        >
          {isField && tree.config.includedFields[0]}&nbsp;&nbsp;
          {modifiedProps ? <b style={{ color: "orange" }}>modified Props!&nbsp;&nbsp;</b> : ""}
          {customRender ? <b style={{ color: "red" }}>Custom render!&nbsp;&nbsp;</b> : ""}
          {overrides ? <b style={{ color: "red" }}>Override!&nbsp;&nbsp;</b> : ""}
          {tree.config.uiType}&nbsp;&nbsp;

          <a
            onClick={(e) => handleClick2(e)}
            href={""}
          >
            <u>Log Config</u>
          </a>&nbsp;&nbsp;
          <a
            onContextMenu={(e) => handleClick2(e)}
            href={`${location.protocol}//${location.host}/configurator?form=${treeConfig.form}#${tree.id}`}
            target="_blank"
          >
            <u>Configurator</u>
          </a>
          &nbsp;&nbsp;
          {!!tree.config?.fullyQualifiedName && window && (
            <a onClick={(e) => handleClickToOpen(e)}
              href={""}>
              <u>Open File in VSCode</u>
            </a>
          )}
        </div>
      )}
    </>
  );
};


export default Inspect;
