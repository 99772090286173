export const uploadCertificateUnitFieldsNotToCopy = ["id", "modelNumber", "modelName", "__isLinked"];
export const loaderMinRunTime = 1500;
export const dummyIdPrefix = "dummyId_";

export const formDefaultKeys = [
  "step",
  "__normList",
  "commit",
  "formErrors",
  "formErrorCount",
  "__category",
  "id",
  "revision",
];

export const certificateFileTypes = {
  "Test Report": "c337b998-84b4-5534-85ee-0461abd69366",
  Parameters: "e6489279-ed6e-5d49-b649-4a06425968d6",
  "Prototype Confirmation": "a84b6197-3dcc-52f4-a325-6a44157e27d4",
  Other: "daad643c-18c2-501b-be70-75ff4ff3d730",
  "Cover Sheet": "b849df26-580a-51f2-b035-85af026e1786",
  "Digital Attachment": "dc31de78-908f-56af-aea1-98dfe6fa5fe8",
  "Manufacturer's Declaration": "a980cca4-ca1b-5954-9d95-d784a5008571",
  Certificate: "1311341d-f702-51f3-ad44-e26ff3d677a1",
  "Perturbations Test Report": "fcf9363a-c491-59ab-9a44-a9ba51e0006a",
  Model: "a6cd668b-d6f5-5fed-9e8a-36593cd6042a", // "Evaluation Report": "a6cd668b-d6f5-5fed-9e8a-36593cd6042a",
  "Evaluation Report": "48697a16-e73d-5d5d-994b-f4872cb973ff", // Model: "48697a16-e73d-5d5d-994b-f4872cb973ff",
};
