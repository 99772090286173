import { DataGridPro, GridColDef } from "@mui/x-data-grid-pro";
import { EntityMappings } from "graphqlBase/__utils__/entityMappings";
import * as React from "react";
import { useRecoilState } from "recoil";
import { makeGridState } from "./gridStatePersist";
import { BaseRow, DataGridFactoryParams, GridWrapperProps } from "./types";
import { useEffect } from "react";

const usePersistTableState = ({
  apiRef,
  persistance,
  tableId,
}: Omit<DataGridFactoryParams, "DataGridComponent" | "executeQuery">) => {
  const tableState = makeGridState(tableId, persistance);
  const [persistedTabelState, setTableState] = useRecoilState(tableState);
  const v = apiRef.current;
  useEffect(() => {
    if (!persistance || !v?.state) return;
    return () => {
      setTableState(v?.state);
    };
  }, [setTableState, persistance, v]);

  const restoreState = React.useCallback(() => {
    if (!persistedTabelState) return;
    apiRef.current.restoreState(persistedTabelState);
  }, [apiRef, persistedTabelState]);

  useEffect(() => {
    if (persistedTabelState) {
      restoreState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return persistedTabelState;
};

export function DataGridFactory<R extends BaseRow, E extends keyof EntityMappings>({
  apiRef,
  executeQuery,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  DataGridComponent = DataGridPro,
  tableMode = "server",
  persistance,
  tableId,
}: DataGridFactoryParams) {
  const GridWrapper: React.FC<GridWrapperProps<R, E>> = ({ rows, columns, ...props }) => {
    const [rowCount, setRowCount] = React.useState<number>(0);
    const pagination = props.pagination ?? true;

    const gridRows = React.useMemo(() => rows?.slice(0, props.pageSize ?? 99) ?? [], [props.pageSize, rows]);
    const serverSideLogic = tableMode === "server";
    React.useEffect(() => {
      setRowCount(props.totalCount ?? 0);
    }, [props.totalCount]);

    const persistedTabelState = usePersistTableState({ apiRef, tableMode, persistance, tableId });

    return (
      <DataGridComponent
        {...props}
        experimentalFeatures={{ rowPinning: true }}
        initialState={{ ...props.initialState, ...persistedTabelState }}
        // initialState={persistance ? deepmerge(props.initialState, persistedTabelState) : props.initialState}
        apiRef={apiRef}
        processRowUpdate={(newV, oldV) => {
          return newV;
        }}
        filterMode={tableMode}
        sortingMode={tableMode}
        paginationMode={tableMode}
        onFilterModelChange={(model, details) => {
          if (serverSideLogic) executeQuery();
          setRowCount(0);
          if (props.onFilterModelChange) props.onFilterModelChange(model, details);
        }}
        onSortModelChange={(model, details) => {
          if (serverSideLogic) executeQuery();
          if (props.onSortModelChange) props.onSortModelChange(model, details);
        }}
        onPageChange={(page, details) => {
          if (serverSideLogic) executeQuery();
          if (props.onPageChange) props.onPageChange(page, details);
        }}
        onPageSizeChange={(pageSize, details) => {
          if (serverSideLogic) executeQuery();
          if (props.onPageSizeChange) props.onPageSizeChange(pageSize, details);
        }}
        pagination={pagination}
        rowCount={pagination ? rowCount : undefined}
        rows={gridRows}
        columns={columns as GridColDef[]}
      />
    );
  };

  return GridWrapper;
}
