import * as React from "react";

import Props from "./index.d";

interface GFCFieldSet_FieldSetDiv extends Props {
  children: React.ReactNode;
}

const Div: React.FC<GFCFieldSet_FieldSetDiv> = ({ children, className }) => {
  return <div className={className}>{children}</div>;
};

export default Div;
