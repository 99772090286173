import type * as Types from '../../types';

import client from "apollo/client";
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetsoftwareVersionsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetsoftwareVersionsQuery = { __typename?: 'Query', softwareVersions?: { __typename: 'SoftwareVersionCollectionSegment', items?: Array<{ __typename: 'SoftwareVersion', id: string, softwareType: Types.SoftwareType, version: string }> | null } | null };


        export type ExecGetsoftwareVersionsOptions = Omit<
        Apollo.QueryOptions<
        GetsoftwareVersionsQueryVariables, GetsoftwareVersionsQuery
        >,
        "query"
      >;
    export const execGetsoftwareVersionsQuery = (options: ExecGetsoftwareVersionsOptions) =>
    client.query< GetsoftwareVersionsQuery, GetsoftwareVersionsQueryVariables>({
      ...options,
      query: GetsoftwareVersionsDocument,
    });
    

export const GetsoftwareVersionsDocument = gql`
    query getsoftwareVersions {
  softwareVersions {
    items {
      id
      softwareType
      version
      __typename
    }
    __typename
  }
}
    `;

/**
 * __useGetsoftwareVersionsQuery__
 *
 * To run a query within a React component, call `useGetsoftwareVersionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetsoftwareVersionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetsoftwareVersionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetsoftwareVersionsQuery(baseOptions?: Apollo.QueryHookOptions<GetsoftwareVersionsQuery, GetsoftwareVersionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetsoftwareVersionsQuery, GetsoftwareVersionsQueryVariables>(GetsoftwareVersionsDocument, options);
      }
export function useGetsoftwareVersionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetsoftwareVersionsQuery, GetsoftwareVersionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetsoftwareVersionsQuery, GetsoftwareVersionsQueryVariables>(GetsoftwareVersionsDocument, options);
        }
export type GetsoftwareVersionsQueryHookResult = ReturnType<typeof useGetsoftwareVersionsQuery>;
export type GetsoftwareVersionsLazyQueryHookResult = ReturnType<typeof useGetsoftwareVersionsLazyQuery>;
export type GetsoftwareVersionsQueryResult = Apollo.QueryResult<GetsoftwareVersionsQuery, GetsoftwareVersionsQueryVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
          export type ResultType<T> = Types.ResultType<T>;
          export type PaginatedResultType<T> = Types.PaginatedResultType<T>;
          export type NonMaybe<T> = Types.NonMaybe<T>;
          export type Maybe<T> = Types.Maybe<T>;