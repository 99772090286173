import type * as Types from '../../types';

import client from "apollo/client";
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetCertificateNormIssueDatesForNormIdQueryVariables = Types.Exact<{
  normId?: Types.InputMaybe<Types.Scalars['UUID']>;
}>;


export type GetCertificateNormIssueDatesForNormIdQuery = { __typename?: 'Query', certificateNormIssueDates?: { __typename?: 'CertificateNormIssueDateCollectionSegment', items?: Array<{ __typename?: 'CertificateNormIssueDate', id: string, certificateNormIssueDate: string }> | null } | null };


        export type ExecGetCertificateNormIssueDatesForNormIdOptions = Omit<
        Apollo.QueryOptions<
        GetCertificateNormIssueDatesForNormIdQueryVariables, GetCertificateNormIssueDatesForNormIdQuery
        >,
        "query"
      >;
    export const execGetCertificateNormIssueDatesForNormIdQuery = (options: ExecGetCertificateNormIssueDatesForNormIdOptions) =>
    client.query< GetCertificateNormIssueDatesForNormIdQuery, GetCertificateNormIssueDatesForNormIdQueryVariables>({
      ...options,
      query: GetCertificateNormIssueDatesForNormIdDocument,
    });
    

export const GetCertificateNormIssueDatesForNormIdDocument = gql`
    query getCertificateNormIssueDatesForNormId($normId: UUID) {
  certificateNormIssueDates(
    where: {certificateNormBridges: {some: {certificateNormId: {eq: $normId}}}}
  ) {
    items {
      id
      certificateNormIssueDate
    }
  }
}
    `;

/**
 * __useGetCertificateNormIssueDatesForNormIdQuery__
 *
 * To run a query within a React component, call `useGetCertificateNormIssueDatesForNormIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCertificateNormIssueDatesForNormIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCertificateNormIssueDatesForNormIdQuery({
 *   variables: {
 *      normId: // value for 'normId'
 *   },
 * });
 */
export function useGetCertificateNormIssueDatesForNormIdQuery(baseOptions?: Apollo.QueryHookOptions<GetCertificateNormIssueDatesForNormIdQuery, GetCertificateNormIssueDatesForNormIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCertificateNormIssueDatesForNormIdQuery, GetCertificateNormIssueDatesForNormIdQueryVariables>(GetCertificateNormIssueDatesForNormIdDocument, options);
      }
export function useGetCertificateNormIssueDatesForNormIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCertificateNormIssueDatesForNormIdQuery, GetCertificateNormIssueDatesForNormIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCertificateNormIssueDatesForNormIdQuery, GetCertificateNormIssueDatesForNormIdQueryVariables>(GetCertificateNormIssueDatesForNormIdDocument, options);
        }
export type GetCertificateNormIssueDatesForNormIdQueryHookResult = ReturnType<typeof useGetCertificateNormIssueDatesForNormIdQuery>;
export type GetCertificateNormIssueDatesForNormIdLazyQueryHookResult = ReturnType<typeof useGetCertificateNormIssueDatesForNormIdLazyQuery>;
export type GetCertificateNormIssueDatesForNormIdQueryResult = Apollo.QueryResult<GetCertificateNormIssueDatesForNormIdQuery, GetCertificateNormIssueDatesForNormIdQueryVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
          export type ResultType<T> = Types.ResultType<T>;
          export type PaginatedResultType<T> = Types.PaginatedResultType<T>;
          export type NonMaybe<T> = Types.NonMaybe<T>;
          export type Maybe<T> = Types.Maybe<T>;