import AddIcon from "@mui/icons-material/Add";
import {
  Autocomplete,
  AutocompleteChangeReason,
  Button,
  Divider,
  FormControl,
  MenuItem,
  Paper,
  styled,
  TextField,
} from "@mui/material";

export type SelectItem = {
  value: string;
  label: string;
};

interface SelectFreeSoloProps {
  selectedItem: SelectItem | null;
  loading?: boolean;
  disabled?: boolean;
  handleAdd?: () => void;
  addLabel?: string;
  label: string;
  options: SelectItem[] | undefined;
  handleChange: (event: React.SyntheticEvent, value: SelectItem | null, reason: AutocompleteChangeReason) => void;
  searchText?: string;
  handleInputChange: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> | undefined;
  width?: string;
}

const CustomPaper = styled(Paper)(({ theme }) => ({
  paddingBottom: theme.spacing(1),
}));

const buttonSx = {
  height: "30px",
  width: "100%",
  justifyContent: "flex-start",
  paddingLeft: "0px",
  ":hover": { background: "none" },
};

const SelectFreeSolo: React.FC<SelectFreeSoloProps> = ({
  selectedItem,
  loading,
  handleAdd,
  addLabel,
  label,
  options,
  handleChange,
  handleInputChange,
  searchText,
  disabled,
  width,
}) => {
  return (
    <>
      <FormControl fullWidth>
        <Autocomplete
          disabled={disabled}
          loading={loading}
          options={options ?? []}
          getOptionLabel={(option) => option.label}
          value={selectedItem}
          size="small"
          onChange={handleChange}
          renderInput={(params) => (
            <TextField
              {...params}
              value={searchText}
              sx={{ width: width ? width : "100%" }}
              onChange={handleInputChange}
              label={label}
              inputProps={{
                ...params.inputProps,
              }}
            />
          )}
          PaperComponent={({ children }) => (
            <CustomPaper onMouseDown={(e) => e.preventDefault()}>
              {children}
              {handleAdd ? (
                <>
                  <Divider />
                  <MenuItem>
                    <Button variant="text" sx={buttonSx} startIcon={<AddIcon />} onClick={handleAdd}>
                      {addLabel}
                    </Button>
                  </MenuItem>
                </>
              ) : null}
            </CustomPaper>
          )}
        />
      </FormControl>
    </>
  );
};

export default SelectFreeSolo;
