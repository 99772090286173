import type * as Types from '../../types';

import client from "apollo/client";
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetCertificateHistoriesQueryVariables = Types.Exact<{
  skip?: Types.InputMaybe<Types.Scalars['Int']>;
  take?: Types.InputMaybe<Types.Scalars['Int']>;
  where?: Types.InputMaybe<Types.CertificateHistoryFilterInput>;
  order?: Types.InputMaybe<Array<Types.CertificateHistorySortInput> | Types.CertificateHistorySortInput>;
}>;


export type GetCertificateHistoriesQuery = { __typename?: 'Query', certificateHistories?: { __typename?: 'CertificateLedgerCollectionSegment', items?: Array<{ __typename?: 'CertificateLedger', id: string, certificateAuthorityId: string, certificateHolderId: string, certificateIssueDate: string, certificateNumber: string, category: Types.Category, certificateTypeId: string, certificateValidityStatusId: string, isVerified?: boolean | null, primaryEnergySource: Types.PrimaryEnergySource, restrictions: string, validityEndDate?: string | null, validityStartDate?: string | null, replacedByCertificateId?: string | null, revision?: number | null, inEditByTenantId?: string | null, createdAt: string, modifiedAt?: string | null, ledgerTransactionId: any, ledgerSequenceNumber: any }> | null } | null };


        export type ExecGetCertificateHistoriesOptions = Omit<
        Apollo.QueryOptions<
        GetCertificateHistoriesQueryVariables, GetCertificateHistoriesQuery
        >,
        "query"
      >;
    export const execGetCertificateHistoriesQuery = (options: ExecGetCertificateHistoriesOptions) =>
    client.query< GetCertificateHistoriesQuery, GetCertificateHistoriesQueryVariables>({
      ...options,
      query: GetCertificateHistoriesDocument,
    });
    

export const GetCertificateHistoriesDocument = gql`
    query getCertificateHistories($skip: Int, $take: Int, $where: CertificateHistoryFilterInput, $order: [CertificateHistorySortInput!]) {
  certificateHistories(skip: $skip, take: $take, where: $where, order: $order) {
    items {
      id
      certificateAuthorityId
      certificateHolderId
      certificateIssueDate
      certificateNumber
      category
      certificateTypeId
      certificateValidityStatusId
      isVerified
      primaryEnergySource
      restrictions
      validityEndDate
      validityStartDate
      replacedByCertificateId
      revision
      inEditByTenantId
      createdAt
      modifiedAt
      ledgerTransactionId
      ledgerSequenceNumber
    }
  }
}
    `;

/**
 * __useGetCertificateHistoriesQuery__
 *
 * To run a query within a React component, call `useGetCertificateHistoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCertificateHistoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCertificateHistoriesQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      where: // value for 'where'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useGetCertificateHistoriesQuery(baseOptions?: Apollo.QueryHookOptions<GetCertificateHistoriesQuery, GetCertificateHistoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCertificateHistoriesQuery, GetCertificateHistoriesQueryVariables>(GetCertificateHistoriesDocument, options);
      }
export function useGetCertificateHistoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCertificateHistoriesQuery, GetCertificateHistoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCertificateHistoriesQuery, GetCertificateHistoriesQueryVariables>(GetCertificateHistoriesDocument, options);
        }
export type GetCertificateHistoriesQueryHookResult = ReturnType<typeof useGetCertificateHistoriesQuery>;
export type GetCertificateHistoriesLazyQueryHookResult = ReturnType<typeof useGetCertificateHistoriesLazyQuery>;
export type GetCertificateHistoriesQueryResult = Apollo.QueryResult<GetCertificateHistoriesQuery, GetCertificateHistoriesQueryVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
          export type ResultType<T> = Types.ResultType<T>;
          export type PaginatedResultType<T> = Types.PaginatedResultType<T>;
          export type NonMaybe<T> = Types.NonMaybe<T>;
          export type Maybe<T> = Types.Maybe<T>;