import { styled } from "@mui/material";

export const StyledUnitRow = styled("div", {
  name: "StyledUnitRow",
  shouldForwardProp: (propName) => propName !== "isSingle",
})<{ isSingle: boolean }>(({ theme, isSingle }) => ({
  display: "flex",

  width: "100%",
  height: "59px",
  [theme.breakpoints.down("xxxl")]: {
    flexDirection: "column",
    height: isSingle ? "59px" : "118px",
  },
  "@media (max-width: 1200px)": { height: "auto" },
}));
