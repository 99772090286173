import { FormControl, FormHelperText, InputBase, InputLabel, MenuItem, Select, styled } from "@mui/material";
import { getTranslatedCertificateTypeString } from "lib/helper/getTranslatedRowDataStrings";
import React, { useEffect } from "react";
import ordinaryColors from "themes/ordinaryColors";
import Props from "./index.d";
import useGetForeignKeyList from "./useGetForeignKeyList";
import { LabelArray, getSortedOptions } from "lib/helper/getSortedOptions";

export const StyledInputBase = styled(InputBase)<{ error?: boolean; isEnumSelector?: boolean }>(
  ({ error, isEnumSelector }) => ({
    maxHeight: "42px",
    minHeight: "42px",
    display: "flex",
    padding: "8px 12px",
    alignContent: "center",
    rowGap: "100px",
    flex: "1 0 0",
    flexWrap: "wrap",
    borderRadius: "0px",
    border: "1px solid ",
    borderColor: error ? ordinaryColors.error : ordinaryColors.border,
    backgroundColor: error ? "rgba(211, 47, 47, 0.02)" : "transparent",
    marginBottom: "0px",
  })
);

const ForeignKeySelector: React.FC<Props> = (props) => {
  const {
    data: { state, setstate, updateValue, onBlur },
    title,
    pickDefaultFirst,
    className,
    foreignKeyParams,
    hideLabel,
    errorManager,
    isRequiredField,
    isUnit,
  } = props;
  const elemsPre = useGetForeignKeyList({ foreignKeyParams });

  useEffect(() => {
    if (pickDefaultFirst && !state && !!elemsPre && elemsPre.length) {
      setstate(elemsPre[0].value);
    }
  }, [elemsPre, state, pickDefaultFirst, setstate]);

  const errorManagerRes = errorManager({
    errorJoiner: (errors) => {
      return errors.join(", ");
    },
  });

  const elems =
    foreignKeyParams.entityName === "certificateTypes"
      ? (getSortedOptions({ labelArray: elemsPre, sortFn: getTranslatedCertificateTypeString }) as LabelArray)
      : elemsPre;

  return (
    <>
      <FormControl fullWidth className={className}>
        {!isUnit || (hideLabel && <InputLabel id="demo-simple-select-label">{title}</InputLabel>)}
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          input={<StyledInputBase {...errorManagerRes} />}
          value={state ?? ""}
          label={hideLabel === true ? undefined : title}
          onBlur={onBlur}
          onChange={updateValue}
          required={isRequiredField}
          {...errorManagerRes}
        >
          {elems.map(({ value, label }) => (
            <MenuItem key={value} value={value}>
              {foreignKeyParams.entityName !== "certificateTypes" ? label : getTranslatedCertificateTypeString(value)}
            </MenuItem>
          ))}
        </Select>
        {errorManagerRes.error && !isUnit && (
          <FormHelperText sx={{ color: ordinaryColors.error, marginLeft: "0px", fontSize: "12px" }}>
            {errorManagerRes.helperText}
          </FormHelperText>
        )}
      </FormControl>
    </>
  );
};

export default ForeignKeySelector;
