export const toBase64 = (file: File[]) =>
  new Promise<string>((resolve, reject) => {
    if (file.length) {
      const reader = new FileReader();

      reader.readAsDataURL(file[0]);

      reader.onload = () => resolve((reader.result as string).split(";base64,")[1]);
      reader.onerror = (error) => reject(error);
    }
  });
