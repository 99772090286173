import { Box } from "@mui/system";
import ResetFilterButton from "components/molecules/ResetFilterButton";
import React from "react";
import { useRecoilValue, useResetRecoilState } from "recoil";
import ordinaryColors from "themes/ordinaryColors";
import { translations } from "translations";
import {
  filterValueLengthAtom,
  maxDateAtom,
  minDateAtom,
  openTaskListFilterAtom,
} from "../State/manageFiltersInOpenTaskList";
import {
  DividerHorizontalSC,
  FilterOpenTasksSC,
  FilterOpenTasksSCTitle,
  StyledLabelForDateRangeFilter,
} from "../styles";
import OpenTaskDateRangeFilter from "./OpenTaskDateRangeFilter";
import OpenTaskDropdownFilterMultiSelect from "./OpenTaskDropdownFilterMultiSelect";

interface Props {}

const CustomFilterComponents: React.FC<Props> = () => {
  const resetOpenTaskListFilter = useResetRecoilState(openTaskListFilterAtom);
  const resetMaxDate = useResetRecoilState(maxDateAtom);
  const resetMinDate = useResetRecoilState(minDateAtom);
  const filterValueLength = useRecoilValue(filterValueLengthAtom);
  const useResetAllFilters = () => {
    resetOpenTaskListFilter();
    resetMaxDate();
    resetMinDate();
  };

  return (
    <>
      <FilterOpenTasksSC>
        <FilterOpenTasksSCTitle>{translations.pages.certificateValidation.filters}</FilterOpenTasksSCTitle>
        <OpenTaskDropdownFilterMultiSelect
          title={translations.pages.certificateValidation.label}
          filterType="issueType"
        />
        <OpenTaskDropdownFilterMultiSelect
          title={translations.entities.certificate.manufacturer}
          filterType="manufacturer"
        />
        <StyledLabelForDateRangeFilter>
          {translations.pages.certificateValidation.dateRange}
        </StyledLabelForDateRangeFilter>
        <OpenTaskDateRangeFilter />
        <DividerHorizontalSC />
        <Box sx={{ alignItems: "center", justifyContent: "center", width: "100%", display: "flex" }}>
          <ResetFilterButton
            color={filterValueLength && filterValueLength > 0 ? ordinaryColors.grey700 : ordinaryColors.grey400}
            title={translations.globals.button.reset}
            resetFilters={useResetAllFilters}
          />
        </Box>
      </FilterOpenTasksSC>
    </>
  );
};

export default CustomFilterComponents;
