import { useCreateUnitBridgeUsersMutation } from "graphqlBase/mutationCreateUnitBridgeUser/__generated__/mutationCreateUnitBridgeUsers";
import { useDeleteUnitBridgeUsersMutation } from "graphqlBase/mutationCreateUnitBridgeUser/__generated__/mutationDeleteUnitBridgeUsers";

interface CreateUnitBridgeUsersFunctionProps {
  unitId: string;
  userId: string | undefined;
  refetchAfterUnitBridgeUsersMutations: () => Promise<void>;
}
interface DeleteUnitBridgeUserFunctionProps {
  unitBridgeUserId: string | undefined;
  refetchAfterUnitBridgeUsersMutations: () => Promise<void>;
}

export default function useUnitBridgeUsersMutations() {
  const [createUnitBridgeUserMutation] = useCreateUnitBridgeUsersMutation();
  const [deleteUnitBridgeUserMutation] = useDeleteUnitBridgeUsersMutation();
  const createUnitBridgeUsersFunction = ({
    unitId,
    userId,
    refetchAfterUnitBridgeUsersMutations,
  }: CreateUnitBridgeUsersFunctionProps) => {
    createUnitBridgeUserMutation({
      variables: {
        unitBridgeUsers: [{ unitId: unitId, userId: userId ?? " " }],
      },
    }).then(() => refetchAfterUnitBridgeUsersMutations());
  };
  const deleteUnitBridgeUserFunction = ({
    unitBridgeUserId,
    refetchAfterUnitBridgeUsersMutations,
  }: DeleteUnitBridgeUserFunctionProps) => {
    deleteUnitBridgeUserMutation({
      variables: {
        ids: [unitBridgeUserId ?? ""],
      },
    }).then(() => refetchAfterUnitBridgeUsersMutations());
  };

  return { createUnitBridgeUsersFunction, deleteUnitBridgeUserFunction };
}
