import { Localization, Updaters } from "../formGen.model";
import { QqlFormGenConfig } from "../types";
import fieldState from "./fieldState";
import FormGenerator from "./RenderChilds";
import { makeBride, getNextBridgeState } from "../GenericBrigdeUtils";
import { BridgeParams } from "../GenericBrigdeUtils/BridgeParamTypes";
import { useMemo } from "react";

type ActionFunction = ({ key, obj }: { key: string; obj: any; root: any }) => void;

interface KeyActions {
  [key: string]: ActionFunction;
}

const language = (localization: Localization) => {};
const translationPefixed = (str: string) => (process.env.REACT_APP_TEXTTOOLS === "true" ? `tr_${str}__tr` : str);
// const addInjection (id:string,)

export default (params: any, config: QqlFormGenConfig<{}>, localization: Localization, self: FormGenerator) => {
  const keyActions: KeyActions = {
    multiLangStrings: ({ key, obj, root }) => {
      const currentLanuage = localization.getLanguage(),
        itemLanguages = Object.keys(obj);

      if (itemLanguages.includes(currentLanuage)) return translationPefixed(obj[currentLanuage]);
      if (itemLanguages.includes(config.languages[0])) return translationPefixed(obj[config.languages[0]]);
      return "";
    },
    _validateOnField: ({ key, obj, root }) => {
      return self.data ? self.data[obj[key]] : null;
    },
    bridgeParams: ({ key, obj, root }) => {
      const { bridgeParams, ...rest } = Object.assign({}, obj) as {
        bridgeParams: BridgeParams;
      };
      const updaters = (ids: string[]) => {
        const oldIds: string[] = (self.showData() ?? {})[bridgeParams.bridgeEntityName] ?? [];
        if (
          oldIds.length !== ids.length ||
          (oldIds.some((id) => !ids.includes(id)) && ids.some((id) => !oldIds.includes(id)))
        ) {
          self.setTouchedFields(bridgeParams.bridgeEntityName);
          self.update({ [bridgeParams.bridgeEntityName]: ids });
        }
      };
      const id = self.variables.id ?? undefined;
      const { useBrigePre, saver } = useMemo(() => makeBride({ bridgeParams, client: self.client, updaters, id }), []);
      const useBridge = useBrigePre({ id });
      const usedBridge = useBridge();
      root.useBridge = useBridge;
      if (bridgeParams.saveOn === "onSubmit" && !self.bridgeSaves[bridgeParams.bridgeEntityName]) {
        const fullSaver = async (idInner: string) => {
          const saverInner = saver({ client: self.client, bridgeParams });
          const { adds, removes } = await saverInner(idInner);
          if (!adds.length && !removes.length) return { adds, removes };
          const { get, set } = await usedBridge.bridgeStateCallBack();
          const prev = await get();
          const nextState = getNextBridgeState({ prev, adds, removes });
          await set(nextState);
          return { adds, removes };
        };

        self.bridgeSaves[bridgeParams.bridgeEntityName] = fullSaver;
      }

      delete root.bridgeParams;
      return rest;
    },
  };
  ``;
  const actionsKeys = Object.keys(keyActions);

  const walkObj = ({ obj, root }: { obj: any; root: any }) => {
    Object.keys(obj).forEach((key) => {
      // console.log(key, obj[key]);
      if (obj[key] && typeof obj[key] === "string" && obj[key].includes("raftValuePick$%$")) {
        // console.log(obj[key]);
        const id = `${key}${obj[key]}` || "dd";
        const stateEffects = fieldState({
          valueIn: null,
          idPre: id,
          saveData: self.saveData,
          onDefocus: () => self.setTouchedFields(id),
        });
        const dataId = obj[key].replace("raftValuePick$%$", "").replace("$%$raftValuePick", "");

        const updatersIndex = self.updaters.findIndex((u) => u.id === id);
        if (updatersIndex > -1) {
          self.updaters[updatersIndex] = { dataId, stateEffects, id };
        } else {
          self.updaters.push({ dataId, stateEffects, id });
        }
        obj[key] = stateEffects;
      }
      if (obj[key] && typeof obj[key] !== "boolean" && typeof obj[key] !== "string" && typeof obj[key] !== "number") {
        obj[key] = walkObj({
          root,
          // @ts-ignore
          obj: Array.isArray(obj[key]) ? [...obj[key]] : { ...obj[key] },
        });
      }
      if (actionsKeys.includes(key)) {
        obj = keyActions[key]({ key, obj, root });
      }
    });
    return obj;
    //   if (obj.uiTypeDisplay && !obj.title) obj.title = obj.uiTypeDisplay;
  };
  walkObj({ obj: params, root: params });

  // console.log(params);
  return { ...params, raftLanguage: localization.getLanguage() };
};
