import styled from "@emotion/styled";
import { Button } from "@mui/material";
import React from "react";
import ordinaryColors from "themes/ordinaryColors";

interface Props {
  onClick?: () => void;
  title: string;
  autoFocus?: boolean;
  disabled?: boolean;
  flexibleWidth?: boolean;
}

const StyledAddButton = styled(Button)<{ disabled: boolean | undefined; flexibleWidth?: boolean | undefined }>(
  ({ disabled, flexibleWidth }) => ({
    width: flexibleWidth ? "fit-content" : "99px",
    height: "42px",
    padding: "8px 22px",
    borderRadius: "0px",
    gap: "8px",
    marginLeft: "16px",
    backgroundColor: disabled ? ordinaryColors.functionalLightGrey : ordinaryColors.primaryBrand,
    color: ordinaryColors.white,
    ":hover": { backgroundColor: ordinaryColors.primaryBrandHover },
    "&.Mui-disabled": {
      color: ordinaryColors.white,
    },
  })
);

const AddButton: React.FC<Props> = ({ onClick, title, autoFocus, disabled, flexibleWidth }) => {
  return (
    <StyledAddButton onClick={onClick} autoFocus={autoFocus} disabled={disabled} flexibleWidth={flexibleWidth}>
      {title}
    </StyledAddButton>
  );
};

export default AddButton;
