import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import Button from "@mui/material/Button";
import ordinaryColors from "themes/ordinaryColors";

export const StyledExcellUploadButton = styled(Button)(({ theme }) => ({
  width: "100%",
  fontWeight: "500",
  fontSize: "15px",
  lineHeight: "26px",
  letter: "0.46px",
  borderRadius: "0px",
  height: "43px",
}));

export const StyledTitleTypography = styled(Typography)(({ theme }) => ({
  fontSize: "32px",
  fontWeight: "300",
  lineHeight: "40px",
  letterSpacing: "0.25px",
  textAlign: "left",
}));

export const StyledDescriptionTypography = styled(Typography)(({ theme }) => ({
  fontWeight: "400",
  fontSize: "18px",
  lineHeight: "26px",
  letter: "0.17px",
}));
export const StyledAddButton = styled(Button)(({ theme }) => ({
  width: "100%",
  borderRadius: "0px",
  variant: "outlined",
  border: "1px solid",
  borderColor: ordinaryColors.functionalBlack,
  minWidth: "64px",
  color: ordinaryColors.functionalBlack,
}));
