import * as React from "react";

import Props from "./index.d";

interface GFCFieldSet_NoLayoutGroupExtended extends Props {
  children: React.ReactNode;
}

const NoLayoutGroup: React.FC<GFCFieldSet_NoLayoutGroupExtended> = ({ children }) => {
  return <>{children}</>;
};

export default NoLayoutGroup;
