import { useGetIssueTypeNamesQuery } from "graphqlBase/issue/__generated__/getIssueTypeNames";
import { useGetTenantsWithCertificatesToValidateQuery } from "graphqlBase/opentTaskListFilters/__generated__/getTenantsWithCertificatesToValidate";
import { useGetUserInfo } from "lib/userInfo";
import { useEffect } from "react";
import { atom, useSetRecoilState } from "recoil";
import useGetTenantNameById from "utils/useGetTenantNameById";

export type FilterItemStrings = {
  key: string;
  value: string;
};

export const tenantNameAtom = atom<FilterItemStrings[] | undefined>({
  key: "tenantNameAtom",
  default: [],
});
export const issueTypeNamesAtom = atom<FilterItemStrings[] | undefined>({
  key: "issueTypeNamesAtom",
  default: [],
});

export default () => {
  const userInfo = useGetUserInfo();
  const { tenantNameById } = useGetTenantNameById();
  const { data: issueTypeNamesData } = useGetIssueTypeNamesQuery();
  const setIssueTypeNames = useSetRecoilState(issueTypeNamesAtom);
  const setTenantNameAtom = useSetRecoilState(tenantNameAtom);
  const { data: certificateHolderData } = useGetTenantsWithCertificatesToValidateQuery({
    variables: {
      where: {
        issueStatus: {
          issueStatusName: {
            or: [
              {
                eq: "Open",
              },
              {
                eq: "Reopened",
              },
              {
                eq: "Rejected",
              },
            ],
          },
        },
        assignedToTenantId: {
          eq: userInfo?.tenantId,
        },
      },
    },
  });

  useEffect(() => {
    const uniqueManufacturerIds = Array.from(
      new Set(certificateHolderData?.issues?.items?.map((item) => item.certificate?.certificateHolderId))
    );

    setTenantNameAtom(
      uniqueManufacturerIds.map((id) => {
        return {
          key: id ?? "",
          value: tenantNameById[id] ?? "",
        };
      })
    );
  }, [certificateHolderData?.issues?.items, setTenantNameAtom, tenantNameById]);

  useEffect(() => {
    setIssueTypeNames(issueTypeNamesData?.issueTypes?.items ?? []);
  }, [issueTypeNamesData?.issueTypes?.items, setIssueTypeNames]);

  return null;
};
