import { categoriesInvalidOnNorm5, isNormEqualOrGreater10 } from "pages/UploadCertificate/validationRules/utils";

import { getCertificateCreateFieldSelector } from "raft/CertificateForm";
import { useMemo } from "react";
import { useRecoilValue } from "recoil";
import raftEnums from "raft/internal/enums";

const categories = raftEnums.category;

export const useGetCategoryOptions = () => {
  const normListAtom = useMemo(() => getCertificateCreateFieldSelector({})("__normList"), []);
  const activeNorms = useRecoilValue(normListAtom);
  const options = useMemo(() => {
    const normIs10Up = isNormEqualOrGreater10({ __normList: activeNorms });
    const options = Object.entries(categories).map(([value, label]) => ({
      disabled: !normIs10Up && categoriesInvalidOnNorm5.includes(value),
      value,
      label,
    }));
    return options;
  }, [activeNorms]);

  return options;
};
