import { useGridApiContext } from "@mui/x-data-grid-pro";
import { StyledGridToolbarQuickFilter } from "components/templates/dataGridTable/styles";
import { StringOperationFilterInput } from "graphqlBase/types";
import React, { useEffect } from "react";
import { atom, useRecoilState } from "recoil";
import translations from "translations";

interface Props {}
export const unitQuickFilterValueAtom = atom<StringOperationFilterInput | undefined>({
  key: "unitQuickFilterValue",
  default: undefined,
});
const UnitsQuickFilter: React.FC<Props> = () => {
  const [value, setValue] = useRecoilState(unitQuickFilterValueAtom);
  const apiRef = useGridApiContext();

  useEffect(() => {
    if (!value) return;
    apiRef.current.setQuickFilterValues([value]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiRef]);

  return (
    <>
      <StyledGridToolbarQuickFilter
        onBlur={(e) => {
          setValue(e.target.value as unknown as StringOperationFilterInput | undefined);
        }}
        variant="outlined"
        placeholder={translations.globals.table.searchTableDefault}
      />
    </>
  );
};

export default UnitsQuickFilter;
