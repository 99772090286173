import { AccordionStates, accordionStates } from "components/molecules/Accordion/recoil";
import AccordionStateButtons from "components/molecules/AccordionStateButtons";
import * as React from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import GFCFieldSet_AccordionWrapper from "./index.d";
import ResetButton from "components/molecules/ResetButton";
import translations from "translations";
import resetIcon from "./ResetChangesIcon.svg";
import disabledResetIcon from "./DisabledResetChangesIcon.svg";
import {
  StyledContent,
  contentSx,
  StyledResetBox,
  resetBoxSx,
  StyledTitle,
  titleSx,
  contentBoxSx,
  labelSx,
  resetIconSx,
} from "./styles";
import { discardChangesState } from "components/molecules/DiscardChangesWarning/recoil";
import { useEffect } from "react";

interface AccordionWrapperProps extends GFCFieldSet_AccordionWrapper {
  children: React.ReactNode[];
}

export const setDisabled = (accordionStates: AccordionStates, buttonType: "expand" | "collapse") => {
  return (
    accordionStates.filter((accordionState) => accordionState.state === (buttonType === "expand" ? false : true))
      .length === 0
  );
};

const AccordionWrapper: React.FC<AccordionWrapperProps> = (props) => {
  const [value, setValue] = useRecoilState(accordionStates);
  const setDiscardChangesState = useSetRecoilState(discardChangesState);
  const resetIconSrc = !props.data?.state ? disabledResetIcon : resetIcon;
  const disabled = !props.data?.state;
  useEffect(() => {
    setValue([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <StyledContent contentSx={contentSx} className={props.className}>
      <StyledResetBox resetBoxSx={resetBoxSx}>
        {props.title && <StyledTitle titleSx={titleSx}>{props.title}</StyledTitle>}
        {props.hasResetButton && (
          <ResetButton
            contentBoxSx={contentBoxSx}
            labelSx={labelSx({ disabled: disabled })}
            label={translations.pages.certificateUpload.resetButtonLabel}
            resetIconSrc={resetIconSrc}
            resetIconSx={resetIconSx({ disabled: disabled })}
            setState={setDiscardChangesState}
            disabled={disabled}
          />
        )}
      </StyledResetBox>
      <AccordionStateButtons accordionStates={value} setDisabled={setDisabled} setState={setValue} {...props} />
      {props.children}
    </StyledContent>
  );
};

export default AccordionWrapper;
