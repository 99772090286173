import { useGetsoftwareVersionsQuery } from "graphqlBase/SoftwareVersions/__generated__/getsoftwareVersions";
import { useCallback } from "react";

const useGetItemtext = () => {
  const { data } = useGetsoftwareVersionsQuery();
  return useCallback(
    (id: string) => {
      const item = data?.softwareVersions?.items?.find((item) => item.id === id);
      if (!item) return "";
      return `${item.softwareType}: ${item.version}`;
    },
    [data]
  );
};
export default useGetItemtext;
