import * as React from "react";
import CreateSoftware from "./CreateSoftware";
import { useManageSoftwSoftwarelist } from "./CreateSoftware/useManageNormlist";
import Props from "./index.d";
import SoftwareList from "./SoftwareBridgeList";

const CertificateSoftware: React.FC<Props> = (props) => {
  const { useBridge, className } = props;
  const { useBridgeArrayState } = useBridge();

  const [SoftwareBridgeIds, setSoftwareBridgeIds] = useBridgeArrayState();
  useManageSoftwSoftwarelist(useBridgeArrayState);
  const removeSoftware = (SoftwareBridgeId: string) => {
    setSoftwareBridgeIds((prev) => prev.filter((id) => id !== SoftwareBridgeId));
  };
  const addSoftwareBridgeId = (SoftwareBridgeId: string) => {
    setSoftwareBridgeIds((prev) => [...prev, SoftwareBridgeId]);
  };

  return (
    <div className={className}>
      <CreateSoftware addSoftwareBridgeId={addSoftwareBridgeId} softwareBridgeIds={SoftwareBridgeIds} />
      <SoftwareList removeSoftware={removeSoftware} softwareBridgeIds={SoftwareBridgeIds} />
    </div>
  );
};

export default CertificateSoftware;
