import { uploadFiletypeListSelector } from "pages/UploadCertificate/state/uploadCertificateFileState";
import * as React from "react";
import { useRecoilValue } from "recoil";
import Props from "./index.d";

const CertificateFileUploadList: React.FC<Props> = (props) => {
  const fileTypes = useRecoilValue(uploadFiletypeListSelector);
  const {
    data: { setAndSave, state },
  } = props;

  React.useEffect(() => {
    if (state !== fileTypes && (state || fileTypes.length)) {
      setAndSave(fileTypes);
    }
  }, [setAndSave, fileTypes, state]);

  return null;
};

export default CertificateFileUploadList;
