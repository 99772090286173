import ordinaryColors from "themes/ordinaryColors";
import PrivacyTipSharpIcon from "@mui/icons-material/PrivacyTipSharp";
import ApprovalSharpIcon from "@mui/icons-material/ApprovalSharp";
import VerifiedSharpIcon from "@mui/icons-material/VerifiedSharp";
import ManageSearchSharpIcon from "@mui/icons-material/ManageSearchSharp";
import { translations } from "translations";

type switchIssueTypeStatusProps = {
  issueTypeName: string;
};

export default function switchIssueTypeStatus({ issueTypeName }: switchIssueTypeStatusProps) {
  switch (issueTypeName) {
    case "Error Correction":
      return {
        iconBackgroundColor: ordinaryColors.errorCorrection,
        icon: <PrivacyTipSharpIcon sx={{ color: ordinaryColors.grey700, background: "transparent" }} />,
        label: translations.pages.certificateValidation.issueTypes["b01df1c0-0ea3-5313-bd0c-54931887c428"],
      };

    case "Certificate Validation":
      return {
        iconBackgroundColor: ordinaryColors.validateCertificate,
        icon: <ApprovalSharpIcon htmlColor={ordinaryColors.grey700} />,
        label: translations.pages.certificateValidation.issueTypes["9524be5a-6656-548a-a3c1-754b20df564a"],
      };

    case "Import":
      return {
        iconBackgroundColor: ordinaryColors.import,
        icon: <VerifiedSharpIcon htmlColor={ordinaryColors.grey700} />,
        label: translations.pages.certificateValidation.issueTypes["b3ef1a67-6644-568f-b8d2-898b2d897562"],
      };

    case "Error Report":
      return {
        iconBackgroundColor: ordinaryColors.errorReport,
        icon: <ManageSearchSharpIcon htmlColor={ordinaryColors.grey700} />,
        label: translations.pages.certificateValidation.issueTypes["4e7118b6-4883-567a-b4ad-ab245717ff08"],
      };

    default:
      return { iconBackgroundColor: "fff", icon: null };
  }
}
