import { isNotRecoilDefaultValue } from "lib/helper/isNotRecoilsDefaultValue";
import { atom, atomFamily, selector } from "recoil";
import { ActiveTaskSelection, ActiveTaskStatusAndComment } from "./types";

export const activeTaskSelectionState = atom<ActiveTaskSelection | null>({
  key: "activeTaskSelectionState",
  default: null,
});

export const currentActiveTaskStatusSelector = selector<ActiveTaskStatusAndComment>({
  key: "currentActiveTaskStatusSelector",
  get: ({ get }) => {
    const activeTaskSelection = get(activeTaskSelectionState);
    if (!activeTaskSelection) return undefined;
    const { persistMode, comment, certificateHolderId } = activeTaskSelection ?? {
      persistMode: undefined,
      comment: undefined,
    };
    return { persistMode, comment, certificateHolderId };
  },
  set: ({ set, get }, newValue) => {
    const activeTaskSelection = get(activeTaskSelectionState);

    if (isNotRecoilDefaultValue(newValue) && newValue !== undefined) {
      if (activeTaskSelection) {
        const newState: ActiveTaskSelection = {
          ...activeTaskSelection,
          edited: true,
          ...newValue,
        };
        set(activeTaskSelectionState, newState);
      }
    }
  },
});
export const refetchRecoil = atomFamily<() => Promise<void>, string>({
  key: "refetch",
  default: () => Promise.resolve(),
});
