import { styled, Theme } from "@mui/material";
import Pagination from "@mui/material/Pagination";
import { Box } from "@mui/system";
import {
  DataGridPro,
  GridFooterContainer,
  GridSelectedRowCount,
  GridToolbarContainer,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid-pro";
import ordinaryColors from "themes/ordinaryColors";
import { DataGridComponentType } from "./serverSide/types";

interface StyledGridType extends DataGridComponentType {
  props: { height: string };
}
export const StyledDataGrid = styled(DataGridPro)(
  ({ theme, props }: { props: { height: string; toolbarBackgroundColor: string }; theme: Theme }) => {
    const { doveGrayClassic, altoGray } = theme.palette.ordinaryColors;
    return {
      ".MuiDataGrid-toolbarContainer .MuiButton-text": {
        fontSize: "14px",
      },
      ".MuiOutlinedInput-notchedOutline": {
        borderWidth: "1px",
        borderColor: ordinaryColors.functionalInputFieldStroke,
      },
      ".MuiOutlinedInput-root": {
        borderRadius: "0px",
        backgroundColor: "white",
        // border: "0,5rem solid",
        // " &:hover": {
        //   borderColor: ordinaryColors.functionalBlack,
        // },
      },
      backgroundColor: theme.palette.background.default,
      "&&& .MuiDataGrid-toolbarContainer": {
        backgroundColor: props.toolbarBackgroundColor,
        padding: "0px",
      },

      ".css-ql7ovq": {
        backgroundColor: props.toolbarBackgroundColor,
        padding: "0px",
      },

      ".MuiDataGrid-cell": { paddingTop: "1rem", paddingBottom: "1rem", fontSize: "2rem" },
      border: "none",
      height: props.height,
      ".MuiButtonBase-root.MuiCheckbox-root": {
        // borderColor: ordinaryColors.primaryBrand,
        padding: 0,
        borderRadius: 0,
        height: 18,
        width: 18,
        // color: ordinaryColors.functionalLightGrey,
        ".MuiButtonBase-root.MuiCheckbox-root.Mui-checked": {
          color: ordinaryColors.primaryBrand,
        },
        ".MuiSvgIcon-root, .PrivateSwitchBase-input": {
          height: 18,
          width: 18,
        },
      },
      ".MuiDataGrid-columnHeader:focus-within, .MuiDataGrid-cell:focus-within": {
        outline: "none",
      },
      ".Mui-checked.MuiDataGrid-checkboxInput, .MuiButtonBase-root.MuiCheckbox-root.MuiCheckbox-indeterminate": {
        // color: "white",
        //backgroundColor: "#00000099",
        padding: 0,
        borderRadius: 5,
        // backgroundColor: theme.palette.text.secondary,
        ".MuiButtonBase-root.MuiCheckbox-root": {
          borderColor: ordinaryColors.primaryBrand,
        },
      },

      // ".MuiDataGrid-columnHeaders": {
      //   backgroundColor: "#F0F3F6",
      // },
      // ".MuiDataGrid-columnHeader": {
      //   "&:not(:last-child)": {
      //     borderRight: "1.5px solid #D3D8DD",
      //   },
      // },
      // ".MuiDataGrid-columnHeadersInner": { backgroundColor: "#F0F3F6" },
      //  ".MuiDataGrid-toolbarContainer": { backgroundColor: "#fff" },
      ".MuiDataGrid-columnHeaderTitle": {
        fontSize: "14px",
      },
      ".MuiDataGrid-columnSeparator": {
        visibility: "hidden",
      },
      // ".MuiDataGrid-columnHeaderTitleContainer": {
      //   backgroundColor: "#fff",
      // },
      ".MuiFormControlLabel-labelPlacementEnd": {
        marginLeft: 0,
        marginRight: 0,
      },
      ".MuiDataGrid-row.Mui-selected": {
        backgroundColor: "transparent",
      },
      ".MuiDataGrid-row": {
        // borderBottom: "0.1px solid #D3D8DD",
        // borderTop: "0.1px solid #D3D8DD",
        // borderBottom: "0.1px solid",
        // borderTop: "0.1px solid",
        borderColor: "divider",
        borderTopColor: theme.palette?.mode === "dark" ? doveGrayClassic : altoGray,
        borderBottomColor: theme.palette?.mode === "dark" ? doveGrayClassic : altoGray,
        ".MuiDataGrid-cell": {
          fontFamily: "var(--copytext-font-family)",
          fontSize: "18px",
          cursor: "default",
          letterSpacing: "0.17000000178813934px",
          lineHeight: "26px",
          fontWeight: "400",
          "@media (max-width: 992px)": { fontSize: "14px" },
        },
      },
      ".MuiTablePagination-actions": {
        "& svg": { width: "20px", top: "1px", left: "25px", color: "rgba(0, 0, 0, 0.54)" },
      },
      ".MuiPopover-paper": {
        borderRadius: "0px",
      },
      // to change the inputBase-root is dangerous because it will affect all the inputBase-root in the dataGrid
      // ".MuiInputBase-root": { "& svg": { width: "25px", top: "1px", left: "25px", color: "rgba(0, 0, 0, 0.54)" } },
      ".MuiTablePagination-displayedRows": {
        fontSize: "12px",
        color: "rgba(0, 0, 0, 0.87)",
      },
      ".MuiTablePagination-select": {
        fontSize: "12px",
        color: "rgba(0, 0, 0, 0.87)",
      },
      ".MuiTablePagination-selectLabel": {
        fontSize: "12px",
        color: "rgba(0, 0, 0, 0.6)",
      },
    };
  }
) as unknown as StyledGridType;

export const StyledContainer = styled("div")(({ props }: { props: { height: string } }) => {
  return {
    height: props.height,
    width: "100%",
  };
});

export const StyledPagination = styled(Pagination)({
  ".Mui-selected": {
    color: "white",
    backgroundColor: "black !important",
    borderRadius: "0px !important",
    opacity: 1,
    "&:hover": {
      backgroundColor: "black !important",
      borderRadius: "0px !important",
    },
  },
  ".MuiPaginationItem-root": {
    height: "30px",
    padding: 0,
    minWidth: "24px",
    width: "24px",
    fontFamily: "var(--copytext-font-family)",
    fontSize: "12px",
    ".Mui-selected": {
      fontFamily: "var(--copytext-font-family-bold)",
    },
    ".MuiButtonBase-root": {
      padding: 0,
    },
    ".MuiPaginationItem-icon": {
      height: "25px",
      width: "fit-content",
      fontSize: "25px",
      fontFamily: "var(--copytext-font-family)",
      fontWeight: "light",
      padding: 0,
    },
    "&:hover": {
      borderRadius: "0px !important",
    },
  },
});

export const StyledPageRowCount = styled("div")({
  fontFamily: "var(--copytext-font-family)",
  fontSize: "12px",
});

export const StyledGridFooterContainer = styled(GridFooterContainer)({
  backgroundColor: "#F0F3F6",
  padding: "5px 20px 5px 10px",
});

export const StyledGridTagsToolbarOptions = styled("div")({
  flexGrow: 3,
  display: "flex",
  alignItems: "center",
  flex: 3,
});

export const StyledGridActionsToolbarOptions = styled("div")({
  flexGrow: 3,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  flex: 3,
});

export const StyledGridSelectedRowCount = styled(GridSelectedRowCount)({
  fontFamily: "var(--copytext-font-family)",
  fontSize: "15px",
  "&.MuiDataGrid-selectedRowCount": {
    display: "flex",
    alignItems: "center",
    height: "54px",
  },
});

export const StyledTagsButton = styled("button")({
  all: "unset",
  display: "flex",
  marginLeft: "4px",
  fontFamily: "var(--copytext-font-family)",
  fontSize: "16px",
  alignItems: "center",
  justifyContent: "center",
  color: "#888E94",
  lineHeight: 0,
});

export const StyledActionButton = styled("button")({
  all: "unset",
  display: "flex",
  marginLeft: "2.5em",
  alignItems: "center",
  justifyContent: "space-between",
  color: "#888E94",
  lineHeight: 0,
});

export const tableComponentStyles = {
  "& .MuiDataGrid-filterForm": { p: 2 },
  "& .MuiDataGrid-filterFormLinkOperatorInput": { mr: 2 },
  "& .MuiDataGrid-filterFormColumnInput": { mr: 2, width: 150 },
  "& .MuiDataGrid-filterFormOperatorInput": { mr: 2 },
  "& .MuiDataGrid-filterFormValueInput": { width: 200 },
  "& .MuiOutlinedInput-root": { borderRadius: 0, "&:hover": { backgroundColor: "white" } },
  "& .MuiInputLabel-root": { marginTop: "4px" },
};

export const TableContainerSC = styled("div")(
  ({ withMargin }: { withMargin?: boolean }) => `
  min-width: 100%;
  background-color: #F0F3F6;
  margin: ${withMargin ? "0 20px 10px 30px" : 0};
`
);

export const OverlayContainerSC = styled("div")(
  ({ theme }) => `
        background-color: ${theme.palette.background.paper};
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        min-height: 100%;
`
);

export const tableTitleStyles = { height: "100%", display: "flex", alignItems: "center", marginRight: "3em" };

// export const filterButtonStyles = {
//   fontFamily: "var(--copytext-font-family)",
//   fontSize: "15px",
//   textTransform: "none",
//   lineHeight: 1,
//   "&:hover": { backgroundColor: "transparent" },
// };

// export const toolbarItemsBoxStyles = {
//   display: "flex",
//   borderRight: "1.5px solid #D3D8DD",
//   paddingRight: "25px",
//   height: "54px",
// };

export const StyledToolbarBox = styled(Box)(
  ({ theme, props }: { props: { unitsToolbarBackgroundColor?: string }; theme?: Theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    // cursor: "pointer",
    maxWidth: "100%",
    paddingTop: "1rem",
    backgroundColor: props.unitsToolbarBackgroundColor,
    [theme?.breakpoints.down(1400).toString() ?? ""]: {
      fontSize: "0px",
      color: theme?.palette.text.primary,
    },
  })
);

export const StyledToolbarContainerStart = styled(GridToolbarContainer)(({ theme }) => ({
  display: "flex",
  gap: "1.5rem",
  alignItems: "center",
  "& .MuiButton-text": {
    color: theme.palette.text.primary,
  },
  "& .MuiButton-root": { color: ordinaryColors.functionalDarkGrey },
}));

export const StyledToolbarContainerEnd = styled(GridToolbarContainer)({
  display: "flex",
  gap: "1rem",
  alignItems: "center",
  "& .MuiIconButton-root": { color: ordinaryColors.functionalDarkGrey },
  "& .css-241ux4-MuiButtonBase-root-MuiIconButton-root:hover": {
    background: ordinaryColors.unnamed0,
  },
});

export const StyledGridToolbarQuickFilter = styled(GridToolbarQuickFilter)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  height: "85px",
  verticalAlign: "center",
  justifyContent: "center",
  paddingBottom: "0",
  ".MuiOutlinedInput-notchedOutline": {
    // borderWidth: "1px",
    borderColor: ordinaryColors.functionalLightGreyBack,
  },
  ".MuiOutlinedInput-input": {
    padding: 0,
    height: "36px",
  },
  ".MuiOutlinedInput-root": {
    backgroundColor: ordinaryColors.concreteSolid,
    width: "400px",
    height: "43px",
    borderRadius: "0px",
  },
  ".MuiInputBase-adornedStart": { color: "rgba(0, 0, 0, 0.6)" },
  // ".MuiSvgIcon-root": { height: "20px", width: "20px" },
  ".MuiInputBase-input": {
    color: "rgba(0, 0, 0, 0.9)",
    marginLeft: "6px",
  },
}));
