import type * as Types from '../../types';

import client from "apollo/client";
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetCertificatesForRelacementQueryVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.CertificateFilterInput>;
  take?: Types.InputMaybe<Types.Scalars['Int']>;
  order?: Types.InputMaybe<Array<Types.CertificateSortInput> | Types.CertificateSortInput>;
}>;


export type GetCertificatesForRelacementQuery = { __typename?: 'Query', certificates?: { __typename?: 'CertificateCollectionSegment', items?: Array<{ __typename?: 'Certificate', id: string, certificateNumber: string, revision?: number | null, certificateValidityStatus: { __typename?: 'CertificateValidityStatus', id: string, certificateValidityStatusName: string } }> | null } | null };


        export type ExecGetCertificatesForRelacementOptions = Omit<
        Apollo.QueryOptions<
        GetCertificatesForRelacementQueryVariables, GetCertificatesForRelacementQuery
        >,
        "query"
      >;
    export const execGetCertificatesForRelacementQuery = (options: ExecGetCertificatesForRelacementOptions) =>
    client.query< GetCertificatesForRelacementQuery, GetCertificatesForRelacementQueryVariables>({
      ...options,
      query: GetCertificatesForRelacementDocument,
    });
    

export const GetCertificatesForRelacementDocument = gql`
    query getCertificatesForRelacement($where: CertificateFilterInput, $take: Int, $order: [CertificateSortInput!]) {
  certificates(where: $where, take: $take, order: $order) {
    items {
      id
      certificateNumber
      revision
      certificateValidityStatus {
        id
        certificateValidityStatusName
      }
    }
  }
}
    `;

/**
 * __useGetCertificatesForRelacementQuery__
 *
 * To run a query within a React component, call `useGetCertificatesForRelacementQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCertificatesForRelacementQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCertificatesForRelacementQuery({
 *   variables: {
 *      where: // value for 'where'
 *      take: // value for 'take'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useGetCertificatesForRelacementQuery(baseOptions?: Apollo.QueryHookOptions<GetCertificatesForRelacementQuery, GetCertificatesForRelacementQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCertificatesForRelacementQuery, GetCertificatesForRelacementQueryVariables>(GetCertificatesForRelacementDocument, options);
      }
export function useGetCertificatesForRelacementLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCertificatesForRelacementQuery, GetCertificatesForRelacementQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCertificatesForRelacementQuery, GetCertificatesForRelacementQueryVariables>(GetCertificatesForRelacementDocument, options);
        }
export type GetCertificatesForRelacementQueryHookResult = ReturnType<typeof useGetCertificatesForRelacementQuery>;
export type GetCertificatesForRelacementLazyQueryHookResult = ReturnType<typeof useGetCertificatesForRelacementLazyQuery>;
export type GetCertificatesForRelacementQueryResult = Apollo.QueryResult<GetCertificatesForRelacementQuery, GetCertificatesForRelacementQueryVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
          export type ResultType<T> = Types.ResultType<T>;
          export type PaginatedResultType<T> = Types.PaginatedResultType<T>;
          export type NonMaybe<T> = Types.NonMaybe<T>;
          export type Maybe<T> = Types.Maybe<T>;