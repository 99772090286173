import * as React from "react";
import ordinaryColors from "themes/ordinaryColors";
import Props from "./index.d";

const ErrorForTextField: React.FC<Props> = (props) => {
  const { errorManager, className } = props;

  const errorManagerRes = errorManager({
    errorJoiner: (errors) => {
      return (
        <>
          {errors.map((e) => (
            <>
              {e}
              <br />
            </>
          ))}{" "}
        </>
      );
    },
  });

  return (
    <>
      {errorManagerRes.error && (
        <div className={className} style={{ color: ordinaryColors.error, fontSize: "12px", margin: "3px 0 0 0" }}>
          {errorManagerRes.helperText}
        </div>
      )}
    </>
  );
};

export default ErrorForTextField;
