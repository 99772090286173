import styled from "@emotion/styled";
import { Box } from "@mui/system";
import * as React from "react";
import ordinaryColors from "themes/ordinaryColors";
import GFCFieldSet_AccordionFieldWrapper from "./index.d";

interface AccordionFieldWrapperProps extends GFCFieldSet_AccordionFieldWrapper {
  children: React.ReactNode;
}

const StyledBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: "48px",
});
const StyledTitle = styled("div")({
  paddingTop: "4px",
  width: "15rem",
  // minWidth: "20rem",
  color: ordinaryColors.functionalBlack,
  textAlign: "right",
  fontFeatureSettings: "'clig' off, 'liga' off",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "150% /* 24px */",
  letterSpacing: "0.15px",
});

const AccordionFieldWrapper: React.FC<AccordionFieldWrapperProps> = (props) => {
  return (
    <Box sx={{ display: "flex", minWidth: "25rem", justifyContent: "center" }}>
      <StyledBox className={props.className}>
        {props.title && <StyledTitle>{props.title}</StyledTitle>}
        <Box
          sx={{
            width: "15rem",
            textAlign: "left",
            display: "flex",
            justifyContent: "flex-start",
          }}
        >
          {props.children}
        </Box>
      </StyledBox>
    </Box>
  );
};

export default AccordionFieldWrapper;
