import { GridApiPro } from "@mui/x-data-grid-pro/models/gridApiPro";
import * as React from "react";
import { getRanHex } from "./utils/helpers";

export function addNewRowPre<R extends object>(apiRef: React.MutableRefObject<GridApiPro>) {
  return function ({
    defaultRow,
    newIdPrefix = "new",
    columnToFocus,
  }: {
    defaultRow: R;
    newIdPrefix?: string;
    columnToFocus?: string;
  }) {
    return () => {
      const id = `${newIdPrefix}${getRanHex(32 - newIdPrefix.length)}`;

      apiRef.current.updateRows([{ isNew: true, ...defaultRow, id }]);

      setTimeout(() => {
        apiRef.current.scrollToIndexes({
          rowIndex: apiRef.current.getRowsCount() - 1,
        });
        const field = apiRef.current.getVisibleColumns().reduce((field, column) => {
          if (!field.length || column.field === columnToFocus) return column.field;
          return field;
        }, "");

        apiRef.current.startCellEditMode({ id, field });
        apiRef.current.setCellFocus(id, field);
      });
    };
  };
}
