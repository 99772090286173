import * as React from "react";
import Props from "./index.d";
import UnitWrapper from "components/formElements/fieldSets/UnitWrapper";
import { StyledContentContainer } from "components/formElements/customElements/UnitFormErrorDisplay/styles";
import { UnitFormErrorDisplay } from "components/formElements/customElements/UnitFormErrorDisplay";
import { StyledForeignKeySelector } from "./styles";

const ForeignKeyUnit: React.FC<Props> = (props) => {
  const errorManagerRes = props.errorManager({
    errorJoiner: (errors) => errors.join(", "),
  });

  return (
    <UnitWrapper title={props.title ?? ""}>
      <StyledContentContainer>
        {UnitFormErrorDisplay(errorManagerRes)}
        <StyledForeignKeySelector {...props} {...errorManagerRes} isUnit />
      </StyledContentContainer>
    </UnitWrapper>
  );
};

export default ForeignKeyUnit;
