import { Box } from "@mui/system";
import React, { useEffect } from "react";
import { useRecoilState } from "recoil";
import { translations } from "translations";
import { dateRangeSliderFilterSelectorFamily, maxDateAtom, minDateAtom } from "../../State/manageFiltersInOpenTaskList";
import DesktopDatePickerMaxDate from "./DesktopDatePickerMaxDate";
import DesktopDatePickerMinDate from "./DesktopDatePickerMinDate";

interface Props {}

const OpenTaskDateRangeFilter: React.FC<Props> = () => {
  const [filters, setFilters] = useRecoilState(dateRangeSliderFilterSelectorFamily("createdAtRange"));
  const [minDate, setMinDate] = useRecoilState<Date | null>(minDateAtom);
  const [maxDate, setMaxDate] = useRecoilState<Date | null>(maxDateAtom);
  const handleChangeMinDate = (date: Date | null) => {
    if (date && isNaN(date.getTime())) return setMinDate(null);
    setMinDate(date);
  };

  const handleChangeMaxDate = (date: Date | null) => {
    if (date && isNaN(date.getTime())) return setMaxDate(null);
    setMaxDate(date);
  };

  useEffect(() => {
    if (minDate && maxDate) {
      setFilters([minDate, maxDate]);
    }
    if (!minDate && !maxDate && filters.length > 0) {
      setFilters([]);
    }
  }, [minDate, maxDate, setFilters, filters.length]);

  return (
    <Box sx={{ display: "flex", gap: "1rem", alignItems: "center" }}>
      <DesktopDatePickerMinDate
        handleChange={handleChangeMinDate}
        value={minDate}
        filters={filters}
        placeholder={translations.pages.certificateValidation.from}
      />
      -
      <DesktopDatePickerMaxDate
        handleChange={handleChangeMaxDate}
        value={maxDate}
        filters={filters}
        placeholder={translations.pages.certificateValidation.until}
      />
    </Box>
  );
};

export default OpenTaskDateRangeFilter;
