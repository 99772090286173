import { Box } from "@mui/system";
import * as React from "react";
import Props from "./index.d";
import Typography from "@mui/material/Typography";
import ordinaryColors from "themes/ordinaryColors";

const FormErrors: React.FC<Props> = (props) => {
  const {
    title,

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    data: { state, setstate, onBlur, updateValue, setNull, setAndSave },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    placeholder = "placeholder",
    disabled,
    testId,
    errorManager,
    isRequiredField,
    className,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ...rest
  } = props;

  if (!state) return null;
  if (!state.meta.submitWasInvalid) return null;
  const { errors } = state;
  console.log({ errors });

  return (
    <Box className={className}>
      {Object.entries(errors).map(([key, entry]) => {
        if (!entry) return null;
        const { messages, fieldName } = entry;
        return (
          <React.Fragment key={key}>
            <Box key={key} sx={{ color: ordinaryColors.error, margin: "2rem 0" }}>
              <Typography variant="body1">{fieldName} :</Typography>
              {messages.map(({ message }) => {
                return (
                  <Box key={message?.toString()} sx={{ marginLeft: "2rem" }}>
                    <Typography variant="body2">{message}</Typography>
                  </Box>
                );
              })}
            </Box>
          </React.Fragment>
        );
      })}
    </Box>
  );
};

export default FormErrors;
