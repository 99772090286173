import * as React from "react";
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import { SetterOrUpdater } from "recoil";
import Props from "./index.d";

const Checker = ({
  id,
  label,
  className,
  useRowSelected,
}: {
  id: string;
  label: string;
  className?: string | undefined;
  useRowSelected: (id: string) => [boolean, SetterOrUpdater<boolean>];
}) => {
  const [checked, setChecked] = useRowSelected(id);
  return (
    <FormGroup className={className}>
      <FormControlLabel
        control={<Checkbox key={id} checked={checked} onChange={() => setChecked((prev) => !prev)} />}
        label={label}
      />
    </FormGroup>
  );
};

const GenericBridge: React.FC<Props> = (props) => {
  const { useBridge, className } = props;
  const { useRowSelected, items } = useBridge();
  return (
    <div className={className}>
      {items.list.map((elem) => (
        <Checker key={elem.id} {...elem} useRowSelected={useRowSelected} />
      ))}
    </div>
  );

  return null;
};

export default GenericBridge;
