import { ValidateFormPropsInner as ValidateFormPropsInnerPre } from "../formGen.model";
export type ValidateFormPropsInner<T extends object> = ValidateFormPropsInnerPre<T, any>;
export type MaybePromise<T> = T | Promise<T>;

export type PendingRuleResult = Promise<RuleResult | undefined>;
export type PropertyKey = string | number | symbol;
export type AnyObject = Record<PropertyKey, unknown>;
export interface ErrorInList<T extends object> {
  errors: RuleResult[];
  key: keyof T;
}

export interface RuleOptions<T> {
  stopOnPreviousError?: boolean;
  liveValidation?: boolean;
  waitForPreviousRules?: boolean;
  triggerFields?: (keyof T)[];
  makeOptional?: boolean;
}

export type RuleArgs<T, V, K extends keyof T> = V & {
  value: T[K] | undefined;
  field: string;
  language: string;
};

export type RuleResultType = "error" | "warning" | "info" | "pending" | "initial";

export type RuleResult = {
  message: React.ReactNode;
  type: RuleResultType;
  liveValidation?: boolean;
};

export type Rule<T, V, K extends keyof T> =
  | RuleConditon<T, V, K>
  | ({
      rule: RuleConditon<T, V, K>;
    } & RuleOptions<T>);

export type RuleConditon<T, V, K extends keyof T> = (
  vals: RuleArgs<T, V, K>
) => MaybePromise<RuleResult | React.ReactNode | undefined>;

export type Rules<T extends object, V> = Partial<{
  [K in keyof T]: Rule<T, V, K>[];
}>;
export type ErrorResult = {
  fieldName: string;
  messages: RuleResult[];
};

export type FormErrors<T extends object> = {
  meta: {
    submitWasInvalid: boolean;
    errorCount: number;
    isInitialValidation: boolean;
  };
  errors: Errors<T>;
};

export type Errors<T extends object> = Partial<{ [k in keyof T]: ErrorResult }>;
export type ApplyRuleFunc<T extends object, V extends ValidateFormPropsInner<T>> = ({
  rules,
  validationArgs,
  validationOptions,
}: {
  rules: Rules<T, V>;
  validationArgs: V;
  validationOptions?: RuleOptions<T> | undefined;
}) => Promise<Errors<T>>;

export function isRuleCondition<T, V, K extends keyof T>(rule: Rule<T, V, K>): rule is RuleConditon<T, V, K> {
  return typeof rule === "function";
}

export function isRuleResultObject(ruleResult: RuleResult | React.ReactNode): ruleResult is RuleResult {
  return typeof ruleResult === "object" && "type" in (ruleResult ?? {});
}

const p: RuleConditon<any, any, any> = async ({ value }) => {
  if (!value) return;
  return {
    message: "sdd",
    type: "error",
  };
};
