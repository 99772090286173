import * as React from "react";
import { ChangeEventHandler } from "react";
import Props from "./index.d";
import ordinaryColors from "themes/ordinaryColors";
import { TextareaAutosize } from "@mui/material";
import styled from "@mui/system/styled";

export const StyledTextArea = styled(TextareaAutosize, {
  label: "TextStyledTextArea",
  shouldForwardProp(propName) {
    return propName !== "hasError" && propName !== "isUnit";
  },
})<{
  hasError: boolean;
  isUnit: boolean;
}>(({ hasError, isUnit }) => ({
  fontFamily: "Encode Sans Semi Condensed",
  padding: "10px",
  height: "35px",
  marginBottom: hasError ? "5px" : "14px",
  borderRadius: "0px",
  border: hasError && !isUnit ? "1px solid rgba(214, 40, 57, 1) " : "1px solid #9E9E9E66",
  backgroundColor: hasError ? "rgba(211, 47, 47, 0.02)" : "transparent",
  "& .MuiFormHelperText-root": {
    marginLeft: "0px",
    fontSize: "12px",
    color: ordinaryColors.error,
  },

  " .MuiOutlinedInput-notchedOutline": {
    borderWidth: "0px",
  },
  "& .MuiOutlinedInput-input": {
    padding: "0.5rem 0.75rem",
    width: "100%",
  },
  "& .MuiOutlinedInput-root": {
    borderRadius: "0px",
    border: hasError && !isUnit ? "1px solid rgba(214, 40, 57, 1)" : "1px solid #9E9E9E66",
    backgroundColor: hasError ? "rgba(211, 47, 47, 0.02)" : "transparent",
    padding: "0px",
    "& fieldset": {
      borderRadius: "0px",
    },
  },
}));

const TextArea: React.FC<Props> = (props) => {
  const {
    title,

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    data: { state, setstate, onBlur, updateValue, setNull, setAndSave },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    placeholder = "placeholder",
    disabled,
    testId,
    errors,
    isRequiredField,
    errorManager,
    className,
    isUnit,
    hideLabel,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ...rest
  } = props;

  const change: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = (e) => {
    const value = e.target.value;

    if (value === null) {
      if (setNull) {
        setNull();
      } else {
        setstate("");
      }
    } else {
      if (typeof value === "string") setstate(value);
    }
  };

  const errorManagerRes = errorManager({
    errorJoiner: (errors) => {
      return errors.join(", ");
    },
  });

  return (
    <>
      <StyledTextArea
        hasError={errorManagerRes.error}
        isUnit={isUnit ?? false}
        minRows={props.minRows}
        maxRows={props.maxRows}
        onBlur={onBlur}
        value={state || ""}
        onChange={change}
        disabled={props.disabled}
        className={className}
        // showRemoveIcon={!isRequiredField}
        required={isRequiredField}
        helperText={errorManagerRes.error && !isUnit ? errorManagerRes.helperText : ""}
        {...errorManagerRes}
      />
      {errorManagerRes.error && (
        <span style={{ color: ordinaryColors.error, fontSize: "12px" }}>{errorManagerRes.helperText}</span>
      )}
    </>
  );
};

export default TextArea;
