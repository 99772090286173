import * as React from "react";
import Props from "./index.d";
import UnitWrapper from "components/formElements/fieldSets/UnitWrapper";
import DecimalEditor from "../DecimalEditor";
import { StyledContentContainer } from "components/formElements/customElements/UnitFormErrorDisplay/styles";
import { UnitFormErrorDisplay } from "components/formElements/customElements/UnitFormErrorDisplay";

const NumberUnit: React.FC<Props> = (props) => {
  const errorManagerRes = props.errorManager({
    errorJoiner: (errors) => errors.join(", "),
  });

  return (
    <UnitWrapper title={props.title ?? ""}>
      <StyledContentContainer>
        {UnitFormErrorDisplay(errorManagerRes)}
        <DecimalEditor {...props} isUnit {...errorManagerRes} />
      </StyledContentContainer>
    </UnitWrapper>
  );
};

export default NumberUnit;
