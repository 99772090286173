import { styled } from "@mui/material";
import AsyncFileLoader from "./AsyncFileLoader";
import React, { ReactElement, useEffect } from "react";
import { overEvery } from "lodash";
const useComposeOverride = ({
  Render,
  overrides,
  newProps,
  Component,
}: {
  Render: any;
  overrides: any;
  newProps: any;
  Component: any;
}) => {
  const RaftComponent = React.useMemo(
    () =>
      newProps.styles
        ? styled(Component, {
            shouldForwardProp: (prop) => true,
          })`
            ${newProps.styles}
          `
        : Component,
    [Component, newProps.styles]
  );

  const InnerComp = React.useMemo(
    () =>
      Render
        ? (newProps: any) => <Render {...newProps} RaftComponent={RaftComponent} styled={styled} />
        : RaftComponent,
    [Render, RaftComponent]
  );

  const OutterComp = React.useMemo(
    () =>
      overrides
        ? (newProps: any) =>
            AsyncFileLoader(overrides)({
              ...newProps,
              RaftComponent: InnerComp,
              styled,
            })
        : InnerComp,
    [overrides, InnerComp]
  );

  return OutterComp;
};

export default useComposeOverride;
