import * as React from "react";

const MigrationNoteIcon = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g id="eos-icons:database-migration" clipPath="url(#clip0_8630_152810)">
        <path
          id="Vector"
          d="M24 11.6055C24 12.6185 21.538 13.4385 18.5 13.4385C15.462 13.4385 13 12.6185 13 11.6055V9.77246C13 10.7845 15.462 11.6055 18.5 11.6055C21.538 11.6055 24 10.7855 24 9.77246"
          fill="#F3A712"
        />
        <path
          id="Vector_2"
          d="M18.5 6.72167C15.465 6.72167 13 5.89667 13 4.88867V8.52867C13 9.53767 15.465 10.3627 18.5 10.3627C21.535 10.3627 24 9.53767 24 8.52867V4.88867C24 5.89667 21.535 6.72167 18.5 6.72167Z"
          fill="#F3A712"
        />
        <path
          id="Vector_3"
          d="M24 2.41747C24 1.40547 21.538 0.584473 18.5 0.584473C15.462 0.584473 13 1.40447 13 2.41747V3.64047C13 4.65247 15.462 5.47347 18.5 5.47347C21.538 5.47347 24 4.65347 24 3.64047M11 22.6055C11 23.6185 8.538 24.4385 5.5 24.4385C2.462 24.4385 0 23.6185 0 22.6055V20.7725C0 21.7845 2.462 22.6045 5.5 22.6045C8.538 22.6045 11 21.7845 11 20.7715V22.6055Z"
          fill="#F3A712"
        />
        <path
          id="Vector_4"
          d="M5.5 17.7217C2.465 17.7217 0 16.8967 0 15.8887V19.5287C0 20.5377 2.465 21.3627 5.5 21.3627C8.535 21.3627 11 20.5377 11 19.5287V15.8887C11 16.8967 8.535 17.7217 5.5 17.7217Z"
          fill="#F3A712"
        />
        <path
          id="Vector_5"
          d="M11 13.4175C11 12.4055 8.538 11.5845 5.5 11.5845C2.462 11.5845 0 12.4045 0 13.4175V14.6405C0 15.6525 2.462 16.4735 5.5 16.4735C8.538 16.4735 11 15.6535 11 14.6405V13.4175ZM20 21.5845H18V18.9945L15.41 21.5845L14 20.1745L16.59 17.5845H14V15.5845H20V21.5845Z"
          fill="#F3A712"
        />
      </g>
      <defs>
        <clipPath id="clip0_8630_152810">
          <rect width="24" height="24" fill="white" transform="translate(0 0.584473)" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default MigrationNoteIcon;
