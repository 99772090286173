import { Typography } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { Box, styled } from "@mui/system";
import { StyledInputBase } from "components/formElements/fields/ForeignKeySelector";
import { useCertificateNormsQuery } from "graphqlBase/certificateNorm/__generated__/certificateNorms";
import { useGetCertificateNormIssueDatesForNormIdLazyQuery } from "graphqlBase/certificateNormIssueDate/__generated__/getCertificateNormIssueDatesForNormId";
import { certificateToReplaceIdAtom } from "pages/UploadCertificate/state/uploadCertificateStateBase";
import * as React from "react";
import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { formatISODateString } from "translations/formatter";

export interface AddNewNormBridgeProps {
  addNormBridgeId: (normBridgeId: string) => void;
  normBridgeIds: string[];
  normBridgeId?: string;
}

export const StyledCreateNormSelects = styled(Box, { label: "StyledCreateNormModal" })(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "32px",
  flex: "1 0 0",
  width: "100%",
}));

const isNormFitting = ({
  normId,
  normBridgeIds,
  certificateToBeReplaced,
}: {
  normId: string;
  normBridgeIds: string[];
  certificateToBeReplaced: boolean;
}) => {
  if (certificateToBeReplaced && normId === "b760fa9d-24fb-5412-8672-bb4e300e581a") return false;
  if (normId === "b760fa9d-24fb-5412-8672-bb4e300e581a") {
    return !normBridgeIds.length || normBridgeIds.includes("b760fa9d-24fb-5412-8672-bb4e300e581a");
  } else {
    return !normBridgeIds.length || !normBridgeIds.includes("b760fa9d-24fb-5412-8672-bb4e300e581a");
  }
};

const AddNewNormBridge: React.FC<AddNewNormBridgeProps> = ({ addNormBridgeId, normBridgeId, normBridgeIds }) => {
  const { data: normsData } = useCertificateNormsQuery();
  const certificateToBeReplaced = !!useRecoilValue(certificateToReplaceIdAtom);
  const [normId, setNormId] = useState<string | undefined>();

  const [getCertificateNormIssueDatesForNormId, { data: certificateNormIssueDatesData }] =
    useGetCertificateNormIssueDatesForNormIdLazyQuery();

  useEffect(() => {
    if (!normId) return;
    getCertificateNormIssueDatesForNormId({ variables: { normId } });
  }, [getCertificateNormIssueDatesForNormId, normId]);

  const handleNormChange = (event: SelectChangeEvent) => {
    setNormId(event.target.value as string);
  };
  const handleNormBridgeChange = (event: SelectChangeEvent) => {
    addNormBridgeId(event.target.value as string);
  };

  return (
    <StyledCreateNormSelects>
      <FormControl fullWidth>
        <Typography sx={{ fontSize: "14px", marginBottom: "6px" }}>Norm</Typography>

        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={normId}
          input={<StyledInputBase />}
          label="Age"
          onChange={handleNormChange}
        >
          {[...(normsData?.certificateNorms?.items ?? [])]
            ?.sort((a, b) => a.certificateNormName.localeCompare(b.certificateNormName))
            .map((norm) => (
              <MenuItem
                key={norm.id}
                value={norm.id}
                disabled={!isNormFitting({ normId: norm.id, normBridgeIds, certificateToBeReplaced })}
              >
                {norm.certificateNormName}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      <FormControl fullWidth>
        <Typography sx={{ fontSize: "14px", marginBottom: "6px" }}>Revision</Typography>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={normBridgeId}
          label="Age"
          input={<StyledInputBase />}
          onChange={handleNormBridgeChange}
          disabled={!normId}
        >
          {[...(certificateNormIssueDatesData?.certificateNormIssueDates?.items ?? [])]
            ?.sort((a, b) => b.certificateNormIssueDate.localeCompare(a.certificateNormIssueDate))
            .map((certificateNormIssueDate) => (
              <MenuItem
                key={certificateNormIssueDate.id}
                value={certificateNormIssueDate.id}
                disabled={normBridgeIds.includes(certificateNormIssueDate.id)}
              >
                {formatISODateString(certificateNormIssueDate.certificateNormIssueDate)}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </StyledCreateNormSelects>
  );
};

export default AddNewNormBridge;
