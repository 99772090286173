import type * as Types from '../../../types';

import client from "apollo/client";
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetUsersQueryVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.UserFilterInput>;
  take?: Types.InputMaybe<Types.Scalars['Int']>;
  skip?: Types.InputMaybe<Types.Scalars['Int']>;
  order?: Types.InputMaybe<Array<Types.UserSortInput> | Types.UserSortInput>;
}>;


export type GetUsersQuery = { __typename?: 'Query', users?: { __typename?: 'UserCollectionSegment', totalCount: number, items?: Array<{ __typename?: 'User', id: string, displayName: string, email: string, userBridgeRoles: Array<{ __typename?: 'UserBridgeRole', id: string, role: { __typename?: 'Role', id: string, name: string, description?: string | null } }>, tenant: { __typename?: 'Tenant', id: string, tenantName: string, tenantRole: Types.TenantRole } }> | null } | null };


        export type ExecGetUsersOptions = Omit<
        Apollo.QueryOptions<
        GetUsersQueryVariables, GetUsersQuery
        >,
        "query"
      >;
    export const execGetUsersQuery = (options: ExecGetUsersOptions) =>
    client.query< GetUsersQuery, GetUsersQueryVariables>({
      ...options,
      query: GetUsersDocument,
    });
    

export const GetUsersDocument = gql`
    query getUsers($where: UserFilterInput, $take: Int, $skip: Int, $order: [UserSortInput!]) {
  users(where: $where, take: $take, skip: $skip, order: $order) {
    totalCount
    items {
      id
      displayName
      email
      userBridgeRoles {
        id
        role {
          id
          name
          description
        }
      }
      tenant {
        id
        tenantName
        tenantRole
      }
    }
  }
}
    `;

/**
 * __useGetUsersQuery__
 *
 * To run a query within a React component, call `useGetUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersQuery({
 *   variables: {
 *      where: // value for 'where'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useGetUsersQuery(baseOptions?: Apollo.QueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
      }
export function useGetUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
        }
export type GetUsersQueryHookResult = ReturnType<typeof useGetUsersQuery>;
export type GetUsersLazyQueryHookResult = ReturnType<typeof useGetUsersLazyQuery>;
export type GetUsersQueryResult = Apollo.QueryResult<GetUsersQuery, GetUsersQueryVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
          export type ResultType<T> = Types.ResultType<T>;
          export type PaginatedResultType<T> = Types.PaginatedResultType<T>;
          export type NonMaybe<T> = Types.NonMaybe<T>;
          export type Maybe<T> = Types.Maybe<T>;