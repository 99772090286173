import { getCertificateCreateFieldSelector } from "raft/CertificateForm";
import * as React from "react";
import { useRecoilValue } from "recoil";
import Props from "./index.d";

const ConnectCategory: React.FC<Props> = (props) => {
  const {
    data: { setAndSave },
  } = props;

  const certificateCategoryAtom = getCertificateCreateFieldSelector({})("category");
  const certificateCategory = useRecoilValue(certificateCategoryAtom);
  React.useEffect(() => {
    setAndSave(certificateCategory);
  }, [certificateCategory, setAndSave]);
  return null;
};

export default ConnectCategory;
