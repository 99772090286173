import {
  execGetsoftwareVersionsQuery,
  PaginatedResultType,
  GetsoftwareVersionsQuery,
} from "graphqlBase/SoftwareVersions/__generated__/getsoftwareVersions";
import { CertificateFieldMappingGetter } from "pages/UploadCertificate/types";
type Restype = PaginatedResultType<GetsoftwareVersionsQuery["softwareVersions"]>;

const getSoftWares = (() => {
  let cache: Restype[] | null = null;

  return async () => {
    if (cache) {
      return cache;
    }

    const data = await execGetsoftwareVersionsQuery({});
    cache = (data.data.softwareVersions?.items ?? []).map((i) => i).sort((a, b) => b.version.length - a.version.length);
    return cache;
  };
})();

export const getsoftwareVersions: CertificateFieldMappingGetter = async ({ value }: { value: string }) => {
  try {
    const softWaresInDb = await getSoftWares();
    const softWareList = value.replace(/\n/, ",").split(",");
    const versions = softWareList.reduce((acc, softWare) => {
      const id = softWaresInDb.find((softWareInDb) => {
        return softWare.includes(softWareInDb.version) && softWare.includes(softWareInDb.softwareType);
      })?.id;
      if (id) return acc.concat(id);
      return acc;
    }, [] as string[]);
    return versions;
  } catch (e) {
    return undefined;
  }
};
