import Loader from "@ml-pa/react-apollo-form-tool/buildForm/unloadedForm"
import loadable from "@loadable/component"
import React, { Suspense } from "react"


export default (path: string) => {
    // const Configurator = React.lazy(() => import(`raft/config/asset/create/active/overrides/GFCField_TextField_CustomUniqueIdentifier_LBW7JQ7NT8FEKXCZD5Q_Override`))
    const Configurator = React.lazy(() => import(`raft/config/${path}`))

    return (props: any) => {

        return (
            <Suspense fallback={Loader()}>
                <Configurator {...props} />
            </Suspense>

        )
    }
}