import * as React from "react";
import { StyledUnitWrapperContent, StyledUnitWrapperRoot, StyledUnitWrapperTitle } from "./styles";

interface WrapperProps {
  children: React.ReactNode;
  title: string;
}

const UnitWrapper: React.FC<WrapperProps> = ({ children, title }) => {
  return (
    <StyledUnitWrapperRoot>
      <StyledUnitWrapperContent>
        <StyledUnitWrapperTitle>{title}</StyledUnitWrapperTitle>
        {children}
      </StyledUnitWrapperContent>
    </StyledUnitWrapperRoot>
  );
};

export default UnitWrapper;
