import { EntityMappings } from "graphqlBase/__utils__/entityMappings";
import * as React from "react";
import { BaseRow, Columns, UseMakeDataGridProps } from "./types";

export const makeColumnsPre =
  <R extends BaseRow, E extends keyof EntityMappings>({ tableMode = "server" }: UseMakeDataGridProps<R, E>) =>
  (columns: Columns<R, E>, depedancyArray?: unknown[]) => {
    return React.useMemo(() => {
      return columns.map((col) => {
        const filterable = tableMode === "server" && !col.remoteFilter ? false : col.filterable ?? true;
        const sortable = tableMode === "server" && !col.remoteOrder ? false : col.sortable ?? true;
        const headerClassName = col.headerClassName ?? "MuiDataGrid-columnHeaderTitle";
        const column = { ...col, filterable, sortable, headerClassName };
        if (column.remoteFilter?.filterOperators)
          return {
            ...column,
            filterOperators: column.remoteFilter.filterOperators,
          };
        return column;
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...(depedancyArray ?? []), columns]);
  };
