import { styled } from "@mui/material";
import { UnitFormErrorDisplay } from "components/formElements/customElements/UnitFormErrorDisplay";
import { StyledContentContainer } from "components/formElements/customElements/UnitFormErrorDisplay/styles";
import UnitWrapper from "components/formElements/fieldSets/UnitWrapper";
import EnumSelector from "../EnumSelector";
import Props from "./index.d";

const StyledEnumSelector = styled(EnumSelector, { name: "StyledEnumSelector" })({
  maxWidth: "244px",
  minWidth: "244px",
  maxHeight: "42px",
  "& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select": {
    maxWidth: "244px",
    minWidth: "244px",
    maxHeight: "42px",
    padding: "8px 12px",
  },
  "& .css-1sq3h5y-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root": { borderRadius: 0 },
  "& .css-14lo706": { display: "none" },
  "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": { top: 0, minHeight: "42px !important" },
});

const EnumSelectorUnit: React.FC<Props> = (props) => {
  const errorManagerRes = props.errorManager({
    errorJoiner: (errors) => errors.join(", "),
  });

  return (
    <UnitWrapper title={props.title ?? ""}>
      <StyledContentContainer>
        {UnitFormErrorDisplay(errorManagerRes)}
        <StyledEnumSelector {...props} />
      </StyledContentContainer>
    </UnitWrapper>
  );
};

export default EnumSelectorUnit;
