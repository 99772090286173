import { FormStateExtended } from "@ml-pa/react-apollo-form-tool/formGen.model";
import { atom, AtomEffect, RecoilState, selectorFamily } from "recoil";

export interface FormStateParameters {
  operation: string;
  entity: string;
}

type FormStateAtom = RecoilState<any | undefined>;
interface FormsStates {
  [key: string]: RecoilState<any | undefined>;
}

type typedFormState<T> = RecoilState<T | undefined>;

const formStates: FormsStates = {};

export function makeFormState<T extends FormStateExtended = {}>(id: string): RecoilState<T | undefined> {
  if (formStates[id]) {
    return formStates[id] as typedFormState<T>;
  }

  const state = atom<T | undefined>({
    key: `@_Raft_FormState.${id}`,

    default: undefined,
  });
  formStates[id] = state as typedFormState<T>;
  return state;
}

export function connectField<T extends FormStateExtended, K extends keyof T>(formAtom: RecoilState<T | undefined>) {
  const fieldState = selectorFamily({
    key: "@_Raft_Field",
    get: (field: K) => ({ get }): T[K] => {
      const atom = get(formAtom) as T;
      return atom?.[field] as T[K];
    },
    set: (field: K) => ({ set }, newValue) => {
      if (field === "id") return;
      set(formAtom as FormStateAtom, (prevState: FormStateExtended) => {
        return { ...prevState, [field]: newValue };
      });
    },
  });
  return fieldState;
}
