import ModalWithButtons from "components/molecules/Modal/ModalWithButtons";
import React, { useCallback, useState } from "react";
import translations from "translations";
import AddNewSoftwareBridge, { AddNewSoftwareBridgeProps } from "./AddNewSoftwareBridge";

interface Props extends AddNewSoftwareBridgeProps {
  setOpenModal: (value: boolean) => void;
  openModal: boolean;
  title: string;
  softwareBridgeIds: string[];
  addSoftwareBridgeId: (SoftwareBridgeId: string) => void;
}

const CreateSoftwareModal: React.FC<Props> = ({
  setOpenModal,
  openModal,
  title,
  addSoftwareBridgeId,
  softwareBridgeIds,
}) => {
  const handleClose = useCallback(() => {
    setSoftwareId(undefined);
    setOpenModal(false);
  }, [setOpenModal]);
  const [SoftwareId, setSoftwareId] = useState<string | undefined>();
  const save = useCallback(() => {
    if (!SoftwareId) return;
    addSoftwareBridgeId(SoftwareId);
    handleClose();
  }, [addSoftwareBridgeId, SoftwareId, handleClose]);

  return (
    <ModalWithButtons
      openModal={openModal}
      onClose={handleClose}
      title={title}
      disabled={!SoftwareId}
      addButtonTitle={translations.globals.button.add}
      addButtonClick={save}
      children={<AddNewSoftwareBridge addSoftwareBridgeId={setSoftwareId} softwareBridgeIds={softwareBridgeIds} />}
      width="715px"
    />
  );
};

export default CreateSoftwareModal;
