import type * as Types from '../../types';

import client from "apollo/client";
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetIssueTypeNamesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetIssueTypeNamesQuery = { __typename?: 'Query', issueTypes?: { __typename?: 'IssueTypeCollectionSegment', items?: Array<{ __typename?: 'IssueType', key: string, value: string }> | null } | null };


        export type ExecGetIssueTypeNamesOptions = Omit<
        Apollo.QueryOptions<
        GetIssueTypeNamesQueryVariables, GetIssueTypeNamesQuery
        >,
        "query"
      >;
    export const execGetIssueTypeNamesQuery = (options: ExecGetIssueTypeNamesOptions) =>
    client.query< GetIssueTypeNamesQuery, GetIssueTypeNamesQueryVariables>({
      ...options,
      query: GetIssueTypeNamesDocument,
    });
    

export const GetIssueTypeNamesDocument = gql`
    query getIssueTypeNames {
  issueTypes {
    items {
      key: id
      value: issueTypeName
    }
  }
}
    `;

/**
 * __useGetIssueTypeNamesQuery__
 *
 * To run a query within a React component, call `useGetIssueTypeNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIssueTypeNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIssueTypeNamesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetIssueTypeNamesQuery(baseOptions?: Apollo.QueryHookOptions<GetIssueTypeNamesQuery, GetIssueTypeNamesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetIssueTypeNamesQuery, GetIssueTypeNamesQueryVariables>(GetIssueTypeNamesDocument, options);
      }
export function useGetIssueTypeNamesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetIssueTypeNamesQuery, GetIssueTypeNamesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetIssueTypeNamesQuery, GetIssueTypeNamesQueryVariables>(GetIssueTypeNamesDocument, options);
        }
export type GetIssueTypeNamesQueryHookResult = ReturnType<typeof useGetIssueTypeNamesQuery>;
export type GetIssueTypeNamesLazyQueryHookResult = ReturnType<typeof useGetIssueTypeNamesLazyQuery>;
export type GetIssueTypeNamesQueryResult = Apollo.QueryResult<GetIssueTypeNamesQuery, GetIssueTypeNamesQueryVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
          export type ResultType<T> = Types.ResultType<T>;
          export type PaginatedResultType<T> = Types.PaginatedResultType<T>;
          export type NonMaybe<T> = Types.NonMaybe<T>;
          export type Maybe<T> = Types.Maybe<T>;