import { FC } from "react";
import { Box, Button, styled, Typography } from "@mui/material";
import { DropzoneInputProps, DropzoneRootProps } from "react-dropzone";
import UploadFileOutlinedIcon from "@mui/icons-material/UploadFileOutlined";
import ordinaryColors from "themes/ordinaryColors";

interface Props {
  getRootProps: <T extends DropzoneRootProps>(props?: T | undefined) => T;
  getInputProps: <T extends DropzoneInputProps>(props?: T | undefined) => T;
  openDialog: () => void;
  formatsString?: string;
}

const StyledContainer = styled("div")(({ theme }) => ({
  alignItems: "center",
  alignSelf: "stretch",
  background: ordinaryColors.functionalWhite,
  border: `1px dashed ${ordinaryColors.functionalLightGrey}`,
  borderRadius: "4px",
  display: "flex",
  flex: "1 0 0",
  flexDirection: "column",
  gap: "8px",
  height: "100%",
  justifyContent: "center",
  marginBottom: "12px",
  maxHeight: "100px",
  outline: "none",
  padding: "8px 16px",
  transition: "none",
}));

const FileDropZone: FC<Props> = ({ openDialog, getRootProps, getInputProps, ...props }) => {
  return (
    <div {...getRootProps({ className: "dropzone" })}>
      <input {...getInputProps()} />
      <StyledContainer>
        <UploadFileOutlinedIcon sx={{ color: ordinaryColors.primaryBrand, width: "16px", height: "16px" }} />
        <Box sx={{ display: "flex", alignItems: "center", maxHeight: "14px" }}>
          <Button
            sx={{
              color: ordinaryColors.primaryBrand,
              fontFeatureSettings: "'clig' off, 'liga' off",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: 400,
              letterSpacing: "0.15px",
              textDecorationStyle: "solid",
              textDecorationLine: "underline",
              textTransform: "none",
            }}
            variant="text"
            color="primary"
            onClick={openDialog}
          >
            Click to upload
          </Button>
          <Typography
            sx={(theme) => ({
              color: ordinaryColors.functionalBlack,
              fontFeatureSettings: "'clig' off, 'liga' off",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: 400,
              letterSpacing: "0.15px",
            })}
          >
            or drag and drop
          </Typography>
        </Box>
        <Typography
          sx={(theme) => ({
            color: ordinaryColors.functionalLightGrey,
            textAlign: "center",
            fontFeatureSettings: "'clig' off, 'liga' off",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "100% /* 20.02px */",
            letterSpacing: "0.17px",
          })}
        >
          {props.formatsString ?? "SVG, PNG, JPG or GIF (max. 3MB)"}
        </Typography>
      </StyledContainer>
    </div>
  );
};

export default FileDropZone;
