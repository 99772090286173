import { styled, Typography } from "@mui/material";
import Animations from "components/molecules/Animations";
import no_search_results from "components/molecules/Animations/LottieFilesJson/no_search_results.json";
import useCheckIfFiltersAreApplied from "pages/CertificationManagement/CustomDataGridFilters/utils/useCheckIfFiltersAreApplied";
import React from "react";
import translations from "translations";

const NoDataSC = styled("div")({
  display: "flex",
  flex: 1,
  justifyContent: "center",
  alignItems: "center",
  height: "100%",
});

const noItemsTextStyles = { height: "fit-content" };
const animationStyle = {
  style: {
    height: "250px",
    width: "250px",
  },
};
const NoDataOverlay: React.FC<{
  message: string | undefined;
  noResultsAnimation?: boolean;
}> = ({ message, noResultsAnimation }) => {
  const { filtersIsTrue } = useCheckIfFiltersAreApplied();

  return noResultsAnimation && filtersIsTrue ? (
    <Animations
      animationData={no_search_results}
      title={translations.pages.certificationManagement.animation.title}
      description={translations.pages.certificationManagement.animation.description}
      style={animationStyle.style}
    />
  ) : !!message ? (
    <NoDataSC>
      <Typography variant="body1" sx={noItemsTextStyles}>
        {message}
      </Typography>
    </NoDataSC>
  ) : null;
};
export default NoDataOverlay;
