import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import { Tooltip } from "@mui/material";
import MainLayout from "components/templates/MainLayout";
import Configurator from "raft/configurator";
import { Outlet } from "react-router-dom";
import translations from "translations";
import { AsyncRoute } from "./utils/AsyncRoute";
import { Route } from "./utils/types";
import { useGetUserInfo } from "lib/userInfo";
const LandingPage = () => import("pages/LandingPage");
const UploadCertificate = () => import("pages/UploadCertificate");
const CertificationManagement = () => import("pages/CertificationManagement");
const CertificateDetailView = () =>
  import("pages/CertificationManagement/UnitsOverview/DetailView/CertificateDetailView");
const UnitDetailView = () => import("pages/CertificationManagement/UnitsOverview/DetailView/UnitDetailView");
const CertificationValidation = () => import("pages/CertificateValidation");
const UserManagement = () => import("pages/UserManagement");
const MappingRolesUserUpdate = () => import("pages/UserManagement/MappingRolesUserUpdate");
const UserRolesManager = () => import("pages/UserManagement/UserRolesManager");
const UpdateRole = () => import("pages/UserManagement/UpdateRole");
const CreateRole = () => import("pages/UserManagement/CreateRole");
const PendingUserRequests = () => import("pages/UserManagement/PendingUserRequests");
const ApiKeys = () => import("pages/UserManagement/ApiKeys");
const ErrorPage = () => import("globalErrorhandling/ErrorPage");
const UserProfile = () => import("pages/UserProfile");
const Notifications = () => import("pages/UserProfile/Notifications");
const Imprint = () => import("pages/Imprint");
const Contact = () => import("pages/Contact");
const PrivacyPolicy = () => import("pages/PrivacyPolicy");
const FaqPage = () => import("pages/LandingPage/FaqPage");
const OtherDocumentsPage = () => import("pages/LandingPage/OtherDocumentsPage");
const AccessPermissionManagement = () => import("pages/AccessPermissionManagement");

const useDefinedRoutes = (): Route[] => {
  const userInfo = useGetUserInfo();
  const routesArray: Route[] = [
    {
      path: "",
      element: <MainLayout noPadding hideBreadcrumb hideSimpleSearch />,
      label: "",
      menuItem: false,
      mainLayout: false,
      scope: "Home",
      notification: true,
      errorElement: AsyncRoute(ErrorPage),
      children: [
        {
          path: "/",
          element: AsyncRoute(LandingPage),
          label: translations.pages.home.label,
          menuItem: false,
          mainLayout: true,
          scope: "Home",
          notification: false,
        },
        {
          path: "/imprint",
          element: AsyncRoute(Imprint),
          label: "",
          menuItem: false,
          mainLayout: true,
          scope: "Home",
          notification: false,
        },
        {
          path: "/contact",
          element: AsyncRoute(Contact),
          label: "",
          menuItem: false,
          mainLayout: true,
          scope: "Home",
          notification: false,
        },
        {
          path: "/privacypolicy",
          element: AsyncRoute(PrivacyPolicy),
          label: "",
          menuItem: false,
          mainLayout: true,
          scope: "Home",
          notification: false,
        },
        {
          path: "/faq",
          element: AsyncRoute(FaqPage),
          label: "",
          menuItem: false,
          mainLayout: true,
          scope: "Home",
          notification: false,
        },
        {
          path: "/otherdocuments",
          element: AsyncRoute(OtherDocumentsPage),
          label: translations.pages.userProfile.label,
          menuItem: false,
          mainLayout: true,
          scope: "Home",
          notification: false,
        },
        {
          path: "/userprofile",
          element: AsyncRoute(UserProfile),
          label: translations.pages.userProfile.label,
          menuItem: false,
          mainLayout: true,
          scope: "Home",
          notification: false,
        },
        {
          path: "/notifications",
          element: AsyncRoute(Notifications),
          label: translations.pages.userProfile.notifcations.notificationsTitle,
          menuItem: false,
          mainLayout: true,
          scope: "Home",
          notification: false,
        },

        {
          path: "/certificate-upload",
          element: AsyncRoute(UploadCertificate),
          label: translations.pages.certificateUpload.label,
          menuItem: false,
          mainLayout: true,
          scope: "Certificate.Create",
          notification: false,
        },
        {
          path: "/units-and-certificates",
          element: AsyncRoute(CertificationManagement),
          label: translations.pages.certificationManagement.label,
          menuItem: true,
          mainLayout: true,
          scope: "Home",
          // scope: "Certificate.Create",
          notification: false,
        },
        {
          path: "/units",
          element: Outlet({}),
          label: "",
          menuItem: false,
          mainLayout: true,
          scope: "Home",
          notification: false,
          children: [
            {
              path: ":unitId",
              element: AsyncRoute(UnitDetailView),
              label: "",
              menuItem: false,
              mainLayout: true,
              scope: "Home",
              notification: false,
            },
          ],
        },
        {
          path: "/certificates",
          element: Outlet({}),
          label: "",
          menuItem: false,
          mainLayout: true,
          scope: "Home",
          notification: false,
          children: [
            {
              path: ":certificateId",
              element: AsyncRoute(CertificateDetailView),
              label: "",
              menuItem: false,
              mainLayout: true,
              scope: "Home",
              notification: false,
            },
          ],
        },
        {
          path: "/open-tasks",
          element: AsyncRoute(CertificationValidation),
          label: translations.pages.certificateValidation.label,
          menuItem: true,
          mainLayout: true,
          scope: "Certificate.Update",
          notification: false,
        },
        {
          path: "/userManagement",
          element: AsyncRoute(UserManagement),
          // element: OutletWithFallback({ fallBackComponent: AsyncRoute(UserManagement) }),
          // element: <Outlet />,
          menuItem: true,
          label: translations.pages.userManagement.label,
          mainLayout: true,
          scope: "Role.Read",
          notification: false,
          children: [
            // {
            //   path: "",
            //   element: AsyncRoute(UserManagement),
            //   menuItem: true,
            //   label: translations.pages.userManagement.userAuthorization,
            //   mainLayout: true,
            //   scope: "Authenticated",
            //   notification: false,
            // },
            {
              path: "pendingUserRequests",
              element: AsyncRoute(PendingUserRequests),
              menuItem: true,
              label: translations.pages.userManagement.userAuthorization,
              mainLayout: true,
              scope: "ApproveOrBlockUsers.Create",
              notification: false,
            },
            {
              path: "apiKeys",
              element: AsyncRoute(ApiKeys),
              menuItem: true,
              label: translations.pages.userManagement.tabs.apiKeys,
              mainLayout: true,
              scope: "EntraApplicationSecret.Read.Own",
              notification: false,
            },
          ],
        },
        {
          path: "userManagement/userRolesManager",
          // eslint-disable-next-line react/display-name

          element: <Outlet />,
          label: translations.pages.userManagement.userRoleManager.label,
          mainLayout: true,
          menuItem: false,

          notification: false,
          children: [
            {
              path: "",
              element: AsyncRoute(UserRolesManager),

              label: translations.pages.userManagement.userRoleManager.label,
              mainLayout: true,
              menuItem: false,

              notification: false,
            },
            {
              path: "updateRole/:roleId",
              element: AsyncRoute(UpdateRole),

              label: translations.pages.userManagement.form.label.role,
              mainLayout: true,
              menuItem: false,

              notification: false,
            },
            {
              path: "newRole",
              element: AsyncRoute(CreateRole),
              label: `${translations.globals.button.new} ${translations.entities.role.name}`,
              mainLayout: true,
              menuItem: false,

              notification: false,
            },
          ],
        },
        {
          path: "assignRolesToUser/:userId",
          element: AsyncRoute(MappingRolesUserUpdate),
          scope: "Hidden",
          label: translations.pages.userManagement.assignRolesToUser.label,
          mainLayout: true,
          menuItem: false,

          notification: false,
        },
        // ],
        // },
      ],
    },
  ];

  const rolesWithAccessRequestManagementViewPermission = [
    "2e2f2352-7a98-404c-9cae-26a71989a872", // TenantAdmin
    "6e2f2352-7a98-404c-9cae-26a71989a872", // AccessRequestManager
  ];

  if (userInfo?.userRoleIds?.some((roleId) => rolesWithAccessRequestManagementViewPermission.includes(roleId))) {
    routesArray[0]?.children?.push({
      path: "/access-permission",
      element: AsyncRoute(AccessPermissionManagement),
      label: translations.pages.accessPermissionManagement.accessPermissionManagement,
      menuItem: true,
      mainLayout: true,
      notification: false,
    });
  }

  if (process.env.NODE_ENV === "development") {
    routesArray[0]?.children?.push({
      path: "configurator",
      element: <Configurator />,
      menuIcon: ({ isActive }) => (
        <Tooltip title="Configurator">
          <div>
            <SettingsSuggestIcon sx={{ color: isActive ? "#ED7826" : "inherit" }} />
          </div>
        </Tooltip>
      ),
      label: "Configurator",
      menuItem: true,
      mainLayout: false,
      scope: "Home",
      notification: false,
    });
  }
  return routesArray;
};
export default useDefinedRoutes;
