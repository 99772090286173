import { AutocompleteChangeReason } from "@mui/material";
import SelectFreeSolo, { SelectItem } from "components/molecules/SelectFreeSolo";
import lodash from "lodash";
import { ChangeEvent, useEffect, useState } from "react";

interface ServerSideSingleSelectProps {
  handleSaveSelectedValue: (value: SelectItem | null) => void;
  loading?: boolean;
  options: SelectItem[] | undefined;
  label: string;
  fetchOptions: (searchValue?: string) => void;
  selected: SelectItem | null;
  disabled?: boolean;
  handleAdd?: () => void;
  addLabel?: string;
  width?: string;
}

const ServerSideSingleSelect: React.FC<ServerSideSingleSelectProps> = ({
  handleSaveSelectedValue,
  fetchOptions,
  loading,
  options,
  label,
  selected,
  disabled,
  handleAdd,
  addLabel,
  width,
}) => {
  const [searchText, setSearchText] = useState<string | undefined>();
  const [uiOptions, setUiOptions] = useState<SelectItem[]>([]);

  useEffect(() => {
    return () => {
      handleSaveSelectedValue(null);
      setSearchText(undefined);
      setUiOptions([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setUiOptions(options ?? []);
  }, [options]);

  const handleChange = (event: React.SyntheticEvent, value: SelectItem | null, reason: AutocompleteChangeReason) => {
    if (reason === "createOption") return;
    handleSaveSelectedValue(value);
    if (reason !== "selectOption") {
      setSearchText(value?.value);
      fetchOptions();
    }
    if (reason === "clear") {
      fetchOptions();
    }
    if (value) {
      const optionsDeduped = lodash.uniqWith([value, ...(options ?? [])], lodash.isEqual);
      setUiOptions(optionsDeduped);
    }
  };

  const handleInputChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setSearchText(event.target.value);
    setTimeout(() => {
      fetchOptions(event?.target?.value ?? "");
    }, 500);
  };

  return (
    <SelectFreeSolo
      options={uiOptions}
      selectedItem={selected}
      searchText={searchText}
      handleInputChange={handleInputChange}
      handleChange={handleChange}
      loading={loading}
      label={label}
      width={width}
      disabled={disabled}
      handleAdd={handleAdd}
      addLabel={addLabel}
    />
  );
};

export default ServerSideSingleSelect;
