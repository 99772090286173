import CloseIcon from "components/icons/iconComponents/CloseIcon";
import MigrationNoteIcon from "components/icons/iconComponents/MigrationNoteIcon";
import { useSetRecoilState } from "recoil";
import { globalShowMigrationNoteState, globalUnitNotFoundMessageAtom } from "./recoilState";
import {
  StyledCloseIconBox,
  StyledMigrationNoteContent,
  StyledMigrationNoteInfo,
  StyledMigrationNoteRoot,
  StyledTextBox,
} from "./styles";

const MigrationNote = ({
  note,
  showIcon = false,
  canClose = true,
  backgroundColor,
  isUnitNotFoundMessage,
}: {
  note: string;
  showIcon?: boolean;
  canClose?: boolean;
  backgroundColor?: string;
  isUnitNotFoundMessage?: boolean;
}) => {
  const setOpen = useSetRecoilState(globalShowMigrationNoteState);
  const setUnitNotFoundMessage = useSetRecoilState(globalUnitNotFoundMessageAtom);
  return (
    <StyledMigrationNoteRoot>
      <StyledMigrationNoteContent background={backgroundColor}>
        <StyledMigrationNoteInfo>
          {showIcon && <MigrationNoteIcon />}
          <StyledTextBox dangerouslySetInnerHTML={{ __html: note }} />
        </StyledMigrationNoteInfo>
        {canClose && (
          <StyledCloseIconBox>
            <CloseIcon
              onClick={() => {
                return isUnitNotFoundMessage ? setUnitNotFoundMessage(false) : setOpen(false);
              }}
            />
          </StyledCloseIconBox>
        )}
      </StyledMigrationNoteContent>
    </StyledMigrationNoteRoot>
  );
};

export default MigrationNote;
