import * as React from "react";
import CTA from "components/mlpaUiComponents/CTA";
import Props from "./index.d";

const CTASubmitButton: React.FC<Props> = (props) => {
  const {
    disabled,
    ctaType,
    data: { setAndSave },
  } = props;

  return (
    <CTA
      ctaType={ctaType}
      disabled={disabled}
      label={props.title ?? ""}
      margin={props.margin}
      onClick={() => setAndSave(true)}
      width={props.width}
    />
  );
};

export default CTASubmitButton;
