import * as React from "react";

import { useEffect } from "react";
import Props from "./index.d";

const TenantId: React.FC<Props> = ({ data: { state, setstate } }) => {
  useEffect(() => {
    if (!state) {
      setstate("e2648199-e8b4-474b-8f4f-48e004389e1b");
    }
  }, [state, setstate]);

  return null;
};

export default TenantId;
