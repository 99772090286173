import { FormControl, FormHelperText, InputBase, InputLabel, MenuItem, Select, styled } from "@mui/material";
import { getTranslatedCertificateValidityStatusString } from "lib/helper/getTranslatedRowDataStrings";
import { getCertificateCreateFieldSelector } from "raft/CertificateForm";
import React, { useEffect } from "react";
import { useRecoilValue } from "recoil";
import ordinaryColors from "themes/ordinaryColors";
import Props from "./index.d";
import useGetForeignKeyList from "./useGetForeignKeyList";
import { LabelArray, getSortedOptions } from "lib/helper/getSortedOptions";

export const StyledInputBase = styled(InputBase)<{ error?: boolean; isEnumSelector?: boolean }>(
  ({ error, isEnumSelector }) => ({
    maxHeight: "42px",
    minHeight: "42px",
    display: "flex",
    padding: "8px 12px",
    alignContent: "center",
    rowGap: "100px",
    flex: "1 0 0",
    flexWrap: "wrap",
    borderRadius: "0px",
    border: "1px solid ",
    borderColor: error ? ordinaryColors.error : ordinaryColors.border,
    backgroundColor: error ? "rgba(211, 47, 47, 0.02)" : "transparent",
    marginBottom: "0px",
  })
);

const ForeignKeySelectorStatus: React.FC<Props> = (props) => {
  const {
    data: { state, setstate, updateValue, onBlur },
    title,
    pickDefaultFirst,
    className,
    foreignKeyParams,
    hideLabel,
    errorManager,
    isRequiredField,
    isUnit,
  } = props;
  let elems = useGetForeignKeyList({ foreignKeyParams });

  useEffect(() => {
    if (pickDefaultFirst && !state && !!elems && elems.length) {
      setstate(elems[0].value);
    }
  }, [elems, state, pickDefaultFirst, setstate]);

  const errorManagerRes = errorManager({
    errorJoiner: (errors) => {
      return errors.join(", ");
    },
  });
  const createFieldSelector = getCertificateCreateFieldSelector({});
  const __normList = useRecoilValue(createFieldSelector("__normList"));
  if (foreignKeyParams.entityName === "certificateValidityStatuses")
    elems = getSortedOptions({
      labelArray: elems,
      sortFn: getTranslatedCertificateValidityStatusString,
    }) as LabelArray;

  return (
    <>
      <FormControl fullWidth className={className}>
        {!isUnit || (hideLabel && <InputLabel id="demo-simple-select-label">{title}</InputLabel>)}
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          input={<StyledInputBase {...errorManagerRes} />}
          value={state ?? ""}
          label={hideLabel === true ? undefined : title}
          onBlur={onBlur}
          onChange={updateValue}
          required={isRequiredField}
          {...errorManagerRes}
        >
          {elems
            .filter((e) => {
              if (__normList === "05")
                return ["c47a0b7c-701b-5544-92b0-6bcb48aac633", "63a507c8-7124-5eec-8510-02b74a3a8dca"].includes(
                  e.value
                );
              return true;
            })
            .map(({ value, label }) => (
              <MenuItem key={value} value={value} disabled={value === "38fa47c8-12a4-5e6f-b9d1-387d3c8222b8"}>
                {foreignKeyParams.entityName !== "certificateValidityStatuses"
                  ? label
                  : getTranslatedCertificateValidityStatusString(value)}
              </MenuItem>
            ))}
        </Select>
        {errorManagerRes.error && !isUnit && (
          <FormHelperText sx={{ color: ordinaryColors.error, marginLeft: "0px", fontSize: "12px" }}>
            {errorManagerRes.helperText}
          </FormHelperText>
        )}
      </FormControl>
    </>
  );
};

export default ForeignKeySelectorStatus;
