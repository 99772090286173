import { atom, DefaultValue, selectorFamily } from "recoil";

export interface OpenTaskListFilterProps {
  issueType: string[];
  manufacturer: string[];
  createdAtRange: (Date | null)[];
}

export type QueryMultiSelectOpenTaskFilterProps = {
  issueType: string[];
  manufacturer: string[];
};
export type DateRangeOpenTaskFilterProps = {
  createdAtRange: (Date | null)[];
};

export const openTaskListFilterAtom = atom<OpenTaskListFilterProps>({
  key: "openTaskListFilterAtom",
  default: {
    issueType: [],
    manufacturer: [],
    createdAtRange: [],
  },
});

export const minDateAtom = atom<Date | null>({
  key: "minDateAtom",
  default: null,
});

export const maxDateAtom = atom<Date | null>({
  key: "maxDateAtom",
  default: null,
});

export const openTaskListLengthAtom = atom<number | undefined>({
  key: "openTaskListLength",
  default: undefined,
});
export const filterValueLengthAtom = atom<number | undefined>({
  key: "filterValueLengthAtom",
  default: undefined,
});

export const queryFilterOpenTaskSelectorFamily = selectorFamily<string[], keyof QueryMultiSelectOpenTaskFilterProps>({
  key: "queryFilterSelectorFamily",
  get:
    (filterType) =>
    ({ get }) => {
      const filterValues = get(openTaskListFilterAtom);
      return filterValues[filterType] ?? [];
    },

  set:
    (filterType) =>
    ({ set }, newValue) => {
      if (!newValue || newValue instanceof DefaultValue) return;
      if (newValue.length === 0 || newValue === undefined) {
        set(
          openTaskListFilterAtom,

          (prev) => ({ ...prev, [filterType]: [] })
        );
      }
      if (newValue.length > 0) {
        set(openTaskListFilterAtom, (prev) => ({ ...prev, [filterType]: newValue as string[] }));
      }
    },
});

export const dateRangeSliderFilterSelectorFamily = selectorFamily<(Date | null)[], keyof DateRangeOpenTaskFilterProps>({
  key: "dateRangeSliderFilterSelectorFamily",
  get:
    (filterType) =>
    ({ get }) => {
      const filterValues = get(openTaskListFilterAtom);
      return filterValues[filterType] ?? [];
    },

  set:
    (filterType) =>
    ({ set }, newValue) => {
      if (!newValue || newValue instanceof DefaultValue) return;
      if (newValue.length === 0 || newValue === undefined || newValue === null) {
        set(
          openTaskListFilterAtom,

          (prev) => ({ ...prev, [filterType]: [] })
        );
      }
      if (newValue.length > 0) {
        set(openTaskListFilterAtom, (prev) => ({ ...prev, [filterType]: newValue as Date[] }));
      }
    },
});
