import type * as Types from '../../types';

import client from "apollo/client";
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UnitSubScribedByUserQueryVariables = Types.Exact<{
  userId: Types.Scalars['UUID'];
}>;


export type UnitSubScribedByUserQuery = { __typename?: 'Query', unitBridgeUsers?: { __typename?: 'UnitBridgeUserCollectionSegment', items?: Array<{ __typename?: 'UnitBridgeUser', id: string, unitId: string, userId: string }> | null } | null };


        export type ExecUnitSubScribedByUserOptions = Omit<
        Apollo.QueryOptions<
        UnitSubScribedByUserQueryVariables, UnitSubScribedByUserQuery
        >,
        "query"
      >;
    export const execUnitSubScribedByUserQuery = (options: ExecUnitSubScribedByUserOptions) =>
    client.query< UnitSubScribedByUserQuery, UnitSubScribedByUserQueryVariables>({
      ...options,
      query: UnitSubScribedByUserDocument,
    });
    

export const UnitSubScribedByUserDocument = gql`
    query unitSubScribedByUser($userId: UUID!) {
  unitBridgeUsers(where: {userId: {eq: $userId}}) {
    items {
      id
      unitId
      userId
    }
  }
}
    `;

/**
 * __useUnitSubScribedByUserQuery__
 *
 * To run a query within a React component, call `useUnitSubScribedByUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnitSubScribedByUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnitSubScribedByUserQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUnitSubScribedByUserQuery(baseOptions: Apollo.QueryHookOptions<UnitSubScribedByUserQuery, UnitSubScribedByUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UnitSubScribedByUserQuery, UnitSubScribedByUserQueryVariables>(UnitSubScribedByUserDocument, options);
      }
export function useUnitSubScribedByUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UnitSubScribedByUserQuery, UnitSubScribedByUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UnitSubScribedByUserQuery, UnitSubScribedByUserQueryVariables>(UnitSubScribedByUserDocument, options);
        }
export type UnitSubScribedByUserQueryHookResult = ReturnType<typeof useUnitSubScribedByUserQuery>;
export type UnitSubScribedByUserLazyQueryHookResult = ReturnType<typeof useUnitSubScribedByUserLazyQuery>;
export type UnitSubScribedByUserQueryResult = Apollo.QueryResult<UnitSubScribedByUserQuery, UnitSubScribedByUserQueryVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
          export type ResultType<T> = Types.ResultType<T>;
          export type PaginatedResultType<T> = Types.PaginatedResultType<T>;
          export type NonMaybe<T> = Types.NonMaybe<T>;
          export type Maybe<T> = Types.Maybe<T>;