import styled from "@emotion/styled";
import CloseIcon from "@mui/icons-material/Close";
import { Box, IconButton, Modal, Typography } from "@mui/material";
import AddButton from "components/atomics/AddButton";
import CancelButton from "components/atomics/CancelButton";
import React from "react";
import ordinaryColors from "themes/ordinaryColors";
interface ModalWithButtonProps {
  openModal: boolean;
  onClose?: (event?: object, reason?: string) => void;
  title: string;
  children: React.ReactNode;
  disabled?: boolean;
  addButtonTitle?: string;
  addButtonClick?: () => void;
  width: string;
  cancelTitle?: string;
  backgroundColor?: string;
}

export const StyledModalBox = styled(Box, { label: "StyledCreateNormModal" })(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "32px",
  margin: "2rem 1rem",
}));

export const StyledModalContainer = styled(Box)<{ width: string; backgroundColor?: string }>(
  ({ width, backgroundColor }) => ({
    width: width,
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: backgroundColor ?? ordinaryColors.white,
    padding: "24px ",
    borderRadius: "0px",
    boxShadow: "rgba(0, 0, 0, 0.12)",
    // p: 4,
    gap: "10px",
  })
);

const ModalWithButtons: React.FC<ModalWithButtonProps> = ({
  openModal,
  backgroundColor,
  onClose: handleClose,
  title,
  children,
  disabled,
  addButtonTitle,
  addButtonClick,
  width,
  cancelTitle,
}) => {
  return (
    <Modal open={openModal} onClose={handleClose}>
      <StyledModalContainer width={width} backgroundColor={backgroundColor}>
        <StyledModalBox>
          <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
            <Typography dangerouslySetInnerHTML={{ __html: title }} variant="h5" />
            <IconButton color="inherit" onClick={handleClose}></IconButton>
            <IconButton color="inherit" onClick={handleClose}>
              <CloseIcon style={{ width: "24px", height: "26px" }} />
            </IconButton>
          </Box>
          {children}
          <Box sx={{ display: "flex", justifyContent: "end", marginTop: "20px", width: "100%" }}>
            {cancelTitle && <CancelButton onClick={handleClose} title={cancelTitle} flexibleWidth />}
            {addButtonTitle && addButtonClick && (
              <AddButton disabled={disabled} onClick={addButtonClick} title={addButtonTitle} flexibleWidth />
            )}
          </Box>
        </StyledModalBox>
      </StyledModalContainer>
    </Modal>
  );
};

export default ModalWithButtons;
