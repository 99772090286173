/* eslint-disable filenames/match-exported */
import * as React from "react";
import translations from "translations";
import Props from "./index.d";
import Button from "@mui/material/Button";
const CancelButton: React.FC<Props> = (props) => {
  const {
    disabled,
    variant,
    className,
    data: { setAndSave },
  } = props;

  return (
    <Button variant={variant} onClick={() => setAndSave(true)} disabled={disabled} className={className}>
      {translations.globals.button.cancel}
    </Button>
  );
};

export default CancelButton;
