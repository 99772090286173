import { useIsAuthenticated } from "@azure/msal-react";
import { Button } from "@mui/material";
import { filterButtonStyles } from "components/templates/dataGridTable/Toolbars/CustomToolbar";
import {
  certicateUploadProcessTypeAtom,
  certificateToReplaceIdAtom,
} from "pages/UploadCertificate/state/uploadCertificateStateBase";
import { CerticateUploadProcessType } from "pages/UploadCertificate/types";
import React from "react";
import { useResetRecoilState, useSetRecoilState } from "recoil";
import translations from "translations";
import CerticateUploadProcesSelection from "pages/UploadCertificate/CerticateUploadProcesSelection";
interface Props {}

const UploadCertificateButton: React.FC<Props> = () => {
  const setCerticateUploadProcess = useSetRecoilState(certicateUploadProcessTypeAtom);
  const resetCertificateToReplaceId = useResetRecoilState(certificateToReplaceIdAtom);
  const isUserLoggedIn = useIsAuthenticated();
  const goToCertificateUpload = () => {
    resetCertificateToReplaceId();
    setCerticateUploadProcess(CerticateUploadProcessType.toDecide);
  };

  return (
    <>
      {isUserLoggedIn && (
        <>
          <Button onClick={goToCertificateUpload} variant="contained" sx={() => filterButtonStyles()}>
            {translations.pages.certificateUpload.label}
          </Button>
          <CerticateUploadProcesSelection />
        </>
      )}
    </>
  );
};

export default UploadCertificateButton;
