import { FormControl, Switch, Typography } from "@mui/material";
import React from "react";
import { useRecoilState } from "recoil";
import { toggleFilterSelectorFamily, ToggleFiltersProps } from "../../State/manageFiltersInUnitsOverview";
import { StyledToggleBox } from "../styles";

interface ToggleProps {
  filterType: keyof ToggleFiltersProps;
  title: string;
}

const BooleanToggleFilters: React.FC<ToggleProps> = ({ filterType, title }) => {
  const [filters, setFilters] = useRecoilState(toggleFilterSelectorFamily(filterType));
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilters(event.target.checked);
  };

  return (
    <FormControl sx={{ m: 1 }} size="small">
      <StyledToggleBox aria-label={title}>
        <Typography sx={{ paddingLeft: "10px" }}>{title}</Typography>
        <Switch
          aria-label={filterType}
          checked={filters}
          onChange={handleChange}
          inputProps={{ "aria-label": "controlled" }}
        />
      </StyledToggleBox>
    </FormControl>
  );
};

export default BooleanToggleFilters;
