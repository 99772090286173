import {
  CertificateNormsQuery,
  execCertificateNormsQuery,
  PaginatedResultType,
} from "graphqlBase/certificateNorm/__generated__/certificateNorms";
import { CertificateFieldMappingGetter } from "pages/UploadCertificate/types";
type Restype = PaginatedResultType<CertificateNormsQuery["certificateNorms"]>;

export const getNormNameForExcelId = (excelId: string) => {
  switch (excelId) {
    case "1.06":
      return "VDE-AR-N 4105";
    case "1.07":
      return "VDE-AR-N 4110";
    case "1.08":
      return "VDE-AR-N 4120";
    case "1.09":
      return "VDE-AR-N 4130";
  }
};

const getNorms = (() => {
  let cache: Restype[] | null = null;

  return async () => {
    if (cache) {
      return cache;
    }

    const data = await execCertificateNormsQuery({});
    cache = data.data.certificateNorms?.items ?? [];
    return cache;
  };
})();

export const getIssueDateForNorm = async (dateString: string, certificateNormName: string) => {
  try {
    const isoDate = `${dateString.split("=").reverse().join("-")}-01T00:00:00.000Z`;
    const norms = await getNorms();
    const certificateNormIssueDate = norms
      .find((norm) => norm.certificateNormName === certificateNormName)
      ?.certificateNormBridges?.find(({ certificateNormIssueDate }) => {
        return isoDate === certificateNormIssueDate.certificateNormIssueDate;
      });

    return certificateNormIssueDate?.certificateNormIssueDate.id ?? undefined;
  } catch (e) {
    console.log(e);
    return undefined;
  }
};

export const getCertificateNormIssueDateId = async ({ value, id }: { id: string; value?: string }) => {
  const issueDatePre = `${value}`.split(":")[1];
  if (!issueDatePre) return undefined;

  const normName = getNormNameForExcelId(id);
  if (!normName) return undefined;

  const issueDate = await getIssueDateForNorm(issueDatePre, normName);
  return issueDate;
};

export const getCertificateBridgeCertificateNormIssueDates: CertificateFieldMappingGetter = async ({
  value,
  data,
  id,
}) => {
  const issueDate = await getCertificateNormIssueDateId({ value, id });
  if (!issueDate) return data.certificateBridgeCertificateNormIssueDates;
  return (data.certificateBridgeCertificateNormIssueDates ?? []).concat(issueDate);
};
export const getNormList: CertificateFieldMappingGetter = async ({ value, id, data }) => {
  const issueDate = await getCertificateNormIssueDateId({ value, id });
  const normNamePre = getNormNameForExcelId(id);
  if (!issueDate || !normNamePre) return data.__normList;
  const normName = normNamePre.replace("VDE-AR-N 41", "");
  return data.__normList ? data.__normList.split(",").concat(normName).join(",") : normName;
};

// export const makeNormList = (norms: string[])  => {
