import { LegalNoteLink, MailTo, nextLine } from "components/mlpaUiComponents/LegalNote/lib";

export default {
  migrationNote:
    "Due to the data migration from the previous database version, some information may be missing or incorrect in this certificate, which must be updated by the manufacturer for use and then receive a ZEREZ ID.",
  migrationNoteTooltipCertificate:
    "In this certificate some information may be missing or incorrect. It must be updated by the responsible authority.",
  migrationNoteTooltipUnit:
    "In this certificate some information may be missing or incorrect. It must be updated by the responsible authority.",
  noDataAvailable: "No data available...",
  noResultsAvailable: "No results available",
  noUploadedFiles: "No documents have been uploaded for this certifcate",
  unitOrCertificateNotFoundMessage:
    "If you cannot find your unit or the corresponding unit or component certificate in the register, please contact your manufacturer.",
  reducedFunctionalityNote: `Please note:
  ${nextLine()}All upload functions for the mandatory data are available, if you have problems uploading the data, please give us feedback as soon as possible ${MailTo(
    {
      mailTo: "info@zerez.net",
      label: "(info@zerez.net)",
    }
  )}.
  ${nextLine()}If you have any questions, please take a look at our ${LegalNoteLink({
    url: "https://www.zerez.net/faq",
    ariaLabel: "Link to",
    label: "FAQ",
  })} and note the option of uploading via Excel import for a simplified upload; the corresponding template can be found on the website under ${LegalNoteLink(
    {
      url: "https://www.zerez.net/otherdocuments",
      ariaLabel: "Link zu",
      label: "Template and documents",
    }
  )}.`,
};
