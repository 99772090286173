import { StyledButton } from "components/molecules/AccordionStateButtons/AccordionStateButton";
import { MUIStyledCommonProps } from "@mui/system";
import { Box, styled } from "@mui/material";

export interface StyledResetButtonProps {
  buttonSx?: MUIStyledCommonProps["sx"];
}

export interface StyledContentBoxProps {
  contentBoxSx?: MUIStyledCommonProps["sx"];
}

export interface StyledResetIconProps {
  disabled?: boolean;
  resetIconSx?: MUIStyledCommonProps["sx"];
}

export interface StyledLabelProps {
  labelSx?: MUIStyledCommonProps["sx"];
}

export const StyledResetButton = styled(StyledButton)(({ buttonSx }: StyledResetButtonProps) => ({
  ...buttonSx,
}));

export const StyledContentBox = styled(Box)(({ contentBoxSx }: StyledContentBoxProps) => ({
  ...contentBoxSx,
}));

export const StyledResetIcon = styled("img")(({ resetIconSx }: StyledResetIconProps) => ({
  ...resetIconSx,
}));

export const StyledLabel = styled("div")(({ labelSx }: StyledLabelProps) => ({
  ...labelSx,
}));
