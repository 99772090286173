import type * as Types from '../../../types';

import client from "apollo/client";
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetRoleByUserIdQueryVariables = Types.Exact<{
  userId?: Types.InputMaybe<Types.Scalars['UUID']>;
}>;


export type GetRoleByUserIdQuery = { __typename?: 'Query', roles?: { __typename?: 'RoleCollectionSegment', items?: Array<{ __typename?: 'Role', id: string, name: string }> | null } | null };


        export type ExecGetRoleByUserIdOptions = Omit<
        Apollo.QueryOptions<
        GetRoleByUserIdQueryVariables, GetRoleByUserIdQuery
        >,
        "query"
      >;
    export const execGetRoleByUserIdQuery = (options: ExecGetRoleByUserIdOptions) =>
    client.query< GetRoleByUserIdQuery, GetRoleByUserIdQueryVariables>({
      ...options,
      query: GetRoleByUserIdDocument,
    });
    

export const GetRoleByUserIdDocument = gql`
    query getRoleByUserId($userId: UUID) {
  roles(where: {userBridgeRoles: {some: {userId: {eq: $userId}}}}) {
    items {
      id
      name
    }
  }
}
    `;

/**
 * __useGetRoleByUserIdQuery__
 *
 * To run a query within a React component, call `useGetRoleByUserIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRoleByUserIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRoleByUserIdQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetRoleByUserIdQuery(baseOptions?: Apollo.QueryHookOptions<GetRoleByUserIdQuery, GetRoleByUserIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRoleByUserIdQuery, GetRoleByUserIdQueryVariables>(GetRoleByUserIdDocument, options);
      }
export function useGetRoleByUserIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRoleByUserIdQuery, GetRoleByUserIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRoleByUserIdQuery, GetRoleByUserIdQueryVariables>(GetRoleByUserIdDocument, options);
        }
export type GetRoleByUserIdQueryHookResult = ReturnType<typeof useGetRoleByUserIdQuery>;
export type GetRoleByUserIdLazyQueryHookResult = ReturnType<typeof useGetRoleByUserIdLazyQuery>;
export type GetRoleByUserIdQueryResult = Apollo.QueryResult<GetRoleByUserIdQuery, GetRoleByUserIdQueryVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
          export type ResultType<T> = Types.ResultType<T>;
          export type PaginatedResultType<T> = Types.PaginatedResultType<T>;
          export type NonMaybe<T> = Types.NonMaybe<T>;
          export type Maybe<T> = Types.Maybe<T>;