import { useGetUserInfo } from "lib/userInfo";
import * as React from "react";

import Props from "./index.d";

interface GFCFieldSet_FieldSetDiv extends Props {
  children: React.ReactNode;
}

const ShowForRole: React.FC<GFCFieldSet_FieldSetDiv> = ({ children, role }) => {
  const userInfo = useGetUserInfo();
  if (role != userInfo?.role) {
    return null;
  }

  return <>{children}</>;
};

export default ShowForRole;
