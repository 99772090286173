import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { accordionStatesFamily } from "components/molecules/Accordion/recoil";
import React from "react";
import { DefaultValue, useRecoilState } from "recoil";
import ordinaryColors from "themes/ordinaryColors";
import AccordionInner from "../../../molecules/Accordion";
import GFCFieldSet_Accordion from "./index.d";

type Props = GFCFieldSet_Accordion;

export function getAccordionSx(openState: boolean | DefaultValue | undefined) {
  return {
    "& .MuiAccordionSummary-root": {
      borderBottom: openState ? "none" : `1px solid  ${ordinaryColors.functionalDivider}`,
    },
    ":before": {
      background: "none",
    },
  };
}

export const accordionSummarySx = {
  flexDirection: "row-reverse",
  borderBottom: `1px solid  ${ordinaryColors.functionalDivider}`,
  "& .MuiAccordionSummary-expandIconWrapper": {
    transform: "rotate(-90deg)",
  },
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(0deg)",
  },
};

export const accordionDetailsSx = {
  borderBottom: `1px solid  ${ordinaryColors.functionalDivider}`,
  borderLeft: `1px solid  ${ordinaryColors.functionalDivider}`,
  borderRight: `1px solid ${ordinaryColors.functionalDivider}`,
  background: ordinaryColors.functionalGreyBack,
};

export const boxSx = {
  display: "flex",
  padding: "32px 0px",
  justifiyContent: "center",
  flexDirection: "column",
  gap: "10px",
  flex: "1 0 0",
};

export const expandIcon = (
  <ArrowDropDownIcon sx={{ height: "43px", width: "43px", color: ordinaryColors.primaryBrand }} />
);

const Accordion: React.FC<Props> = (props) => {
  const [value, setValue] = useRecoilState(accordionStatesFamily(props.accordionId));
  return (
    <AccordionInner
      accordionSx={getAccordionSx(value)}
      accordiondetailssx={accordionDetailsSx}
      accordionsummarysx={accordionSummarySx}
      boxSx={boxSx}
      children
      expandIcon={expandIcon}
      setValue={setValue}
      value={value}
      {...props}
    />
  );
};

export default Accordion;
