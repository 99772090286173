import { LegalNoteLink, MailTo, nextLine } from "components/mlpaUiComponents/LegalNote/lib";

export default {
  migrationNote:
    "Aufgrund der Datenmigration aus der vorherigen Datenbankversion können in diesem Zertifikat einige Informationen fehlen oder fehlerhaft sein. Diese müssen zur Nutzung vom Hersteller aktualisiert werden und erhalten anschließend eine ZEREZ-ID.",

  migrationNoteTooltipCertificate:
    "In diesem Zertifikat könnten einige Informationen fehlen oder fehlerhaft sein. Es muss von der zuständigen Stelle aktualisiert werden.",
  migrationNoteTooltipUnit:
    "In diesem Zertifikat könnten einige Informationen fehlen oder fehlerhaft sein. Es muss von der zuständigen Stelle aktualisiert werden.",
  noDataAvailable: "Keine Daten verfügbar...",
  noResultsAvailable: "Keine Ergebnisse verfügbar",
  noUploadedFiles: "Für dieses Zertifikat wurden keine Dokumente hochgeladen",
  unitOrCertificateNotFoundMessage:
    "Falls Sie Ihre Einheit oder das entsprechende Einheiten- oder Komponentenzertifikat nicht im Register finden, melden Sie sich bei Ihrem Hersteller.",
  reducedFunctionalityNote: `Bitte beachten Sie: 
  ${nextLine()}Alle Upload-Funktionen für die Pflichtangaben sind vorhanden, falls sie Probleme beim Upload der Daten haben, geben Sie uns schnellstmöglich eine Rückmeldung (${MailTo(
    {
      mailTo: "info@zerez.net",
      label: "info@zerez.net",
    }
  )}).
  ${nextLine()}Falls Sie Fragen haben, schauen Sie gerne in unsere ${LegalNoteLink({
    url: "https://www.zerez.net/faq",
    ariaLabel: "Link zu",
    label: "FAQ",
  })} und beachten Sie für einen vereinfachten Upload die Möglichkeit des Uploadens per Excel-Import, die entsprechende Vorlage finden Sie auf der Webseite unter ${LegalNoteLink(
    {
      url: "https://www.zerez.net/otherdocuments",
      ariaLabel: "Link zu",
      label: "Vorlage und Dokumente",
    }
  )}.`,
};
