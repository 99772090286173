import { ValidateFormPropsInner } from "@ml-pa/react-apollo-form-tool/formGen.model";
import { Rules } from "@ml-pa/react-apollo-form-tool/formValidation/types";
import { CertificateFileCreateInput } from "graphqlBase/types";
import {
  CertificateCreateAdditionalFields,
  CertificateCreateRules,
  CertificateUpdateAdditionalFields,
  CertificateUpdateRules,
} from "raft/CertificateForm";
import * as SchemaTypes from "raft/internal/schemaTypes";
import { CertificateInputCreate, UnitInputCreate } from "raft/internal/schemaTypes";
import { UnitCreateAdditionalFields } from "raft/UnitForm";
import { SetterOrUpdater } from "recoil";
export interface CertificateFormProps {
  uploadCertificate: UploadCertificate | undefined;
  userInfo: {
    role: string;
  };
}

export interface CertificateFormBaseProps {
  uploadCertificate: UploadCertificate | undefined;
  persistForm: (data: CertficateModify & { id: string }) => Promise<void>;
  setLoading: (loading: boolean) => void;
  setSharedUnitValues: SetterOrUpdater<Partial<UploadCertificateUnit>>;
}

export interface CertificateCreateFormProps extends CertificateFormBaseProps {
  certificateCreateFormValidationRules: CertificateCreateRules;
}
export interface CertificateUpdateFormProps extends CertificateFormBaseProps {
  certificateUpdateFormValidationRules: CertificateUpdateRules;
  id: string;
  tenantId: string;
  resetToRemoteValues: () => void;
  onCancel: () => void;
}

export type UploadCertificateNonPartial = CertificateInputCreate & CertificateCreateAdditionalFields;

export interface UploadCertificate extends Partial<UploadCertificateNonPartial> {}

export type UploadCertificateUnitNonPartial = UnitInputCreate & UnitCreateAdditionalFields;
export interface UploadCertificateUnit extends Partial<UploadCertificateUnitNonPartial> {
  id: string;
}

export interface UploadCertificateFile extends CertificateFileCreateInput {
  id?: string;
}

export interface RowJson {
  key: string;
  label: string;
  value: string;
}

export type CertificateFieldMapping = FieldMapper<UploadCertificate, keyof UploadCertificate>;
export type UnitFieldMapping = FieldMapper<UploadCertificateUnit, keyof UploadCertificateUnit>;
export type MappingValueGetter<T extends object, K extends keyof T> = (args: {
  value: string;
  data: T;
  rows: RowJson[];
  id: string;
}) => Promise<T[K]> | T[K] | undefined; // | T[K];

export type FieldMapper<T extends object, K extends keyof T> = {
  key: string;
  field: K;
  getter?: MappingValueGetter<T, K>;
  maxDecPlaces?: number;
};
export type UnitFieldMappingGetter = MappingValueGetter<UploadCertificateUnit, keyof UploadCertificateUnit>;
export type CertificateFieldMappingGetter = MappingValueGetter<UploadCertificate, keyof UploadCertificate>;

export type ImportConfirmValues = "NoExcelData" | "ToConfirm" | "Import";
export type FormValueGetter = ({ columns }: { columns: RowJson[][] }) => Promise<
  | {
      certificateUpload: UploadCertificate;
      certificateUploadUnits: UploadCertificateUnit[];
      certificateBridgeCertificateNormIssueDates: string[];
      certificateBridgeSoftwareVersions: string[];
    }
  | undefined
>;
export enum CerticateUploadProcessType {
  toDecide = "toDecide",
  newCertificate = "newCertificate",
  replaceCertificate = "replaceCertificate",
}

export type CertificateRules = Rules<
  CertficateModify,
  ValidateFormPropsInner<
    CertficateModify,
    SchemaTypes.CertificateInputCreateProps["injectedValues"] & CertificateCreateAdditionalFields
  >
>;
type Overwrite<T, NEWT> = Omit<T, keyof NEWT> & NEWT;
export type CertficateModify = Partial<
  Overwrite<
    SchemaTypes.CertificateInputCreate & CertificateCreateAdditionalFields,
    SchemaTypes.CertificateInputUpdate & CertificateUpdateAdditionalFields
  >
>;
