import { ApolloClient } from "@apollo/client";
import { getRecoilStore } from "recoil-toolkit";
import { BridgeParams, BridgeState } from "./BridgeParamTypes";
import { makeBridgeState, MakeBridgeStateParams } from "./gerenericBridgeState";
import { makeBridgeRaftMutation, upperCaseFirst } from "./makeBridgeEntityOperations";

export const saver =
  (id?: string) =>
  ({ client, bridgeParams }: { client: ApolloClient<unknown>; bridgeParams: BridgeParams }) => {
    const mutatation = makePersistor({ bridgeParams, client });
    const getBridgeDelta = getBridgeDeltaFactory({ bridgeParams, id });
    return async (idInner: string) => {
      const { creates, deletes, state } = await getBridgeDelta(idInner);
      if (!deletes.length && !Object.keys(creates).length) return Promise.resolve({ adds: [], removes: [] });
      return mutatation({ creates, deletes });
    };
  };

const getBridgeDeltaFactory = ({ bridgeParams, id }: MakeBridgeStateParams) => {
  const { state } = makeBridgeState({ bridgeParams, id });
  return async (id: string) => {
    const bridges = await getRecoilStore().then((store) => store.getPromise(state));
    const { creates, deletes } = getBridgeDelta({ id, bridgeParams, bridges });
    return { creates, deletes, state };
  };
};

export const getBridgeDelta = ({
  bridges,
  bridgeParams,
  id,
}: {
  bridges: BridgeState;
  bridgeParams: BridgeParams;
  id: string;
}) => {
  return Object.values(bridges).reduce<{
    creates: { [key: string]: string }[];
    deletes: string[];
  }>(
    ({ creates, deletes }, row) => {
      if (row.isDeleted && row.id) {
        return { creates, deletes: deletes.concat(row.id) };
      }
      if (!row.id && !row.isDeleted) {
        return {
          creates: creates.concat({ [bridgeParams.innerId]: id, [bridgeParams.outerId]: row.outerId }),
          deletes,
        };
      }
      return { creates, deletes };
    },
    { creates: [], deletes: [] }
  );
};

export const makePersistor = ({
  client,
  bridgeParams,
}: {
  client: ApolloClient<unknown>;
  bridgeParams: BridgeParams;
}) => {
  return async ({ creates, deletes }: { creates: { [key: string]: string }[]; deletes: string[] }) => {
    const res = await executeSplitMutations({ creates, deletes, client, bridgeParams });
    const newIDs = (res.data[`create${upperCaseFirst(bridgeParams.bridgeEntityName)}`] ?? []) as {
      id: string;
      outerId: string;
    }[];
    const removed = (res.data[`delete${upperCaseFirst(bridgeParams.bridgeEntityName)}`] ?? []) as {
      id: string;
      outerId: string;
    }[];
    const adds = newIDs.map(({ id, outerId }) => ({ id, outerId }));
    const removes = removed.map(({ id, outerId }) => ({ id, outerId }));
    return { adds, removes };
  };
};

const executeSplitMutations = async ({
  creates,
  deletes,
  client,
  bridgeParams,
}: {
  creates: { [key: string]: string }[];
  deletes: string[];
  client: ApolloClient<unknown>;
  bridgeParams: BridgeParams;
}) => {
  const { mutation, createMutation, deleteMutation } = makeBridgeRaftMutation({ bridgeParams });
  if (!bridgeParams.splitMutations) {
    const res = await client.mutate({ mutation, variables: { creates, deletes } });
    return res;
  }
  const removes = await client.mutate({ mutation: deleteMutation, variables: { deletes } });
  const adds = await client.mutate({ mutation: createMutation, variables: { creates } });
  const combined = { data: { ...removes.data, ...adds.data } };
  return combined;
};
