import styled, { CSSObject } from "@emotion/styled";
import { Button } from "@mui/material";
import React, { CSSProperties } from "react";
import ordinaryColors from "themes/ordinaryColors";
import translations from "translations";
import { AccordionStateButtonsProps } from ".";

interface Props extends AccordionStateButtonsProps {
  buttonType: "expand" | "collapse";
  style?: CSSProperties & CSSObject;
}

export const StyledButton = styled(Button)(({ style }: { style?: CSSObject }) => ({
  color: ordinaryColors.primaryBrand,
  fontFeatureSettings: "'clig' off, 'liga' off",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "150% /* 24px */",
  letterSpacing: "0.15px",
  ":disabled": {
    color: ordinaryColors.functionalLightGrey,
  },
  ...style,
}));

const AccordionStateButton: React.FC<Props> = (props) => {
  function handleClick(buttonType: "expand" | "collapse") {
    if (buttonType === "expand") {
      props.setState(props.accordionStates.map((accordionState) => ({ ...accordionState, state: true })));
      return;
    }
    props.setState(props.accordionStates.map((accordionState) => ({ ...accordionState, state: false })));
  }

  return (
    <StyledButton
      disabled={props.setDisabled(props.accordionStates, props.buttonType)}
      onClick={() => handleClick(props.buttonType)}
      style={props.style}
    >
      {props.buttonType === "expand"
        ? props.expandLabel ?? translations.globals.button.expand
        : props.collapseLabel ?? translations.globals.button.collapse}
    </StyledButton>
  );
};

export default AccordionStateButton;
