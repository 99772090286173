import { FC, useState } from "react";
import { DialogTitle, List, ListItem, ListItemButton, ListItemText, Divider, Box } from "@mui/material";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import CurrentSortIcon from "./CurrentSortIcon";
import ordinaryColors from "themes/ordinaryColors";
import { formattedTitle } from "utils/formattedTitle";
import { StyledApplyButton } from "./styles";
import { translations } from "translations";

interface Props {
  sortingOptions: {
    fields: { title: string; value: string }[];
    orders: string[];
  };
  selectedValue: string;
  sort: string;

  handleSortApply: (sortValue: string, fieldValue: string) => void;
  title?: string;
  left?: number;
  top?: number;
}

const PopoverForSort: FC<Props> = ({ selectedValue, sort, handleSortApply, sortingOptions, title, left, top }) => {
  const [sortOrder, setSortOrder] = useState<string>(sort);
  const [selectedFieldValue, setSelectedFieldValue] = useState<string>(selectedValue);
  const [activeAscDesc, setactiveAscDesc] = useState<boolean>(true);
  const handleSort = (value: string) => {
    setSortOrder(value);
    setactiveAscDesc(true);
  };

  const handleValueField = (value: string) => {
    setSelectedFieldValue(value);
    setactiveAscDesc(false);
  };
  return (
    <Box
      sx={{
        flexDirection: "column",
        display: "flex",
        justifyContent: "flex-start",
        left: left,
        top: top,
      }}
    >
      {title && <DialogTitle>{title}</DialogTitle>}
      <List sx={{ pt: 0, marginTop: "16px" }}>
        {sortingOptions?.fields?.map((field: { title: string; value: string }, i) => (
          <ListItem
            disableGutters
            key={i}
            sx={(theme) => ({
              color: field.value === selectedFieldValue ? ordinaryColors.background : ordinaryColors.grey300,
              paddingTop: "0",
              paddingBottom: "0",
            })}
          >
            <ListItemButton
              onClick={() => handleValueField(field.value)}
              sx={{ width: "220px", paddingTop: "4px", paddingBottom: "4px" }}
            >
              <ListItemText
                primaryTypographyProps={{ fontSize: "16px", marginLeft: "12px" }}
                primary={`${formattedTitle(field.title)}`}
              />
              {field.value === selectedFieldValue && (
                <CheckOutlinedIcon
                  sx={(theme) => ({
                    fontSize: "24px",
                    color: theme.palette.primary.main,
                  })}
                />
              )}
            </ListItemButton>
          </ListItem>
        ))}
        <Divider sx={{ marginLeft: "16px", marginRight: "16px", marginTop: "16px", marginBottom: "16px" }} />
        {sortingOptions?.orders?.map((value: string, i) => (
          <ListItem
            disableGutters
            key={i}
            sx={(theme) => ({
              paddingTop: "0px",
              paddingBottom: "0px",
              color:
                value.toUpperCase() === sortOrder.toUpperCase() && activeAscDesc
                  ? ordinaryColors.background
                  : ordinaryColors.grey300,
            })}
          >
            <ListItemButton
              onClick={() => handleSort(value)}
              sx={(theme) => ({
                width: "220px",
                paddingTop: "4px",
                paddingBottom: "4px",
                display: "flex",
                justifyContent: "space-between",
              })}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginLeft: "12px",
                  marginRight: "24px",
                  width: "100%",
                }}
              >
                <ListItemText
                  sx={{ display: "flex", width: "fit-content" }}
                  primaryTypographyProps={{ fontSize: "16px" }}
                  primary={`${formattedTitle(value)}`}
                />
                <CurrentSortIcon value={value} sort={sortOrder} activeAscDesc={activeAscDesc} />
              </Box>
              <CheckOutlinedIcon
                sx={(theme) => ({
                  fontSize: "24px",
                  color: theme.palette.primary.main,
                  visibility: value.toUpperCase() === sortOrder.toUpperCase() && activeAscDesc ? "visible" : "hidden",
                })}
              />
            </ListItemButton>
          </ListItem>
        ))}
        <Divider sx={{ marginLeft: "16px", marginRight: "16px", marginTop: "16px" }} />
      </List>
      <StyledApplyButton
        variant="outlined"
        disabled={!activeAscDesc}
        onClick={() => handleSortApply(sortOrder, selectedFieldValue)}
      >
        {translations.globals.button.apply}
      </StyledApplyButton>
    </Box>
  );
};

export default PopoverForSort;
