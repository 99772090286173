import Box from "@mui/system/Box";
import * as React from "react";

import Props from "./index.d";

interface GFCForm_Base extends Props {
  children: React.ReactNode;
}

// eslint-disable-next-line react/display-name
export default ({ children, className }: GFCForm_Base) => {
  return (
    <Box
      role="form"
      className={className}
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
      }}
    >
      {children}
    </Box>
  );
};
