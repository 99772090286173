import { useGetCertificateNormIssueDatesQuery } from "graphqlBase/certificateNormIssueDate/__generated__/getCertificateNormIssueDates";
import { useCallback } from "react";
import { formatISODateString } from "translations/formatter";

const useGetItemtext = () => {
  const { data } = useGetCertificateNormIssueDatesQuery();
  return useCallback(
    (id: string) => {
      const item = data?.certificateNormIssueDates?.items?.find((item) => item.id === id);
      if (!item) return "";
      return `${item.certificateNormBridges[0].certificateNorm.certificateNormName}: ${formatISODateString(
        item.certificateNormIssueDate
      )}`;
    },
    [data]
  );
};
export default useGetItemtext;
