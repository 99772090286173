import type * as Types from '../../types';

import client from "apollo/client";
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetCertificateAuthoritiesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetCertificateAuthoritiesQuery = { __typename?: 'Query', tenants?: { __typename?: 'TenantCollectionSegment', items?: Array<{ __typename?: 'Tenant', key: string, value: string }> | null } | null };


        export type ExecGetCertificateAuthoritiesOptions = Omit<
        Apollo.QueryOptions<
        GetCertificateAuthoritiesQueryVariables, GetCertificateAuthoritiesQuery
        >,
        "query"
      >;
    export const execGetCertificateAuthoritiesQuery = (options: ExecGetCertificateAuthoritiesOptions) =>
    client.query< GetCertificateAuthoritiesQuery, GetCertificateAuthoritiesQueryVariables>({
      ...options,
      query: GetCertificateAuthoritiesDocument,
    });
    

export const GetCertificateAuthoritiesDocument = gql`
    query getCertificateAuthorities {
  tenants(where: {tenantRole: {eq: CERTIFICATION_AUTHORITY}}) {
    items {
      key: id
      value: tenantName
    }
  }
}
    `;

/**
 * __useGetCertificateAuthoritiesQuery__
 *
 * To run a query within a React component, call `useGetCertificateAuthoritiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCertificateAuthoritiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCertificateAuthoritiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCertificateAuthoritiesQuery(baseOptions?: Apollo.QueryHookOptions<GetCertificateAuthoritiesQuery, GetCertificateAuthoritiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCertificateAuthoritiesQuery, GetCertificateAuthoritiesQueryVariables>(GetCertificateAuthoritiesDocument, options);
      }
export function useGetCertificateAuthoritiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCertificateAuthoritiesQuery, GetCertificateAuthoritiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCertificateAuthoritiesQuery, GetCertificateAuthoritiesQueryVariables>(GetCertificateAuthoritiesDocument, options);
        }
export type GetCertificateAuthoritiesQueryHookResult = ReturnType<typeof useGetCertificateAuthoritiesQuery>;
export type GetCertificateAuthoritiesLazyQueryHookResult = ReturnType<typeof useGetCertificateAuthoritiesLazyQuery>;
export type GetCertificateAuthoritiesQueryResult = Apollo.QueryResult<GetCertificateAuthoritiesQuery, GetCertificateAuthoritiesQueryVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
          export type ResultType<T> = Types.ResultType<T>;
          export type PaginatedResultType<T> = Types.PaginatedResultType<T>;
          export type NonMaybe<T> = Types.NonMaybe<T>;
          export type Maybe<T> = Types.Maybe<T>;