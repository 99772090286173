import { makeNewFormMemoized } from "./"
import {
  Operation,
  QueryArray,
  DeleteChildProps,
  BeforeRemoteMuatation,
  BeforeRemoteMuatationProps,
  OnUpdateDataMiddleWare,
  OnUpdateDataMiddleWareProps,
  ValidateForm,
  ValidateFormProps,
  ValidateFormPropsInner,
  SetValidationCallback,
  RegisterValidationCallbackProps,
  ValidationCallBackArgs,
} from "@ml-pa/react-apollo-form-tool/formGen.model"
import getErrorInformation from "@ml-pa/react-apollo-form-tool/formValidation/getErrorInformation"
import React from "react"
import * as SchemaTypes from "./internal/schemaTypes"
import {
  FormTypes,
  ModifyProps,
  CustomRenderExternal,
} from "./internal/typeLinks"
import {
  makeFormState,
  connectField,
} from "@ml-pa/react-apollo-form-tool/buildForm/formState"
import { RecoilState } from "recoil"
import { Scalars } from "@ml-pa/react-apollo-form-tool/scalars"
import { AdditonalFieldsType } from "./internal/raft.config"
import {
  Rules,
  extractTriggerFieldsFromRules,
} from "@ml-pa/react-apollo-form-tool/formValidation"
type FieldTypes = AdditonalFieldsType & Scalars

export type UnitCreateCustomRender<K extends keyof FormTypes> =
  CustomRenderExternal<
    K,
    SchemaTypes.UnitInputCreate & UnitCreateAdditionalFields
  >
export type UnitCreateAdditionalFields = {
  __aa?: FieldTypes["integer"]
  _test?: FieldTypes["test"]
  __certificateTypeId?: FieldTypes["string"]
  __modifiedByUser?: FieldTypes["decimal"]
  __normList?: FieldTypes["string"]
  __isLinked?: FieldTypes["decimal"]
  __category?: FieldTypes["string"]
  __unitToReplaceId?: FieldTypes["string"]
  __withTG8Rev25Conformity?: FieldTypes["boolean"]
}
export type UnitCreateOnUpdateDataMiddleWare =
  OnUpdateDataMiddleWare<SchemaTypes.UnitInputCreate>
export type UnitCreateOnUpdateDataMiddleWareProps = OnUpdateDataMiddleWareProps<
  SchemaTypes.UnitInputCreate & UnitCreateAdditionalFields
>
export type UnitCreateBeforeRemoteMuatation = BeforeRemoteMuatation<
  SchemaTypes.UnitInputCreate & UnitCreateAdditionalFields,
  SchemaTypes.UnitInputCreateProps["injectedValues"] &
    UnitCreateAdditionalFields
>
export type UnitCreateBeforeRemoteMuatationProps = BeforeRemoteMuatationProps<
  SchemaTypes.UnitInputCreate & UnitCreateAdditionalFields,
  SchemaTypes.UnitInputCreateProps["injectedValues"] &
    UnitCreateAdditionalFields
>
export type UnitCreateValidateForm = ValidateForm<
  SchemaTypes.UnitInputCreate & UnitCreateAdditionalFields,
  SchemaTypes.UnitInputCreateProps["injectedValues"] &
    UnitCreateAdditionalFields
>
export type UnitCreateValidateFormProps = ValidateFormProps<
  SchemaTypes.UnitInputCreate & UnitCreateAdditionalFields,
  SchemaTypes.UnitInputCreateProps["injectedValues"] &
    UnitCreateAdditionalFields
>
export type UnitCreateRules = Rules<
  SchemaTypes.UnitInputCreate & UnitCreateAdditionalFields,
  ValidateFormPropsInner<
    SchemaTypes.UnitInputCreate & UnitCreateAdditionalFields,
    SchemaTypes.UnitInputCreateProps["injectedValues"] &
      UnitCreateAdditionalFields
  >
>
export type UnitCreateSetValidationCallback = SetValidationCallback<
  SchemaTypes.UnitInputCreate & UnitCreateAdditionalFields,
  SchemaTypes.UnitInputCreateProps["injectedValues"] &
    UnitCreateAdditionalFields
>
export type UnitCreateRegisterValidationCallbackProps =
  RegisterValidationCallbackProps<
    SchemaTypes.UnitInputCreate & UnitCreateAdditionalFields,
    SchemaTypes.UnitInputCreateProps["injectedValues"] &
      UnitCreateAdditionalFields,
    ValidateFormPropsInner<
      SchemaTypes.UnitInputCreate & UnitCreateAdditionalFields,
      SchemaTypes.UnitInputCreateProps["injectedValues"] &
        UnitCreateAdditionalFields
    >
  >
export type UnitCreateValidationCallBackArgs = ValidationCallBackArgs<
  SchemaTypes.UnitInputCreate & UnitCreateAdditionalFields,
  SchemaTypes.UnitInputCreateProps["injectedValues"] &
    UnitCreateAdditionalFields
>
export type UnitCreateValidationArgs = Partial<
  ValidateFormPropsInner<
    SchemaTypes.UnitInputCreate & UnitCreateAdditionalFields,
    SchemaTypes.UnitInputCreateProps["injectedValues"] &
      UnitCreateAdditionalFields
  >
>

export interface UnitCreateProps
  extends SchemaTypes.UnitInputCreateProps,
    UnitCreateAdditionalFields {
  injectedValues?: Omit<
    SchemaTypes.UnitInputCreate & UnitCreateAdditionalFields,
    "id"
  >
  multiInstance?: boolean
  formVersion?: string
  configpath?: string
  onCancel?: () => void
  modifyProps?: ModifyProps<
    SchemaTypes.UnitInputCreate & UnitCreateAdditionalFields
  >
  afterCreate?: (
    data: SchemaTypes.UnitInputCreate &
      UnitCreateAdditionalFields & { id: string }
  ) => void
  onUpdateDataMiddleWare?: OnUpdateDataMiddleWare<
    SchemaTypes.UnitInputCreate & UnitCreateAdditionalFields
  >
  beforeRemoteMuatation?: BeforeRemoteMuatation<
    SchemaTypes.UnitInputCreate & UnitCreateAdditionalFields,
    SchemaTypes.UnitInputCreateProps["injectedValues"] &
      UnitCreateAdditionalFields
  >
  validateForm?: ValidateForm<
    SchemaTypes.UnitInputCreate & UnitCreateAdditionalFields,
    SchemaTypes.UnitInputCreateProps["injectedValues"] &
      UnitCreateAdditionalFields
  >
  validationRules?: UnitCreateRules
  fetchOnlyId?: boolean
  validateOnLoad?: boolean
  onSubmitError?: (
    data: SchemaTypes.UnitInputCreate &
      UnitCreateAdditionalFields & { id: string }
  ) => void
  setValidationCallback?: UnitCreateSetValidationCallback
  updateAfterCreateQueries?: QueryArray
}

const getUnitCreateTriggerMap = (rulesSet: UnitCreateRules) => {
  return extractTriggerFieldsFromRules<
    SchemaTypes.UnitInputCreate & UnitCreateAdditionalFields,
    ValidateFormPropsInner<
      SchemaTypes.UnitInputCreate & UnitCreateAdditionalFields,
      any
    >
  >(rulesSet)
}

export const validateUnitCreate =
  (
    rules: UnitCreateRules,
    setValidationCallback?: UnitCreateSetValidationCallback
  ): UnitCreateValidateForm =>
  ({ applyRules, ...validationArgs }) => {
    registerValidationCallbackUnitCreate({
      setValidationCallback,
      rules,
      applyRules,
      ...validationArgs,
    })
    return applyRules({
      validationArgs,
      rules: rules,
    })
  }

export function registerValidationCallbackUnitCreate({
  setValidationCallback,
  rules,
  applyRules,
  ...validationArgs
}: UnitCreateRegisterValidationCallbackProps) {
  if (setValidationCallback)
    setValidationCallback({
      validation: async (validationArgsInner, fullValidation = true) => {
        const rulesFields = Object.keys(rules)
        const fields = fullValidation
          ? { changedFields: rulesFields, fieldsToValidate: rulesFields }
          : {}
        const combinedErrors = await applyRules({
          validationArgs: {
            ...validationArgs,
            ...validationArgsInner,
            ...fields,
          },
          rules: rules,
        })
        return getErrorInformation({
          combinedErrors,
          isSubmit: validationArgsInner.isSubmit ?? true,
          formErrorsPre: validationArgs.errors,
        })
      },
    })
}

export const UnitCreateForm: React.FC<UnitCreateProps> = (props) => {
  const {
      afterCreate,
      onSubmitError,
      injectedValues,
      multiInstance = false,
      formVersion,
      configpath,
      onCancel,
      updateAfterCreateQueries,
      beforeRemoteMuatation,
      onUpdateDataMiddleWare,
      validateForm,
      validationRules,
      modifyProps,
      fetchOnlyId,
      validateOnLoad,
      ...params
    } = props,
    { Form: UnitCreate } = makeNewFormMemoized({
      entity: "unit",

      operation: "create",
      formVersion,
      configpath,
      params: multiInstance ? params : undefined,
    })

  const validationTriggerFields = React.useMemo(() => {
    return getUnitCreateTriggerMap(props.validationRules ?? {})
  }, [props.validationRules])

  const validateFormInner = React.useMemo(() => {
    return (
      validateForm ??
      validateUnitCreate(
        props.validationRules ?? {},
        props.setValidationCallback
      )
    )
  }, [validateForm, props.validationRules])
  return (
    <UnitCreate
      params={params}
      onCancel={onCancel}
      injectedValues={injectedValues}
      fetchOnlyId={fetchOnlyId}
      validateOnLoad={validateOnLoad}
      afterCreate={afterCreate}
      updateAfterCreateQueries={updateAfterCreateQueries}
      beforeRemoteMuatation={beforeRemoteMuatation}
      onSubmitError={onSubmitError}
      onUpdateDataMiddleWare={onUpdateDataMiddleWare}
      validateForm={validateFormInner}
      validationTriggerFields={validationTriggerFields}
      modifyProps={modifyProps}
    />
  )
}
export const getUnitCreateFormAtom = ({
  formVersion,
}: {
  formVersion?: string
}) => {
  return makeFormState<
    SchemaTypes.UnitInputCreate & UnitCreateAdditionalFields
  >(`unit.create.${formVersion ?? "active"}.current`)
}
export const getUnitCreateFieldSelector = ({
  formVersion,
}: {
  formVersion?: string
}) => {
  const formAtom = makeFormState<
    SchemaTypes.UnitInputCreate & UnitCreateAdditionalFields
  >(`unit.create.${formVersion ?? "active"}.current`)
  return function d<
    K extends keyof (SchemaTypes.UnitInputCreate & UnitCreateAdditionalFields)
  >(key: K) {
    return connectField<
      SchemaTypes.UnitInputCreate & UnitCreateAdditionalFields,
      K
    >(formAtom)(key)
  }
}

export type UnitUpdateCustomRender<K extends keyof FormTypes> =
  CustomRenderExternal<
    K,
    SchemaTypes.UnitInputUpdate & UnitUpdateAdditionalFields
  >
export type UnitUpdateAdditionalFields = {
  __aa?: FieldTypes["integer"]
  _test?: FieldTypes["test"]
  __modifiedByUser?: FieldTypes["decimal"]
  __normList?: FieldTypes["string"]
}
export type UnitUpdateOnUpdateDataMiddleWare =
  OnUpdateDataMiddleWare<SchemaTypes.UnitInputUpdate>
export type UnitUpdateOnUpdateDataMiddleWareProps = OnUpdateDataMiddleWareProps<
  SchemaTypes.UnitInputUpdate & UnitUpdateAdditionalFields
>
export type UnitUpdateBeforeRemoteMuatation = BeforeRemoteMuatation<
  SchemaTypes.UnitInputUpdate & UnitUpdateAdditionalFields,
  SchemaTypes.UnitInputUpdateProps["injectedValues"] &
    UnitUpdateAdditionalFields
>
export type UnitUpdateBeforeRemoteMuatationProps = BeforeRemoteMuatationProps<
  SchemaTypes.UnitInputUpdate & UnitUpdateAdditionalFields,
  SchemaTypes.UnitInputUpdateProps["injectedValues"] &
    UnitUpdateAdditionalFields
>
export type UnitUpdateValidateForm = ValidateForm<
  SchemaTypes.UnitInputUpdate & UnitUpdateAdditionalFields,
  SchemaTypes.UnitInputUpdateProps["injectedValues"] &
    UnitUpdateAdditionalFields
>
export type UnitUpdateValidateFormProps = ValidateFormProps<
  SchemaTypes.UnitInputUpdate & UnitUpdateAdditionalFields,
  SchemaTypes.UnitInputUpdateProps["injectedValues"] &
    UnitUpdateAdditionalFields
>
export type UnitUpdateRules = Rules<
  SchemaTypes.UnitInputUpdate & UnitUpdateAdditionalFields,
  ValidateFormPropsInner<
    SchemaTypes.UnitInputUpdate & UnitUpdateAdditionalFields,
    SchemaTypes.UnitInputUpdateProps["injectedValues"] &
      UnitUpdateAdditionalFields
  >
>
export type UnitUpdateSetValidationCallback = SetValidationCallback<
  SchemaTypes.UnitInputUpdate & UnitUpdateAdditionalFields,
  SchemaTypes.UnitInputUpdateProps["injectedValues"] &
    UnitUpdateAdditionalFields
>
export type UnitUpdateRegisterValidationCallbackProps =
  RegisterValidationCallbackProps<
    SchemaTypes.UnitInputUpdate & UnitUpdateAdditionalFields,
    SchemaTypes.UnitInputUpdateProps["injectedValues"] &
      UnitUpdateAdditionalFields,
    ValidateFormPropsInner<
      SchemaTypes.UnitInputUpdate & UnitUpdateAdditionalFields,
      SchemaTypes.UnitInputUpdateProps["injectedValues"] &
        UnitUpdateAdditionalFields
    >
  >
export type UnitUpdateValidationCallBackArgs = ValidationCallBackArgs<
  SchemaTypes.UnitInputUpdate & UnitUpdateAdditionalFields,
  SchemaTypes.UnitInputUpdateProps["injectedValues"] &
    UnitUpdateAdditionalFields
>
export type UnitUpdateValidationArgs = Partial<
  ValidateFormPropsInner<
    SchemaTypes.UnitInputUpdate & UnitUpdateAdditionalFields,
    SchemaTypes.UnitInputUpdateProps["injectedValues"] &
      UnitUpdateAdditionalFields
  >
>

export interface UnitUpdateProps
  extends SchemaTypes.UnitInputUpdateProps,
    UnitUpdateAdditionalFields {
  id: string
  injectedValues?: Omit<
    SchemaTypes.UnitInputUpdate & UnitUpdateAdditionalFields,
    "id"
  >
  multiInstance?: boolean
  formVersion?: string
  configpath?: string
  onCancel?: () => void
  modifyProps?: ModifyProps<
    SchemaTypes.UnitInputUpdate & UnitUpdateAdditionalFields
  >
  afterUpdate?: (
    data: SchemaTypes.UnitInputUpdate & UnitUpdateAdditionalFields
  ) => void
  onUpdateDataMiddleWare?: OnUpdateDataMiddleWare<
    SchemaTypes.UnitInputUpdate & UnitUpdateAdditionalFields
  >
  beforeRemoteMuatation?: BeforeRemoteMuatation<
    SchemaTypes.UnitInputUpdate & UnitUpdateAdditionalFields,
    SchemaTypes.UnitInputUpdateProps["injectedValues"] &
      UnitUpdateAdditionalFields
  >
  validateForm?: ValidateForm<
    SchemaTypes.UnitInputUpdate & UnitUpdateAdditionalFields,
    SchemaTypes.UnitInputUpdateProps["injectedValues"] &
      UnitUpdateAdditionalFields
  >
  validationRules?: UnitUpdateRules
  fetchAllFieldsOnUpdate?: boolean
  fetchOnlyId?: boolean
  validateOnLoad?: boolean
  onSubmitError?: (
    data: SchemaTypes.UnitInputUpdate &
      UnitUpdateAdditionalFields & { id: string }
  ) => void
  setValidationCallback?: UnitUpdateSetValidationCallback
}

const getUnitUpdateTriggerMap = (rulesSet: UnitUpdateRules) => {
  return extractTriggerFieldsFromRules<
    SchemaTypes.UnitInputUpdate & UnitUpdateAdditionalFields,
    ValidateFormPropsInner<
      SchemaTypes.UnitInputUpdate & UnitUpdateAdditionalFields,
      any
    >
  >(rulesSet)
}

export const validateUnitUpdate =
  (
    rules: UnitUpdateRules,
    setValidationCallback?: UnitUpdateSetValidationCallback
  ): UnitUpdateValidateForm =>
  ({ applyRules, ...validationArgs }) => {
    registerValidationCallbackUnitUpdate({
      setValidationCallback,
      rules,
      applyRules,
      ...validationArgs,
    })
    return applyRules({
      validationArgs,
      rules: rules,
    })
  }

export function registerValidationCallbackUnitUpdate({
  setValidationCallback,
  rules,
  applyRules,
  ...validationArgs
}: UnitUpdateRegisterValidationCallbackProps) {
  if (setValidationCallback)
    setValidationCallback({
      validation: async (validationArgsInner, fullValidation = true) => {
        const rulesFields = Object.keys(rules)
        const fields = fullValidation
          ? { changedFields: rulesFields, fieldsToValidate: rulesFields }
          : {}
        const combinedErrors = await applyRules({
          validationArgs: {
            ...validationArgs,
            ...validationArgsInner,
            ...fields,
          },
          rules: rules,
        })
        return getErrorInformation({
          combinedErrors,
          isSubmit: validationArgsInner.isSubmit ?? true,
          formErrorsPre: validationArgs.errors,
        })
      },
    })
}

export const UnitUpdateForm: React.FC<UnitUpdateProps> = (props) => {
  const {
      afterUpdate,
      injectedValues,
      multiInstance = false,
      onSubmitError,
      formVersion,
      configpath,
      onCancel,
      fetchAllFieldsOnUpdate,
      beforeRemoteMuatation,
      onUpdateDataMiddleWare,
      validateForm,
      validationRules,
      modifyProps,
      fetchOnlyId,
      validateOnLoad,
      ...params
    } = props,
    { Form: UnitUpdate } = makeNewFormMemoized({
      entity: "unit",

      operation: "update",
      formVersion,
      configpath,
      params: multiInstance ? params : undefined,
    })

  const validationTriggerFields = React.useMemo(() => {
    return getUnitUpdateTriggerMap(props.validationRules ?? {})
  }, [props.validationRules])

  const validateFormInner = React.useMemo(() => {
    return (
      validateForm ??
      validateUnitUpdate(
        props.validationRules ?? {},
        props.setValidationCallback
      )
    )
  }, [validateForm, props.validationRules])
  return (
    <UnitUpdate
      params={params}
      onCancel={onCancel}
      injectedValues={injectedValues}
      fetchOnlyId={fetchOnlyId}
      validateOnLoad={validateOnLoad}
      afterUpdate={afterUpdate}
      fetchAllFieldsOnUpdate={fetchAllFieldsOnUpdate}
      beforeRemoteMuatation={beforeRemoteMuatation}
      onSubmitError={onSubmitError}
      onUpdateDataMiddleWare={onUpdateDataMiddleWare}
      validateForm={validateFormInner}
      validationTriggerFields={validationTriggerFields}
      modifyProps={modifyProps}
    />
  )
}
export const getUnitUpdateFormAtom = ({
  id,
  formVersion,
}: {
  id: string
  formVersion?: string
}) => {
  return makeFormState<
    SchemaTypes.UnitInputUpdate & UnitUpdateAdditionalFields
  >(`unit.update.${formVersion ?? "active"}.${id}`)
}
export const getUnitUpdateFieldSelector = ({
  id,
  formVersion,
}: {
  id: string
  formVersion?: string
}) => {
  const formAtom = makeFormState<
    SchemaTypes.UnitInputUpdate & UnitUpdateAdditionalFields
  >(`unit.update.${formVersion ?? "active"}.${id}`)
  return function d<
    K extends keyof (SchemaTypes.UnitInputUpdate & UnitUpdateAdditionalFields)
  >(key: K) {
    return connectField<
      SchemaTypes.UnitInputUpdate & UnitUpdateAdditionalFields,
      K
    >(formAtom)(key)
  }
}
