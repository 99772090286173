import { nextLine } from "components/mlpaUiComponents/LegalNote/lib";

export default {
  accessPermissionManagement: "Access Permission Management",
  table: {
    column: {
      certificateName: "Certificate Number",
      requestedOn: "Requested On",
      tenantName: "Tenant Name",
      tenantRole: "Tenant Role",
      status: "Status",
      accessRequestedTo: "Requested Access To",
      typeOfRequestedData: "Type of Requested Data",
      approvedBy: "Approved/Denied By",
    },
  },
  gridConnectionValues: "Grid Connection Values",
  openRequest: "open request",
  buttons: {
    approveRequest: "Approve Request(s)",
    denyRequest: "Deny Request(s)",
  },
  modal: {
    approveTitle: "Approve Request(s)",
    denyTitle: "Deny Request(s)",
    approveBody: `Are you sure you want to approve this/these access request(s)?${nextLine()}Once approved, user(s) of the tenant(s) will gain access to the data. Access can be revoked at any time if needed.`,
    denyBody: `Are you sure you want to deny this/these access request(s)?${nextLine()}The requesting user(s) will be informed of your decision via email. They can submit a new access request at any time if needed.`,
    confirmApprove: "approve request(s)",
    confirmDeny: "deny request(s)",
  },
  filters: {
    status: "Status",
    typeOfRequestedData: "Type of Requested Data",
  },
};
