/* eslint-disable import/no-unresolved */
import { FC, useState } from "react";
import { Box } from "@mui/material";
import Props from "./index.d";
import { FileWithPath, useDropzone } from "react-dropzone";
import FileDropZone from "./FileDropZone";
import FileLists from "./FileLists";

export type { FileWithPath };

const VirtualDropZone: FC<Props> = ({ title, formatsString, ...props }) => {
  const {
    getRootProps,
    getInputProps,
    open: openDialog,
    acceptedFiles,
  } = useDropzone({
    accept: props.accept,
    noClick: true,
    noKeyboard: true,
    maxSize: 3000000,
    maxFiles: 1,
    useFsAccessApi: false,
  });
  const [acceptedFilesCount, setAcceptedFilesCount] = useState(0);

  return (
    <Box
      sx={{
        minHeight: "122px",
      }}
    >
      <Box
        sx={{
          fontFeatureSettings: "'clig' off, 'liga' off",
          fontSize: "14px",
          fontStyle: "normal",
          fontWeight: 400,
          lineHeight: "143%",
          letterSpacing: "0.17px",
          marginBottom: "6px",
        }}
      >
        {title ? title : ""}
      </Box>
      {acceptedFilesCount === 0 && (
        <FileDropZone
          openDialog={openDialog}
          getRootProps={getRootProps}
          getInputProps={getInputProps}
          formatsString={formatsString}
        />
      )}
      <FileLists acceptedFiles={acceptedFiles} setAcceptedFilesCount={setAcceptedFilesCount} />
    </Box>
  );
};

export default VirtualDropZone;
