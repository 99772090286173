/* eslint-disable react/jsx-max-depth */
import AllInclusiveIcon from "@mui/icons-material/AllInclusive";
import { Divider, FormControl, IconButton, OutlinedInput, Select, Typography } from "@mui/material";
import Slider from "@mui/material/Slider";
import { Stack } from "@mui/system";
import ResetFilterButton from "components/molecules/ResetFilterButton";
import * as React from "react";
import { useRecoilState } from "recoil";
import translations from "translations";
import { rangeSliderFilterSelectorFamily, RangeSliderProps } from "../../State/manageFiltersInUnitsOverview";
import TitleWithFilterCounter from "../TitleWithFilterCounter";
import MaxField from "./MaxField";
import MinField from "./MinField";
import {
  StyledIconButtonWrapper,
  StyledRangeSliderBox,
  StyledRangeSliderRoot,
  StyledResetFilterButtonWrapper,
} from "./styles";
interface RangeSliderFilterProps {
  filterType: keyof RangeSliderProps;
  title: string;
}

const showLabel = [1, 200, 500, 1000, 1500, 2000, 2500, 3000, 3500, 4000, 4500, 5000];

const getFilterSettings = (filterType: keyof RangeSliderProps, filters: number[]) => {
  switch (filterType) {
    case "maxActivePower":
      return {
        defaultValue: undefined,
        marks: [1, 2, 5, 10, 20, 50, 100, 200, 500, 1000, 1200, 1500, 2000, 2500, 3000, 3500, 4000, 4500, 5000].map(
          (value) => {
            return !showLabel.includes(value) ? { value, label: "" } : { value, label: value.toString() };
          }
        ),
        maxmax: 5000,
        minmin: 0,
        renderValue: (title: string) => {
          return filters[0] !== 0 || filters[1] !== 5000 ? (
            <TitleWithFilterCounter selectedLength={1} title={title} />
          ) : (
            <Typography>{title}</Typography>
          );
        },
        step: null,
        unitsForVoltageAndKw: "kW",
        valueLabelDisplay: "auto",
      };
    case "ratedCurrent":
      return {
        defaultValue: 100,
        marks: undefined,
        maxmax: 141,
        minmin: 0,
        renderValue: (title: string) => {
          return filters[0] !== 0 || filters[1] !== Infinity ? (
            <TitleWithFilterCounter selectedLength={1} title={title} />
          ) : (
            <Typography>{title}</Typography>
          );
        },
        step: 10,
        unitsForVoltageAndKw: "A",
        valueLabelDisplay: filters[1] === Infinity ? "off" : "auto",
      };
    case "ratedVoltage":
      return {
        defaultValue: 100,
        marks: undefined,
        maxmax: 1001,
        minmin: 0,
        renderValue: (title: string) => {
          return filters[0] !== 0 || filters[1] !== Infinity ? (
            <TitleWithFilterCounter selectedLength={1} title={title} />
          ) : (
            <Typography>{title}</Typography>
          );
        },
        step: 100,
        unitsForVoltageAndKw: "V",
        valueLabelDisplay: filters[1] === Infinity ? "off" : "auto",
      };
  }
};

const RangeSliderFilter: React.FC<RangeSliderFilterProps> = ({ filterType, title }) => {
  const [filters, setFilters] = useRecoilState(rangeSliderFilterSelectorFamily(filterType));
  const handleChange = (event: Event, newValue: number | number[]) => {
    if (typeof newValue === "number") return;
    if (filterType === "maxActivePower") {
      setFilters(newValue as number[]);
      return;
    }
    if (newValue[1] === 141 || newValue[1] === 1001) newValue[1] = Infinity;
    setFilters(newValue as number[]);
  };
  const resetFilters = (filterType: keyof RangeSliderProps) =>
    filterType === "maxActivePower" ? setFilters([0, 5000]) : setFilters([0, Infinity]);
  const { defaultValue, marks, maxmax, minmin, renderValue, step, unitsForVoltageAndKw, valueLabelDisplay } =
    getFilterSettings(filterType, filters);

  return (
    <>
      <FormControl sx={{ m: 1 }} size="small" aria-label={filterType}>
        <Select
          labelId={filterType}
          id={filterType}
          multiple
          displayEmpty
          sx={{ borderRadius: "0px" }}
          value={filters}
          input={<OutlinedInput />}
          renderValue={() => renderValue(title)}
        >
          <StyledRangeSliderRoot filterType={filterType}>
            <StyledRangeSliderBox filterType={filterType}>
              <Stack
                direction="row"
                justifyContent="space-evenly"
                alignItems="center"
                sx={{ width: filterType !== "maxActivePower" ? "100%" : "50%", borderRadius: "0px" }}
              >
                <MinField filters={filters} valueSetter={setFilters} unitsForVoltageAndKw={unitsForVoltageAndKw} />
                <Typography>-</Typography>
                <MaxField filters={filters} valueSetter={setFilters} unitsForVoltageAndKw={unitsForVoltageAndKw} />
              </Stack>
              <Slider
                marks={marks}
                defaultValue={defaultValue}
                step={step}
                min={minmin}
                max={maxmax}
                sx={{ width: "80%", display: "flex", justifyContent: "center", margin: "auto" }}
                value={filters}
                onChange={handleChange}
                valueLabelDisplay={valueLabelDisplay as "auto" | "off" | "on" | undefined}
                size="small"
              />
            </StyledRangeSliderBox>
            <StyledIconButtonWrapper>
              {filterType !== "maxActivePower" && (
                <IconButton onClick={() => setFilters([filters[0], Infinity])}>
                  <AllInclusiveIcon />
                </IconButton>
              )}
            </StyledIconButtonWrapper>
          </StyledRangeSliderRoot>
          <Divider />
          <StyledResetFilterButtonWrapper>
            <ResetFilterButton
              title={translations.globals.button.reset}
              resetFilters={() => resetFilters(filterType)}
            />
          </StyledResetFilterButtonWrapper>
        </Select>
      </FormControl>
    </>
  );
};
export default RangeSliderFilter;
