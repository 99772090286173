import { atom } from "recoil";

export const globalShowMigrationNoteState = atom({
  key: "globalShowMigrationNoteState",
  default: true,
});
export const globalUnitNotFoundMessageAtom = atom({
  key: "globalUnitNotFoundMessageAtom",
  default: true,
});
