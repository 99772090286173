import { atom, DefaultValue, selectorFamily } from "recoil";

export type AccordionStates = Array<{ id: string; state: boolean | DefaultValue | undefined }>;

export const accordionStates = atom<AccordionStates>({
  key: "accordionStates",
  default: [],
});

export const accordionStatesFamily = selectorFamily({
  key: "accordionStatesFamily",
  get:
    (id: string) =>
    ({ get }) => {
      const accordionStatesInner = get(accordionStates);
      const accordionState = accordionStatesInner.find((accordion) => accordion.id === id);
      return accordionState?.state;
    },
  set:
    (id: string) =>
    ({ get, set }, newValue) => {
      if (!id) return;
      const accordionStatesInner = get(accordionStates);
      const accordion = accordionStatesInner.find((accordion) => accordion.id === id);
      const nextState =
        accordionStatesInner.length < 1
          ? [{ id, state: newValue }]
          : accordion?.state === newValue
          ? [...accordionStatesInner]
          : [{ id, state: newValue }, ...accordionStatesInner.filter((accordion) => accordion.id !== id)];
      set(accordionStates, nextState);
    },
});
