import { Box } from "@mui/system";
import { useMemo } from "react";
import { useRecoilValue } from "recoil";
import { getFormatedDateTime } from "translations/formatter";
import useGetTenantNameById from "utils/useGetTenantNameById";
import { Issue } from ".";
import { activeTaskSelectionState } from "../recoil";
import {
  StyledTaskRowBox,
  StyledTaskRowCertificateLabel,
  StyledTaskRowContainer,
  StyledTaskRowCreatedAt,
  StyledTaskRowTitle,
} from "../styles";
import IssueTypeNameIcon from "./IssueTypeNameIcon";
import switchIssueTypeStatus from "./switchIssueTypeStatus";

const TaskRow = ({ row }: { row: Issue }) => {
  const ativeTaskSelection = useRecoilValue(activeTaskSelectionState);
  const { tenantNameById } = useGetTenantNameById();
  const isSelected = ativeTaskSelection?.issueId === row.id;
  const certificate = row.certificate;
  const manufacturerId = certificate.certificateHolderId;
  const manufacturer = manufacturerId ? tenantNameById[manufacturerId] ?? "" : "";
  const issueTypeData = useMemo(() => {
    return switchIssueTypeStatus({ issueTypeName: row.issueType.issueTypeName });
  }, [row.issueType.issueTypeName]);

  return (
    <StyledTaskRowContainer isSelected={isSelected}>
      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "1rem" }}>
        <IssueTypeNameIcon icon={issueTypeData.icon} iconBackgroundColor={issueTypeData.iconBackgroundColor} />
        <Box>
          <StyledTaskRowBox>
            <StyledTaskRowTitle isSelected={isSelected}>{`${issueTypeData.label}`}</StyledTaskRowTitle>
            <StyledTaskRowCreatedAt isSelected={isSelected}>{`${getFormatedDateTime({
              date: row.createdAt ? new Date(row.createdAt ?? new Date()) : undefined,
              formatString: "d.MM.y kk:mm",
            })}`}</StyledTaskRowCreatedAt>
          </StyledTaskRowBox>
          <StyledTaskRowCertificateLabel
            isSelected={isSelected}
          >{`${certificate.certificateNumber} (${manufacturer})`}</StyledTaskRowCertificateLabel>
        </Box>
      </Box>
    </StyledTaskRowContainer>
  );
};

export default TaskRow;
