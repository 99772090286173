import { Box, Button, styled, Theme, Typography } from "@mui/material";
import { GridToolbarFilterButton } from "@mui/x-data-grid-pro";
import ordinaryColors from "themes/ordinaryColors";

export const customStyle = (theme: Theme) => ({
  "& .MuiDataGrid-row:hover": {
    // backgroundColor: "inherit",
    // color: "red"
    // borderRadius: "0.5rem",
  },
});

export const StyledCertificateValidation = styled(Box, { name: "StyledCertificateValidation" })(({ theme }) => ({
  display: "flex",
  width: "100%",
  minWidth: "48rem",
  background: ordinaryColors.functionalGreyBack,
}));

export const StyledOpenTaskListContainer = styled(Box, { name: "StyledOpenTaskListContainer" })(({ theme }) => ({
  display: "flex",
  width: "100%",
  overflow: "autoY",
  maxWidth: "477px",
  flexDirection: "column",
  alignItems: "flex-start",
  alignSelf: "stretch",
  borderRight: `1px solid ${ordinaryColors.functionalDivider}`,
  background: ordinaryColors.functionalGreyBack,
  padding: "0px 2rem",
  // "@media (max-width: 1200px)": {
  //   padding: "0px 0px 0 12px",
  //   minWidth: "17rem",
  // },
  "& .MuiDataGrid-root": {
    background: "none",
    borderRadius: 0,
    display: "flex",
  },
  "& > div": {
    display: "flex",
    margin: 0,
    height: "100%",
    background: "none",
  },
  // "& .MuiDataGrid-virtualScroller": {
  //   marginTop: "0 !important",
  // },
  "& .MuiDataGrid-row": {
    maxHeight: "108px !important",
  },
  "& .MuiDataGrid-cell": {
    maxHeight: "108px !important",
    padding: "0 !important",
  },
  "& .MuiDataGrid-columnHeadersInner": {
    display: "none",
  },
  "& .MuiDataGrid-columnHeaders": {
    borderBottom: "0px",
  },
}));

export const StyledCustomToolbarContainer = styled(Box, { name: "StyledOpenTaskListContainer" })({
  display: "flex",
  justifyContent: "space-between",
  width: "380px",
  alignItems: "center",
  cursor: "default",
});

export const StyledTitleBox = styled("div", { name: "StyledTitleBox" })({
  display: "flex",
  padding: "32px 0px",
  alignItems: "flex-start",
});

export const StyledTitle = styled(Box, { name: "StyledTitle" })({
  color: ordinaryColors.functionalBlack,
  fontFeatureSettings: "'clig' off, 'liga' off",
  fontSize: "34px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "123.5% /* 41.99px */",
  letterSpacing: "0.25px",
  whiteSpace: "nowrap",
});

export const StyledIconButtonWrapperBox = styled("div", { name: "StyledIconButtonWrapperBox" })({
  display: "flex",
  width: "100%",
  justifyContent: "flex-end",
  height: "24px",
  color: ordinaryColors.functionalBlack,
  "& .MuiButtonBase-root": {
    padding: 0,
    minWidth: 0,
    "& span": { margin: 0 },
  },
});

export const StyledButton = styled(Button, { name: "StyledButton" })({});

export const StyledTaskRowContainer = styled("div", { name: "StyledTaskRowContainer" })(
  ({ isSelected }: { isSelected: boolean }) => ({
    display: "flex",
    width: "100%",

    height: "107px",
    padding: "16px",
    background: isSelected ? ordinaryColors.primary50 : "transparent",
    cursor: "pointer",
    justifyContent: "center",
    borderColor: isSelected ? ordinaryColors.primaryBrand : "transparent",
    borderStyle: "solid",
    borderWidth: "0.0625rem",
    // border: isSelected ? `0.0625rem solid ${ordinaryColors.primaryBrand ?? "#1A6B8B"}` : "none",

    // borderRadius: isSelected ? "0.5rem" : "0",
    "&:hover": {
      // borderRadius: "0.5rem",
    },
  })
);
export const StyledTaskRowBox = styled("div", { name: "StyledTaskRowBox" })(() => ({
  display: "flex",
  width: "100%",
  justifyContent: "space-between",
  "@media (max-width: 1200px)": {
    flexDirection: "column",
  },
}));

export const StyledTaskRowTitle = styled("div", { name: "StyledTaskRowTitle" })(
  ({ isSelected }: { isSelected: boolean }) => ({
    fontSize: "1.125rem",
    flex: "1 0 0",
    overflow: "hidden",
    color: isSelected ? ordinaryColors.primaryBrand : ordinaryColors.grey900,
    fontFeatureSettings: "'clig' off, 'liga' off",
    textOverflow: "ellipsis",
    whiteSpace: "break-spaces",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "143% /* 25.74px */",
    letterSpacing: "0.17px",
    minWidth: "191px",
    maxWidth: "200px",
    "@media (max-width: 1200px)": {
      overflow: "visible",
      minWidth: "15rem",
    },
  })
);

export const StyledTaskRowCreatedAt = styled("div", { name: "StyledTaskRowCreatedAt" })(
  ({ isSelected }: { isSelected: boolean }) => ({
    height: "1.375rem",
    color: isSelected ? ordinaryColors.primaryBrand : ordinaryColors.grey700,
    textAlign: "right",

    fontSize: "0.875rem",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "143% /* 1.25125rem */",
    letterSpacing: "0.010625rem",
    "@media (max-width: 1200px)": {
      textAlign: "left",
    },
  })
);

export const StyledTaskRowCertificateLabel = styled("div", { name: "StyledTaskRowCertificateLabel" })(
  ({ isSelected }: { isSelected: boolean }) => ({
    width: "277px",
    height: "24px",
    color: isSelected ? ordinaryColors.primaryBrand : ordinaryColors.functionalDarkGrey,
    fontFeatureSettings: "'clig' off, 'liga' off",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "24px /* 200% */",
    letterSpacing: "0.15px",
  })
);

export const StyledDetailViewContainer = styled("div", { name: "StyledDetailViewContainer" })({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  overflowX: "hidden",
});

export const StyledDetailViewHeader = styled("div", { name: "StyledDetailViewHeader" })({
  width: "100%",
  display: "flex",
  height: "120px",
  padding: "0px 48px",
  justifyContent: "space-between",
  alignItems: "center",
  alignSelf: "stretch",
  borderBottom: `1px solid ${ordinaryColors.functionalDivider}`,
  background: ordinaryColors.functionalWhite,
});

export const StyledTitleWrapper = styled("div", { name: "StyledTitleWrapper" })({
  display: "flex",
  color: ordinaryColors.functionalBlack,
  fontFeatureSettings: "'clig' off, 'liga' off",
  fontSize: "24px",
  fontStyle: "normal",
  lineHeight: "133.4% /* 32.016px */",
});

export const StyledTitlePrefix = styled("div", { name: "StyledTitlePrefix" })({
  marginRight: "5px",
});

export const StyledCertificateLabel = styled("div", { name: "StyledCertificateLabel" })({
  fontWeight: 500,
});

export const StyledContentContainer = styled("div", { name: "StyledContentContainer" })({
  display: "flex",
  // minWidth: "40rem",
  padding: "48px",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "56px",
  alignSelf: "stretch",
  background: ordinaryColors.functionalGreyBack,
});

export const StyledUserInputContainer = styled(Box, { name: "StyledUserInputContainer" })({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  gap: "24px",
});

export const StyledUserInputHeader = styled("div", { name: "StyledUserInputHeader" })({
  color: ordinaryColors.functionalBlack,
  fontFeatureSettings: "'clig' off, 'liga' off",
  fontSize: "24px",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "133.4% /* 32.016px */",
});

export const StyledValidationFieldWrapper = styled("div", { name: "StyledValidationFieldWrapper" })({
  display: "flex",
  padding: "12px 24px",
  flexDirection: "column",
  alignItems: "flex-start",
  width: "100%",
  border: `1px solid ${ordinaryColors.functionalDivider}`,
  background: ordinaryColors.functionalWhite,
});

export const StyledConfirmationTextBox = styled("div", { name: "StyledConfirmationTextBox" })({
  flex: "1 0 0",
  color: ordinaryColors.functionalDarkGrey,
  fontFeatureSettings: "'clig' off, 'liga' off",
  fontSize: "18px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "143% /* 25.74px */",
  letterSpacing: "0.17px",
  paddingLeft: "30px",
});

export const StyledSelectionLabel = styled("div", { name: "StyledSelectionLabel" })({
  color: ordinaryColors.functionalBlack,
  fontFeatureSettings: "'clig' off, 'liga' off",
  fontSize: "18px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "143% /* 25.74px */",
  letterSpacing: "0.17px",
});

export const ButtonToolbarSortSC = styled(Button)(({ theme }) => ({
  minWidth: 0,
  "& .MuiButton-startIcon": {
    marginRight: "0px",
  },
}));
export const GridToolbarFilterButtonSC = styled(GridToolbarFilterButton)(({ theme }) => ({
  minWidth: 20,
  padding: "6px 8px",
  marginRight: "5px",
  // marginRight: "0.75rem",
  "& .MuiButton-startIcon": {
    marginRight: "0px",
  },
}));
export const StyledTypographyVerticalTabs = styled(Typography)(({ theme }) => ({
  fontSize: "1.5rem",
  fontWeight: 500,
  marginRight: "107px",
  color: theme.palette.ordinaryColors.background,
}));
export const StyledTopbarStartContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  paddingTop: "2rem",
  paddingLeft: "1rem  ",
  justifyContent: "space-between",
}));
