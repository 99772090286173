import { Box, styled, Switch } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Tooltip from "@mui/material/Tooltip";
import * as React from "react";
import Props from "./index.d";

// https://www.figma.com/file/9ASzePPu7KzcCD87f0P8Un/?type=design&mode=dev&node-id=4159:85676
export const BooleanSwitchBoxSC = styled(Box, {
  label: "Box",
  shouldForwardProp(propName) {
    return propName !== "";
  },
})(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  margin: "1.5rem 0",
  marginLeft: "0.56rem",
  alignSelf: "stretch",
}));

const BooleanSwitch: React.FC<Props> = (props) => {
  const {
    title,
    disabled,
    className,
    tooltipText,
    notificationText,
    data: { state, setstate },
  } = props;
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setstate(event.target.checked);
  };

  return (
    <BooleanSwitchBoxSC className={className}>
      <Tooltip
        sx={{ fontSize: "4.2rem" }}
        title={tooltipText}
        placement={notificationText && state ? "right-end" : undefined}
      >
        <FormGroup className={className}>
          <FormControlLabel
            control={<Switch checked={state} onChange={handleChange} disabled={disabled} />}
            label={title}
          />
        </FormGroup>
      </Tooltip>
      {notificationText && state && <span style={{ fontSize: "16px", textAlign: "justify" }}>{notificationText}</span>}
    </BooleanSwitchBoxSC>
  );
};
export default BooleanSwitch;
