export default {
  category: {
    INVERTER: "Umrichter",
    STORAGE_INVERTER: "Umrichter des Speichers",
    ASYNCHRONOUS_GENERATOR: "Asynchrongenerator",
    SYNCHRONOUS_GENERATOR: "Synchrongenerator",
    FUEL_CELL: "Brennstoffzelle",
    UNDERVOLTAGE_PROTECTION: "NA-Schutz",
    PAV_E_MONITORING: "PAV,E-Überwachung",
    VOLTAGE_REGULATOR: "Spannungsregler",
    PROTECTIVE_DEVICE_INTERMEDIATE: "Schutzgerät (Zwischengelagert)",
    EZA_CONTROLLER: "EZA-Regler",
    OTHER: "Andere",
  },
  primaryEnergySource: {
    // BIOGAS: "Biogas",
    NOT_APPLICABLE: "Unzutreffend",
    OTHER: "Sonstiges",
    OTHER_FUELS: "Gasförmige und flüssige Brennstoffe",
    PV: "PV",
    PV_WITH_STORAGE: "PV / Speicher",
    STORAGE: "Speicher",
    WATER: "Wasser",
    WIND: "Wind",
  },
  netFeedIn: {
    SINGLE_PHASE: "Einphasig",
    TWO_PHASE: "Zweiphasig",
    THREE_PHASE: "Dreiphasig",
    UNKNOWN: "Unbekannt",
  },
  sort: {
    ASC: "Aufsteigend",
    DESC: "Absteigend",
  },
  tenantRole: {
    MANUFACTURER: "Hersteller",
    CERTIFICATION_AUTHORITY: "Zertifizierungsstelle",
    NETWORK_OPERATOR: "Netzbetreiber",
    PLATFORM_OPERATOR: "Plattformbetreiber",
    PLANNER: "Planer",
    TENANT_ADMIN: "Administrator",
  },
  accessRequestStatus: {
    REQUESTED: "Angefragt",
    APPROVED: "Genehmigt",
    REJECTED: "Abgelehnt",
  },
};
