import ArrowUpwardOutlinedIcon from "@mui/icons-material/ArrowUpwardOutlined";
import ArrowDownwardOutlinedIcon from "@mui/icons-material/ArrowDownwardOutlined";
import { getTranslatedSortString } from "lib/helper/getTranslatedRowDataStrings";

const arrowIconStyle = { width: "24px", height: "24px" };

const CurrentSortIcon = ({ value, sort, activeAscDesc }: { value: string; sort: string; activeAscDesc: boolean }) => {
  const ArrowIcon = value === getTranslatedSortString("ASC") ? ArrowUpwardOutlinedIcon : ArrowDownwardOutlinedIcon;
  return (
    <ArrowIcon
      sx={(theme) => ({
        ...arrowIconStyle,
        color:
          value.toUpperCase() === sort.toUpperCase() && activeAscDesc
            ? theme.palette.ordinaryColors.background
            : theme.palette.ordinaryColors.silverChalice,
      })}
    />
  );
};

export default CurrentSortIcon;
