import CloseIcon from "@mui/icons-material/Close";
import { IconButton, Typography } from "@mui/material";
import { Box } from "@mui/system";
import * as React from "react";
import { titleFileStyle } from "../../CertificateFileUpload";
import useGetItemtext from "./useGetItemtext";

interface SoftwareBridgeListProps {
  removeSoftware: (SoftwareBridgeId: string) => void;
  softwareBridgeIds: string[];
}

const SoftwareBridgeList: React.FC<SoftwareBridgeListProps> = ({ removeSoftware, softwareBridgeIds }) => {
  const getItemText = useGetItemtext();
  return (
    <Box>
      {softwareBridgeIds.map((softwareBridgeId) => (
        <Box key={softwareBridgeId}>
          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", paddingTop: "0.5rem" }}>
            <Typography sx={titleFileStyle}>{getItemText(softwareBridgeId)}</Typography>
            <IconButton onClick={() => removeSoftware(softwareBridgeId)}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
      ))}
    </Box>
  );
};
export default SoftwareBridgeList;
