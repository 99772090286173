import { UnitCreateValidationCallBackArgs } from "raft/UnitForm";
import { useState } from "react";
import { atom, DefaultValue, selector, useRecoilCallback } from "recoil";
import { v4 as uuidv4 } from "uuid";
import { dummyIdPrefix, uploadCertificateUnitFieldsNotToCopy } from "../config";
import { UploadCertificateUnit } from "../types";

export const uploadCertificateUnitListAtom = atom<UploadCertificateUnit[]>({
  key: "uploadCertificateUnitListState",
  default: [],
});
export const uploadCertificateUnitSharedValuesAtom = atom<Partial<UploadCertificateUnit>>({
  key: "uploadCertificateUnitSharedValuesAtom",
  default: {},
});
export const activeUploadCertificateUnitIndexAtom = atom<number>({
  key: "activeUploadCertificateUnitIndexState",
  default: 0,
});

export const activeFormInChangeAtom = atom<number>({
  key: "activeFormInChangeAtom",
  default: 0,
});

export const activeUploadCertificateUnitIdexAtomFreeze = atom<boolean>({
  key: "activeUploadCertificateUnitIdexAtomFreeze",
  default: false,
});

export const unitFormValidationAtom = atom<UnitCreateValidationCallBackArgs | undefined>({
  key: "unitFormValidationAtom",
  default: undefined,
});

export const unitInSwitchAtom = atom<boolean>({
  key: "unitInSwitchAtom",
  default: false,
});

export const unitFormValidationSingleSetSelector = selector({
  key: "setUnitFormValidationSelector",
  get: ({ get }) => {
    return get(unitFormValidationAtom);
  },
  set: ({ set, get }, newValue) => {
    const prev = get(unitFormValidationAtom);
    if (!!prev) return;

    if (newValue && !(newValue instanceof DefaultValue)) {
      set(unitFormValidationAtom, newValue);
    }
  },
});

export const activeUploadCertificateUnitSaveSelector = selector<number>({
  key: "activeUploadCertificateUnitSaveSelector",
  get: ({ get }) => {
    const activeUnitIndex = get(activeUploadCertificateUnitIndexAtom);
    return activeUnitIndex;
  },
  set: ({ set, get }, newValue) => {
    const isInSwitch = get(unitInSwitchAtom);
    if (!isInSwitch) {
      // console.log("block");
      return;
    }
    // console.log("set");
    set(activeUploadCertificateUnitIndexAtom, newValue);
  },
});

export const useCreateNewuploadCertificateUnit = () => {
  const [cloning, setCloning] = useState(false);
  const cloneUnit = useRecoilCallback(
    ({ set, snapshot }) => {
      if (cloning) return () => {};
      setCloning(true);
      return async () => {
        const activeUnitIndex = await snapshot.getPromise(activeUploadCertificateUnitIndexAtom);
        const units = await snapshot.getPromise(uploadCertificateUnitListAtom);
        const activeUnit = units[activeUnitIndex];
        const uploadCertificateUnitList = await snapshot.getPromise(uploadCertificateUnitListAtom);
        if (!activeUnit) return;
        const nextUploadCertificateUnit = makeCleanUploadCertificateUnitEmptyCopy(activeUnit);
        set(uploadCertificateUnitListAtom, (olduploadCertificateUnitList) => [
          ...olduploadCertificateUnitList,
          nextUploadCertificateUnit,
        ]);
        set(activeUploadCertificateUnitIndexAtom, uploadCertificateUnitList.length);
        setTimeout(() => {
          setCloning(false);
        }, 500);
      };
    },
    [cloning]
  );

  return { cloneUnit, cloning };
};

const makeCleanUploadCertificateUnitEmptyCopy = (uploadCertificateUnit: UploadCertificateUnit) => {
  const nextUploadCertificateUnit = Object.entries(uploadCertificateUnit).reduce(
    (acc, [key, value]) => {
      if (uploadCertificateUnitFieldsNotToCopy.includes(key)) {
        return acc;
      }
      return { ...acc, [key]: undefined };
    },
    {
      id: `${dummyIdPrefix}${uuidv4()}`,
      modelName: undefined,
      modelNumber: undefined,
      __isLinked: undefined,
    } as UploadCertificateUnit
  );
  return nextUploadCertificateUnit;
};

export const useRemoveUploadCertificateUnit = () => {
  return useRecoilCallback(({ set, snapshot }) => async (id: string) => {
    const uploadCertificateUnitList = await snapshot.getPromise(uploadCertificateUnitListAtom);
    const nextUploadCertificateUnitList = uploadCertificateUnitList.filter(
      (unit) => unit.id !== id && !(id === "" && unit.id === undefined)
    );
    const lastUnitIndex = nextUploadCertificateUnitList.length - 1;
    set(uploadCertificateUnitListAtom, nextUploadCertificateUnitList);
    set(activeUploadCertificateUnitIndexAtom, lastUnitIndex);
  });
};

export const unitFormsWithErrorsSelector = selector({
  key: "unitFormsWithErrosSelector",
  get: ({ get }) => {
    const uploadCertificateUnitList = get(uploadCertificateUnitListAtom);
    const unitFormsWithErros = (uploadCertificateUnitList ?? []).reduce<
      { name: string; count: number; isInitialValidation: boolean }[]
    >((unitsWithError, unit, index) => {
      if (unit?.formErrorCount ?? 0 > 0) {
        // if (process.env.NODE_ENV === "development") console.log(unit.formErrors);
        return unitsWithError.concat({
          name: unit.modelName ?? `No. ${index + 1}`,
          count: unit.formErrorCount ?? 0,
          isInitialValidation: unit.formErrors?.meta.isInitialValidation ?? false,
        });
      }
      return unitsWithError;
    }, []);
    return unitFormsWithErros;
  },
});

export const allUnitNamesSelector = selector({
  key: "allUnitNamesSelector",
  get: ({ get }) => {
    const uploadCertificateUnitList = get(uploadCertificateUnitListAtom);
    const unitNames = uploadCertificateUnitList.map((unit) => unit.modelName);
    return unitNames;
  },
});

export const unitsWithHighMaxPowerCountSelector = selector({
  key: "getHaveSomeUnitsHighRatedPower",
  get: ({ get }) => {
    const uploadCertificateUnitList = get(uploadCertificateUnitListAtom);
    const haveSomeUnitsHighRatedPower = uploadCertificateUnitList.filter(
      (unit) => (unit?.maxActivePower ?? 0) >= 30
    ).length;
    return haveSomeUnitsHighRatedPower;
  },
});
export const allNewUnitNamesSelector = selector({
  key: "allNewUnitNamesSelector",
  get: ({ get }) => {
    const uploadCertificateUnitList = get(uploadCertificateUnitListAtom);
    const unitNames = uploadCertificateUnitList.reduce<string[]>((acc, unit) => {
      if (unit.__isLinked !== 1) {
        return acc.concat(unit.modelName ?? "");
      }
      return acc;
    }, []);
    return unitNames;
  },
});

export const linkedUnitsManufactuterIdsSelector = selector({
  key: "linkedUnitsManufactuterIdsSelector",
  get: ({ get }) => {
    const uploadCertificateUnitList = get(uploadCertificateUnitListAtom);
    const unitNames = uploadCertificateUnitList.reduce<string[]>((acc, unit) => {
      if (unit.__isLinked === 1 && unit.manufacturerId) {
        return acc.concat(unit.manufacturerId);
      }
      return acc;
    }, []);
    return unitNames;
  },
});

export const allUnitIdsSelector = selector({
  key: "allUnitIdsSelector",
  get: ({ get }) => {
    const uploadCertificateUnitList = get(uploadCertificateUnitListAtom);
    const unitIds = uploadCertificateUnitList.map((unit) => unit.id).filter((name) => name);
    return unitIds;
  },
});
