import * as React from "react";
import FormHasErrorsIcon from "components/icons/iconComponents/FormHasErrorsIcon";
import { IconWrapper, StyledHelperText, StyledTitleWrapper, StyledTooltip, StyledTooltipWrapper } from "./styles";

export type ErrorManagerProps = {
  helperText?: React.ReactNode;
  error: boolean;
  onBlurCapture: () => void;
  onFocusCapture: () => void;
};

export const UnitFormErrorDisplay = (errorManagerRes: ErrorManagerProps) => (
  <StyledTooltipWrapper>
    {errorManagerRes.error && (
      <StyledTooltip
        slotProps={{ tooltip: { sx: { backgroundColor: "transparent !important" } } }}
        title={
          <StyledTitleWrapper>
            <StyledHelperText>{errorManagerRes.helperText}</StyledHelperText>
          </StyledTitleWrapper>
        }
        placement="top"
      >
        <IconWrapper>
          <FormHasErrorsIcon />
        </IconWrapper>
      </StyledTooltip>
    )}
  </StyledTooltipWrapper>
);
