import React, { useEffect, useMemo, useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import { debounce } from "@mui/material/utils";
import Props from "./index.d";
import { FormHelperText } from "@mui/material";
import ordinaryColors from "themes/ordinaryColors";
import { StyledTextfield } from "components/formElements/customElements/UnitFormErrorDisplay/styles";

export interface TenantResult {
  key: string;
  label: string;
}
export type TenantSearch = (
  input: { inputValue?: string; key?: string },
  callback: (results?: readonly TenantResult[]) => void
) => void;
export interface AutoCompleteProps extends Props {
  search: TenantSearch;
  isUnit?: boolean;
}

const AutoComplete: React.FC<AutoCompleteProps> = (props) => {
  const {
    title,
    search,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    data: { state, setstate, onBlur, updateValue, setNull, setAndSave },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    isUnit,
    disabled,
    testId,
    errorManager,
    className,
  } = props;

  const errorManagerRes = errorManager({
    errorJoiner: (errors) => errors.join(", "),
  });

  const [value, setValue] = useState<TenantResult | null>(null);
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState<readonly TenantResult[]>([]);

  const fetch = useMemo(() => debounce(search, 400), [search]);
  useEffect(() => {
    if (!value) {
      search({ key: state, inputValue: "" }, (results?: readonly TenantResult[]) => {
        if (results) {
          setOptions(results);
          setValue(results.find((x) => x.key === state) || null);
        }
      });
    }
  }, [state, search, value]);

  useEffect(() => {
    let active = true;

    if (inputValue === "") {
      setOptions([]);
      return undefined;
    }

    fetch({ inputValue }, (results?: readonly TenantResult[]) => {
      if (active) {
        let newOptions: readonly TenantResult[] = [];

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  const handleChange = (event: unknown, newValue: TenantResult | null) => {
    setOptions(newValue ? [newValue, ...options] : options);
    setValue(newValue);
    if (newValue) {
      setAndSave(newValue.key);
    } else {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      setAndSave(null);
    }
  };

  return (
    <>
      <Autocomplete
        title={title}
        data-testid={testId}
        className={className}
        disabled={disabled}
        getOptionLabel={(option) => option.label}
        isOptionEqualToValue={(option, value) => option.key === value.key}
        filterOptions={(x) => x}
        options={options}
        autoComplete
        value={value}
        onChange={handleChange}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        renderInput={(params) => <StyledTextfield {...errorManagerRes} {...params} fullWidth />}
      />
      {errorManagerRes.error && !isUnit && (
        <FormHelperText sx={{ color: ordinaryColors.error, marginLeft: "0px", fontSize: "12px" }}>
          {errorManagerRes.helperText}
        </FormHelperText>
      )}
    </>
  );
};

export default AutoComplete;
