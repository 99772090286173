import * as React from "react";
import Typography from "@mui/material/Typography";
import Props from "./index.d";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { IconButton } from "@mui/material";
import { Box } from "@mui/system";
import { dummyIdPrefix } from "pages/UploadCertificate/config";
const GoToLinkedUnit: React.FC<Props> = (props) => {
  const { className } = props;
  const onLinkClick = () => {
    window.open(`units/${props.data.state}`, "_blank");
  };
  if (!props.data.state || props.data.state.startsWith(dummyIdPrefix)) return null;
  return (
    <Box sx={{ display: "flex", flexDirection: "row", maxWidth: "100%", alignItems: "center" }}>
      <Typography className={className} variant="body1">
        {props.title}
      </Typography>
      <IconButton aria-label="share" onClick={onLinkClick}>
        <OpenInNewIcon />
      </IconButton>
    </Box>
  );
};

export default GoToLinkedUnit;
