import CloseIcon from "@mui/icons-material/Close";
import { Box, IconButton, Typography } from "@mui/material";
import { FC, ReactNode } from "react";
import { FileWithPath } from "react-dropzone";
import ordinaryColors from "themes/ordinaryColors";
import UploadedFileIcon from "./UploadedFileIcon";

export const titleFileStyle = {
  width: "247px",
  color: ordinaryColors.lightTextPrimary,
  fontFeatureSettings: "'clig' off, 'liga' off",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "175% /* 28px */",
  letterSpacing: "0.15px",
};
export const sizeStyle = {
  color: ordinaryColors.lightTextSecondary,
  fontFeatureSettings: "'clig' off, 'liga' off",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "143% /* 20.02px */",
  letterSpacing: "0.17px",
};

interface Props {
  acceptedFiles: FileWithPath[];
  setAcceptedFilesCount: React.Dispatch<React.SetStateAction<number>>;
}
const fileListStyle = { marginBottom: "0px", marginLeft: "-33px" };
const removeButtonStyle = { display: "flex", justifyContent: "flex-end", marginTop: "-40px" };

const FileLists: FC<Props> = ({ acceptedFiles, ...props }) => {
  const removeFile = (file: FileWithPath) => () => {
    acceptedFiles.splice(acceptedFiles.indexOf(file), 1);
  };

  props.setAcceptedFilesCount(acceptedFiles.length);
  const fileType = acceptedFiles.length ? acceptedFiles[0].type.split("/")[1] : "";

  const fileList = (files: FileWithPath[]): ReactNode =>
    files.map((file, index) => (
      <Box key={index}>
        <Box sx={{ display: "flex" }}>
          <UploadedFileIcon fileType={fileType} />
          <Box sx={{ display: "flex" }}>
            <Typography sx={titleFileStyle}>{file.name}</Typography>
          </Box>
        </Box>
        <Box sx={removeButtonStyle}>
          <IconButton onClick={removeFile(file)}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Typography sx={sizeStyle}>{`${(file.size / 1048576).toFixed(3)} MB •`}</Typography>
      </Box>
    ));

  return (
    <Box sx={fileListStyle}>
      <aside>
        <ul>{fileList(acceptedFiles)}</ul>
      </aside>
    </Box>
  );
};

export default FileLists;
