import { useGetTenantsQuery } from "graphqlBase/getTenants/__generated__/getTenants";
import { useMemo } from "react";

const useGetTenantNameById = () => {
  const { data: tenants } = useGetTenantsQuery({
    variables: {
      take: 10000,
      where: {
        approvalStatus: { neq: "DENIED" },
      },
    },
  });

  const tenantNameById: { [key: string]: string } = useMemo(() => {
    return (
      tenants?.tenants?.items?.reduce<{ [key: string]: string }>((acc, cur) => {
        acc[cur.id] = cur.tenantName;
        return acc;
      }, {}) ?? {}
    );
  }, [tenants]);
  const getTenantById = (id: string) => {
    return tenantNameById[id];
  };

  const tenantRoleByTenantId: { [key: string]: string } = useMemo(() => {
    return (
      tenants?.tenants?.items?.reduce<{ [key: string]: string }>((acc, cur) => {
        acc[cur.id] = cur.tenantRole;
        return acc;
      }, {}) ?? {}
    );
  }, [tenants]);

  const getTenantRoleByTenantId = (id: string) => {
    return tenantRoleByTenantId[id];
  };

  return { tenantNameById, getTenantById, tenantRoleByTenantId, getTenantRoleByTenantId };
};

export default useGetTenantNameById;
