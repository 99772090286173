import { TextField } from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers";

import React, { useEffect, useState } from "react";
import ordinaryColors from "themes/ordinaryColors";

interface DesktopDatePickerProps {
  handleChange: (date: Date | null) => void;
  value: Date | null;
  filters?: (Date | null)[];
  placeholder: string;
}

const DesktopDatePickerMinDate: React.FC<DesktopDatePickerProps> = ({ handleChange, value, filters, placeholder }) => {
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);

  useEffect(() => {
    if (filters) return setSelectedDate(filters[0]);
  }, [filters]);

  return (
    <DesktopDatePicker
      onChange={(newValue) => {
        handleChange(newValue);
      }}
      value={selectedDate ? selectedDate : value}
      // components={{
      //   OpenPickerIcon: () => null, // Replace `YourCustomIcon` with your desired icon component
      // }}
      renderInput={(params) => (
        <TextField
          {...params}
          inputProps={{
            ...params.inputProps,
            placeholder: placeholder,
            sx: {
              height: "26px",
              padding: "7px 0px 7px 7px",
            },
          }}
          sx={{
            "& .MuiOutlinedInput-root": {
              borderRadius: "0px",
              backgroundColor: ordinaryColors.functionalBlack50 ?? `#F2F2F2`,
              "& .fieldset": {
                borderWidth: "0px",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderWidth: "0px",
              },
            },
          }}
        />
      )}
    />
  );
};

export default DesktopDatePickerMinDate;
