import { RecoilState, atom, selectorFamily, DefaultValue } from "recoil";
import { BridgeParams, BridgeState } from "./BridgeParamTypes";

export interface MakeBridgeStateParams {
  id?: string;
  bridgeParams: BridgeParams;
}
interface BridgeAtoms {
  [key: string]: RecoilState<BridgeState> | undefined;
}
interface BridgeSelectors {
  [key: string]: ((param: string) => RecoilState<boolean>) | undefined;
}

const bridgeStates: BridgeAtoms = {};
const bridgeSelectors: BridgeSelectors = {};

const remove = (id: string) => {
  delete bridgeStates[id];
  delete bridgeSelectors[id];
};

export function makeBridgeState({ bridgeParams: { bridgeEntityName }, id = "create" }: MakeBridgeStateParams) {
  const bridgeIdentifier = `${bridgeEntityName}.${id}`;
  if (bridgeStates[bridgeIdentifier]) {
    return {
      state: bridgeStates[bridgeIdentifier] as RecoilState<BridgeState>,
      bridgeSelector: bridgeSelectors[bridgeIdentifier] as (param: string) => RecoilState<boolean>,
      remove: () => remove(bridgeIdentifier),
    };
  }
  const state = atom<BridgeState>({
    key: `@_Raft.BridgeState.${bridgeIdentifier}`,
    default: {},
  });

  const bridgeSelector = selectorFamily<boolean, string>({
    key: "@_Raft.BridgeSelector",
    get:
      (field) =>
      ({ get }) => {
        const row = get(state)[field];
        return !!row && !row.isDeleted;
      },
    set:
      (outerId) =>
      ({ set }, isChecked) => {
        if (isChecked instanceof DefaultValue) return;
        set(state, (prevState) => ({
          ...prevState,
          [outerId]: { ...prevState[outerId], outerId, isDeleted: !isChecked },
        }));
      },
  });

  bridgeStates[bridgeIdentifier] = state;
  bridgeSelectors[bridgeIdentifier] = bridgeSelector;
  return { state, bridgeSelector, remove: () => remove(bridgeIdentifier) };
}
