import { IconButton } from "@mui/material";
import React, { useState } from "react";
import ordinaryColors from "themes/ordinaryColors";
import useUnitBridgeUsersMutations from "../useUnitBridgeUsersMutations";
import StarIcon from "@mui/icons-material/Star";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import { translations } from "translations";

interface Props {
  unitId: string;
  userId: string;
  isSubscribed: boolean;
  unitBridgeUserId: string;
  unitTenantIdEqualsCurrentTenantId: boolean;
  refetchUnitsOverview: () => Promise<void>;
}

const SubscriptionButton = ({
  unitId,
  userId,
  refetchUnitsOverview,
  unitBridgeUserId,
  isSubscribed,
  ...rest
}: Props) => {
  const { createUnitBridgeUsersFunction, deleteUnitBridgeUserFunction } = useUnitBridgeUsersMutations();
  const [blockSubscription, setBlockSubscription] = useState<boolean | undefined>(false);
  const handleSubscribe = () => {
    setBlockSubscription(true);
    createUnitBridgeUsersFunction({
      unitId,
      userId,
      refetchAfterUnitBridgeUsersMutations: refetchUnitsOverview,
    });
  };

  const handleUnsubscribe = () => {
    setBlockSubscription(true);
    deleteUnitBridgeUserFunction({
      unitBridgeUserId,
      refetchAfterUnitBridgeUsersMutations: refetchUnitsOverview,
    });
  };

  return (
    <IconButton
      disabled={blockSubscription}
      aria-label={
        isSubscribed || blockSubscription
          ? translations.globals.button.unsubscribe
          : translations.globals.button.subscribe
      }
      onClick={isSubscribed || blockSubscription ? handleUnsubscribe : handleSubscribe}
      {...rest}
    >
      {isSubscribed || blockSubscription ? (
        <StarIcon
          sx={{ color: blockSubscription ? ordinaryColors.mercuryGray : ordinaryColors.subscribeSuccessTag }}
          fontSize="large"
        />
      ) : (
        <StarOutlineIcon sx={{ color: ordinaryColors.mercuryGray }} fontSize="large" />
      )}
    </IconButton>
  );
};

const SubscribeColumn: React.FC<Props> = (props) => {
  if (props.unitTenantIdEqualsCurrentTenantId) return null;
  return <SubscriptionButton {...props} />;
};

export default SubscribeColumn;
