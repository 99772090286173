import ordinaryColors from "themes/ordinaryColors";
import { styled, TextField, Tooltip } from "@mui/material";

export const StyledTooltipWrapper = styled("div")({ height: "24px" });
export const StyledTooltip = styled(Tooltip)({});
export const StyledTitleWrapper = styled("div")({
  display: "flex",
  padding: "4px 8px",
  alignItems: "center",
  borderRadius: "4px",
  background: ordinaryColors.statusTagError,
});
export const StyledHelperText = styled("div")({
  color: ordinaryColors.functionalWhite,
  fontFeatureSettings: "'clig' off, 'liga' off",
  fontSize: "18px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "143% /* 25.74px */",
  letterSpacing: "0.17px",
});
export const IconWrapper = styled("div")({ marginRight: "24px" });

export const StyledContentContainer = styled("div")({ display: "flex", alignItems: "center" });

export const StyledTextfield = styled(TextField)<{ error?: boolean }>(({ error }) => ({
  height: "42px",

  color: ordinaryColors.functionalLightGrey,
  "& .MuiOutlinedInput-root": {
    borderRadius: "0px",
    "& input": {
      padding: "0px",
      borderRadius: "0px",
    },
    background: error ? "rgba(211, 47, 47, 0.02)" : "inherit",
  },
  "& .MuiFormHelperText-root.Mui-error": {
    display: "none",
  },
}));
