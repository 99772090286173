import ForeignKeySelector from "../ForeignKeySelector";
import { styled } from "@mui/material";

export const StyledForeignKeySelector = styled(ForeignKeySelector, { name: "StyledForeignKeySelector" })({
  maxWidth: "244px",
  minWidth: "244px",
  maxHeight: "42px",
  "& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select": {
    maxWidth: "244px",
    minWidth: "244px",
    maxHeight: "42px",
    padding: "8px 12px",
  },
  "& .css-1sq3h5y-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root": { borderRadius: 0 },
  "& .css-14lo706": { display: "none" },
  "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": { top: 0, minHeight: "42px !important" },
});
