import { concat } from "lodash";
import { BaseErrorMessges } from "../types";
import {
  Errors,
  RuleOptions,
  Rules,
  ErrorInList,
  RuleResult,
  PendingRuleResult,
  ValidateFormPropsInner,
  Rule,
  isRuleCondition,
  RuleArgs,
  MaybePromise,
  RuleResultType,
  isRuleResultObject,
} from "./types";

const baseErrorMessagesDefault: BaseErrorMessges = {
  required: () => `This field is required`,
  dataType: () => `This field is not of the correct type`,
  minLength: (field) => `This fields value must be ${field.minLength} min`,
  maxLength: (field) => `This fields value must be ${field.maxLength} min`,
};

function buildRulesFormFieldSpecs<T extends object, V extends ValidateFormPropsInner<T>>({
  rules,
  validationArgs,
}: {
  validationArgs: V;
  rules: Rules<T, V>;
}): Partial<{ [K in keyof T]: Rule<T, V, K>[] }> {
  const {
    remoteFieldSpecs,
    config: { baseErrorMessages },
    language,
  } = validationArgs;
  const remoteSpecRules = Object.entries(remoteFieldSpecs).reduce<Partial<{ [K in keyof T]: Rule<T, V, K>[] }>>(
    (acc, [key, { required, dataType, minLength, maxLength }]) => {
      //@ts-ignore
      const rulesForKeyIn = (acc[key] || []) as Rule<T, V, any>[];
      const isOptional = rulesForKeyIn.some((rule) => !isRuleCondition<T, V, keyof T>(rule) && rule.makeOptional);
      const rulesForKey = [] as unknown as Rule<T, V, keyof T>[];

      if (required && !isOptional) {
        rulesForKey.push(({ value }: { value: unknown }) => {
          return value === undefined || value === null ? baseErrorMessages.required({ language }) : undefined;
        });
      }
      if (dataType) {
        rulesForKey.push(({ value }: { value: unknown }) => {
          if (value === undefined || value === null) return undefined;
          return typeof value !== dataType ? baseErrorMessages.dataType({ language }) : undefined;
        });
      }
      if (typeof minLength === "number") {
        rulesForKey.push(({ value }: { value: unknown }) =>
          (value as number) < minLength ? baseErrorMessages.minLength({ language, minLength }) : undefined
        );
      }
      if (typeof maxLength === "number") {
        rulesForKey.push(({ value }: { value: unknown }) =>
          (value as number) > maxLength ? baseErrorMessages.maxLength({ language, maxLength }) : undefined
        );
      }

      return { ...acc, [key]: [...rulesForKey, ...rulesForKeyIn] };
    },
    rules
  );
  return remoteSpecRules;
}

export function extractTriggerFieldsFromRules<T extends object, V extends ValidateFormPropsInner<T>>(
  rules: Rules<T, V>
): Record<string, string[]> {
  const triggerMap = Object.entries(rules).reduce<Record<string, string[]>>((acc, [key, rulesForKey]) => {
    const rules = rulesForKey as Rule<T, V, keyof T>[];
    const triggerFieldsForKey = rules.reduce<string[]>((triggers, rule) => {
      const triggerFieldsForRule = extractTriggerFieldsFromRule<T, V>(rule);
      return triggers.concat(triggerFieldsForRule);
    }, []);
    if (!triggerFieldsForKey.length) return acc;
    return { ...acc, [key]: triggerFieldsForKey };
  }, {} as Record<string, string[]>);

  const reveredTriggerMap = Object.entries(triggerMap).reduce<Record<string, string[]>>((acc, [key, triggers]) => {
    triggers.forEach((trigger) => {
      const prev = (acc[trigger] ?? []).map((k) => k);
      if (!prev.length) {
        acc[trigger] = [key];
      } else {
        acc[trigger] = concat(prev, key);
      }
    });
    return acc;
  }, {} as Record<string, string[]>);
  return reveredTriggerMap;
}

export function extractTriggerFieldsFromRule<T extends object, V extends ValidateFormPropsInner<T>>(
  rule: Rule<T, V, keyof T>
): string[] {
  const { triggerFields } = isRuleCondition<T, V, keyof T>(rule)
    ? { triggerFields: [] }
    : (rule as Rule<T, V, keyof T> & RuleOptions<T>);
  return (triggerFields || []) as string[];
}
export async function applyRules<T extends object, V extends ValidateFormPropsInner<T>>({
  rules,
  validationArgs,
  validationOptions,
}: {
  rules: Rules<T, V>;
  validationArgs: V;
  validationOptions?: RuleOptions<T>;
}): Promise<Errors<T>> {
  const fullRules = buildRulesFormFieldSpecs<T, V>({ rules, validationArgs });
  const errorBuilds = Object.entries(fullRules).reduce<Array<Promise<ErrorInList<T>>>>(
    (errorsFulllist, [key, rulesForKeyIn = []]) => {
      const rulesForKey = rulesForKeyIn as Rule<T, V, keyof T>[];

      const errorsList = rulesForKey.reduce<PendingRuleResult[]>((previousResults, ruleDef) => {
        const isFieldInUpdate =
          validationArgs.fieldsToValidate.includes(key) || validationArgs.fieldsToValidate.includes("submit");
        const getError = isFieldInUpdate
          ? getErrors<T, V>({ ruleDef, validationArgs, key, previousResults })
          : () => Promise.resolve(undefined);
        return previousResults.concat(getError());
      }, []);

      const getErrorForField = async (): Promise<ErrorInList<T>> => {
        const errors = await Promise.all(errorsList);
        // console.log("errors", errors);
        return {
          key: key as keyof T,
          errors: errors.reduce<RuleResult[]>((acc, error) => {
            if (!error) return acc;
            return acc.concat({ ...error, liveValidation: error?.liveValidation || validationOptions?.liveValidation });
          }, []),
        };
      };

      return errorsFulllist.concat(getErrorForField());
    },
    []
  );

  const erroList = await Promise.all(errorBuilds);
  const cleanErrors = erroList.reduce<Errors<T>>((acc, { key, errors }) => {
    if (validationArgs.fieldsToValidate.includes(key as string) || validationArgs.fieldsToValidate.includes("submit")) {
      //@ts-ignore
      const fieldName = validationArgs.remoteFieldSpecs[key]?.title ?? "";
      return {
        ...acc,
        [key]: { fieldName, messages: errors },
      };
    }
    return acc;
  }, {});

  return cleanErrors;
}

function getErrors<T extends object, V extends ValidateFormPropsInner<T>>({
  previousResults,
  ruleDef,
  validationArgs,
  key,
  validationOptions,
}: {
  previousResults: PendingRuleResult[];
  ruleDef: Rule<T, V, keyof T>;
  validationArgs: V;
  key: string;
  validationOptions?: RuleOptions<T>;
}): () => PendingRuleResult {
  return async () => {
    const errorType: RuleResultType = validationArgs.isInitalVaildation ? "initial" : "error";
    const ruleFunc = isRuleCondition<T, V, keyof T>(ruleDef);
    const { rule, ...options } = ruleFunc
      ? { ...validationOptions, rule: ruleDef }
      : { ...validationOptions, ...ruleDef };

    if (options.waitForPreviousRules && previousResults.length > 0) {
      await Promise.all(previousResults);
    }
    if (options.stopOnPreviousError && previousResults.length > 0) {
      const previousErrors = await Promise.all(previousResults);
      if (previousErrors.some((error) => error)) return undefined;
    }

    const value = validationArgs.data[key as keyof T];
    const check = await rule({ ...validationArgs, value, field: key });
    if (!check) return undefined;
    const result = isRuleResultObject(check) ? check : { message: check, type: errorType };

    return { ...result, liveValidation: options.liveValidation };
  };
}
