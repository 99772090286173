import * as React from "react";
import { SVGProps } from "react";
const ParkOutlineCertificate = (props: SVGProps<SVGSVGElement>) => (
  <svg width={40} height={40} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g stroke="currentColor" strokeWidth={3.2}>
      <path
        d="M21.67 30H5.002a1.667 1.667 0 0 1-1.667-1.667V6.667A1.667 1.667 0 0 1 5.003 5h30a1.667 1.667 0 0 1 1.666 1.667v21.666A1.666 1.666 0 0 1 35.003 30h-6.667M10.003 11.667h20m-20 5.833h5m-5 5.833h3.333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M25 27.5a5 5 0 1 0 0-10 5 5 0 0 0 0 10Z" />
      <path
        d="M25.005 33.333 28.34 35v-8.773s-.95 1.273-3.334 1.273c-2.383 0-3.333-1.25-3.333-1.25V35l3.333-1.667Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);
export default ParkOutlineCertificate;
