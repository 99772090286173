import {
  Checkbox,
  Divider,
  FormControl,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import ResetFilterButton from "components/molecules/ResetFilterButton";
import { useRecoilState } from "recoil";
import translations from "translations";
import { getPrimaryEnergySourceFilterValues } from "../../FilterData/getPrimaryEnergySourceFilterValues";
import { enumFilterSelectorFamily, EnumMultiSelectProps } from "../../State/manageFiltersInUnitsOverview";
import TitleWithFilterCounter from "../TitleWithFilterCounter";
import {
  getTranslatedPrimaryEnergySourceString,
  getTranslatedCategoryEnumString,
} from "lib/helper/getTranslatedRowDataStrings";
import { LabelArray, getSortedOptions } from "lib/helper/getSortedOptions";
import { getCategoryClassEquipmentFilterValues } from "../../FilterData/getCategoryClassEquipmentFilterValues";
interface EnumProps {
  filterType: keyof EnumMultiSelectProps;
  title: string;
}

const MultiSelectFilterForEnums: React.FC<EnumProps> = ({ filterType, title }) => {
  const primaryEnergySourceFilterOptions = getPrimaryEnergySourceFilterValues();
  const categoryClassEquipmentFilterOptions = getCategoryClassEquipmentFilterValues();
  const [filters, setFilters] = useRecoilState(enumFilterSelectorFamily(filterType));
  const optionsPre = Object.entries(
    filterType === "categoryClassEquipment" ? categoryClassEquipmentFilterOptions : primaryEnergySourceFilterOptions
  )
    .map((entry) => ({
      value: entry[0],
      label:
        filterType === "categoryClassEquipment"
          ? getTranslatedCategoryEnumString(entry[0])
          : getTranslatedPrimaryEnergySourceString(entry[0]),
    }))
    .filter(
      (option) => option.label && option.label.toLowerCase() !== getTranslatedPrimaryEnergySourceString("BIOGAS")
    );
  const resetFilters = () => setFilters([]);
  const handleChange = (event: SelectChangeEvent<string[]>) => {
    const value = event.target.value;
    setFilters(typeof value === "string" ? value.split(",") : value);
  };

  const options = getSortedOptions({
    labelArray: optionsPre,
    sortFn:
      filterType === "categoryClassEquipment"
        ? getTranslatedCategoryEnumString
        : getTranslatedPrimaryEnergySourceString,
  }) as LabelArray;

  return (
    <FormControl sx={{ m: 1 }} size="small" aria-label={filterType}>
      <Select
        aria-label={filterType}
        labelId={filterType}
        id={filterType}
        multiple
        displayEmpty
        sx={{ borderRadius: "0px" }}
        value={filters}
        onChange={handleChange}
        input={<OutlinedInput />}
        renderValue={(selected) => {
          if (selected.length === 0 || selected === undefined) {
            return <Typography>{title}</Typography>;
          }
          return <TitleWithFilterCounter selectedLength={selected.length} title={title} />;
        }}
      >
        {options.map(({ label, value }) => (
          <MenuItem key={value} value={value} sx={{ borderRadius: "0px" }}>
            <Checkbox checked={filters.includes(value)} />
            <ListItemText primary={label} />
          </MenuItem>
        ))}
        <Divider />
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <ResetFilterButton title={translations.globals.button.reset} resetFilters={resetFilters} />
        </Box>
      </Select>
    </FormControl>
  );
};

export default MultiSelectFilterForEnums;
