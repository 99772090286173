import React from "react";
import CustomFilterComponents from "./CustomFilterComponents";
import FilterDataLoader from "./FilterData/FilterDataLoader";

interface Props {}

const CustomOpenTaskFilters: React.FC<Props> = () => {
  return (
    <>
      <FilterDataLoader />
      <CustomFilterComponents />
    </>
  );
};

export default CustomOpenTaskFilters;
