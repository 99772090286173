import TenantSelectFreeSolo from "components/molecules/TenantSelectFreeSolo";
import React, { useEffect } from "react";
import Props from "./index.d";
import { useRecoilState, useRecoilValue } from "recoil";
import { tenantIdAtom } from "./state";
import { userIsPlatformOperatorAtom } from "pages/UserProfile/recoil";

const TenantIdForUser: React.FC<Props> = (props) => {
  const userIsPlatformOperator = useRecoilValue(userIsPlatformOperatorAtom);

  const {
    data: { state, setstate, setNull },
    pickDefaultFirst,
    disabled,
  } = props;
  const [tenantId, setTenantId] = useRecoilState(tenantIdAtom);
  useEffect(() => {
    if (state && state !== tenantId) {
      setTenantId(state);
    }
  }, [state, setTenantId, tenantId]);

  return (
    <TenantSelectFreeSolo
      pickDefaultFirst={pickDefaultFirst}
      disabled={disabled || !userIsPlatformOperator}
      value={state}
      handleSaveTenant={(tenant) => {
        if (!tenant) {
          setNull?.();
          setstate("");
        } else {
          setstate(tenant.value);
        }
      }}
    />
  );
};

export default TenantIdForUser;
