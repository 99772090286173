import type * as Types from '../../types';

import client from "apollo/client";
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetCertificateValidityStatusesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetCertificateValidityStatusesQuery = { __typename?: 'Query', certificateValidityStatuses?: { __typename?: 'CertificateValidityStatusCollectionSegment', items?: Array<{ __typename?: 'CertificateValidityStatus', key: string, value: string }> | null } | null };


        export type ExecGetCertificateValidityStatusesOptions = Omit<
        Apollo.QueryOptions<
        GetCertificateValidityStatusesQueryVariables, GetCertificateValidityStatusesQuery
        >,
        "query"
      >;
    export const execGetCertificateValidityStatusesQuery = (options: ExecGetCertificateValidityStatusesOptions) =>
    client.query< GetCertificateValidityStatusesQuery, GetCertificateValidityStatusesQueryVariables>({
      ...options,
      query: GetCertificateValidityStatusesDocument,
    });
    

export const GetCertificateValidityStatusesDocument = gql`
    query getCertificateValidityStatuses {
  certificateValidityStatuses {
    items {
      key: id
      value: certificateValidityStatusName
    }
  }
}
    `;

/**
 * __useGetCertificateValidityStatusesQuery__
 *
 * To run a query within a React component, call `useGetCertificateValidityStatusesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCertificateValidityStatusesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCertificateValidityStatusesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCertificateValidityStatusesQuery(baseOptions?: Apollo.QueryHookOptions<GetCertificateValidityStatusesQuery, GetCertificateValidityStatusesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCertificateValidityStatusesQuery, GetCertificateValidityStatusesQueryVariables>(GetCertificateValidityStatusesDocument, options);
      }
export function useGetCertificateValidityStatusesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCertificateValidityStatusesQuery, GetCertificateValidityStatusesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCertificateValidityStatusesQuery, GetCertificateValidityStatusesQueryVariables>(GetCertificateValidityStatusesDocument, options);
        }
export type GetCertificateValidityStatusesQueryHookResult = ReturnType<typeof useGetCertificateValidityStatusesQuery>;
export type GetCertificateValidityStatusesLazyQueryHookResult = ReturnType<typeof useGetCertificateValidityStatusesLazyQuery>;
export type GetCertificateValidityStatusesQueryResult = Apollo.QueryResult<GetCertificateValidityStatusesQuery, GetCertificateValidityStatusesQueryVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
          export type ResultType<T> = Types.ResultType<T>;
          export type PaginatedResultType<T> = Types.PaginatedResultType<T>;
          export type NonMaybe<T> = Types.NonMaybe<T>;
          export type Maybe<T> = Types.Maybe<T>;