export const inputProps = {
  sx: {
    height: "42px",
    display: "flex",
    padding: "8px 12px",
    justifyContent: "space-between",
    alignItems: "center",
    alignContent: "center",
    rowGap: "100px",
    flex: "1 0 0",
    flexWrap: "wrap",
    borderRadius: "0px",
    width: "100%",
    minWidth: "244px",
  },
};
