                                    import type * as Types from "../types";
      export type Filtertypes ={
            accessStatus: Types.AccessStatusOperationFilterInput;
            approvalStatus: Types.ApprovalStatusOperationFilterInput;
            boolean: Types.BooleanOperationFilterInput;
            category: Types.CategoryOperationFilterInput;
            dateTimeOffset: Types.ComparableDateTimeOffsetOperationFilterInput;
            dateTime: Types.ComparableDateTimeOperationFilterInput;
            guid: Types.ComparableGuidOperationFilterInput;
            int: Types.ComparableInt32OperationFilterInput;
            int64: Types.ComparableInt64OperationFilterInput;
            historyTableType: Types.HistoryTableTypeOperationFilterInput;
            notificationFrequency: Types.NotificationFrequencyOperationFilterInput;
            notificationStatus: Types.NotificationStatusOperationFilterInput;
            notificationSubscriberType: Types.NotificationSubscriberTypeOperationFilterInput;
            notificationType: Types.NotificationTypeOperationFilterInput;
            primaryEnergySource: Types.PrimaryEnergySourceOperationFilterInput;
            softwareType: Types.SoftwareTypeOperationFilterInput;
            string: Types.StringOperationFilterInput;
            tenantRole: Types.TenantRoleOperationFilterInput;}
    
    export const filterdefs = {
  "accessStatus": [
    "eq",
    "neq"
  ],
  "approvalStatus": [
    "eq",
    "neq"
  ],
  "boolean": [
    "eq",
    "neq"
  ],
  "category": [
    "eq",
    "neq"
  ],
  "dateTimeOffset": [
    "eq",
    "gt",
    "gte",
    "lt",
    "lte",
    "neq",
    "ngt",
    "ngte",
    "nlt",
    "nlte"
  ],
  "dateTime": [
    "eq",
    "gt",
    "gte",
    "lt",
    "lte",
    "neq",
    "ngt",
    "ngte",
    "nlt",
    "nlte"
  ],
  "guid": [
    "eq",
    "gt",
    "gte",
    "lt",
    "lte",
    "neq",
    "ngt",
    "ngte",
    "nlt",
    "nlte"
  ],
  "int": [
    "eq",
    "gt",
    "gte",
    "lt",
    "lte",
    "neq",
    "ngt",
    "ngte",
    "nlt",
    "nlte"
  ],
  "int64": [
    "eq",
    "gt",
    "gte",
    "lt",
    "lte",
    "neq",
    "ngt",
    "ngte",
    "nlt",
    "nlte"
  ],
  "historyTableType": [
    "eq",
    "neq"
  ],
  "notificationFrequency": [
    "eq",
    "neq"
  ],
  "notificationStatus": [
    "eq",
    "neq"
  ],
  "notificationSubscriberType": [
    "eq",
    "neq"
  ],
  "notificationType": [
    "eq",
    "neq"
  ],
  "primaryEnergySource": [
    "eq",
    "neq"
  ],
  "softwareType": [
    "eq",
    "neq"
  ],
  "string": [
    "contains",
    "endsWith",
    "eq",
    "ncontains",
    "nendsWith",
    "neq",
    "nstartsWith",
    "startsWith"
  ],
  "tenantRole": [
    "eq",
    "neq"
  ]
}