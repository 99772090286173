import { styled } from "@mui/material";
import { execGetTenantsForRoleQuery } from "graphqlBase/tenant/__generated__/getTenantsForRole";
import { useGetUserInfo } from "lib/userInfo";
import * as React from "react";
import Autocomplete, { TenantResult, TenantSearch } from "./AutoComplete";
import Props from "./index.d";
import translations from "translations";

const StyledAutocomplete = styled(Autocomplete)<Props>(({ theme, isUnit }) => ({
  width: isUnit ? "244px" : "",
  "& .MuiOutlinedInput-root": {
    padding: "0px",
  },
}));

const TenantIdForRole: React.FC<Props> = (props) => {
  const {
    role,
    data: { setAndSave },
    isUnit,
    disabled,
  } = props;

  const userInfo = useGetUserInfo();
  const search: TenantSearch = ({ inputValue }, callback) => {
    execGetTenantsForRoleQuery({ variables: { role, inputValue, approvalStatus: "DENIED" } }).then((res) => {
      const tenants = res.data.tenants?.items ?? [];
      const tenantResults: TenantResult[] = tenants.reduce((acc, tenant) => {
        acc.push({
          key: tenant.id,
          label:
            tenant.id === "28d2afd0-4db5-5a06-b7d0-542eb13f528a"
              ? translations.pages.certificateUpload.placeHolderCertificationBodyForManufacturersDeclaration
              : tenant.tenantName,
        });
        return acc.sort((a, b) => a.label.localeCompare(b.label));
      }, [] as TenantResult[]);

      callback(tenantResults);
    });
  };

  React.useEffect(() => {
    if (userInfo?.role === role) {
      //@todo remove default value
      setAndSave(userInfo?.tenantId);
    }
  }, [userInfo, role, setAndSave]);

  return (
    <StyledAutocomplete {...props} search={search} disabled={role === userInfo?.role || disabled} isUnit={isUnit} />
  );
};

export default TenantIdForRole;
