import React from "react"
import memoize from "lodash/memoize"
import {
  Operation,
  QueryArray,
  RemoteFieldSpecExtended,
} from "@ml-pa/react-apollo-form-tool/formGen.model"
import BuildForm from "@ml-pa/react-apollo-form-tool/buildForm"
import deleteItems, {
  DeleteItemsFucType,
  IdType,
} from "@ml-pa/react-apollo-form-tool/buildForm/deleteItems"
import elements from "./internal/elements"
import config from "./internal/raft.config"
import clientFields from "./internal/clientFields.json"
import externalFields from "./internal/externalFields.json"
import schemaDescription from "./internal/schemaDescription.json"
import client from "apollo/client"
import localization from "translations"

const cleanPath = (configuratorKey: string) =>
  configuratorKey.replace("tree", "config")
const clientLocal = async (path: string) => {
  try {
    const v = await import(`./${cleanPath(path)}.json`)
    return { data: { configurator: { data: JSON.stringify(v) } } }
  } catch (e) {
    return { data: { configurator: { data: "{}" } } }
  }
}

export type DeleteItemsFuncType = DeleteItemsFucType

export interface FormParameters {
  params?: any
  operation: Operation
  entity: keyof typeof schemaDescription
  formVersion?: string
  configpath?: string
}

const makeNewForm = ({
  params,
  entity,
  operation,
  formVersion,
  configpath,
}: FormParameters) => {
  const requiredFields: RemoteFieldSpecExtended[] = schemaDescription[
    entity
  ].required[operation].map((field) => {
    return {
      required: true,
      name: field,
      //@ts-ignore
      dataType: schemaDescription[entity].fields[field].type,
      title: field.replace(/_/g, " "),
    }
  })
  return new BuildForm({
    client,
    clientFields,
    externalFields,
    config,
    entity,
    operation,
    elements,
    clientLocal,

    allFields: Object.keys(schemaDescription[entity].fields),
    constraints: schemaDescription[entity].constraints,
    requiredFields,

    formVersion,
    configpath,
    localization,
  })
}
export const makeNewFormMemoized = memoize(makeNewForm)

export interface GenericDeleteParams {
  afterDelete?: (ids: IdType) => void
  onSubmitError?: (ids: IdType) => void
  updateAfterDeleteQueries?: QueryArray
  entity: keyof typeof schemaDescription
}
export const genericDeleteFunction = (props: GenericDeleteParams) => {
  const baseProps = {
    client,
  }
  const genericDelete = deleteItems({ ...props, ...baseProps })
  return genericDelete
}
