import styled from "@emotion/styled";
import { Button } from "@mui/material";
import ordinaryColors from "themes/ordinaryColors";

export const StyledApplyButton = styled(Button)(({ theme }) => ({
  color: ordinaryColors.background,
  borderColor: ordinaryColors.background,
  height: "30px",
  marginTop: "8px ",
  marginBottom: "16px",
  alignSelf: "self-end",
  marginRight: "16px",
}));
