import { UnitFormErrorDisplay } from "components/formElements/customElements/UnitFormErrorDisplay";
import { StyledContentContainer } from "components/formElements/customElements/UnitFormErrorDisplay/styles";
import UnitWrapper from "components/formElements/fieldSets/UnitWrapper";
import * as React from "react";
import TenantIdForRole from "../TenantIdForRole";
import Props from "./index.d";

const UnitManufacturer: React.FC<Props> = (props) => {
  const errorManagerRes = props.errorManager({
    errorJoiner: (errors) => {
      return errors.join(", ");
    },
  });

  return (
    <UnitWrapper title={props.title ?? ""}>
      <StyledContentContainer>
        {UnitFormErrorDisplay(errorManagerRes)}
        <TenantIdForRole role="MANUFACTURER" isUnit {...props} />
      </StyledContentContainer>
    </UnitWrapper>
  );
};

export default UnitManufacturer;
