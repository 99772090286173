import React from "react";
import FilterBar from "./FilterBar";
import FilterDataLoader from "./FilterData/FilterDataLoader";

interface Props {}

const CustomDataGridFilters: React.FC<Props> = () => {
  return (
    <>
      <FilterDataLoader />
      <FilterBar />
    </>
  );
};

export default CustomDataGridFilters;
