import { EntityInner } from "../configurator/types";

const fieldstoExclude = ["submit", "formErrors", "cancel", "virtualField", "formErrorCount", "step"];

const checkClientFieldsPre = (clientFields: string[]) => (field: string, path?: string) =>
  clientFields.includes(path ? path : field) ? `${field} @client` : field;

const getFields = ({
  usedFields,
  requiredFields,
  usedMappingInfos,
  clientFields,
  externalFields,
  entity,
  usedFieldTypes,
}: {
  entity: string;
  usedFields: string[];
  requiredFields: string[];
  usedMappingInfos: EntityInner[];
  clientFields: string[];
  externalFields: string[];
  usedFieldTypes: { [key: string]: string };
}) => {
  // console.log({
  //   usedFields,
  //   requiredFields,
  //   usedMappingInfos,
  //   clientFields,
  //   externalFields,
  //   entity,
  // });
  const checkClientFields = checkClientFieldsPre(clientFields),
    mappingToCache: string[] = [],
    fields: string[] = usedFields
      .concat(requiredFields)
      .filter((x, i, a) => a.indexOf(x) == i && !externalFields.includes(x) && !x.includes("Bridge"))
      .map((field) => {
        if (usedFieldTypes[field] === "GeoJSONPolygonType") return `${field}{\n\t\t\tcoordinates\n\t\t\t}`;
        return checkClientFields(field, `${entity}.${field}`);
      });
  return { fields: ["id"].concat(fields), mappingToCache };
};

export default (
  { entity, usedFields, requiredFields, usedMappingInfos, usedFieldTypes, ...rest }: any,
  clientFields: string[],
  externalFields: string[],
  useHC11: boolean
) => {
  const { fields, mappingToCache } = getFields({
    usedFields: usedFields.filter((field: string) => !fieldstoExclude.includes(field)),
    requiredFields,
    usedMappingInfos,
    clientFields,
    externalFields,
    entity,
    usedFieldTypes,
  });

  const idName = "id";
  const uuidName = useHC11 ? `Uuid` : "UUID";

  const query = `query Raftget${entity}($id: ${uuidName}!) {\n\tresult : ${entity}(id: $id) {\n\t\t${fields.join(
    // const query = `query get${entity}($id: Int!) {\n\tresult : ${entity}(id: $id) {\n\t\t${fields.join(
    ",\n\t\t"
  )}\n\t}${mappingToCache.join("\n\n")}\n}
       
   `;
  return query;
};
