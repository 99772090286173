import styled from "@emotion/styled";
import { Button } from "@mui/material";
import { discardChangesModalOpenState, discardChangesState } from "components/molecules/DiscardChangesWarning/recoil";
import * as React from "react";
import { useSetRecoilState } from "recoil";
import ordinaryColors from "themes/ordinaryColors";
import Props from "./index.d";
export const StyledUploadButton = styled(Button)(({ theme }) => ({
  height: "43px",
  width: "100%",
  borderRadius: "0px",
  variant: "outlined",
  border: "1px solid",
  borderColor: ordinaryColors.functionalBlack,
  minWidth: "64px",
  color: ordinaryColors.functionalBlack,
}));

const CTACancelButton: React.FC<Props> = (props) => {
  const {
    disabled,
    className,
    data: { setAndSave },
  } = props;
  const setDiscardChangesModalOpen = useSetRecoilState(discardChangesModalOpenState);
  const setDiscardChangesState = useSetRecoilState(discardChangesState);

  return (
    <StyledUploadButton
      className={className}
      disabled={disabled}
      onClick={() => {
        setDiscardChangesModalOpen(true);
        setDiscardChangesState("restart");
        setAndSave(true);
      }}
    >
      {props.title ?? ""}
    </StyledUploadButton>
  );
};

export default CTACancelButton;
