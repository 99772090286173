import { Link } from "@mui/material";
import { GridRowModel } from "@mui/x-data-grid-pro";
// import { accordionStates } from "components/molecules/Accordion/recoil";
import { GetCertificateBridgeUnitsQuery } from "graphqlBase/getCertificateBridgeUnits/__generated__/getCertificateBridgeUnits";
import { GetUnitsForUnitsOverviewQuery } from "graphqlBase/getUnitsForUnitsOverview/__generated__/getUnitsForUnitsOverview";
import { PaginatedResultType } from "graphqlBase/types";
import { useNavigate } from "react-router-dom";
import ordinaryColors from "themes/ordinaryColors";
import { translations } from "translations";
// import { useResetRecoilState } from "recoil";
import { CertificateOnUnit } from "./DetailPanelContent";
import ClampedText from "components/atomics/ClampedText";

export type UnitOverview = PaginatedResultType<GetUnitsForUnitsOverviewQuery["unitOverview"]>;

export const getUnitLinkInput = ({
  row,

  isPlatformOperator,
}: {
  row: GridRowModel<UnitOverview>;
  hasActiveErrorReport: boolean;
  isPlatformOperator: boolean;
}) => ({
  label: row.modelName ?? "",
  path: `/units/${row?.id}`,

  isPlatformOperator,
});

export type CertificateBridgeUnit = PaginatedResultType<GetCertificateBridgeUnitsQuery["certificateBridgeUnits"]>;

export const getCertificateLinkInput = ({
  row,
  hasActiveErrorReport,
  isPlatformOperator,
}: {
  row: GridRowModel<CertificateOnUnit>;
  hasActiveErrorReport?: boolean;
  isPlatformOperator?: boolean;
}) => ({
  label: row.certificateNumber ?? "",
  path: `/certificates/${row?.id}`,
  hasActiveErrorReport,
  isPlatformOperator,
});
const UnitsAndCertificatesLink = ({
  label,
  path,
  hasActiveErrorReport,
  isPlatformOperator,
  clampText,
}: {
  label: string;
  path: string;
  hasActiveErrorReport?: boolean;
  isPlatformOperator?: boolean;
  clampText?: boolean;
}) => {
  // const resetValue = useResetRecoilState(accordionStates);
  const navigate = useNavigate();
  return (
    <Link
      aria-label={`${translations.pages.certificationManagement.linkToDetailPageOfModel} ${label}`}
      sx={{
        cursor: "pointer",
        paddingTop: "5px",
        color: hasActiveErrorReport && isPlatformOperator ? ordinaryColors.warningDark : "",
      }}
      onClick={(e) => {
        e.stopPropagation();
        // resetValue();
        navigate(path);
      }}
      underline="none"
    >
      {clampText ? <ClampedText>{label}</ClampedText> : label}
    </Link>
  );
};

export default UnitsAndCertificatesLink;
