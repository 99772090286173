import { useGetCertificateAuthoritiesQuery } from "graphqlBase/UnitsOverviewFilterOptions/__generated__/getCertificateAuthorities";
import { useGetCertificateHoldersQuery } from "graphqlBase/UnitsOverviewFilterOptions/__generated__/getCertificateHolders";
import { useGetCertificateNormsQuery } from "graphqlBase/UnitsOverviewFilterOptions/__generated__/getCertificateNorms";
import { useGetCertifcateTypeNamesQuery } from "graphqlBase/UnitsOverviewFilterOptions/__generated__/getCertificateTypeNames";
import { useGetCertificateValidityStatusesQuery } from "graphqlBase/UnitsOverviewFilterOptions/__generated__/getCertificateValidityStatuses";
import { useEffect } from "react";
import { atom, useSetRecoilState } from "recoil";

export type FilterItemStrings = {
  key: string;
  value: string;
};

export const certificateTypeNamesAtom = atom<FilterItemStrings[] | undefined>({
  key: "documentType",
  default: [],
});
export const certificateValidityStatuesAtom = atom<FilterItemStrings[] | undefined>({
  key: "certificateValidityStatus",
  default: [],
});
export const certificateNormsDataAtom = atom<FilterItemStrings[] | undefined>({
  key: "certificateNorms",
  default: [],
});

export const certificateHolderDataAtom = atom<FilterItemStrings[] | undefined>({
  key: "certificateHolderIdData",
  default: [],
});
export const certificateAuthorityDataAtom = atom<FilterItemStrings[] | undefined>({
  key: "certificateAuthorityIdData",
  default: [],
});

export default () => {
  const { data: certificateTypeNamesData } = useGetCertifcateTypeNamesQuery();
  const { data: certificateValidityStatusData } = useGetCertificateValidityStatusesQuery();
  const { data: certificateNormsData } = useGetCertificateNormsQuery();
  const { data: certificateHolderData } = useGetCertificateHoldersQuery();
  const { data: certificateAuthorityData } = useGetCertificateAuthoritiesQuery();

  const setCertificateTypeNames = useSetRecoilState(certificateTypeNamesAtom);
  const setCertificateValidityStatuses = useSetRecoilState(certificateValidityStatuesAtom);
  const setCertificateNormsData = useSetRecoilState(certificateNormsDataAtom);
  const setCertificateHolderData = useSetRecoilState(certificateHolderDataAtom);
  const setCertificateAuthorityData = useSetRecoilState(certificateAuthorityDataAtom);
  useEffect(() => {
    setCertificateTypeNames(certificateTypeNamesData?.certificateTypes?.items ?? []);
  }, [certificateTypeNamesData?.certificateTypes?.items, setCertificateTypeNames]);

  useEffect(() => {
    setCertificateValidityStatuses(certificateValidityStatusData?.certificateValidityStatuses?.items ?? []);
  }, [certificateValidityStatusData?.certificateValidityStatuses?.items, setCertificateValidityStatuses]);

  useEffect(() => {
    setCertificateNormsData(certificateNormsData?.certificateNorms?.items ?? []);
  }, [certificateNormsData?.certificateNorms?.items, setCertificateNormsData]);

  useEffect(() => {
    setCertificateHolderData(certificateHolderData?.tenants?.items ?? []);
  }, [certificateHolderData?.tenants?.items, setCertificateHolderData]);
  useEffect(() => {
    setCertificateAuthorityData(certificateAuthorityData?.tenants?.items ?? []);
  }, [certificateAuthorityData?.tenants?.items, setCertificateAuthorityData]);

  return null;
};
