import { Box, Typography, styled } from "@mui/material";

export const BoxSC = styled(Box, {
  label: "Box",
  shouldForwardProp(propName) {
    return propName !== "active";
  },
})<{ active: boolean }>(({ theme, active }) => ({
  display: "flex",
  width: "54.3125rem",
  padding: "1rem 1.5rem",
  alignItems: "flex-start",
  gap: "1.5rem",
  border: `0.0625rem solid ${active ? theme.palette.primary.main : theme.palette.grey[300]}`,
  background: active ? theme.palette.ordinaryColors.primary50 : theme.palette.common.white,
  cursor: "pointer",
}));

interface PrcocessOptionProps {
  icon: React.ReactNode;
  title: string;
  description: string;
  active: boolean;
  setProcessOption: () => void;
}

const ProcessOption: React.FC<PrcocessOptionProps> = ({
  icon,
  title,
  description,

  active,
  setProcessOption,
}) => {
  return (
    <BoxSC onClick={setProcessOption} active={active}>
      <Box
        sx={{
          width: "2.5rem",
          height: "2.5rem",
          flexShrink: 0,
          color: (theme) => (active ? theme.palette.primary.main : theme.palette.grey[500]),
        }}
      >
        {icon}
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          gap: "0.5rem",
          flex: "1 0 0",
        }}
      >
        <Typography variant="h6">{title}</Typography>
        <Typography variant="body1">{description}</Typography>
      </Box>
    </BoxSC>
  );
};
export default ProcessOption;
