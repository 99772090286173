import styled from "@emotion/styled";
import { Button } from "@mui/material";
import React from "react";
import ordinaryColors from "themes/ordinaryColors";
import translations from "translations";

interface Props {
  onClick?: () => void;
  flexibleWidth?: boolean;
  title?: string;
}

const StyledCancelButton = styled(Button)<Omit<Props, "title">>(({ flexibleWidth }) => ({
  minWidth: flexibleWidth ? "99px" : undefined,
  width: !flexibleWidth ? "99px" : undefined,
  height: "42px",
  padding: "8px 22px",
  borderRadius: "0px",
  gap: "8px",
  backgroundColor: ordinaryColors.black,
  color: ordinaryColors.white,
  ":hover": { backgroundColor: ordinaryColors.black },
}));

const CancelButton: React.FC<Props> = ({ onClick, title, flexibleWidth }) => {
  return (
    <StyledCancelButton flexibleWidth={flexibleWidth} onClick={onClick}>
      {title ?? translations.globals.button.cancel}
    </StyledCancelButton>
  );
};

export default CancelButton;
