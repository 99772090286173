/* eslint-disable filenames/match-exported */
import { useGridApiRef } from "@mui/x-data-grid-pro";
import { EntityMappings } from "graphqlBase/__utils__/entityMappings";
import * as React from "react";
import { addNewRowPre } from "./addNewRowPre";
import { DataGridFactory } from "./DataGridFactory";
import { makeColumnsPre } from "./makeColumnsPre";
import { makeSeverSideFilterPre } from "./makeSeverSideFilterPre";
import { BaseRow, UseMakeDataGridProps } from "./types.d";
import { useExecuteQuery } from "./useExecuteQuery";

const useServerSide = <R extends BaseRow, E extends keyof EntityMappings>(params: UseMakeDataGridProps<R, E>) => {
  const { query, variables, DataGridComponent, tableMode, persistance, tableId } = params;
  const apiRef = useGridApiRef();
  //const { submitCellChanges } = useOnCellEditCommit({});
  const executeQuery = useExecuteQuery({ query, apiRef, variables });

  return React.useMemo(() => {
    return {
      DataGrid: DataGridFactory<R, E>({
        apiRef,
        executeQuery,
        DataGridComponent,
        tableMode,
        persistance,
        tableId,
      }),
      getRowCount: () => apiRef.current.getAllRowIds().length,
      useMakeColumns: makeColumnsPre<R, E>(params),
      makeSeverSideFilter: makeSeverSideFilterPre<R, E>(),
      //submitCellChanges,
      apiRef: apiRef,
      addNewRow: addNewRowPre<Partial<EntityMappings[E]>>(apiRef),
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [executeQuery]);
};

export default useServerSide;
