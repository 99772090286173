import {
  Checkbox,
  Divider,
  FormControl,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import ResetFilterButton from "components/molecules/ResetFilterButton";
import {
  getTranslatedCertificateTypeString,
  getTranslatedCertificateValidityStatusString,
} from "lib/helper/getTranslatedRowDataStrings";
import React from "react";
import { RecoilValue, useRecoilState, useRecoilValue } from "recoil";
import translations from "translations";
import {
  certificateNormsDataAtom,
  certificateTypeNamesAtom,
  certificateValidityStatuesAtom,
  FilterItemStrings,
} from "../../FilterData/FilterDataLoader";
import { queryFilterSelectorFamily, QueryMultiSelectProps } from "../../State/manageFiltersInUnitsOverview";
import TitleWithFilterCounter from "../TitleWithFilterCounter";

export interface MultiSelectFilterForQueriesProps {
  filterType: keyof QueryMultiSelectProps;
  title: string;
}

const FilterMultiselectForQueries: React.FC<MultiSelectFilterForQueriesProps> = ({ filterType, title }) => {
  const queryOptions = useRecoilValue(
    filterType === "documentType"
      ? certificateTypeNamesAtom
      : filterType === "certificateValidityStatus"
      ? certificateValidityStatuesAtom
      : filterType === "certificateNorms"
      ? certificateNormsDataAtom
      : ([] as unknown as RecoilValue<FilterItemStrings[] | undefined>)
  );
  const [filters, setFilters] = useRecoilState(queryFilterSelectorFamily(filterType));
  const resetFilters = () => setFilters([]);

  const options = (queryOptions ?? [])
    .map<{ key: string; value: string; label: string }>((entry) => {
      switch (filterType) {
        case "documentType":
          return {
            key: entry.key,
            value: entry.key,
            label: getTranslatedCertificateTypeString(entry.key),
          };
        case "certificateValidityStatus":
          return {
            key: entry.key,
            value: entry.key,
            label: getTranslatedCertificateValidityStatusString(entry.key),
          };
        default:
          return {
            key: entry.key,
            value: entry.value,
            label: entry.value,
          };
      }
    })
    .filter((i) => !!i.label)
    .sort((a, b) => a.label.localeCompare(b.label));

  const handleChange = (event: SelectChangeEvent<string[]>) => {
    const value = event.target.value;
    setFilters(typeof value === "string" ? value.split(",") : value);
  };

  return (
    <>
      <FormControl sx={{ m: 1 }} size="small" aria-label={filterType}>
        <Select
          labelId={filterType}
          id={filterType}
          multiple
          displayEmpty
          sx={{ borderRadius: "0px" }}
          value={filters}
          onChange={handleChange}
          input={<OutlinedInput />}
          renderValue={(selected) => {
            if (selected.length === 0 || selected === undefined) {
              return <Typography>{title}</Typography>;
            }
            return <TitleWithFilterCounter selectedLength={selected.length} title={title} />;
          }}
        >
          {options?.map(({ key, value, label }) => (
            <MenuItem key={key} value={value}>
              <Checkbox checked={filters.includes(value)} />
              <ListItemText primary={label} />
            </MenuItem>
          ))}
          <Divider />
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <ResetFilterButton title={translations.globals.button.reset} resetFilters={resetFilters} />
          </Box>
        </Select>
      </FormControl>
    </>
  );
};

export default FilterMultiselectForQueries;
