import type * as Types from '../../types';

import client from "apollo/client";
import { gql } from '@apollo/client';
import { AccessRequestsFragementFragmentDoc } from './accessRequestsFragement';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetAccessRequestsForPermissionsManagementTableQueryVariables = Types.Exact<{
  skip?: Types.InputMaybe<Types.Scalars['Int']>;
  take?: Types.InputMaybe<Types.Scalars['Int']>;
  where?: Types.InputMaybe<Types.AccessRequestFilterInput>;
  order?: Types.InputMaybe<Array<Types.AccessRequestSortInput> | Types.AccessRequestSortInput>;
}>;


export type GetAccessRequestsForPermissionsManagementTableQuery = { __typename?: 'Query', accessRequests?: { __typename?: 'AccessRequestCollectionSegment', totalCount: number, items?: Array<{ __typename?: 'AccessRequest', id: string, certificateId: string, referenceId: string, referenceName: string, status: Types.AccessStatus, scope: string, reason?: string | null, requestedBy: string, requestedOn: string, approvedBy?: string | null, approvedOn?: string | null, rejectedBy?: string | null, rejectedOn?: string | null, accessForTenantId: string, createdAt: string, createdBy?: string | null, modifiedAt?: string | null, modifiedBy?: string | null, tenantId: string, accessRequestedTo: string, typeOfRequestedData: string, requestToTenantId: string, certificate: { __typename?: 'Certificate', id: string, certificateNumber: string } }> | null } | null };


        export type ExecGetAccessRequestsForPermissionsManagementTableOptions = Omit<
        Apollo.QueryOptions<
        GetAccessRequestsForPermissionsManagementTableQueryVariables, GetAccessRequestsForPermissionsManagementTableQuery
        >,
        "query"
      >;
    export const execGetAccessRequestsForPermissionsManagementTableQuery = (options: ExecGetAccessRequestsForPermissionsManagementTableOptions) =>
    client.query< GetAccessRequestsForPermissionsManagementTableQuery, GetAccessRequestsForPermissionsManagementTableQueryVariables>({
      ...options,
      query: GetAccessRequestsForPermissionsManagementTableDocument,
    });
    

export const GetAccessRequestsForPermissionsManagementTableDocument = gql`
    query getAccessRequestsForPermissionsManagementTable($skip: Int, $take: Int, $where: AccessRequestFilterInput, $order: [AccessRequestSortInput!]) {
  accessRequests(skip: $skip, take: $take, where: $where, order: $order) {
    items {
      ...accessRequestsFragement
    }
    totalCount
  }
}
    ${AccessRequestsFragementFragmentDoc}`;

/**
 * __useGetAccessRequestsForPermissionsManagementTableQuery__
 *
 * To run a query within a React component, call `useGetAccessRequestsForPermissionsManagementTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccessRequestsForPermissionsManagementTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccessRequestsForPermissionsManagementTableQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      where: // value for 'where'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useGetAccessRequestsForPermissionsManagementTableQuery(baseOptions?: Apollo.QueryHookOptions<GetAccessRequestsForPermissionsManagementTableQuery, GetAccessRequestsForPermissionsManagementTableQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAccessRequestsForPermissionsManagementTableQuery, GetAccessRequestsForPermissionsManagementTableQueryVariables>(GetAccessRequestsForPermissionsManagementTableDocument, options);
      }
export function useGetAccessRequestsForPermissionsManagementTableLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAccessRequestsForPermissionsManagementTableQuery, GetAccessRequestsForPermissionsManagementTableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAccessRequestsForPermissionsManagementTableQuery, GetAccessRequestsForPermissionsManagementTableQueryVariables>(GetAccessRequestsForPermissionsManagementTableDocument, options);
        }
export type GetAccessRequestsForPermissionsManagementTableQueryHookResult = ReturnType<typeof useGetAccessRequestsForPermissionsManagementTableQuery>;
export type GetAccessRequestsForPermissionsManagementTableLazyQueryHookResult = ReturnType<typeof useGetAccessRequestsForPermissionsManagementTableLazyQuery>;
export type GetAccessRequestsForPermissionsManagementTableQueryResult = Apollo.QueryResult<GetAccessRequestsForPermissionsManagementTableQuery, GetAccessRequestsForPermissionsManagementTableQueryVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
          export type ResultType<T> = Types.ResultType<T>;
          export type PaginatedResultType<T> = Types.PaginatedResultType<T>;
          export type NonMaybe<T> = Types.NonMaybe<T>;
          export type Maybe<T> = Types.Maybe<T>;