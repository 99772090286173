import { Autocomplete, Checkbox, FormControl, ListItemText, MenuItem, Popper } from "@mui/material";
import { Box } from "@mui/system";
import ResetFilterButton from "components/molecules/ResetFilterButton";
import React, { SyntheticEvent } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import ordinaryColors from "themes/ordinaryColors";
import translations from "translations";
import {
  certificateAuthorityDataAtom,
  certificateHolderDataAtom,
  FilterItemStrings,
} from "../../FilterData/FilterDataLoader";
import { queryFilterSelectorFamily, QueryMultiSelectProps } from "../../State/manageFiltersInUnitsOverview";
import { StyledAutocompleteTextField, StyledResetBox } from "../styles";
import TitleWithFilterCounter from "../TitleWithFilterCounter";

export interface MultiSelectFilterForQueriesProps {
  filterType: keyof QueryMultiSelectProps;
  title: string;
  width: number;
}

const FilterForAutoCompleteWithMultiselect: React.FC<MultiSelectFilterForQueriesProps> = ({
  filterType,
  title,
  width,
}) => {
  const certficateHolderOptions = useRecoilValue(certificateHolderDataAtom);
  const certficateAuthorityOptions = useRecoilValue(certificateAuthorityDataAtom);
  const [filters, setFilters] = useRecoilState(queryFilterSelectorFamily(filterType));

  const resetFilters = () => setFilters([]);

  const options = filterType === "certificateAuthorityId" ? certficateAuthorityOptions : certficateHolderOptions;
  const sortedOptions = options?.slice().sort((a, b) => a.value.localeCompare(b.value));

  const handleChange = (_event: SyntheticEvent<Element, Event>, value: (FilterItemStrings | undefined)[]) => {
    if (value === undefined) return;
    setFilters(value.map((option) => option?.key ?? ""));
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const PopperFC = function (props: any) {
    return (
      <Popper
        {...props}
        style={{
          width: "fit-content",
        }}
      >
        <Box>
          <Box>{props.children}</Box>
          <StyledResetBox>
            <ResetFilterButton
              title={translations.globals.button.reset}
              resetFilters={resetFilters}
              onMouseDown={(e: React.MouseEvent<HTMLButtonElement>) => {
                e.preventDefault();
                e.stopPropagation();
              }}
            />
          </StyledResetBox>
        </Box>
      </Popper>
    );
  };
  return (
    <>
      <FormControl sx={{ m: 1, width: width }} size="small" aria-label={filterType}>
        <Autocomplete
          id="combo-box-demo"
          options={sortedOptions ?? []}
          PopperComponent={PopperFC}
          getOptionLabel={(option) => option.value}
          renderOption={(props, option, { selected }) => (
            <>
              <MenuItem key={option?.key} {...props}>
                <Checkbox checked={selected} />
                <ListItemText primary={option?.value ?? ""} />
              </MenuItem>
            </>
          )}
          multiple
          value={filters.map((filter) => ({ key: filter, value: filter }))}
          isOptionEqualToValue={(option, val) => option.key === val.key}
          disableCloseOnSelect
          onChange={handleChange}
          sx={{
            borderRadius: "0px",
            "& .MuiTextField-root": { background: ordinaryColors.functionalWhite },
            "& .MuiAutocomplete-inputRoot .MuiAutocomplete-input": {
              minWidth: "100px",
              overflow: "hidden",
            },
          }}
          disableClearable
          renderInput={(params) => (
            <StyledAutocompleteTextField
              hasPlaceholder
              {...params}
              InputLabelProps={{ shrink: false }}
              placeholder={title}
            />
          )}
          renderTags={(value) => {
            return <TitleWithFilterCounter selectedLength={value.length} title={title} hasPlaceholder />;
          }}
        />
      </FormControl>
    </>
  );
};

export default FilterForAutoCompleteWithMultiselect;
