import { makeNewFormMemoized } from "./"
import {
  Operation,
  QueryArray,
  DeleteChildProps,
  BeforeRemoteMuatation,
  BeforeRemoteMuatationProps,
  OnUpdateDataMiddleWare,
  OnUpdateDataMiddleWareProps,
  ValidateForm,
  ValidateFormProps,
  ValidateFormPropsInner,
  SetValidationCallback,
  RegisterValidationCallbackProps,
  ValidationCallBackArgs,
} from "@ml-pa/react-apollo-form-tool/formGen.model"
import getErrorInformation from "@ml-pa/react-apollo-form-tool/formValidation/getErrorInformation"
import React from "react"
import * as SchemaTypes from "./internal/schemaTypes"
import {
  FormTypes,
  ModifyProps,
  CustomRenderExternal,
} from "./internal/typeLinks"
import {
  makeFormState,
  connectField,
} from "@ml-pa/react-apollo-form-tool/buildForm/formState"
import { RecoilState } from "recoil"
import { Scalars } from "@ml-pa/react-apollo-form-tool/scalars"
import { AdditonalFieldsType } from "./internal/raft.config"
import {
  Rules,
  extractTriggerFieldsFromRules,
} from "@ml-pa/react-apollo-form-tool/formValidation"
type FieldTypes = AdditonalFieldsType & Scalars

export type CertificateCreateCustomRender<K extends keyof FormTypes> =
  CustomRenderExternal<
    K,
    SchemaTypes.CertificateInputCreate & CertificateCreateAdditionalFields
  >
export type CertificateCreateAdditionalFields = {
  __aa?: FieldTypes["integer"]
  _test?: FieldTypes["test"]
  __certificateToReplaceId?: FieldTypes["string"]
  __modifiedByUser?: FieldTypes["decimal"]
  __role?: FieldTypes["string"]
  __unitsWithErrors?: FieldTypes["integer"]
  __normList?: FieldTypes["string"]
  __fileUploadList?: FieldTypes["string"]
  __softwareVersion?: FieldTypes["string"]
  __unitsWithHighMaxPowerCount?: FieldTypes["integer"]
  __resetToRemoteValues?: FieldTypes["boolean"]
  __unitsModifiedByUser?: FieldTypes["decimal"]
}
export type CertificateCreateOnUpdateDataMiddleWare =
  OnUpdateDataMiddleWare<SchemaTypes.CertificateInputCreate>
export type CertificateCreateOnUpdateDataMiddleWareProps =
  OnUpdateDataMiddleWareProps<
    SchemaTypes.CertificateInputCreate & CertificateCreateAdditionalFields
  >
export type CertificateCreateBeforeRemoteMuatation = BeforeRemoteMuatation<
  SchemaTypes.CertificateInputCreate & CertificateCreateAdditionalFields,
  SchemaTypes.CertificateInputCreateProps["injectedValues"] &
    CertificateCreateAdditionalFields
>
export type CertificateCreateBeforeRemoteMuatationProps =
  BeforeRemoteMuatationProps<
    SchemaTypes.CertificateInputCreate & CertificateCreateAdditionalFields,
    SchemaTypes.CertificateInputCreateProps["injectedValues"] &
      CertificateCreateAdditionalFields
  >
export type CertificateCreateValidateForm = ValidateForm<
  SchemaTypes.CertificateInputCreate & CertificateCreateAdditionalFields,
  SchemaTypes.CertificateInputCreateProps["injectedValues"] &
    CertificateCreateAdditionalFields
>
export type CertificateCreateValidateFormProps = ValidateFormProps<
  SchemaTypes.CertificateInputCreate & CertificateCreateAdditionalFields,
  SchemaTypes.CertificateInputCreateProps["injectedValues"] &
    CertificateCreateAdditionalFields
>
export type CertificateCreateRules = Rules<
  SchemaTypes.CertificateInputCreate & CertificateCreateAdditionalFields,
  ValidateFormPropsInner<
    SchemaTypes.CertificateInputCreate & CertificateCreateAdditionalFields,
    SchemaTypes.CertificateInputCreateProps["injectedValues"] &
      CertificateCreateAdditionalFields
  >
>
export type CertificateCreateSetValidationCallback = SetValidationCallback<
  SchemaTypes.CertificateInputCreate & CertificateCreateAdditionalFields,
  SchemaTypes.CertificateInputCreateProps["injectedValues"] &
    CertificateCreateAdditionalFields
>
export type CertificateCreateRegisterValidationCallbackProps =
  RegisterValidationCallbackProps<
    SchemaTypes.CertificateInputCreate & CertificateCreateAdditionalFields,
    SchemaTypes.CertificateInputCreateProps["injectedValues"] &
      CertificateCreateAdditionalFields,
    ValidateFormPropsInner<
      SchemaTypes.CertificateInputCreate & CertificateCreateAdditionalFields,
      SchemaTypes.CertificateInputCreateProps["injectedValues"] &
        CertificateCreateAdditionalFields
    >
  >
export type CertificateCreateValidationCallBackArgs = ValidationCallBackArgs<
  SchemaTypes.CertificateInputCreate & CertificateCreateAdditionalFields,
  SchemaTypes.CertificateInputCreateProps["injectedValues"] &
    CertificateCreateAdditionalFields
>
export type CertificateCreateValidationArgs = Partial<
  ValidateFormPropsInner<
    SchemaTypes.CertificateInputCreate & CertificateCreateAdditionalFields,
    SchemaTypes.CertificateInputCreateProps["injectedValues"] &
      CertificateCreateAdditionalFields
  >
>

export interface CertificateCreateProps
  extends SchemaTypes.CertificateInputCreateProps,
    CertificateCreateAdditionalFields {
  injectedValues?: Omit<
    SchemaTypes.CertificateInputCreate & CertificateCreateAdditionalFields,
    "id"
  >
  multiInstance?: boolean
  formVersion?: string
  configpath?: string
  onCancel?: () => void
  modifyProps?: ModifyProps<
    SchemaTypes.CertificateInputCreate & CertificateCreateAdditionalFields
  >
  afterCreate?: (
    data: SchemaTypes.CertificateInputCreate &
      CertificateCreateAdditionalFields & { id: string }
  ) => void
  onUpdateDataMiddleWare?: OnUpdateDataMiddleWare<
    SchemaTypes.CertificateInputCreate & CertificateCreateAdditionalFields
  >
  beforeRemoteMuatation?: BeforeRemoteMuatation<
    SchemaTypes.CertificateInputCreate & CertificateCreateAdditionalFields,
    SchemaTypes.CertificateInputCreateProps["injectedValues"] &
      CertificateCreateAdditionalFields
  >
  validateForm?: ValidateForm<
    SchemaTypes.CertificateInputCreate & CertificateCreateAdditionalFields,
    SchemaTypes.CertificateInputCreateProps["injectedValues"] &
      CertificateCreateAdditionalFields
  >
  validationRules?: CertificateCreateRules
  fetchOnlyId?: boolean
  validateOnLoad?: boolean
  onSubmitError?: (
    data: SchemaTypes.CertificateInputCreate &
      CertificateCreateAdditionalFields & { id: string }
  ) => void
  setValidationCallback?: CertificateCreateSetValidationCallback
  updateAfterCreateQueries?: QueryArray
}

const getCertificateCreateTriggerMap = (rulesSet: CertificateCreateRules) => {
  return extractTriggerFieldsFromRules<
    SchemaTypes.CertificateInputCreate & CertificateCreateAdditionalFields,
    ValidateFormPropsInner<
      SchemaTypes.CertificateInputCreate & CertificateCreateAdditionalFields,
      any
    >
  >(rulesSet)
}

export const validateCertificateCreate =
  (
    rules: CertificateCreateRules,
    setValidationCallback?: CertificateCreateSetValidationCallback
  ): CertificateCreateValidateForm =>
  ({ applyRules, ...validationArgs }) => {
    registerValidationCallbackCertificateCreate({
      setValidationCallback,
      rules,
      applyRules,
      ...validationArgs,
    })
    return applyRules({
      validationArgs,
      rules: rules,
    })
  }

export function registerValidationCallbackCertificateCreate({
  setValidationCallback,
  rules,
  applyRules,
  ...validationArgs
}: CertificateCreateRegisterValidationCallbackProps) {
  if (setValidationCallback)
    setValidationCallback({
      validation: async (validationArgsInner, fullValidation = true) => {
        const rulesFields = Object.keys(rules)
        const fields = fullValidation
          ? { changedFields: rulesFields, fieldsToValidate: rulesFields }
          : {}
        const combinedErrors = await applyRules({
          validationArgs: {
            ...validationArgs,
            ...validationArgsInner,
            ...fields,
          },
          rules: rules,
        })
        return getErrorInformation({
          combinedErrors,
          isSubmit: validationArgsInner.isSubmit ?? true,
          formErrorsPre: validationArgs.errors,
        })
      },
    })
}

export const CertificateCreateForm: React.FC<CertificateCreateProps> = (
  props
) => {
  const {
      afterCreate,
      onSubmitError,
      injectedValues,
      multiInstance = false,
      formVersion,
      configpath,
      onCancel,
      updateAfterCreateQueries,
      beforeRemoteMuatation,
      onUpdateDataMiddleWare,
      validateForm,
      validationRules,
      modifyProps,
      fetchOnlyId,
      validateOnLoad,
      ...params
    } = props,
    { Form: CertificateCreate } = makeNewFormMemoized({
      entity: "certificate",

      operation: "create",
      formVersion,
      configpath,
      params: multiInstance ? params : undefined,
    })

  const validationTriggerFields = React.useMemo(() => {
    return getCertificateCreateTriggerMap(props.validationRules ?? {})
  }, [props.validationRules])

  const validateFormInner = React.useMemo(() => {
    return (
      validateForm ??
      validateCertificateCreate(
        props.validationRules ?? {},
        props.setValidationCallback
      )
    )
  }, [validateForm, props.validationRules])
  return (
    <CertificateCreate
      params={params}
      onCancel={onCancel}
      injectedValues={injectedValues}
      fetchOnlyId={fetchOnlyId}
      validateOnLoad={validateOnLoad}
      afterCreate={afterCreate}
      updateAfterCreateQueries={updateAfterCreateQueries}
      beforeRemoteMuatation={beforeRemoteMuatation}
      onSubmitError={onSubmitError}
      onUpdateDataMiddleWare={onUpdateDataMiddleWare}
      validateForm={validateFormInner}
      validationTriggerFields={validationTriggerFields}
      modifyProps={modifyProps}
    />
  )
}
export const getCertificateCreateFormAtom = ({
  formVersion,
}: {
  formVersion?: string
}) => {
  return makeFormState<
    SchemaTypes.CertificateInputCreate & CertificateCreateAdditionalFields
  >(`certificate.create.${formVersion ?? "active"}.current`)
}
export const getCertificateCreateFieldSelector = ({
  formVersion,
}: {
  formVersion?: string
}) => {
  const formAtom = makeFormState<
    SchemaTypes.CertificateInputCreate & CertificateCreateAdditionalFields
  >(`certificate.create.${formVersion ?? "active"}.current`)
  return function d<
    K extends keyof (SchemaTypes.CertificateInputCreate &
      CertificateCreateAdditionalFields)
  >(key: K) {
    return connectField<
      SchemaTypes.CertificateInputCreate & CertificateCreateAdditionalFields,
      K
    >(formAtom)(key)
  }
}

export type CertificateUpdateCustomRender<K extends keyof FormTypes> =
  CustomRenderExternal<
    K,
    SchemaTypes.CertificateInputUpdate & CertificateUpdateAdditionalFields
  >
export type CertificateUpdateAdditionalFields = {
  __aa?: FieldTypes["integer"]
  _test?: FieldTypes["test"]
  __certificateToReplaceId?: FieldTypes["string"]
  __modifiedByUser?: FieldTypes["decimal"]
  __role?: FieldTypes["string"]
  __unitsWithErrors?: FieldTypes["integer"]
  __normList?: FieldTypes["string"]
  __fileUploadList?: FieldTypes["string"]
  __softwareVersion?: FieldTypes["string"]
  __unitsWithHighMaxPowerCount?: FieldTypes["integer"]
  __resetToRemoteValues?: FieldTypes["boolean"]
  __unitsModifiedByUser?: FieldTypes["decimal"]
}
export type CertificateUpdateOnUpdateDataMiddleWare =
  OnUpdateDataMiddleWare<SchemaTypes.CertificateInputUpdate>
export type CertificateUpdateOnUpdateDataMiddleWareProps =
  OnUpdateDataMiddleWareProps<
    SchemaTypes.CertificateInputUpdate & CertificateUpdateAdditionalFields
  >
export type CertificateUpdateBeforeRemoteMuatation = BeforeRemoteMuatation<
  SchemaTypes.CertificateInputUpdate & CertificateUpdateAdditionalFields,
  SchemaTypes.CertificateInputUpdateProps["injectedValues"] &
    CertificateUpdateAdditionalFields
>
export type CertificateUpdateBeforeRemoteMuatationProps =
  BeforeRemoteMuatationProps<
    SchemaTypes.CertificateInputUpdate & CertificateUpdateAdditionalFields,
    SchemaTypes.CertificateInputUpdateProps["injectedValues"] &
      CertificateUpdateAdditionalFields
  >
export type CertificateUpdateValidateForm = ValidateForm<
  SchemaTypes.CertificateInputUpdate & CertificateUpdateAdditionalFields,
  SchemaTypes.CertificateInputUpdateProps["injectedValues"] &
    CertificateUpdateAdditionalFields
>
export type CertificateUpdateValidateFormProps = ValidateFormProps<
  SchemaTypes.CertificateInputUpdate & CertificateUpdateAdditionalFields,
  SchemaTypes.CertificateInputUpdateProps["injectedValues"] &
    CertificateUpdateAdditionalFields
>
export type CertificateUpdateRules = Rules<
  SchemaTypes.CertificateInputUpdate & CertificateUpdateAdditionalFields,
  ValidateFormPropsInner<
    SchemaTypes.CertificateInputUpdate & CertificateUpdateAdditionalFields,
    SchemaTypes.CertificateInputUpdateProps["injectedValues"] &
      CertificateUpdateAdditionalFields
  >
>
export type CertificateUpdateSetValidationCallback = SetValidationCallback<
  SchemaTypes.CertificateInputUpdate & CertificateUpdateAdditionalFields,
  SchemaTypes.CertificateInputUpdateProps["injectedValues"] &
    CertificateUpdateAdditionalFields
>
export type CertificateUpdateRegisterValidationCallbackProps =
  RegisterValidationCallbackProps<
    SchemaTypes.CertificateInputUpdate & CertificateUpdateAdditionalFields,
    SchemaTypes.CertificateInputUpdateProps["injectedValues"] &
      CertificateUpdateAdditionalFields,
    ValidateFormPropsInner<
      SchemaTypes.CertificateInputUpdate & CertificateUpdateAdditionalFields,
      SchemaTypes.CertificateInputUpdateProps["injectedValues"] &
        CertificateUpdateAdditionalFields
    >
  >
export type CertificateUpdateValidationCallBackArgs = ValidationCallBackArgs<
  SchemaTypes.CertificateInputUpdate & CertificateUpdateAdditionalFields,
  SchemaTypes.CertificateInputUpdateProps["injectedValues"] &
    CertificateUpdateAdditionalFields
>
export type CertificateUpdateValidationArgs = Partial<
  ValidateFormPropsInner<
    SchemaTypes.CertificateInputUpdate & CertificateUpdateAdditionalFields,
    SchemaTypes.CertificateInputUpdateProps["injectedValues"] &
      CertificateUpdateAdditionalFields
  >
>

export interface CertificateUpdateProps
  extends SchemaTypes.CertificateInputUpdateProps,
    CertificateUpdateAdditionalFields {
  id: string
  injectedValues?: Omit<
    SchemaTypes.CertificateInputUpdate & CertificateUpdateAdditionalFields,
    "id"
  >
  multiInstance?: boolean
  formVersion?: string
  configpath?: string
  onCancel?: () => void
  modifyProps?: ModifyProps<
    SchemaTypes.CertificateInputUpdate & CertificateUpdateAdditionalFields
  >
  afterUpdate?: (
    data: SchemaTypes.CertificateInputUpdate & CertificateUpdateAdditionalFields
  ) => void
  onUpdateDataMiddleWare?: OnUpdateDataMiddleWare<
    SchemaTypes.CertificateInputUpdate & CertificateUpdateAdditionalFields
  >
  beforeRemoteMuatation?: BeforeRemoteMuatation<
    SchemaTypes.CertificateInputUpdate & CertificateUpdateAdditionalFields,
    SchemaTypes.CertificateInputUpdateProps["injectedValues"] &
      CertificateUpdateAdditionalFields
  >
  validateForm?: ValidateForm<
    SchemaTypes.CertificateInputUpdate & CertificateUpdateAdditionalFields,
    SchemaTypes.CertificateInputUpdateProps["injectedValues"] &
      CertificateUpdateAdditionalFields
  >
  validationRules?: CertificateUpdateRules
  fetchAllFieldsOnUpdate?: boolean
  fetchOnlyId?: boolean
  validateOnLoad?: boolean
  onSubmitError?: (
    data: SchemaTypes.CertificateInputUpdate &
      CertificateUpdateAdditionalFields & { id: string }
  ) => void
  setValidationCallback?: CertificateUpdateSetValidationCallback
}

const getCertificateUpdateTriggerMap = (rulesSet: CertificateUpdateRules) => {
  return extractTriggerFieldsFromRules<
    SchemaTypes.CertificateInputUpdate & CertificateUpdateAdditionalFields,
    ValidateFormPropsInner<
      SchemaTypes.CertificateInputUpdate & CertificateUpdateAdditionalFields,
      any
    >
  >(rulesSet)
}

export const validateCertificateUpdate =
  (
    rules: CertificateUpdateRules,
    setValidationCallback?: CertificateUpdateSetValidationCallback
  ): CertificateUpdateValidateForm =>
  ({ applyRules, ...validationArgs }) => {
    registerValidationCallbackCertificateUpdate({
      setValidationCallback,
      rules,
      applyRules,
      ...validationArgs,
    })
    return applyRules({
      validationArgs,
      rules: rules,
    })
  }

export function registerValidationCallbackCertificateUpdate({
  setValidationCallback,
  rules,
  applyRules,
  ...validationArgs
}: CertificateUpdateRegisterValidationCallbackProps) {
  if (setValidationCallback)
    setValidationCallback({
      validation: async (validationArgsInner, fullValidation = true) => {
        const rulesFields = Object.keys(rules)
        const fields = fullValidation
          ? { changedFields: rulesFields, fieldsToValidate: rulesFields }
          : {}
        const combinedErrors = await applyRules({
          validationArgs: {
            ...validationArgs,
            ...validationArgsInner,
            ...fields,
          },
          rules: rules,
        })
        return getErrorInformation({
          combinedErrors,
          isSubmit: validationArgsInner.isSubmit ?? true,
          formErrorsPre: validationArgs.errors,
        })
      },
    })
}

export const CertificateUpdateForm: React.FC<CertificateUpdateProps> = (
  props
) => {
  const {
      afterUpdate,
      injectedValues,
      multiInstance = false,
      onSubmitError,
      formVersion,
      configpath,
      onCancel,
      fetchAllFieldsOnUpdate,
      beforeRemoteMuatation,
      onUpdateDataMiddleWare,
      validateForm,
      validationRules,
      modifyProps,
      fetchOnlyId,
      validateOnLoad,
      ...params
    } = props,
    { Form: CertificateUpdate } = makeNewFormMemoized({
      entity: "certificate",

      operation: "update",
      formVersion,
      configpath,
      params: multiInstance ? params : undefined,
    })

  const validationTriggerFields = React.useMemo(() => {
    return getCertificateUpdateTriggerMap(props.validationRules ?? {})
  }, [props.validationRules])

  const validateFormInner = React.useMemo(() => {
    return (
      validateForm ??
      validateCertificateUpdate(
        props.validationRules ?? {},
        props.setValidationCallback
      )
    )
  }, [validateForm, props.validationRules])
  return (
    <CertificateUpdate
      params={params}
      onCancel={onCancel}
      injectedValues={injectedValues}
      fetchOnlyId={fetchOnlyId}
      validateOnLoad={validateOnLoad}
      afterUpdate={afterUpdate}
      fetchAllFieldsOnUpdate={fetchAllFieldsOnUpdate}
      beforeRemoteMuatation={beforeRemoteMuatation}
      onSubmitError={onSubmitError}
      onUpdateDataMiddleWare={onUpdateDataMiddleWare}
      validateForm={validateFormInner}
      validationTriggerFields={validationTriggerFields}
      modifyProps={modifyProps}
    />
  )
}
export const getCertificateUpdateFormAtom = ({
  id,
  formVersion,
}: {
  id: string
  formVersion?: string
}) => {
  return makeFormState<
    SchemaTypes.CertificateInputUpdate & CertificateUpdateAdditionalFields
  >(`certificate.update.${formVersion ?? "active"}.${id}`)
}
export const getCertificateUpdateFieldSelector = ({
  id,
  formVersion,
}: {
  id: string
  formVersion?: string
}) => {
  const formAtom = makeFormState<
    SchemaTypes.CertificateInputUpdate & CertificateUpdateAdditionalFields
  >(`certificate.update.${formVersion ?? "active"}.${id}`)
  return function d<
    K extends keyof (SchemaTypes.CertificateInputUpdate &
      CertificateUpdateAdditionalFields)
  >(key: K) {
    return connectField<
      SchemaTypes.CertificateInputUpdate & CertificateUpdateAdditionalFields,
      K
    >(formAtom)(key)
  }
}
