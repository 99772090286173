import { QqlFormGenConfig } from "../types";
import { AdditonalFields } from "../types";

export const getAddtitonalFields = ({
  path,
  config,
}: {
  path: string | null;
  config: QqlFormGenConfig<{}>;
}): AdditonalFields<{}> => {
  if (!path) return {};
  const [_, entity, operation] = path.split("/");

  const additionalFields =
    config.forms.find((form) => form.entity === entity)?.operations[operation as "update" | "create"]
      ?.additionalFields ?? {};
  return { ...config.additionalFields, ...additionalFields };
};

export const getAddtitonalFieldNames = ({
  path,
  config,
}: {
  path: string | null;
  config: QqlFormGenConfig<{}>;
}): string[] => {
  return Object.keys(getAddtitonalFields({ path, config }));
};
