import { Box, styled } from "@mui/material";
import { RangeSliderProps } from "../../State/manageFiltersInUnitsOverview";

export const StyledRangeSliderRoot = styled(Box, { label: "StyledRangeSliderRoot" })(
  ({ filterType }: { filterType: keyof RangeSliderProps }) => {
    {
      return filterType !== "maxActivePower"
        ? {
            display: "flex",
            postion: "relative",
            padding: " 0.5rem",
          }
        : { display: "flex", postion: "relative", padding: " 0.5rem", width: "60rem", height: "8rem" };
    }
  }
);

export const StyledRangeSliderBox = styled(Box, { label: "StyledRangeSliderBox" })(
  ({ filterType }: { filterType: keyof RangeSliderProps }) => {
    {
      return filterType !== "maxActivePower"
        ? { width: "100%" }
        : {
            display: "flex",
            width: "100%",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          };
    }
  }
);

export const StyledIconButtonWrapper = styled(Box, { label: "StyledIconButtonWrapper" })(() => ({
  position: "absolute",
  right: "6px",
  bottom: "50px",
  paddingLeft: "15px",
}));

export const StyledResetFilterButtonWrapper = styled(Box, { label: "StyledResetFilterButtonWrapper" })(() => ({
  display: "flex",
  justifyContent: "center",
}));
