import { Container } from "@mui/system";
import React from "react";
import Lottie from "lottie-react";
import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import ordinaryColors from "themes/ordinaryColors";
import { translations } from "translations";

export const StyledAnimationsContainer = styled(Container)({
  width: "500px",
  height: "500px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  position: "relative",
});
export const StyledAnimationsBox = styled(Box, {
  label: "StyledAnimationsBox",
  shouldForwardProp(propName) {
    return propName !== "positionStatic";
  },
})<{ positionStatic?: boolean }>(({ positionStatic }) => ({
  top: "75%",
  display: "flex",
  justifyContent: "center",
  position: positionStatic ? "static" : "absolute",
  // margin: "auto",
  flexDirection: "column",
  alignItems: "center",
  gap: "1rem",
  marginRight: "33px",
}));

export const StyledAnimationsTitle = styled(Typography)({
  textAlign: "center",
  fontFeatureSettings: "'clig' off, 'liga' off",
  fontSize: "24px",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "133.4% /* 32.016px */",
  color: ordinaryColors.background,
});

export const StyledAnimationsDescription = styled(Typography)({
  textAlign: "center",
  fontSize: "18px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "143% /* 25.74px */",
  letterSpacing: "0.17px",
  color: ordinaryColors.animationDescription,
});

export const StyledAnimationsButton = styled(Button)({
  display: "flex",
  padding: "8px 22px",
  alignItems: "flex-start",
  gap: "8px",
  borderRadius: "0px",
  background: ordinaryColors.primaryBrand,
  color: ordinaryColors.white,
  fontSize: "15px",
  boxShadow:
    "0px 1px 18px 0px rgba(0, 0, 0, 0.12), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 3px 5px -1px rgba(0, 0, 0, 0.20)",
  "&:hover": {
    background: ordinaryColors.primaryBrand,
  },
});
export type AnimationProps = {
  animationData: unknown;
  title?: string;
  description?: string;
  buttonText?: string;
  buttonLink?: string;
  positionStatic?: boolean;
  style?: object;
};
const Animations: React.FC<AnimationProps> = ({
  animationData,
  title,
  description,
  buttonText,
  buttonLink,
  positionStatic,
  style,
}) => {
  const navigate = useNavigate();
  return (
    <StyledAnimationsContainer>
      <Lottie animationData={animationData} style={style} />
      <StyledAnimationsBox positionStatic={positionStatic}>
        <StyledAnimationsTitle>{title}</StyledAnimationsTitle>
        {description && <StyledAnimationsDescription>{description}</StyledAnimationsDescription>}
        {buttonLink && buttonText && (
          <StyledAnimationsButton
            aria-label={translations.globals.button.linkToHome}
            onClick={() => navigate(buttonLink as string)}
          >
            {buttonText}
          </StyledAnimationsButton>
        )}
      </StyledAnimationsBox>
    </StyledAnimationsContainer>
  );
};

export default Animations;
