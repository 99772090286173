// eslint-disable-next-line import/no-extraneous-dependencies
import { deepmerge } from "@mui/utils";
import { useEffect, useMemo } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { filterValueLengthAtom, openTaskListFilterAtom } from "../State/manageFiltersInOpenTaskList";

type FilterBuilderVariables = {
  openTaskFilters: object;
};

export const useOpenTaskFilterBuilder = () => {
  const filterValues = useRecoilValue(openTaskListFilterAtom);
  const setLengthOfFilterValues = useSetRecoilState(filterValueLengthAtom);
  useEffect(() => {
    const filterValueLength = Object.entries(filterValues).reduce((acc, [key, value]) => {
      if (value.length > 0) {
        return ++acc;
      }
      return acc;
    }, 0);
    setLengthOfFilterValues(filterValueLength);
  }, [filterValues, setLengthOfFilterValues]);
  const filterValuesForCustomizedFilters = useMemo(
    () =>
      Object.entries(filterValues).reduce<FilterBuilderVariables>(
        (openTaskFilters, [key, filterValue]) => {
          if (!filterValue) return openTaskFilters;
          if (filterValue.length > 0 && key === "issueType") {
            const issueTypeFilter = {
              issueTypeId: {
                in: filterValue,
              },
            };
            const nextFilter = deepmerge(openTaskFilters, { openTaskFilters: issueTypeFilter });
            return nextFilter;
          }
          if (filterValue.length > 0 && key === "manufacturer") {
            const manufacturerFilter = {
              certificate: {
                certificateHolderId: {
                  in: filterValue,
                },
              },
            };
            const nextFilter = deepmerge(openTaskFilters, { openTaskFilters: manufacturerFilter });
            return nextFilter;
          }
          if (filterValue.length > 0 && key === "createdAtRange") {
            const minDate = filterValue[0];
            const maxDate = filterValue[1];

            const createdAtRangeFilter = {
              createdAt: {
                gte: minDate,
                lte: maxDate,
              },
            };
            const createdAtRangeFilterMinDate = {
              createdAt: {
                gte: minDate,
              },
            };
            const createdAtRangeFilterMaxDate = {
              createdAt: {
                lte: maxDate,
              },
            };

            const createAtRangeFilterValues = (() => {
              if (minDate && maxDate) return createdAtRangeFilter;
              if (!minDate && maxDate) return createdAtRangeFilterMaxDate;
              if (minDate && !maxDate) return createdAtRangeFilterMinDate;
              if (!minDate && !maxDate) return {};
            })();
            const nextFilter = deepmerge(openTaskFilters, { openTaskFilters: createAtRangeFilterValues });
            return nextFilter;
          }
          return openTaskFilters;
        },
        { openTaskFilters: {} }
      ),
    [filterValues]
  );
  return filterValuesForCustomizedFilters;
};
