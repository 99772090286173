import { Box, SxProps, Theme } from "@mui/material";
import React from "react";
import { SetterOrUpdater } from "recoil";
import { AccordionStates } from "../Accordion/recoil";
import AccordionStateButton from "./AccordionStateButton";

export interface AccordionStateButtonsProps {
  accordionStates: AccordionStates;
  boxSx?: SxProps<Theme>;
  expandLabel?: string;
  collapseLabel?: string;
  setDisabled: (accordionStates: AccordionStates, buttonType: "expand" | "collapse") => boolean;
  setState: SetterOrUpdater<AccordionStates>;
}

const AccordionStateButtons: React.FC<AccordionStateButtonsProps> = (props) => {
  return (
    <Box sx={props.boxSx}>
      <AccordionStateButton style={{}} buttonType="expand" {...props} />
      <AccordionStateButton style={{}} buttonType="collapse" {...props} />
    </Box>
  );
};

export default AccordionStateButtons;
