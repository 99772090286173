import type * as Types from '../../types';

import client from "apollo/client";
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetCertificateNormsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetCertificateNormsQuery = { __typename?: 'Query', certificateNorms?: { __typename?: 'CertificateNormCollectionSegment', items?: Array<{ __typename?: 'CertificateNorm', key: string, value: string }> | null } | null };


        export type ExecGetCertificateNormsOptions = Omit<
        Apollo.QueryOptions<
        GetCertificateNormsQueryVariables, GetCertificateNormsQuery
        >,
        "query"
      >;
    export const execGetCertificateNormsQuery = (options: ExecGetCertificateNormsOptions) =>
    client.query< GetCertificateNormsQuery, GetCertificateNormsQueryVariables>({
      ...options,
      query: GetCertificateNormsDocument,
    });
    

export const GetCertificateNormsDocument = gql`
    query getCertificateNorms {
  certificateNorms {
    items {
      key: id
      value: certificateNormName
    }
  }
}
    `;

/**
 * __useGetCertificateNormsQuery__
 *
 * To run a query within a React component, call `useGetCertificateNormsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCertificateNormsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCertificateNormsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCertificateNormsQuery(baseOptions?: Apollo.QueryHookOptions<GetCertificateNormsQuery, GetCertificateNormsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCertificateNormsQuery, GetCertificateNormsQueryVariables>(GetCertificateNormsDocument, options);
      }
export function useGetCertificateNormsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCertificateNormsQuery, GetCertificateNormsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCertificateNormsQuery, GetCertificateNormsQueryVariables>(GetCertificateNormsDocument, options);
        }
export type GetCertificateNormsQueryHookResult = ReturnType<typeof useGetCertificateNormsQuery>;
export type GetCertificateNormsLazyQueryHookResult = ReturnType<typeof useGetCertificateNormsLazyQuery>;
export type GetCertificateNormsQueryResult = Apollo.QueryResult<GetCertificateNormsQuery, GetCertificateNormsQueryVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
          export type ResultType<T> = Types.ResultType<T>;
          export type PaginatedResultType<T> = Types.PaginatedResultType<T>;
          export type NonMaybe<T> = Types.NonMaybe<T>;
          export type Maybe<T> = Types.Maybe<T>;