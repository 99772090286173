import { useCallback, useMemo, useState } from "react";
import { ErrorResult, RuleResult } from "./types";
import { type } from "os";

export type ErrorBuilderArgs = {
  errorJoiner?: (val: React.ReactNode[]) => React.ReactNode;
  helperText?: string;
  preserveHelperText?: boolean;
};

export type ErroManagerArgs = {
  setHasFocus: (val: boolean) => void;
  errors: RuleResult[] | undefined;
  errorJoiner?: (val: React.ReactNode[]) => React.ReactNode;
};
export type ErrorBuilder = (vals?: ErrorBuilderArgs) => {
  helperText?: React.ReactNode;
  error: boolean;
  onBlurCapture: () => void;
  onFocusCapture: () => void;
};

const errorManager = ({ setHasFocus, errors, errorJoiner: configErrorJoiner }: ErroManagerArgs): ErrorBuilder => {
  const defaultErorJoiner = configErrorJoiner || ((val: React.ReactNode[]) => val.join(", "));
  const errorBuilder = useCallback(
    (vals?: ErrorBuilderArgs) => {
      const onFocusCapture = () => {
        setHasFocus(true);
      };
      const onBlurCapture = () => {
        setHasFocus(false);
      };

      const defaultErrorBuilderResult = {
        errorMessage: undefined,
        error: false,
        onBlurCapture,
        onFocusCapture,
        helperText: vals?.helperText ?? "",
      };

      const { errorJoiner = defaultErorJoiner, helperText, preserveHelperText } = vals || {};
      if ((errors ?? []).length === 0) return defaultErrorBuilderResult;
      const messages = (errors ?? []).reduce<React.ReactNode[]>(
        (acc, error) => {
          if (!error) return acc;
          return acc.concat(error.message);
        },
        helperText && preserveHelperText ? [helperText] : []
      );

      const errorMessage = errorJoiner(messages);

      return { error: true, onBlurCapture, onFocusCapture, helperText: errorMessage };
    },
    [errors]
  );

  return errorBuilder;
};
export default errorManager;
