import { Box, styled } from "@mui/material";
import ordinaryColors from "themes/ordinaryColors";

export const StyledMigrationNoteContainer = styled(Box, { label: "StyledMigrationNoteContainer" })(() => ({
  display: "flex",
  flexDirection: "column",
  paddingTop: "18px",
  paddingBottom: "48px",
  background: ordinaryColors.functionalLightGreyBack,
}));

export const StyledMigrationNoteRoot = styled(Box, { label: "StyledMigrationNoteRoot" })(() => ({
  background: ordinaryColors.functionalLightGreyBack,
}));

export const StyledMigrationNoteContent = styled(Box, { label: "StyledMigrationNoteContent" })(
  ({ background }: { background?: string }) => ({
    display: "flex",
    padding: "16px",
    alignItems: "center",
    alignSelf: "stretch",
    justifyContent: "space-between",
    background: background ?? ordinaryColors.warning50,
  })
);

export const StyledMigrationNoteInfo = styled(Box, { label: "StyledMigrationNoteInfo" })(() => ({
  display: "flex",
  alignItems: "center",
  alignSelf: "stretch",
}));

export const StyledCloseIconBox = styled(Box, { label: "StyledCloseIconBox" })(() => ({
  display: "flex",
  cursor: "pointer",
}));

export const StyledTextBox = styled(Box, { label: "StyledTextBox" })(() => ({
  color: ordinaryColors.grey900,
  fontFeatureSettings: "'clig' off, 'liga' off",
  fontSize: "18px",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "157% /* 28.26px */",
  letterSpacing: "0.1px",
  marginLeft: "16px",
}));
