import { FormControl, Switch, Typography } from "@mui/material";
import { useGetUserInfo } from "lib/userInfo";
import React from "react";
import { useRecoilState } from "recoil";
import {
  ToggleStringFiltersProps,
  toggleUploadedByFilterSelectorFamily,
} from "../../State/manageFiltersInUnitsOverview";
import { StyledToggleBox } from "../styles";

interface ToggleStringProps {
  filterType: keyof ToggleStringFiltersProps;
  title: string;
}

const ToggleOwnCertificatesAndSubscribed: React.FC<ToggleStringProps> = ({ filterType, title }) => {
  const userInfo = useGetUserInfo();
  const tenantId = userInfo?.tenantId;
  const userId = userInfo?.id;

  const [filters, setFilters] = useRecoilState(toggleUploadedByFilterSelectorFamily(filterType));
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilters(event.target.checked ? (filterType === "tenantId" ? tenantId : userId) ?? " " : "");
  };

  return (
    <>
      <FormControl sx={{ m: 1 }} size="small" aria-label={filterType}>
        <StyledToggleBox>
          <Typography sx={{ paddingLeft: "10px" }}>{title}</Typography>
          <Switch
            aria-label={filterType}
            checked={!!filters}
            onChange={handleChange}
            inputProps={{ "aria-label": "controlled" }}
          />
        </StyledToggleBox>
      </FormControl>
    </>
  );
};

export default ToggleOwnCertificatesAndSubscribed;
