import {
  Checkbox,
  Divider,
  FormControl,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import ResetFilterButton from "components/molecules/ResetFilterButton";
import translations from "translations";

import { getTranslatedIssueTypeString } from "lib/helper/getTranslatedRowDataStrings";
import TitleWithFilterCounter from "pages/CertificationManagement/CustomDataGridFilters/FilterBar/TitleWithFilterCounter";
import { useRecoilState, useRecoilValue } from "recoil";
import { issueTypeNamesAtom, tenantNameAtom } from "../FilterData/FilterDataLoader";
import {
  queryFilterOpenTaskSelectorFamily,
  QueryMultiSelectOpenTaskFilterProps,
} from "../State/manageFiltersInOpenTaskList";
interface EnumProps {
  filterType: keyof QueryMultiSelectOpenTaskFilterProps;
  title: string;
}

const OpenTaskDropdownFilterMultiSelect: React.FC<EnumProps> = ({ filterType, title }) => {
  const issueTypeOptions = useRecoilValue(issueTypeNamesAtom);
  const manufacturerOptions = useRecoilValue(tenantNameAtom);
  const queryOptions = filterType === "issueType" ? issueTypeOptions : manufacturerOptions;
  const [filters, setFilters] = useRecoilState(queryFilterOpenTaskSelectorFamily(filterType));
  const options = (queryOptions ?? []).map<{ key: string; value: string; label: string }>((entry) => {
    return {
      key: entry.key,
      value: entry.key,
      label: filterType === "issueType" ? getTranslatedIssueTypeString(entry.key) : entry.value,
    };
  });
  const resetFilters = () => setFilters([]);
  const handleChange = (event: SelectChangeEvent<string[]>) => {
    const value = event.target.value;
    setFilters(typeof value === "string" ? value.split(",") : value);
  };

  return (
    <FormControl
      sx={{
        m: 1,

        margin: "none",
        width: "100%",
      }}
      size="small"
      aria-label="TaskType Dropdown"
    >
      <Select
        aria-label="TaskType Dropdown"
        labelId="filterType"
        id="filterType"
        multiple
        displayEmpty
        sx={{ borderRadius: "0px" }}
        value={filters}
        onChange={handleChange}
        input={<OutlinedInput />}
        renderValue={(selected) => {
          if (selected.length === 0 || selected === undefined) {
            return <Typography>{title}</Typography>;
          }
          return <TitleWithFilterCounter selectedLength={selected.length} title={title} flexStart />;
        }}
      >
        {options.map(({ label, value }) => (
          <MenuItem key={value} value={value} sx={{ borderRadius: "0px" }}>
            <Checkbox checked={filters.includes(value)} />
            <ListItemText primary={label} />
          </MenuItem>
        ))}
        <Divider />
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <ResetFilterButton title={translations.globals.button.reset} resetFilters={resetFilters} />
        </Box>
      </Select>
    </FormControl>
  );
};

export default OpenTaskDropdownFilterMultiSelect;
