/* eslint-disable filenames/match-exported */
import { Button } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import {
  GridRow,
  GridRowId,
  GridRowParams,
  GridRowProps,
  GridRowsProp,
  GridSelectionModel,
} from "@mui/x-data-grid-pro";
import LoadingSpinner from "components/atomics/LoadingSpinner";
import { EntityMappings } from "graphqlBase/__utils__/entityMappings";
import React, { useCallback, useMemo } from "react";
import theme from "themes/useCustomTheme";
import useServerSide from "./serverSide";
import { BaseRow, Column, GridWrapperProps, UseMakeDataGridProps } from "./serverSide/types";
import { StyledDataGrid, tableComponentStyles, TableContainerSC } from "./styles";
import NoDataOverlay from "./Table/NoDataOverlay";
import CustomToolbar, { CustomToolbarProps } from "./Toolbars/CustomToolbar";

interface DataGridComponentProps<R extends BaseRow, E extends keyof EntityMappings> extends GridWrapperProps<R, E> {
  checkboxSelection?: boolean;
  handleDeleteRows?: (ids: string[]) => void;
  handleEditRow?: (id: string) => void;
  handleOnRowClick?: (params: GridRowParams) => void;
  hasPagination?: boolean;
  hasToolbar?: boolean;
  loading?: boolean;
  noDataMessage?: string;
  selectedRows?: GridRowId[] | undefined;
  setIsTagModalOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedRows?: (rows: GridRowsProp) => void;
  tableHeight: string;
  toolbarBackgroundColor?: string;
  tableTitle: string;
  withMargin?: boolean;
  onRowHover?: (params: GridRowProps) => void;
  customToolBarProps?: CustomToolbarProps;
  pageSize?: number;
  rowsPerPageOptions?: number[];
  rowHeight?: number;
  headerHeight?: number;
  noResultsAnimation?: boolean;
  showMigrationNote?: boolean;
  showReducedFunctionalityNote?: boolean;
}

const useDataGrid = <R extends BaseRow, E extends keyof EntityMappings>(
  config: Omit<UseMakeDataGridProps<R, E>, "defaultProps">
) => {
  const serverSide = useServerSide<R, E>({ ...config, DataGridComponent: StyledDataGrid });
  const ExtendedDataGrid: React.FC<DataGridComponentProps<R, E>> = useMemo(
    () =>
      // eslint-disable-next-line react/display-name
      ({
        rows,
        columns,
        checkboxSelection = true,
        handleOnRowClick,
        selectedRows,
        hasPagination = true,
        hasToolbar = true,
        setIsTagModalOpen,
        setSelectedRows,
        tableTitle,
        noDataMessage,
        handleDeleteRows,
        handleEditRow,
        loading,
        rowsPerPageOptions = [20, 40, 60],
        toolbarBackgroundColor,
        rowHeight = 40,
        headerHeight = 50,
        pageSize = 20,
        tableHeight,
        withMargin = true,
        onRowHover,
        customToolBarProps,
        density = "comfortable",
        apiRef,
        initialState,
        noResultsAnimation,
        showMigrationNote,
        showReducedFunctionalityNote,
        ...rest
      }) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const FilterIcon = () => {
          return <Button>Filter</Button>;
        };
        // const RemoveIcon = () => {
        //   return <Button>X</Button>;
        // };

        // eslint-disable-next-line react-hooks/rules-of-hooks
        const Toolbar = useCallback(() => {
          const hasFilters = !!apiRef?.current?.state?.filter.filterModel.items.filter((item) => !!item.value).length;
          const { deps, ...customToolBarPropsInner } = customToolBarProps ?? { deps: [] };
          return (
            <CustomToolbar hasFilters={hasFilters} showMigrationNote={showMigrationNote} {...customToolBarPropsInner} />
          );
          // eslint-disable-next-line react-hooks/exhaustive-deps
        }, customToolBarProps?.deps ?? []);

        const NoDataComponent = () => (
          <NoDataOverlay message={noDataMessage ?? ""} noResultsAnimation={noResultsAnimation} />
        );

        const customComponents = {
          Toolbar: hasToolbar ? Toolbar : null,
          //BaseCheckbox: CheckboxCell,
          // OpenFilterButtonIcon: FilterIcon,
          // QuickFilterIcon: QuickFilterIcon,
          // Footer: hasPagination ? FooterWithPagination : NoPaginationFooter,
          // FilterPanelDeleteIcon: RemoveIcon,
          NoResultsOverlay: NoDataComponent,
          NoRowsOverlay: NoDataComponent,
          LoadingOverlay: () => <LoadingSpinner loading={loading ?? false} />,
          Row: (props: GridRowProps) => (
            <div onMouseEnter={() => onRowHover?.(props)}>
              <GridRow {...props} />
            </div>
          ),
        };

        const customComponentProps = {
          toolbar: { showQuickFilter: true, quickFilterProps: { debounceMs: 1000 } },
          filterProps: { debounceMs: 1000 },
          filterPanel: {
            filterFormProps: {
              linkOperatorInputProps: {
                variant: "outlined",
                size: "small",
              },
              columnInputProps: {
                variant: "outlined",
                size: "small",
                sx: { mt: "auto" },
              },
              operatorInputProps: {
                variant: "outlined",
                size: "small",
                sx: { mt: "auto" },
              },
              valueInputProps: {
                variant: "outlined",
                size: "small",
                sx: { mt: "auto" },
              },
            },
            sx: tableComponentStyles,
          },
        };

        const handleSetSelectedRows = (ids: GridSelectionModel) => {
          const selectedIDs = new Set(ids);
          const selectedRowData: GridRowsProp = (rows ?? []).reduce<GridRowsProp>((acc: GridRowsProp, curr) => {
            if (selectedIDs.has(curr.id.toString())) {
              return acc.concat(curr);
            } else {
              return acc;
            }
          }, []);
          setSelectedRows?.(selectedRowData);
        };

        return (
          <ThemeProvider theme={theme()}>
            <TableContainerSC withMargin={withMargin}>
              {/* <StyledContainer props={{ height: tableHeight }}> */}
              <serverSide.DataGrid
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                //@ts-ignore
                props={{ height: tableHeight, toolbarBackgroundColor: toolbarBackgroundColor }}
                rows={rows}
                columns={columns as unknown as Column<R, E>[]}
                checkboxSelection={checkboxSelection}
                rowHeight={rowHeight}
                toolbarBackgroundColor={toolbarBackgroundColor}
                headerHeight={headerHeight}
                pagination
                rowsPerPageOptions={rowsPerPageOptions}
                components={customComponents}
                componentsProps={customComponentProps}
                onRowClick={handleOnRowClick}
                disableSelectionOnClick
                onSelectionModelChange={handleSetSelectedRows}
                loading={loading}
                selectionModel={selectedRows}
                density="comfortable"
                initialState={{
                  ...initialState,
                  pagination: { pageSize },
                }}
                {...rest}
              />
              {/* </StyledContainer> */}
            </TableContainerSC>
          </ThemeProvider>
        );
      },
    [serverSide]
  );
  return { ...serverSide, DataGrid: ExtendedDataGrid };
};
export default useDataGrid;
