import certificateUpload from "./certificateUpload/de";
import certificateValidation from "./certificateValidation/de";
import certificationManagement from "./certificationManagement/de";
import configurator from "./configurator/de";
import development from "./development/de";
import home from "./home/de";
import landingPage from "./landingPage/de";
import userManagement from "./userManagement/de";
import userProfile from "./userProfile/de";
import privacyPolicy from "./privacyPolicy/de";
import imprint from "./imprint/de";
import faqPage from "./faqPage/de";
import otherDocumentsPage from "./otherDocuments/de";
import userMenu from "./userMenu/de";
import accessPermissionManagement from "./accessPermissionManagement/de";

export default {
  certificateUpload,
  certificateValidation,
  certificationManagement,
  configurator,
  development,
  home,
  landingPage,
  userManagement,
  userProfile,
  privacyPolicy,
  imprint,
  faqPage,
  otherDocumentsPage,
  userMenu,
  accessPermissionManagement
};
