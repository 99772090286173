import type * as Types from '../../types';

import client from "apollo/client";
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetCertificateHoldersQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetCertificateHoldersQuery = { __typename?: 'Query', tenants?: { __typename?: 'TenantCollectionSegment', items?: Array<{ __typename?: 'Tenant', key: string, value: string }> | null } | null };


        export type ExecGetCertificateHoldersOptions = Omit<
        Apollo.QueryOptions<
        GetCertificateHoldersQueryVariables, GetCertificateHoldersQuery
        >,
        "query"
      >;
    export const execGetCertificateHoldersQuery = (options: ExecGetCertificateHoldersOptions) =>
    client.query< GetCertificateHoldersQuery, GetCertificateHoldersQueryVariables>({
      ...options,
      query: GetCertificateHoldersDocument,
    });
    

export const GetCertificateHoldersDocument = gql`
    query getCertificateHolders {
  tenants(where: {tenantRole: {eq: MANUFACTURER}}) {
    items {
      key: id
      value: tenantName
    }
  }
}
    `;

/**
 * __useGetCertificateHoldersQuery__
 *
 * To run a query within a React component, call `useGetCertificateHoldersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCertificateHoldersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCertificateHoldersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCertificateHoldersQuery(baseOptions?: Apollo.QueryHookOptions<GetCertificateHoldersQuery, GetCertificateHoldersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCertificateHoldersQuery, GetCertificateHoldersQueryVariables>(GetCertificateHoldersDocument, options);
      }
export function useGetCertificateHoldersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCertificateHoldersQuery, GetCertificateHoldersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCertificateHoldersQuery, GetCertificateHoldersQueryVariables>(GetCertificateHoldersDocument, options);
        }
export type GetCertificateHoldersQueryHookResult = ReturnType<typeof useGetCertificateHoldersQuery>;
export type GetCertificateHoldersLazyQueryHookResult = ReturnType<typeof useGetCertificateHoldersLazyQuery>;
export type GetCertificateHoldersQueryResult = Apollo.QueryResult<GetCertificateHoldersQuery, GetCertificateHoldersQueryVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
          export type ResultType<T> = Types.ResultType<T>;
          export type PaginatedResultType<T> = Types.PaginatedResultType<T>;
          export type NonMaybe<T> = Types.NonMaybe<T>;
          export type Maybe<T> = Types.Maybe<T>;