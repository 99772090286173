import { SxProps, Theme } from "@mui/system";
import { DiscardChange } from "components/molecules/DiscardChangesWarning/recoil";
import { SetterOrUpdater } from "recoil";
import { StyledResetButton, StyledContentBox, StyledResetIcon, StyledLabel } from "./styles";

interface ResetButtonProps {
  buttonSx?: SxProps<Theme>;
  contentBoxSx?: SxProps<Theme>;
  labelSx?: SxProps<Theme>;
  disabled?: boolean;
  label?: string;
  resetIconSrc?: string;
  resetIconSx?: SxProps<Theme>;
  setState: SetterOrUpdater<DiscardChange>;
}

const ResetButton: React.FC<ResetButtonProps> = (props) => {
  if (props.disabled) props.setState("");

  return (
    <StyledResetButton buttonSx={props.buttonSx} onClick={() => props.setState("reset")} disabled={props.disabled}>
      <StyledContentBox contentBoxSx={props.contentBoxSx}>
        {props.resetIconSrc && <StyledResetIcon resetIconSx={props.resetIconSx} src={props.resetIconSrc} />}
        <StyledLabel labelSx={props.labelSx}>{props.label && props.label}</StyledLabel>
      </StyledContentBox>
    </StyledResetButton>
  );
};

export default ResetButton;
