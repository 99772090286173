import styled from "@emotion/styled";
import { Divider, Typography } from "@mui/material";
import { Box } from "@mui/system";
import ordinaryColors from "themes/ordinaryColors";

export const FilterOpenTasksSC = styled(Box, {
  label: "FilterOpenTasks",
  shouldForwardProp(propName: string) {
    return propName !== "";
  },
})(({ theme }) => ({
  display: "flex",
  width: "22.5625rem",
  padding: "1.5rem 1rem",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "0.8125rem",
  background: "#FFF",
  boxShadow: "0rem 0rem 0.63125rem 0rem rgba(0, 0, 0, 0.15)",
}));

export const FilterOpenTasksSCTitle = styled(Typography, {
  label: "FilterOpenTasks",
  shouldForwardProp(propName) {
    return propName !== "";
  },
})(({ theme }) => ({
  alignSelf: "stretch",
  color: "#212121",
  fontFeatureSettings: "'liga' off, 'clig' off",
  fontSize: "1.125rem",
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: "1.5rem /* 133.333% */",
  letterSpacing: "0.010625rem",
}));

export const StyledLabelForDateRangeFilter = styled(Typography, {
  label: "StyledLabelForDateRangeFilter",
  shouldForwardProp(propName) {
    return propName !== "";
  },
})(({ theme }) => ({
  alignSelf: "stretch",
  color: ordinaryColors.grey900 ?? `#212121`,
  fontFeatureSettings: "'liga' off, 'clig' off",

  fontSize: "1rem",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "143% /* 1.43rem */",
  letterSpacing: "0.010625rem",
}));

export const DividerHorizontalSC = styled(Divider, {
  label: "DividerHorizontal",
  shouldForwardProp(propName) {
    return propName !== "";
  },
})(({ theme }) => ({
  height: "0.0625rem",
  alignSelf: "stretch",
  border: `0.0625rem solid var(--Light-Other-Divider, rgba(0, 0, 0, 0.12))`,
}));
