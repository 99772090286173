import styled from "@emotion/styled";
import { TextField } from "@mui/material";
import { Box } from "@mui/system";
import ordinaryColors from "themes/ordinaryColors";

export const StyledResetBox = styled(Box)(() => ({
  padding: "10px",
  backgroundColor: "white",
  justifyContent: "center",
  alignItems: "center",
  display: "flex",
  borderTop: "1px solid ",
  borderColor: ordinaryColors.functionalInputFieldStroke,
  boxShadow: "0px 5px 5px -3px rgba(0,0,0,0.2)",
}));

export const StyledAutocompleteTextField = styled(TextField)<{ hasPlaceholder?: boolean }>(
  ({ theme, hasPlaceholder }) => ({
    borderRadius: "0px",
    padding: "0px",
    "& .MuiInputLabel-root": {
      top: "-6px",
      transformOrigin: "",
      color: ordinaryColors.functionalBlack,
    },
    "& .MuiOutlinedInput-root": {
      height: "44px",
      border: "1px solid",
      borderColor: ordinaryColors.functionalInputFieldStroke,
      "&:hover": {
        borderColor: ordinaryColors.functionalBlack,
      },
      "& .MuiAutocomplete-input": {
        minWidth: "150px",
      },
      backgroundColor: "transparent",
      "& .MuiOutlinedInput-notchedOutline": {
        borderWidth: "0px",
      },
      "& input": {
        position: "absolute",
        top: "0px",
        "&::placeholder": {
          color: ordinaryColors.functionalBlack,
          opacity: hasPlaceholder ? 1 : 0.3,
        },
      },
    },
  })
);

export const StyledToggleBox = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  backgroundColor: ordinaryColors.functionalWhite,
  border: "1px solid ",
  borderColor: ordinaryColors.functionalInputFieldStroke,
  "&:hover": {
    borderColor: ordinaryColors.functionalBlack,
  },
  justifyContent: "center",
  height: "43.6px",
}));
