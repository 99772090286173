import { useGetCertificatesToValidateQuery } from "graphqlBase/issue/__generated__/getCertificatesToValidate";
import { useEffect } from "react";
import { useSetRecoilState } from "recoil";
import { useGetUserInfo } from "lib/userInfo";
import { certificatesToValidateIssueStatusCondition } from "pages/CertificateValidation/OpenTaskList";
import { openTaskListLengthAtom } from "pages/CertificateValidation/OpenTaskList/CustomOpenTaskFilters/State/manageFiltersInOpenTaskList";
const useGetOpenTasksCount = () => {
  const userInfo = useGetUserInfo();
  const { data, loading, refetch } = useGetCertificatesToValidateQuery({
    variables: {
      take: 0,
      where: {
        ...certificatesToValidateIssueStatusCondition,
        assignedToTenantId: { eq: userInfo?.tenantId },
      },
    },
    skip: !userInfo,
  });
  const setOpenTasksCount = useSetRecoilState(openTaskListLengthAtom);
  useEffect(() => {
    if (!loading && data) {
      setOpenTasksCount(data.issues?.totalCount ?? undefined);
    }
  }, [data, loading, setOpenTasksCount]);

  return { loading, refetch };
};

export default useGetOpenTasksCount;
