import { saver as saverPre } from "./saver";
import { useBridge, getNextBridgeState as getNextBridgeStatePre } from "./useBridge";
import { BridgeParams } from "./BridgeParamTypes";
import { ApolloClient } from "@apollo/client";

export interface MakeBridgeParams {
  bridgeParams: BridgeParams;
  client: ApolloClient<any>;
  updaters: (ids: string[]) => void;
  id?: string;
}
export const makeBride = ({ bridgeParams, client, updaters, id }: MakeBridgeParams) => {
  const useBrigePre = ({ id }: { id?: string }) => useBridge({ id, bridgeParams, client, updaters });
  const saver = saverPre(id);
  return {
    useBrigePre,
    saver,
  };
};
export const getNextBridgeState = getNextBridgeStatePre;
