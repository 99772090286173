import * as React from "react";
import Typography from "@mui/material/Typography";
import Props from "./index.d";

const Title: React.FC<Props> = (props) => {
  const { className } = props;
  return (
    <Typography aria-label={props.title} className={className} variant="h2">
      {props.title}
    </Typography>
  );
};

export default Title;
