import { Box, styled, Tooltip } from "@mui/material";
import MigrationNoteIcon from "components/icons/iconComponents/MigrationNoteIcon";
import translations from "translations";

export const StyledMigrationNoteTooltip = styled(Tooltip, { label: "StyledMigrationNoteTooltip" })(() => ({
  display: "flex",
}));

export const StyledMigrationNoteIconBox = styled(Box, { label: "StyledMigrationNoteIconBox" })(
  ({ entity = "unit" }: { entity: "certificate" | "unit" }) => ({
    background: "transparent",

    marginTop: entity === "unit" ? "3px" : "-2px",
  })
);

export const MigrationNoteTooltip = ({ entity }: { entity: "unit" | "certificate" }) => (
  <StyledMigrationNoteTooltip
    componentsProps={{
      tooltip: {
        sx: {
          whiteSpace: "nowrap",
          display: "flex",
          maxWidth: "fit-content",
          padding: "4px 8px",
          alignItems: "center",
          borderRadius: "4px",
          background: "rgba(97, 97, 97, 0.90)",
        },
      },
    }}
    title={
      entity === "certificate"
        ? translations.globals.notifications.migrationNoteTooltipCertificate
        : translations.globals.notifications.migrationNoteTooltipUnit
    }
    placement="top-start"
  >
    <StyledMigrationNoteIconBox entity={entity}>
      <MigrationNoteIcon width="18px" />
    </StyledMigrationNoteIconBox>
  </StyledMigrationNoteTooltip>
);
