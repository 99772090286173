import { Box, styled } from "@mui/material";
import ordinaryColors from "themes/ordinaryColors";

const StyledUnitWrapperRoot = styled(Box, { name: "StyledUnitWrapperRoot" })(({ theme }) => ({
  display: "flex",
  maxWidth: "50%",
  minWidth: "380px",
  padding: "0px 32px",
  flexDirection: "column",
  alignItems: "flex-start",
  flex: "1 0 0",
  background: ordinaryColors.functionalWhite,
  [theme.breakpoints.down("xxxl")]: {
    maxWidth: "100%",
    minWidth: "21rem",
  },
}));

const StyledUnitWrapperContent = styled(Box, { name: "StyledUnitWrapperContent" })({
  flex: "1 1 0",
  width: "100%",
  height: 42,
  paddingTop: 8,
  paddingBottom: 8,
  borderBottom: `1px ${ordinaryColors.functionalInputFieldStroke} solid`,
  justifyContent: "space-between",
  alignItems: "center",
  display: "flex",
  "@media (max-width: 1200px)": {
    alignItems: "flex-start",
    flexDirection: "column",
    height: "auto",
    borderBottom: "0px",
  },
});

const StyledUnitWrapperTitle = styled("div", { name: "StyledUnitWrapperTitle" })({
  width: "355.063px",
  color: ordinaryColors.functionalDarkGrey,
  fontFeatureSettings: "'clig' off, 'liga' off",
  fontSize: "18px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "143% /* 25.74px */",
  letterSpacing: "0.17px",
  "@media (max-width: 1200px)": {
    minWidth: "20rem",
    paddingBottom: "0.5rem",
  },
});

export { StyledUnitWrapperRoot, StyledUnitWrapperContent, StyledUnitWrapperTitle };
