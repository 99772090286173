import { Box } from "@mui/material";
import { DataGridProProps, GridRowId, GRID_DETAIL_PANEL_TOGGLE_COL_DEF } from "@mui/x-data-grid-pro";
import zerezLogoIcon from "assets/icons/zerezLogoIcon.svg";
import StatusTag from "components/molecules/StatusTag/StatusTag";
import useDataGrid from "components/templates/dataGridTable";
import DetailPanelToggle from "components/templates/dataGridTable/Cells/DetailPanelToggle";
import { useGetUnitsForUnitsOverviewLazyQuery } from "graphqlBase/getUnitsForUnitsOverview/__generated__/getUnitsForUnitsOverview";
import { execGetTenantsForRoleQuery } from "graphqlBase/tenant/__generated__/getTenantsForRole";

import { execGetCertifcateTypeNamesQuery } from "graphqlBase/UnitsOverviewFilterOptions/__generated__/getCertificateTypeNames";
import { execGetCertificateValidityStatusesQuery } from "graphqlBase/UnitsOverviewFilterOptions/__generated__/getCertificateValidityStatuses";
import {
  getTranslatedCategoryEnumString,
  getTranslatedCertificateTypeString,
  getTranslatedCertificateValidityStatusString,
  getTranslatedPrimaryEnergySourceString,
} from "lib/helper/getTranslatedRowDataStrings";
import { useGetUserInfo } from "lib/userInfo";
import React, { useCallback, useEffect, useState } from "react";
import { atom, useRecoilState } from "recoil";
import ordinaryColors from "themes/ordinaryColors";
import translations from "translations";
import useGetTenatNameById from "utils/useGetTenantNameById";
import useCheckIfFiltersAreApplied from "../CustomDataGridFilters/utils/useCheckIfFiltersAreApplied";
import { useCustomFilterbar } from "../CustomDataGridFilters/utils/useCustomFilterbar";
import DetailPanelContent from "./DetailPanelContent";
import { StyledTitle } from "./DetailView/styles";
import { MigrationNoteTooltip } from "./MigrationNoteTooltip";
import StatusCell from "./StatusColumn/StatusCell";
import SubscribeColumn from "./SubscribeColumn";
import UnitsAndCertificatesLink, { getUnitLinkInput, UnitOverview } from "./UnitsAndCertificatesLink";

export const tenantHasRoleManufacturerOrCertifcationAuthorityAtom = atom<boolean>({
  key: "tenantHasRoleManufacturerOrCertifcationAuthorityAtom",
  default: false,
});
const UnitsOverview: React.FC = () => {
  const { filtersIsTrue } = useCheckIfFiltersAreApplied();
  const [noDataMessage, setNoDataMessage] = useState<string | undefined>(undefined);
  const userInfo = useGetUserInfo();

  const [queryPre, { data, loading, error, refetch }] = useGetUnitsForUnitsOverviewLazyQuery({
    variables: { isLoggedIn: !!userInfo },
  });
  const { tenantNameById } = useGetTenatNameById();

  const userId = userInfo?.id;
  const currentTenantId = userInfo?.tenantId;
  const zerezLogo = <img src={zerezLogoIcon} alt="ZEREZ Logo" />;
  const query = useCustomFilterbar(queryPre);
  const refetchUnitsOverview = useCallback(async () => {
    if (refetch) await refetch();
  }, [refetch]);
  const { DataGrid, useMakeColumns, makeSeverSideFilter, apiRef } = useDataGrid<UnitOverview, "UnitOverview">({
    query,
    tableId: "UnitOverview",
    persistance: "runTime",
  });
  const minColumnWidth = 150;
  const [tenantHasRoleManufacturerOrCertifcationAuthority, setTenantHasRoleManufacturerOrCertifcationAuthority] =
    useRecoilState(tenantHasRoleManufacturerOrCertifcationAuthorityAtom);
  const isPlatformOperator = userInfo?.role === "PLATFORM_OPERATOR";

  useEffect(() => {
    if (!userInfo?.role) return;

    setTenantHasRoleManufacturerOrCertifcationAuthority(
      userInfo.role === "MANUFACTURER" || userInfo.role === "CERTIFICATION_AUTHORITY"
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo]);
  const columns = useMakeColumns(
    [
      {
        ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
        renderCell: (params: { id?: GridRowId; value?: unknown; row: UnitOverview }) => {
          // if (params.row.certificateBridgeUnits.length === 0) return <div />;
          return <DetailPanelToggle id={params.id ?? ""} value={params.value} />;
        },
        remoteFilter: makeSeverSideFilter("string", {
          filterPath: ({ where, filterValue }) => ({
            certificateNumber: filterValue,
          }),
        }),
        type: "string",
        // width: 200,
      },
      {
        field: "ModelName",
        headerName: translations.entities.unit.modelName,
        flex: 1,
        minWidth: 200,
        valueGetter: (params) => {
          return params.row.modelName ?? "";
        },

        renderCell: ({ row }) => {
          return (
            <Box sx={{ display: "flex", flexDirection: "row", gap: "8px" }}>
              {row.isImported && <MigrationNoteTooltip entity="unit" />}
              <span
                style={{
                  display: "flex",
                  width: "fit-content",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  marginTop: "-3px",
                  wordBreak: "break-word",
                }}
              >
                {UnitsAndCertificatesLink(
                  getUnitLinkInput({ row, hasActiveErrorReport: row.hasActiveErrorReport, isPlatformOperator })
                )}
              </span>
            </Box>
          );
        },
        remoteFilter: makeSeverSideFilter("string", {
          filterPath: ({ where, filterValue }) => ({
            modelName: filterValue,
          }),
        }),
        type: "string",
      },

      {
        field: "UnitNumber",
        headerName: translations.entities.unit.unitNumber,
        flex: 1,
        minWidth: minColumnWidth,

        // valueGetter: (params) => {
        //   return params.row.unitCode ?? "";
        // },
        renderCell: ({ row }) => {
          return (
            <span style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
              {" "}
              {row.isImported && !row.isVerified
                ? "-"
                : row.hasActivePowerRange &&
                  (row.minActivePowerRange !== row.maxActivePowerRange ||
                    (row.minActivePowerRange === null && row.maxActivePowerRange === null))
                ? UnitsAndCertificatesLink({
                    label: translations.pages.certificationManagement.referToDetailView,
                    path: `/units/${row?.id}`,
                  })
                : row.unitCode}
            </span>
          );
        },
        remoteFilter: makeSeverSideFilter("string", {
          filterPath: ({ where, filterValue }) => ({
            unitCode: filterValue,
          }),
        }),
        type: "string",
      },
      {
        field: "UnitType",
        headerName: translations.entities.unit.unitTypeName,
        flex: 1,
        minWidth: minColumnWidth,

        valueGetter: ({ row }) => getTranslatedCategoryEnumString(row.category),

        remoteFilter: makeSeverSideFilter("string", {
          filterPath: ({ where, filterValue }) => ({
            certificateTypeName: filterValue,
          }),
        }),
        type: "string",
      },
      {
        field: "Norm",
        headerName: translations.entities.certificate.certificateNorm,
        flex: 1,
        minWidth: minColumnWidth,

        renderCell: ({ row }) => {
          return (
            <div style={{ display: "flex" }}>
              <span style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
                {row.certificateNormIssueDateDescriptions}
              </span>
            </div>
          );
        },
        remoteFilter: makeSeverSideFilter("string", {
          filterPath: ({ where, filterValue }) => ({
            certificateNormIssueDateDescriptions: filterValue,
          }),
        }),
        type: "string",
      },

      {
        field: "PrimaryEnergy",
        headerName: translations.entities.certificate.primaryEnergy,
        flex: 1,
        minWidth: minColumnWidth,

        valueGetter: (params) => getTranslatedPrimaryEnergySourceString(params.row.primaryEnergySource),
        type: "string",
      },
      {
        field: "Manufacturer",
        headerName: translations.entities.certificate.manufacturer,
        flex: 1,
        minWidth: minColumnWidth,

        renderCell: ({ row }) => {
          const certificateHolderId = row.certificateHolderId;
          return (
            <span style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
              {tenantNameById[certificateHolderId] ?? ""}
            </span>
          );
        },
        remoteFilter: makeSeverSideFilter("string", {
          filterPath: async ({ where, filterValue, value, quickValues }) => {
            if (!quickValues?.length) return;
            const joinedQuickValues = quickValues.join(" ");
            const elems = await execGetTenantsForRoleQuery({
              variables: { inputValue: joinedQuickValues, role: "MANUFACTURER", approvalStatus: "DENIED" },
            });
            const ids = elems.data?.tenants?.items?.map<string>((item) => item.id);

            return {
              certificateHolderId: { in: ids },
            };
          },
        }),
        type: "string",
      },
      {
        field: "MaxActivePower",
        headerName: translations.entities.certificate.maxActivePower,
        flex: 1,
        minWidth: minColumnWidth,
        // TODO: Filter adjustment for power range
        valueGetter: (params) => {
          if (params.row.hasActivePowerRange) {
            const areMinAndMaxActivePowerRangeNull =
              params.row.minActivePowerRange === null && params.row.maxActivePowerRange === null;
            const isMinAndMaxPowerRangeEqualAndNotNull =
              params.row.minActivePowerRange === params.row.maxActivePowerRange && !areMinAndMaxActivePowerRangeNull;
            return isMinAndMaxPowerRangeEqualAndNotNull
              ? `${params.row.minActivePowerRange?.toString() ?? ""} kW`
              : areMinAndMaxActivePowerRangeNull
              ? translations.entities.certificate.hasActivePowerRange
              : `${params.row.minActivePowerRange?.toString() ?? ""} - ${
                  params.row.maxActivePowerRange?.toString() ?? ""
                } kW`;
          } else return `${params.row.maxActivePower?.toString() ?? ""} kW`;
        },
        type: "string",
      },
      {
        field: "RatedVoltage",
        headerName: translations.entities.certificate.ratedVoltage,
        flex: 1,
        minWidth: minColumnWidth,
        valueGetter: (params) => {
          return `${params.row.ratedVoltage?.toString() ?? ""} V`;
        },
        type: "string",
      },
      {
        field: "CertificateTypeName",
        headerName: translations.entities.certificate.certificateType,
        minWidth: minColumnWidth,

        valueGetter: (params) => getTranslatedCertificateTypeString(params.row.certificateTypeId),

        remoteFilter: makeSeverSideFilter("string", {
          filterPath: async ({ where, filterValue, value, quickValues }) => {
            if (!quickValues?.length) return;
            const elems = await execGetCertifcateTypeNamesQuery({});
            const ids = elems.data?.certificateTypes?.items?.reduce<string[]>((ids, item) => {
              if (
                [quickValues.join(" ")].some((value) =>
                  getTranslatedCertificateTypeString(item.key).toLowerCase().includes(value.toLowerCase())
                )
              )
                return ids.concat(item.key);
              return ids;
            }, []);

            return {
              certificateTypeId: { in: ids },
            };
          },
        }),
        type: "string",
      },
      {
        field: "Status",
        headerName: translations.pages.certificationManagement.certificateStatus,
        minWidth: minColumnWidth,

        renderCell: ({ row }) => {
          const status = row.certificateValidityStatusName;
          return (
            <Box>
              <StatusCell status={status} />
            </Box>
          );
        },
        remoteFilter: makeSeverSideFilter("string", {
          filterPath: async ({ where, filterValue, value, quickValues }) => {
            if (!quickValues?.length) return;
            const elems = await execGetCertificateValidityStatusesQuery({});
            const ids = elems.data?.certificateValidityStatuses?.items?.reduce<string[]>((ids, item) => {
              if (
                [quickValues.join(" ")].some((value) =>
                  getTranslatedCertificateValidityStatusString(item.key).toLowerCase().includes(value.toLowerCase())
                )
              )
                return ids.concat(item.key);
              return ids;
            }, []);

            return {
              certificateValidityStatusId: { in: ids },
            };
          },
        }),
        type: "string",
      },
      {
        field: "Certificate Check",
        headerName: translations.pages.certificationManagement.certificateCheck,
        minWidth: minColumnWidth,

        renderCell: ({ row }) => {
          const inCorrection = row.inEditByTenantId;
          const isValidityStatusVoid = row.certificateValidityStatusName === "Void";
          const isVerified = !!row.isVerified;
          if (isValidityStatusVoid) return null;
          if (inCorrection)
            return (
              <StatusTag
                title={translations.pages.certificationManagement.inCorrection}
                color={ordinaryColors.warningDark}
              />
            );
          return isVerified ? (
            <StatusTag
              title={translations.pages.certificationManagement.verified}
              backgroundColor={ordinaryColors.sucess200}
              color={ordinaryColors.grey900}
              borderColor="transparent"
            />
          ) : (
            <StatusTag
              title={translations.pages.certificationManagement.verificationPending}
              color={ordinaryColors.grey600}
            />
          );
        },

        type: "string",
      },
      {
        field: "SubscribeCheck",
        headerName: translations.pages.certificationManagement.subscribeStar,
        // flex: 2,
        minWidth: minColumnWidth,

        renderCell: ({ row }) => {
          const unitBridgeUserId = (data?.unitBridgeUsers?.items ?? [])?.find((bridge) => bridge.unitId === row.id)?.id;

          const unitId = row?.id;
          const unitTenantIdEqualsCurrentTenantId = row?.tenantId === currentTenantId;

          return userId !== undefined ? (
            <SubscribeColumn
              unitTenantIdEqualsCurrentTenantId={unitTenantIdEqualsCurrentTenantId}
              unitId={unitId}
              userId={userId}
              isSubscribed={!!unitBridgeUserId}
              unitBridgeUserId={unitBridgeUserId ?? ""}
              refetchUnitsOverview={refetchUnitsOverview}
            />
          ) : null;
        },
        type: "string",
        hide: userId === undefined,
      },
    ],
    []
  );
  const getDetailPanelContent = useCallback<NonNullable<DataGridProProps["getDetailPanelContent"]>>(
    ({ row }) => {
      if (row.certificate) return null;
      return (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <DetailPanelContent row={row} isPlatformOperator={isPlatformOperator} />
        </Box>
      );
    },
    [isPlatformOperator]
  );
  const refetchOnClick = useCallback(() => {
    refetch();
  }, [refetch]);
  const getDetailPanelHeight = useCallback<NonNullable<DataGridProProps["getDetailPanelHeight"]>>(
    () => "auto" as const,
    []
  );

  useEffect(() => {
    if (filtersIsTrue) {
      setNoDataMessage(undefined);
      return;
    }
    setTimeout(() => {
      if (data?.unitOverview?.totalCount === 0) setNoDataMessage(translations.globals.notifications.noDataAvailable);
    }, 200);
  }, [data?.unitOverview?.totalCount, filtersIsTrue]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "start",
          alignItems: "center",
          gap: "15px",
          marginLeft: "30px",
          padding: "28px 0 28px",
        }}
      >
        <Box sx={{ width: "64px", height: "64px" }}>{zerezLogo}</Box>

        <StyledTitle component="h1" role="region">
          {" "}
          {translations.pages.certificationManagement.title}
        </StyledTitle>
      </Box>
      <Box role="region" style={{ display: "flex", flexDirection: "column", minHeight: "auto", maxHeight: "auto" }}>
        <DataGrid
          showReducedFunctionalityNote={tenantHasRoleManufacturerOrCertifcationAuthority}
          showMigrationNote
          componentsProps={{ pagination: { labelRowsPerPage: translations.globals.table.pagination.rowsPerPage } }}
          loading={loading}
          sx={{
            "& .MuiDataGrid-cell": {
              maxWidth: "200px",
              width: "200px",
              alignItems: "start",
              diplay: "flex",
            },
          }}
          initialState={{
            pinnedColumns: { left: [GRID_DETAIL_PANEL_TOGGLE_COL_DEF.field, "ModelName"] },
          }}
          disableColumnFilter
          disableSelectionOnClick
          error={error}
          hasToolbar
          columns={columns}
          noResultsAnimation
          rows={data?.unitOverview?.items}
          noDataMessage={noDataMessage}
          autoHeight={false}
          tableHeight="100vh"
          tableTitle="Assets"
          apiRef={apiRef}
          totalCount={data?.unitOverview?.totalCount}
          checkboxSelection={false}
          getDetailPanelContent={getDetailPanelContent}
          pageSize={10}
          rowsPerPageOptions={[10, 20, 30]}
          getDetailPanelHeight={getDetailPanelHeight}
          pagination
          rowHeight={200}
          getRowHeight={() => "auto"}
          customToolBarProps={{
            isUnitsToolbar: true,
            refetchOnClick,
            hasCustomFilterbar: true,
            showReducedFunctionalityNote: tenantHasRoleManufacturerOrCertifcationAuthority,
          }}
        />
      </Box>
    </>
  );
};

export default UnitsOverview;
