import type * as Types from '../../types';

import client from "apollo/client";
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteUnitBridgeUsersMutationVariables = Types.Exact<{
  ids: Array<Types.Scalars['UUID']> | Types.Scalars['UUID'];
}>;


export type DeleteUnitBridgeUsersMutation = { __typename?: 'Mutation', deleteUnitBridgeUsers: Array<{ __typename?: 'UnitBridgeUser', id: string }> };


        
        export type ExecDeleteUnitBridgeUsersMutationOptions = Omit<
        Apollo.MutationOptions<
        DeleteUnitBridgeUsersMutation, DeleteUnitBridgeUsersMutationVariables
        >,
        "mutation"
    >;
    export const execDeleteUnitBridgeUsersMutation = (options: ExecDeleteUnitBridgeUsersMutationOptions) =>
    client.mutate< DeleteUnitBridgeUsersMutation, DeleteUnitBridgeUsersMutationVariables>({
        ...options,
        mutation: DeleteUnitBridgeUsersDocument,
    });
    

export const DeleteUnitBridgeUsersDocument = gql`
    mutation deleteUnitBridgeUsers($ids: [UUID!]!) {
  deleteUnitBridgeUsers(ids: $ids) {
    id
  }
}
    `;
export type DeleteUnitBridgeUsersMutationFn = Apollo.MutationFunction<DeleteUnitBridgeUsersMutation, DeleteUnitBridgeUsersMutationVariables>;

/**
 * __useDeleteUnitBridgeUsersMutation__
 *
 * To run a mutation, you first call `useDeleteUnitBridgeUsersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUnitBridgeUsersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUnitBridgeUsersMutation, { data, loading, error }] = useDeleteUnitBridgeUsersMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDeleteUnitBridgeUsersMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUnitBridgeUsersMutation, DeleteUnitBridgeUsersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUnitBridgeUsersMutation, DeleteUnitBridgeUsersMutationVariables>(DeleteUnitBridgeUsersDocument, options);
      }
export type DeleteUnitBridgeUsersMutationHookResult = ReturnType<typeof useDeleteUnitBridgeUsersMutation>;
export type DeleteUnitBridgeUsersMutationResult = Apollo.MutationResult<DeleteUnitBridgeUsersMutation>;
export type DeleteUnitBridgeUsersMutationOptions = Apollo.BaseMutationOptions<DeleteUnitBridgeUsersMutation, DeleteUnitBridgeUsersMutationVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
          export type ResultType<T> = Types.ResultType<T>;
          export type PaginatedResultType<T> = Types.PaginatedResultType<T>;
          export type NonMaybe<T> = Types.NonMaybe<T>;
          export type Maybe<T> = Types.Maybe<T>;