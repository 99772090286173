import * as React from "react";
import MuiTextField from "@mui/material/TextField";
import { ChangeEventHandler } from "react";
import Props from "./index.d";
import ordinaryColors from "themes/ordinaryColors";

const TextField: React.FC<Props> = (props) => {
  const {
    title,

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    data: { state, setstate, onBlur, updateValue, setNull, setAndSave },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    placeholder = "placeholder",
    disabled,
    testId,
    errors,
    isRequiredField,
    errorManager,
    className,
    isUnit,
    hideLabel,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ...rest
  } = props;

  const change: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = (e) => {
    const value = e.target.value;

    if (value === null) {
      if (setNull) {
        setNull();
      } else {
        setstate("");
      }
    } else {
      if (typeof value === "string") setstate(value);
    }
  };

  const errorManagerRes = errorManager({
    errorJoiner: (errors) => {
      return errors.join(", ");
    },
  });

  return (
    <>
      <MuiTextField
        sx={{
          height: "35px",
          padding: "0px",
          marginBottom: errorManagerRes.error ? "27px" : "14px",
          borderRadius: "0px",
          "& .MuiFormHelperText-root": {
            marginLeft: "0px",
            fontSize: "12px",
            color: ordinaryColors.error,
          },
          " .MuiOutlinedInput-notchedOutline": {
            borderWidth: "0px",
          },
          "& .MuiOutlinedInput-input": {
            padding: "0.5rem 0.75rem",
            width: "100%",
          },
          "& .MuiOutlinedInput-root": {
            borderRadius: "0px",
            border: errorManagerRes.error && !isUnit ? "1px solid rgba(214, 40, 57, 1) " : "1px solid #9E9E9E66",
            backgroundColor: errorManagerRes.error ? "rgba(211, 47, 47, 0.02)" : "transparent",
            padding: "0px",
            "& fieldset": {
              borderRadius: "0px",
            },
          },
        }}
        aria-label={title}
        onBlur={onBlur}
        value={state || ""}
        onChange={change}
        disabled={props.disabled}
        label={hideLabel ? undefined : title}
        className={className}
        // showRemoveIcon={!isRequiredField}
        required={isRequiredField}
        InputProps={{ sx: { height: "40px" } }}
        helperText={errorManagerRes.error && !isUnit ? errorManagerRes.helperText : ""}
        {...errorManagerRes}
      />
    </>
  );
};

export default TextField;
