import { InputMaybe, TenantFilterInput } from "graphqlBase/types";
import { SelectItem } from "../MultiSelectFreeSolo";
import { useGetTenantsLazyQuery } from "graphqlBase/getTenants/__generated__/getTenants";

const useGetTenantOptions = (state?: string) => {
  const [query, { loading }] = useGetTenantsLazyQuery({
    fetchPolicy: "cache-and-network",
  });

  const queryTenants = async (where?: InputMaybe<TenantFilterInput> | undefined) => {
    const optionResult = await query({
      variables: {
        take: 10,
        where: {
          ...where,
        },
        order: { tenantName: "ASC" },
      },
    });
    if (!optionResult?.data?.tenants?.items?.length) return null;
    return (optionResult?.data?.tenants?.items ?? []).reduce<SelectItem[]>((acc, current) => {
      return acc.concat({ label: current?.tenantName ?? "", value: current?.id });
    }, []);
  };

  const getSelectOptions = () => {
    return queryTenants().then((result) => {
      if (!result) return null;
      if (state) {
        const preSelectedOption = result.find((item) => item.value === state);
        if (!preSelectedOption) {
          return queryTenants({ id: { eq: state } }).then((selectedTenantResult) => {
            if (!selectedTenantResult) return null;
            const newOptions = [...result];
            newOptions.splice(0, 1, selectedTenantResult[0]);
            return newOptions;
          });
        } else {
          return result;
        }
      } else return result;
    });
  };
  return { queryTenants, getSelectOptions, loading };
};

export default useGetTenantOptions;
