import * as React from "react";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Props from "./index.d";

const BooleanCheckBox: React.FC<Props> = (props) => {
  const {
    title,
    disabled,
    className,
    data: { state, setstate },
  } = props;
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setstate(event.target.checked);
  };

  return (
    <FormGroup className={className}>
      <FormControlLabel
        control={<Checkbox checked={state} onChange={handleChange} disabled={disabled} />}
        label={title}
      />
    </FormGroup>
  );
};
export default BooleanCheckBox;
