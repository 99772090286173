// eslint-disable-next-line import/no-extraneous-dependencies
import { deepmerge } from "@mui/utils";
import { UnitOverviewFilterInput } from "graphqlBase/types";
import { useMemo } from "react";
import { useRecoilValue } from "recoil";
import { unitsOverviewFilterAtom } from "../State/manageFiltersInUnitsOverview";

const deepmergeSave = (a: { filters: UnitOverviewFilterInput }, b: { filters: UnitOverviewFilterInput }) =>
  deepmerge(a, b, { clone: true });

type FilterBuilderVariables = {
  filters: UnitOverviewFilterInput;
};
export const useFilterBuilder = () => {
  const filterValues = useRecoilValue(unitsOverviewFilterAtom);
  const filterValuesForCustomizedFilters = useMemo(
    () =>
      Object.entries(filterValues).reduce<FilterBuilderVariables>(
        (filters, [key, filterValue]) => {
          if (!filterValue) return filters;
          if (filterValue.length > 0 && key === "documentType") {
            const documentTypeFilter = {
              certificateTypeId: {
                in: filterValue,
              },
            };
            const nextFilter = deepmergeSave(filters, { filters: documentTypeFilter });
            return nextFilter;
          }
          if (filterValue.length > 0 && key === "primaryEnergySource") {
            const primaryEnergySourceFilter = {
              primaryEnergySource: {
                in: filterValue,
              },
            };
            const nextFilter = deepmergeSave(filters, { filters: primaryEnergySourceFilter });
            return nextFilter;
          }
          if (filterValue.length > 0 && key === "categoryClassEquipment") {
            const categoryClassEquipmentFilter = {
              category: {
                in: filterValue,
              },
            };
            const nextFilter = deepmergeSave(filters, { filters: categoryClassEquipmentFilter });
            return nextFilter;
          }
          if (filterValue.length > 0 && key === "certificateValidityStatus") {
            const certificateValidityStatus = {
              certificateValidityStatusId: {
                in: filterValue,
              },
            };
            const nextFilter = deepmergeSave(filters, { filters: certificateValidityStatus });
            return nextFilter;
          }
          if (filterValue.length > 0 && key === "certificateNorms") {
            const certificateNorms = {
              certificateNormIssueDateDescriptions: {
                or: filterValue.map((norm: string) => ({ contains: norm })),
              },
            };
            const nextFilter = deepmergeSave(filters, { filters: certificateNorms });
            return nextFilter;
          }
          if (key === "hasZerezId" && filterValue === true) {
            const hasZerezId = {
              isImported: {
                eq: !filterValue,
              },
            };
            const nextFilter = deepmergeSave(filters, { filters: hasZerezId });
            return nextFilter;
          }
          if (key === "isVerified" && filterValue === true) {
            const isVerified = {
              isVerified: {
                eq: filterValue,
              },
            };
            const nextFilter = deepmergeSave(filters, { filters: isVerified });
            return nextFilter;
          }
          if (key === "isNotReplaced" && filterValue === true) {
            const isVerified = {
              replacedByUnitId: { eq: null },
            };
            const nextFilter = deepmergeSave(filters, { filters: isVerified });
            return nextFilter;
          }
          if (filterValue.length > 0 && key === "subscribed" && filterValue) {
            const isSubscribed = {
              unitId: {
                in: filterValue,
              },
            };

            // const nextFilter = deepmergeSave(filters, filters);
            const nextFilter = deepmergeSave(filters, { filters: isSubscribed });
            return nextFilter;
          }
          if (filterValue.length > 0 && key === "certificateHolderId") {
            const manufacturer = {
              certificateHolderId: {
                in: filterValue,
              },
            };

            const nextFilter = deepmergeSave(filters, { filters: manufacturer });
            return nextFilter;
          }
          if (filterValue.length > 0 && key === "certificateAuthorityId") {
            const authority = {
              certificateAuthorityId: {
                in: filterValue,
              },
            };

            const nextFilter = deepmergeSave(filters, { filters: authority });
            return nextFilter;
          }
          if (filterValue && key === "tenantId") {
            const tenantId = {
              or: [
                {
                  certificateHolderId: {
                    eq: filterValue,
                  },
                },
                {
                  certificateAuthorityId: {
                    eq: filterValue,
                  },
                },
              ],
            };
            const nextFilter = deepmergeSave(filters, { filters: tenantId });
            return nextFilter;
          }
          if (filterValue.length && key === "ratedVoltage") {
            const ratedVoltage =
              filterValue[0] === 0 && filterValue[1] === Infinity
                ? {
                    or: [
                      {
                        ratedVoltage: {
                          gte: filterValue[0],
                        },
                      },
                      { ratedVoltage: { eq: null } },
                    ],
                  }
                : {
                    ratedVoltage: {
                      gte: filterValue[0],
                      lte: filterValue[1],
                    },
                  };
            const nextFilter = deepmergeSave(filters, { filters: ratedVoltage });
            return nextFilter;
          }
          if (filterValue.length && key === "maxActivePower") {
            const maxActivePower =
              filterValue[0] === 0 && filterValue[1] === 5000
                ? {
                    or: [
                      {
                        maxActivePower: {
                          gte: filterValue[0],
                          lte: filterValue[1],
                        },
                      },
                      { maxActivePower: { eq: null } },
                    ],
                  }
                : {
                    maxActivePower: {
                      gte: filterValue[0],
                      lte: filterValue[1],
                    },
                  };
            const nextFilter = deepmergeSave(filters, { filters: maxActivePower });
            return nextFilter;
          }

          return filters;
        },
        { filters: {} } as { filters: UnitOverviewFilterInput }
      ),
    [filterValues]
  );
  return filterValuesForCustomizedFilters;
};
