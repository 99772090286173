import { GridFilterOperator } from "@mui/x-data-grid-pro";
import { EntityMappings } from "graphqlBase/__utils__/entityMappings";
import { FiltertypesWrapped, PromiseAble } from "./types";
import makeFilters from "./utils/makeFilters";

export type FilterPath<E extends keyof EntityMappings, T extends keyof FiltertypesWrapped> = ({
  where,
  filterValue,
  value,
  quickValues,
}: {
  filterValue: FiltertypesWrapped[T];
  value?: T | undefined | null;
  quickValues?: string[];
  where: EntityMappings[E]["filter"];
}) => PromiseAble<EntityMappings[E]["filter"] | undefined | null> | undefined | null;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function makeSeverSideFilterPre<D extends object, E extends keyof EntityMappings>() {
  return function <T extends keyof FiltertypesWrapped>(
    filterType: T,
    {
      filterOperators,
      filterPath,
    }: {
      filterOperators?: GridFilterOperator[];
      filterPath: FilterPath<E, T>;
    }
  ) {
    return {
      filterPath,
      filterOperators: filterOperators ?? makeFilters[filterType],
    };
  };
}
