import * as React from "react";
import CreateNorm from "./CreateNorm";
import { useManageNormlist } from "./CreateNorm/useManageNormlist";
import Props from "./index.d";
import NormBridgeList from "./NormBridgeList";
import { FormHelperText } from "@mui/material";
import ordinaryColors from "themes/ordinaryColors";

const CertificateNormBridge: React.FC<Props> = (props) => {
  const { useBridge, className, errorManager } = props;
  const { useBridgeArrayState } = useBridge();
  const [normBridgeIds, setNormBridgeIds] = useManageNormlist(useBridgeArrayState);
  const removeFile = (normBridgeId: string) => {
    setNormBridgeIds((prev) => prev.filter((id) => id !== normBridgeId));
  };
  const addNormBridgeId = (normBridgeId: string) => {
    setNormBridgeIds((prev) => [...prev, normBridgeId]);
  };
  const errorManagerRes = errorManager({
    errorJoiner: (errors) => {
      return errors.join(", ");
    },
  });

  return (
    <div className={className}>
      <CreateNorm addNormBridgeId={addNormBridgeId} normBridgeIds={normBridgeIds} />
      <NormBridgeList removeNorm={removeFile} normBridgeIds={normBridgeIds} />
      <FormHelperText sx={{ color: ordinaryColors.error, marginLeft: "0px", fontSize: "12px" }}>
        {errorManagerRes.helperText}
      </FormHelperText>
    </div>
  );
};

export default CertificateNormBridge;
