import Button from "@mui/material/Button";
import * as React from "react";
import translations from "translations";
import Props from "./index.d";

const SubmitButton: React.FC<Props> = (props) => {
  const {
    disabled,
    variant,
    className,
    data: { setAndSave },
  } = props;

  return (
    <Button
      className={className}
      variant={variant}
      onClick={() => setAndSave(true)}
      disabled={disabled}
      sx={{
        borderRadius: "0px",
        "& .MuiButton": {
          borderRadius: "0px",
        },
      }}
    >
      {translations.globals.button.save}
    </Button>
  );
};

export default SubmitButton;
