import { Box, styled } from "@mui/material";
import ordinaryColors from "themes/ordinaryColors";

const StyledUnitFieldWrapper = styled(Box, { name: "StyledUnitFieldWrapper" })({
  display: "flex",
  // minWidth: "585px",
  flexDirection: "column",
  alignItems: "flex-start",
  alignSelf: "stretch",
  background: ordinaryColors.functionalWhite,
  "@media (max-width: 1200px)": {
    maxWidth: "22rem",
    marginBottom: "2rem",
  },
});

const StyledTitleContainer = styled(Box, { name: "StyledTitleContainer" })({
  display: "flex",
  padding: "10px 32px",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "19px",
});

const StyledTitleBox = styled(Box, { name: "StyledTitleBox" })({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
});

const StyledTitle = styled("div", { name: "StyledTitle" })({
  color: ordinaryColors.functionalBlack,
  fontFeatureSettings: "'clig' off, 'liga' off",
  fontSize: "18px",
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: "143% /* 25.74px */",
  letterSpacing: "0.17px",
});

const StyledInputContainer = styled("div", { name: "StyledInputContainer" })({
  display: "flex",
  alignItems: "flex-start",
  alignContent: "flex-start",
  alignSelf: "stretch",
  flexWrap: "wrap",
});

export { StyledInputContainer, StyledTitle, StyledTitleBox, StyledTitleContainer, StyledUnitFieldWrapper };
