import Button from "@mui/material/Button";
import * as React from "react";
import translations from "translations";
import Props from "./index.d";
import { useGetTenantByIdLazyQuery } from "graphqlBase/userManagement/role/__generated__/getTenantById";
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from "recoil";
import {
  editUserOpenModalState,
  openCheckTenantRoleState,
  userIdState,
} from "pages/UserManagement/UpdateUserAndTenant/state";
import { useGetRoleByUserIdLazyQuery } from "graphqlBase/userManagement/role/__generated__/getRoleByUserId";
import { useEffect } from "react";
import { tenantIdAtom } from "../TenantIdForUser/state";
import CheckTenantRole from "pages/UserManagement/UpdateUserAndTenant/Modals/CheckTenantRole";

const SubmitButtonForEditUser: React.FC<Props> = (props) => {
  const {
    disabled,
    variant,
    className,
    data: { setAndSave },
  } = props;
  const [queryTenantByd] = useGetTenantByIdLazyQuery();

  const userId = useRecoilValue(userIdState);
  const [queryRoleByUserId, { data: roleData, refetch }] = useGetRoleByUserIdLazyQuery();

  useEffect(() => {
    queryRoleByUserId({ variables: { userId: String(userId) } });
    if (refetch) refetch();
  }, [queryRoleByUserId, refetch, userId]);

  const [openCheckTenantRole, setOpenCheckTenantRole] = useRecoilState(openCheckTenantRoleState);
  const newTenantId = useRecoilValue(tenantIdAtom);
  const setResetNewTenant = useResetRecoilState(tenantIdAtom);
  const setOpenEditUser = useSetRecoilState(editUserOpenModalState);

  const afterUpdateUser = () => {
    setOpenCheckTenantRole(false);
    setOpenEditUser(false);
    setResetNewTenant();
    setAndSave(true);
  };

  const onClick = async () => {
    const tenantDetails = await queryTenantByd({ variables: { id: newTenantId ?? "" } });
    const tenantRole = tenantDetails.data?.tenant?.tenantRole.replace("_", " ");
    const roles = roleData?.roles?.items?.map((item) => item.name.toUpperCase()) ?? [];
    const isRoleMatching = tenantRole ? roles.includes(tenantRole) : false;
    refetch();
    if (isRoleMatching === false) {
      setOpenCheckTenantRole(true);
    } else {
      afterUpdateUser();
    }
  };
  return (
    <>
      <Button
        className={className}
        variant={variant}
        onClick={onClick}
        disabled={disabled}
        sx={{
          borderRadius: "0px",
          "& .MuiButton": {
            borderRadius: "0px",
          },
        }}
      >
        {translations.globals.button.save}
      </Button>
      <CheckTenantRole
        tenantId={newTenantId}
        afterUpdateUser={afterUpdateUser}
        openCheckTenantRole={openCheckTenantRole}
        setOpenCheckTenantRole={setOpenCheckTenantRole}
      />
    </>
  );
};

export default SubmitButtonForEditUser;
