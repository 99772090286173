import { InputAdornment, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { SetterOrUpdater } from "recoil";

interface MinFieldProps {
  filters: number[];
  unitsForVoltageAndKw: string;
  valueSetter?: SetterOrUpdater<number[]>;
}

const MinField: React.FC<MinFieldProps> = ({ filters, unitsForVoltageAndKw, valueSetter }) => {
  const [value, setValue] = useState<number>(filters[0]);
  useEffect(() => {
    if (filters && filters.length > 0) {
      setValue(filters[0]);
    }
  }, [filters]);

  return (
    <TextField
      label="min"
      type="text"
      variant="outlined"
      InputLabelProps={{ shrink: true }}
      sx={{
        width: "30%",
        borderRadius: "0px",
        ".MuiOutlinedInput-root": {
          borderRadius: "0px",
        },
      }}
      value={value}
      onChange={(e) => setValue(parseInt(e.target.value))}
      onBlur={(e) => valueSetter && valueSetter([parseInt(e.target.value), filters[1]])}
      InputProps={{
        endAdornment: <InputAdornment position="start">{unitsForVoltageAndKw}</InputAdornment>,
      }}
      size="small"
    />
  );
};

export default MinField;
