import { getCertificateCreateFieldSelector } from "raft/CertificateForm";
import { getUnitCreateFieldSelector } from "raft/UnitForm";
import { useCallback, useEffect } from "react";
import { SetterOrUpdater, useRecoilCallback } from "recoil";
import useGetItemtext from "../NormBridgeList/useGetItemtext";

const useGetLables = () => {
  const getItemtext = useGetItemtext();
  const getLables = useCallback(
    (normBridgeIds: string[]): string => {
      const normNumbers = normBridgeIds
        .map((id) => {
          const [certificateNormName] = getItemtext(id).split(":");
          return certificateNormName.replace("VDE-AR-N 41", "").trim();
        })
        .join(",");
      return normNumbers;
    },
    [getItemtext]
  );
  return getLables ?? "";
};

export const useManageNormlist = (
  useBridgeArrayState: () => [string[], SetterOrUpdater<string[]>]
): [string[], SetterOrUpdater<string[]>] => {
  const setNormLists = useSetNormLists();
  const getLables = useGetLables();
  const [normBridgeIds, setNormBridgeIds] = useBridgeArrayState();
  useEffect(() => {
    setNormLists(getLables(normBridgeIds));
  }, [normBridgeIds, getLables, setNormLists]);
  return [normBridgeIds, setNormBridgeIds];
};

const useSetNormLists = () => {
  return useRecoilCallback(
    ({ set }) =>
      (labels: string) => {
        const __normListAtom = getCertificateCreateFieldSelector({ formVersion: "active" })("__normList");
        const __normListAtomUnits = getUnitCreateFieldSelector({ formVersion: "active" })("__normList");
        set(__normListAtom, labels);
        set(__normListAtomUnits, labels);
      },
    []
  );
};
