import type * as Types from '../../types';

import client from "apollo/client";
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CertificateNormsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type CertificateNormsQuery = { __typename?: 'Query', certificateNorms?: { __typename?: 'CertificateNormCollectionSegment', items?: Array<{ __typename?: 'CertificateNorm', id: string, certificateNormName: string, certificateNormBridges: Array<{ __typename?: 'CertificateNormBridge', id: string, certificateNormIssueDate: { __typename?: 'CertificateNormIssueDate', id: string, certificateNormIssueDate: string } }> }> | null } | null };


        export type ExecCertificateNormsOptions = Omit<
        Apollo.QueryOptions<
        CertificateNormsQueryVariables, CertificateNormsQuery
        >,
        "query"
      >;
    export const execCertificateNormsQuery = (options: ExecCertificateNormsOptions) =>
    client.query< CertificateNormsQuery, CertificateNormsQueryVariables>({
      ...options,
      query: CertificateNormsDocument,
    });
    

export const CertificateNormsDocument = gql`
    query certificateNorms {
  certificateNorms {
    items {
      id
      certificateNormName
      certificateNormBridges {
        id
        certificateNormIssueDate {
          id
          certificateNormIssueDate
        }
      }
    }
  }
}
    `;

/**
 * __useCertificateNormsQuery__
 *
 * To run a query within a React component, call `useCertificateNormsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCertificateNormsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCertificateNormsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCertificateNormsQuery(baseOptions?: Apollo.QueryHookOptions<CertificateNormsQuery, CertificateNormsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CertificateNormsQuery, CertificateNormsQueryVariables>(CertificateNormsDocument, options);
      }
export function useCertificateNormsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CertificateNormsQuery, CertificateNormsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CertificateNormsQuery, CertificateNormsQueryVariables>(CertificateNormsDocument, options);
        }
export type CertificateNormsQueryHookResult = ReturnType<typeof useCertificateNormsQuery>;
export type CertificateNormsLazyQueryHookResult = ReturnType<typeof useCertificateNormsLazyQuery>;
export type CertificateNormsQueryResult = Apollo.QueryResult<CertificateNormsQuery, CertificateNormsQueryVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
          export type ResultType<T> = Types.ResultType<T>;
          export type PaginatedResultType<T> = Types.PaginatedResultType<T>;
          export type NonMaybe<T> = Types.NonMaybe<T>;
          export type Maybe<T> = Types.Maybe<T>;