import type * as Types from '../../types';

import client from "apollo/client";
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateUnitBridgeUsersMutationVariables = Types.Exact<{
  unitBridgeUsers: Array<Types.UnitBridgeUserCreateInput> | Types.UnitBridgeUserCreateInput;
}>;


export type CreateUnitBridgeUsersMutation = { __typename?: 'Mutation', createUnitBridgeUsers: Array<{ __typename?: 'UnitBridgeUser', id: string }> };


        
        export type ExecCreateUnitBridgeUsersMutationOptions = Omit<
        Apollo.MutationOptions<
        CreateUnitBridgeUsersMutation, CreateUnitBridgeUsersMutationVariables
        >,
        "mutation"
    >;
    export const execCreateUnitBridgeUsersMutation = (options: ExecCreateUnitBridgeUsersMutationOptions) =>
    client.mutate< CreateUnitBridgeUsersMutation, CreateUnitBridgeUsersMutationVariables>({
        ...options,
        mutation: CreateUnitBridgeUsersDocument,
    });
    

export const CreateUnitBridgeUsersDocument = gql`
    mutation createUnitBridgeUsers($unitBridgeUsers: [UnitBridgeUserCreateInput!]!) {
  createUnitBridgeUsers(unitBridgeUsers: $unitBridgeUsers) {
    id
  }
}
    `;
export type CreateUnitBridgeUsersMutationFn = Apollo.MutationFunction<CreateUnitBridgeUsersMutation, CreateUnitBridgeUsersMutationVariables>;

/**
 * __useCreateUnitBridgeUsersMutation__
 *
 * To run a mutation, you first call `useCreateUnitBridgeUsersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUnitBridgeUsersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUnitBridgeUsersMutation, { data, loading, error }] = useCreateUnitBridgeUsersMutation({
 *   variables: {
 *      unitBridgeUsers: // value for 'unitBridgeUsers'
 *   },
 * });
 */
export function useCreateUnitBridgeUsersMutation(baseOptions?: Apollo.MutationHookOptions<CreateUnitBridgeUsersMutation, CreateUnitBridgeUsersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUnitBridgeUsersMutation, CreateUnitBridgeUsersMutationVariables>(CreateUnitBridgeUsersDocument, options);
      }
export type CreateUnitBridgeUsersMutationHookResult = ReturnType<typeof useCreateUnitBridgeUsersMutation>;
export type CreateUnitBridgeUsersMutationResult = Apollo.MutationResult<CreateUnitBridgeUsersMutation>;
export type CreateUnitBridgeUsersMutationOptions = Apollo.BaseMutationOptions<CreateUnitBridgeUsersMutation, CreateUnitBridgeUsersMutationVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
          export type ResultType<T> = Types.ResultType<T>;
          export type PaginatedResultType<T> = Types.PaginatedResultType<T>;
          export type NonMaybe<T> = Types.NonMaybe<T>;
          export type Maybe<T> = Types.Maybe<T>;