import styled from "@emotion/styled";
import { Button, ButtonProps, SxProps } from "@mui/material";
import { CTAType } from "components/formElements/fields/CTASubmitButton/index.d";
import ordinaryColors from "themes/ordinaryColors";
// eslint-disable-next-line import/no-extraneous-dependencies
import "@fontsource/roboto/500.css";

const ctaTypeSwitch = (ctaType: CTAType | undefined) => ({
  background:
    ctaType === "secondary"
      ? ordinaryColors.functionalBlack
      : ctaType === "tertiary"
      ? ordinaryColors.functionalWhite
      : ordinaryColors.primaryBrand,
  border: ctaType === "tertiary" ? `1px solid ${ordinaryColors.functionalBlack}` : undefined,
  color: ctaType === "tertiary" ? ordinaryColors.functionalBlack : ordinaryColors.functionalWhite,
  ":hover": {
    background:
      ctaType === "secondary"
        ? ordinaryColors.functionalBlackHover
        : ctaType === "tertiary"
        ? ordinaryColors.functionalGreyBack
        : ordinaryColors.primaryBrandHover,
    border: ctaType === "tertiary" ? `1px solid ${ordinaryColors.functionalBlack}` : undefined,
    color: ctaType === "tertiary" ? ordinaryColors.functionalBlack : ordinaryColors.functionalWhite,
  },
  ":focus": {
    background: ctaType === "primary" ? ordinaryColors.primaryBrandFocus : ordinaryColors.functionalBlackFocus,
    border: ctaType === "tertiary" ? `1px solid ${ordinaryColors.functionalBlack}` : undefined,
    color: ordinaryColors.functionalWhite,
  },
  ":disabled": {
    background: ordinaryColors.functionalLightGrey,
    borderColor: ctaType === "tertiary" ? ordinaryColors.functionalWhite : undefined,
    color: ordinaryColors.functionalWhite,
  },
});

const StyledButton = styled(Button, { shouldForwardProp: (propname) => propname !== "ctaType" })(
  ({ width, margin, ctaType }: { margin?: string | number; width?: string | number; ctaType?: CTAType }) => ({
    ...ctaTypeSwitch(ctaType),
    borderRadius: "0px",
    boxShadow: "none",
    display: "flex",
    fontFamily: "Roboto",
    fontFeatureSettings: "'clig' off, 'liga' off",
    fontSize: "0.9375rem",
    fontStyle: "normal",
    fontWeight: 500,
    gap: "0.5rem",
    height: "40px",
    letterSpacing: "0.02875rem",
    lineHeight: "1.625rem /* 173.333% */",
    margin: margin ?? 0,
    padding: "0.5rem 1.375rem",
    textTransform: "uppercase",
    width: width ?? "100%",
    whiteSpace: "nowrap",
  })
);

interface CTAProps extends ButtonProps {
  handleClick?: () => void;
  label?: string;
  margin?: string | number;
  width?: string | number;
  ctaType?: CTAType;
  sx?: SxProps;
}

const CTA: React.FC<CTAProps> = (props) => {
  return (
    <StyledButton
      ctaType={props.ctaType ?? "primary"}
      disabled={props.disabled}
      onClick={props.handleClick}
      margin={props.margin}
      width={props.width}
      {...props}
    >
      {props.label ?? props.children}
    </StyledButton>
  );
};

export default CTA;
