import { ApolloClient, useQuery, useLazyQuery } from "@apollo/client";
import { diff } from "deep-object-diff";
import {
  ApolloQueryResult,
  LazyQueryExecFunction,
  OperationVariables,
  EntityMappings,
  DocumentNode,
  LazyQueryResultTuple,
} from "raft/internal/apolloBarrel";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  RecoilState,
  SetterOrUpdater,
  useRecoilState,
  useRecoilTransactionObserver_UNSTABLE,
  useSetRecoilState,
  useResetRecoilState,
  Resetter,
  useRecoilCallback,
  useRecoilValue,
} from "recoil";
import { BridgeEntityRes, BridgeParams, BridgeState, OuterRes } from "./BridgeParamTypes";
import { makeBridgeState } from "./gerenericBridgeState";
import { makeBridgeEntityQuery, makeOuterEntityQuery } from "./makeBridgeEntityOperations";
import { getBridgeDelta, makePersistor, saver } from "./saver";
type EntityMappingsExtended = EntityMappings & {
  unkown: {
    read: OuterRes;
    filter: unknown;
    sort: unknown;
    update: unknown;
    create: unknown;
  };
};
export type BridgeEntity = keyof EntityMappingsExtended;
type SetterOrUpdaterArgument<T> = ((currVal: T) => T) | T;
export type FuzzySearch = [string | undefined, React.Dispatch<React.SetStateAction<string | undefined>>];
const nullFunc = () => {};

const useFetchOuter = ({
  outerQuery,
  bridgeParams,
}: {
  outerQuery: DocumentNode;
  bridgeParams: BridgeParams;
}): { base: LazyQueryResultTuple<OuterRes, OperationVariables>; fuzzySearch: FuzzySearch } => {
  const [fuzzySearch, setFuzzySearch] = useState<string | undefined>();
  const [fetchOuter, { data, refetch, ...rest }] = useLazyQuery<OuterRes>(outerQuery);

  const fuzzyFilter = useMemo(() => {
    if (!fuzzySearch) return undefined;
    const fuzzyFilterPre = bridgeParams.outerEntityColumns.map((outerEntityColumn) => {
      return { [outerEntityColumn.column]: { [outerEntityColumn.fuzzyComparison ?? "contains"]: fuzzySearch } };
    });
    return {
      or: fuzzyFilterPre,
    };
  }, [fuzzySearch, bridgeParams]);
  return useMemo(() => {
    const fetchOuterWithFuzzy: LazyQueryExecFunction<OuterRes, OperationVariables> = (options) => {
      if (options?.variables)
        return fetchOuter({
          ...options,
          variables: { ...options.variables, where: { and: { ...options.variables.where, fuzzyFilter } } },
        });
      return fetchOuter({ ...options, variables: { where: fuzzyFilter } });
    };
    return {
      base: [
        fetchOuterWithFuzzy,
        {
          data,
          refetch,
          fetchOuter,
          ...rest,
        },
      ],
      fuzzySearch: [fuzzySearch, setFuzzySearch],
    };
  }, [data, refetch, fuzzyFilter]);
};

export const getNextBridgeState = ({
  prev,
  adds,
  removes,
}: {
  prev: BridgeState;
  adds: {
    id: string;
    outerId: string;
  }[];
  removes: {
    id: string;
    outerId: string;
  }[];
}) => {
  const next = { ...prev };
  adds.forEach(({ id, outerId }) => {
    next[outerId] = { ...next[outerId], id };
  });
  removes.forEach(({ outerId }) => {
    const { id, ...rest } = next[outerId];
    next[outerId] = { ...rest };
  });
  return next;
};

export const useBridge = ({
  bridgeParams,
  id,
  client,
  updaters,
}: {
  bridgeParams: BridgeParams;
  id?: string;
  client: ApolloClient<unknown>;
  updaters: (ids: string[]) => void;
}) => {
  const { bridgeSelector, state, remove } = makeBridgeState({ bridgeParams, id });
  const resetBridge = useResetRecoilState(state);
  useConnectState({ state, client, bridgeParams, id, updaters });
  const outerQuery = makeOuterEntityQuery(bridgeParams);
  const {
    base: [fetchOuter, { data: outerData, refetch: refetchOuter }],
    fuzzySearch,
  } = useFetchOuter({ outerQuery, bridgeParams });
  // const [fetchOuter, { data: outerData, refetch: refetchOuter }] = useLazyQuery<OuterRes>(outerQuery);
  const useRowSelected = (id: string) => useRecoilState(bridgeSelector(id));
  const useBridgeState = () => useRecoilState(state);
  const useBridgeArrayState = () => {
    const selectedPre = useRecoilValue(state);

    const setBridgeFunc = useRecoilCallback(
      ({ set }) => {
        return (setter: SetterOrUpdaterArgument<string[]>) => {
          return set(state, (prevState) => {
            const ids = typeof setter === "function" ? setter(bridgeStateToArray(prevState)) : setter;
            const nextStatePre = Object.values(prevState).reduce<typeof selectedPre>(
              (nextState, { outerId, ...entry }) => {
                if (ids.includes(outerId)) return { ...nextState, [outerId]: { ...entry, outerId, isDeleted: false } };
                return { ...nextState, [outerId]: { ...entry, outerId, isDeleted: true } };
              },
              {}
            );

            const nextState = ids.reduce<typeof selectedPre>((nextState, id) => {
              if (!Object.keys(nextState).includes(id)) return { ...nextState, [id]: { outerId: id } };
              return nextState;
            }, nextStatePre);
            return nextState;
          });
        };
      },
      [state]
    );

    const selected = useMemo(() => bridgeStateToArray(selectedPre), [selectedPre]);
    return [selected, setBridgeFunc] as [string[], SetterOrUpdater<string[]>];
  };

  useEffect(() => {
    return () => {
      resetBridge();
      remove();
    };
  }, []);
  const bridgeStateCallBack = useRecoilCallback(({ snapshot, set }) => {
    return async () => ({
      get: async () => {
        const bridgeState = await snapshot.getPromise(state);
        return bridgeState;
      },
      set: async (bridgeState: BridgeState) => {
        set(state, bridgeState);
      },
    });
  }, []);

  const res = useCallback(() => {
    const save = saver(id)({ bridgeParams, client });
    const { titles, rows, list, raw } = makeItems(bridgeParams, outerData);

    return {
      items: { table: { titles, rows }, list, raw },
      bridgeSelector,
      fetchOuter: fetchOuter,
      refetchOuter: refetchOuter ?? (() => Promise.resolve()),
      save: id && bridgeParams.saveOn === "onClick" ? () => save(id) : undefined,
      useRowSelected,
      useBridgeState,
      useBridgeArrayState,
      resetBridge,
      fuzzySearch,
      bridgeParams,
      bridgeStateCallBack,
    };
  }, [outerData]);

  useEffect(() => {
    if (!bridgeParams.selfMangedOuterFetch) fetchOuter();
  }, [bridgeParams, fetchOuter]);
  return res;
};

const bridgeStateToArray = (bridgeState: BridgeState) =>
  Object.values(bridgeState).reduce<string[]>((selected, elem) => {
    if (!elem.isDeleted) return selected.concat(elem.outerId);
    return selected;
  }, []);

const useConnectState = ({
  state,
  client,
  bridgeParams,
  id,
  updaters,
}: {
  updaters: (ids: string[]) => void;
  state: RecoilState<BridgeState>;
  bridgeParams: BridgeParams;
  id?: string;
  client: ApolloClient<unknown>;
}) => {
  const pending: string[] = [];
  const setBridgeState = useSetRecoilState(state);
  const query = makeBridgeEntityQuery({ bridgeParams, id });
  const persist = makePersistor({ client, bridgeParams });
  useRecoilTransactionObserver_UNSTABLE(({ snapshot, previousSnapshot }) => {
    const bridgeStateNew: BridgeState = snapshot.getLoadable(state).contents;
    const prevBridgeState: BridgeState = previousSnapshot.getLoadable(state).contents;
    if (id && bridgeParams.saveOn === "onChange") {
      const bridges = Object.keys(diff(prevBridgeState, bridgeStateNew)).reduce<BridgeState>((bridgeState, key) => {
        if (!Object.keys(bridgeStateNew[key]).includes("isDeleted")) return bridgeState;
        return { ...bridgeState, [key]: bridgeStateNew[key] };
      }, {});
      if (!Object.keys(bridges).length) return;
      const { creates, deletes } = getBridgeDelta({ bridgeParams, bridges, id });
      if (creates.length || deletes.length) {
        persist({ creates, deletes }).then(({ adds, removes }) => {
          setBridgeState((prev) => {
            const next = getNextBridgeState({ prev, adds, removes });
            return next;
          });
        });
      }
    }
    updateRaft({ bridges: bridgeStateNew, updaters });
  });
  useEffect(() => {
    if (id) {
      client.query<BridgeEntityRes, { id?: string }>({ query }).then(({ data }) => {
        const elems = data?.bridgeEntity?.items;
        if (elems.length) {
          const bridges = (elems ?? []).reduce<BridgeState>((bridges, { outerId, id }) => {
            return { ...bridges, [outerId]: { outerId, id } };
          }, {});
          setBridgeState(bridges);
          updateRaft({ bridges, updaters });
        }
      });
    }
  }, [id, setBridgeState]);
};

function updateRaft({ updaters, bridges }: { bridges: BridgeState; updaters: (ids: string[]) => void }) {
  const ids = Object.values(bridges).reduce<string[]>((ids, row) => {
    if (!row.isDeleted) return ids.concat(row.outerId);
    return ids;
  }, []);
  updaters(ids);
}

function makeItems(
  bridgeParams: BridgeParams,
  outerData: OuterRes | undefined
): { titles: string[]; rows: string[][]; list: { id: string; label: string }[]; raw: { [key: string]: string }[] } {
  const titles = ["id"].concat(
    bridgeParams.outerEntityColumns.map<string>((col) => {
      const title = (col.title ?? "").trim().length ? col.title : col.column;
      return title;
    })
  );
  const rows = (outerData?.outerEntity?.items ?? []).map((row) => {
    return Object.values(row).slice(1);
  }) as string[][];
  const list = rows.map<{ id: string; label: string }>((row) => {
    const label = bridgeParams.outerEntityColumns.reduce<string>((label, col, index) => {
      return `${label}${row[index + 1]}${index < bridgeParams.outerEntityColumns.length - 1 ? col.seperator : ""}`;
    }, "");
    return { id: row[0], label };
  });

  return { titles, rows, list, raw: outerData?.outerEntity?.items ?? [] };
}

export type UseBridge<T extends BridgeEntity = "unkown"> = {
  items: {
    table: {
      titles: string[];
      rows: string[][];
    };
    list: {
      id: string;
      label: string;
    }[];
    raw: {
      [key: string]: string;
    }[];
  };
  rowSelector: (param: string) => RecoilState<boolean>;
  refetchOuter: (
    variables?: Partial<EntityMappingsExtended[T]["filter"]> | undefined
  ) => Promise<ApolloQueryResult<OuterRes>>;
  save?: () => void;
  reset: () => void;
  useRowSelected: (id: string) => [boolean, SetterOrUpdater<boolean>];
  useBridgeState: () => [BridgeState, SetterOrUpdater<BridgeState>];
  useBridgeArrayState: () => [string[], SetterOrUpdater<string[]>];
  fuzzySearch: FuzzySearch;
  fetchOuter: LazyQueryExecFunction<
    OuterRes,
    {
      where?: EntityMappingsExtended[T]["filter"];
      sort?: EntityMappingsExtended[T]["sort"];
      skip?: number;
      take?: number;
    }
  >;
  bridgeParams: BridgeParams;
  resetBridge: Resetter;
  bridgeStateCallBack: () => Promise<{
    get: () => Promise<BridgeState>;
    set: (bridgeState: BridgeState) => Promise<void>;
  }>;
};

export function useInjectVariablesToFetchOuter<T extends BridgeEntity = "unkown">(useBridge: () => UseBridge<T>) {
  const { fetchOuter, ...rest } = useBridge();
  return (vars: Parameters<typeof fetchOuter>[0]) => () => {
    const useInjected = {
      ...rest,
      fetchOuter: (vars2: Parameters<typeof fetchOuter>[0]) => fetchOuter(vars),
    } as UseBridge;
    return useInjected;
  };
}
