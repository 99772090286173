export default {
  label: "Mein Profil",
  resetPassword: "Passwort zurücksetzen",
  notifcations: {
    notificationsTitle: "E-Mail-Benachrichtigungseinstellungen",
    notificationToggles: {
      label: {
        "fb9a3615-e273-5adb-a9de-b25920b76a60": "Hochladen neuer Zertifikate",
        "93dfb205-6f3b-5ee0-9463-936231c2318d": "Abgelehnte Zertifikate",
        "6e966a0f-a736-5e03-a298-dcf7e61e0435": "Korrigierte Zertifikate",
        "6bb90b10-23d2-5552-a26e-409b8108c857": "Validierte Zertifikate",
        "41612901-7e48-5021-bde3-9b0f767366d1": "Zertifizierungsfehler melden",
        "24a21cd6-34d8-585c-8b5b-e6c6675b80e6": "Fehlerablehnungen",
        "177c57ff-88a8-5a1c-8705-6f748b1d8578": "Fehlerbehebungen",
        "e9a03d08-c6b8-5aa4-b3e4-92904a3d3bb5": "Fehlerbestätigung",
        "96b84663-ad32-51eb-818e-b70a6400454e": "Zertifikatsablauf",
      },
      description: {
        "fb9a3615-e273-5adb-a9de-b25920b76a60":
          "Erhalten Sie sofort Benachrichtigungen bei neu hochgeladenen Zertifikate, um eine schnelle Genehmigung oder eine gründliche Überprüfung sicherzustellen.",
        "93dfb205-6f3b-5ee0-9463-936231c2318d":
          "Erhalten Sie sofort Benachrichtigungen bei Ablehnungen von Zertifikatsvalidierungen durch Gegenparteien",
        "6e966a0f-a736-5e03-a298-dcf7e61e0435":
          "Erhalten Sie sofort Warnungen, die Zertifikate zusammenfassen, bei denen aufgrund einer früheren Ablehnung während der Validierung Korrekturen vorgenommen wurden",
        "6bb90b10-23d2-5552-a26e-409b8108c857":
          "Erhalten Sie sofort Warnungen, die erfolgreiche Validierungen von Zertifikaten durch Gegenparteien zusammenfassen",
        "41612901-7e48-5021-bde3-9b0f767366d1":
          "Erhalten Sie sofort Benachrichtigungen, wenn Bedenken hinsichtlich potenzieller Fehler in der Genauigkeit des Zertifikats geäußert werden. Sie erhalten täglich eine Zusammenfassung aller neuen gemeldeten Probleme.",

        "24a21cd6-34d8-585c-8b5b-e6c6675b80e6":
          "Erhalten Sie sofort Benachrichtigungen, wenn von Ihnen geäußerte Bedenken hinsichtlich potenzieller Fehler in der Genauigkeit der Zertifikatsdaten von der kontrollierenden Partei als nicht gültig eingestuft wurden. Sie erhalten täglich eine Zusammenfassung solcher Fälle.",
        "177c57ff-88a8-5a1c-8705-6f748b1d8578":
          "Erhalten Sie sofort Benachrichtigungen, wenn Bedenken hinsichtlich eines Zertifikats für eine der Einheiten, für die Sie sich angemeldet haben, behoben wurden. Sie erhalten täglich eine Zusammenfassung solcher Fälle.",
        "e9a03d08-c6b8-5aa4-b3e4-92904a3d3bb5":
          "Erhalten Sie sofort Benachrichtigungen, wenn Fehler hinsichtlich eines Zertifikats für eine der Einheiten, für die Sie sich angemeldet haben, anerkannt wurden",
        "96b84663-ad32-51eb-818e-b70a6400454e":
          "Erhalten Sie Benachrichtigungen, wenn ein Zertifikat für eine der Einheiten, für die Sie sich angemeldet haben, abläuft",
      },
    },
  },
};
