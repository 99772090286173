import ModalWithButtons from "components/molecules/Modal/ModalWithButtons";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState, useSetRecoilState } from "recoil";
import { translations } from "translations";
import { certicateUploadProcessTypeAtom, certificateToReplaceIdAtom } from "../state/uploadCertificateStateBase";
import { CerticateUploadProcessType } from "../types";
import ReplaceCertificateSelect from "./ReplaceCertificateSelect";
import SelectProcess from "./SelectProcess";
interface CerticateUploadProcesSelectionProps {}

const CerticateUploadProcesSelection: React.FC<CerticateUploadProcesSelectionProps> = ({}) => {
  const navigate = useNavigate();
  const [certificateId, setCertificateId] = useState<string | undefined>(undefined);
  const [processOption, setProcessOption] = useState<number>(0);
  const [certicateUploadProcess, setCerticateUploadProcess] = useRecoilState(certicateUploadProcessTypeAtom);
  const setCertificateToReplaceId = useSetRecoilState(certificateToReplaceIdAtom);
  const goToCertificateUpload = useCallback(() => {
    navigate("/certificate-upload");
  }, [navigate]);

  const onClose = useCallback(() => {
    setCerticateUploadProcess(undefined);
    setProcessOption(0);
  }, [setCerticateUploadProcess]);

  const submitForNewCertificate = useCallback(() => {
    if (certificateId) setCertificateToReplaceId(certificateId);
    setCerticateUploadProcess(undefined);
    setCertificateId(undefined);
    onClose();
    goToCertificateUpload();
  }, [certificateId, setCertificateToReplaceId, setCerticateUploadProcess, onClose, goToCertificateUpload]);

  const continueDisabled =
    processOption === 0 || (certicateUploadProcess === CerticateUploadProcessType.replaceCertificate && !certificateId);
  const onContinue = useCallback(() => {
    if (continueDisabled) return;
    if (CerticateUploadProcessType.replaceCertificate === certicateUploadProcess) {
      submitForNewCertificate();
    }
    if (CerticateUploadProcessType.toDecide === certicateUploadProcess) {
      if (processOption === 1) {
        onClose();
        goToCertificateUpload();
      } else if (processOption === 2) {
        setCerticateUploadProcess(CerticateUploadProcessType.replaceCertificate);
      }
    }
  }, [
    certicateUploadProcess,
    continueDisabled,
    goToCertificateUpload,
    onClose,
    processOption,
    setCerticateUploadProcess,
    submitForNewCertificate,
  ]);

  return (
    <ModalWithButtons
      title={
        certicateUploadProcess === CerticateUploadProcessType.toDecide
          ? translations.pages.certificateUpload.label
          : translations.pages.certificateUpload.certificateUploadProcess.replaceExisting.title
      }
      openModal={!!certicateUploadProcess}
      onClose={onClose}
      addButtonTitle={translations.globals.button.continue}
      cancelTitle={translations.globals.button.back}
      addButtonClick={onContinue}
      width="auto"
      disabled={continueDisabled}
    >
      {certicateUploadProcess === CerticateUploadProcessType.toDecide && (
        <SelectProcess setProcessOption={setProcessOption} processOption={processOption} />
      )}
      {certicateUploadProcess === CerticateUploadProcessType.replaceCertificate && (
        <ReplaceCertificateSelect setCertificateId={setCertificateId} />
      )}
    </ModalWithButtons>
  );
};
export default CerticateUploadProcesSelection;
