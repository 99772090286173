import { atom } from "recoil";

export type DiscardChange = "reset" | "restart" | "";

export const discardChangesModalOpenState = atom({
  key: "discardChangesModalOpenState",
  default: false,
});

export const discardChangesState = atom<DiscardChange>({
  key: "discardChangesState",
  default: "",
});
