export type LabelArray = Array<{
  label: string;
  value: string;
  disabled?: boolean;
}>;

export type KeyArray = Array<{ key: string; value: string }>;
export interface SortOptionProps {
  labelArray?: LabelArray;
  keyArray?: KeyArray;
  sortFn: (value: string) => string;
}

export const getSortedOptions = ({
  labelArray,
  keyArray,
  sortFn,
}: {
  labelArray?: LabelArray;
  keyArray?: KeyArray;
  sortFn: (value: string) => string;
}): LabelArray | KeyArray => {
  const sortFnSave = (value: string) => {
    return sortFn(value) || "";
  };
  if (labelArray) {
    return labelArray
      .map((i) => i)
      .filter((a) => sortFn(a.label) || sortFn(a.value))
      .sort((a, b) => {
        return sortFn(a.label)
          ? sortFnSave(a.label).localeCompare(sortFnSave(b.label))
          : sortFnSave(a.value).localeCompare(sortFnSave(b.value));
      });
  }
  if (keyArray) {
    return keyArray
      .map((i) => i)
      .filter((a) => sortFn(a.key) || sortFn(a.value))
      .sort((a, b) => {
        return sortFnSave(a.key).localeCompare(sortFnSave(b.key));
      });
  }
  return [];
};
