import * as React from "react";
import Props from "./index.d";
import { StyledUnitRow } from "./styles";

interface GFCFieldSet_UnitRow extends Props {
  children: React.ReactNode;
}

const UnitRow: React.FC<GFCFieldSet_UnitRow> = ({ children, className }) => {
  const childrenCount = React.Children.count(children);

  return (
    <StyledUnitRow isSingle={childrenCount === 1} className={className}>
      {children}
    </StyledUnitRow>
  );
};

export default UnitRow;
