import { execGetIssueTypeNamesQuery } from "graphqlBase/issue/__generated__/getIssueTypeNames";
import { getTranslatedIssueTypeString } from "lib/helper/getTranslatedRowDataStrings";

export default async function getIdsForFilterValues(quickValues: string[]) {
  // const mappedFilterValue = Object.entries(filterValue).map(([key, value]) => {
  //   return { key, value };
  // });
  const elems = await execGetIssueTypeNamesQuery({});

  // const idsForFilterValues = elems.data?.issueTypes?.items?.reduce<string[]>((idsForFilterValues, item) => {
  //   if (
  //     mappedFilterValue.some(({ key, value }) => {
  //       const translatedString = getTranslatedIssueTypeString(item.key).toLowerCase();
  //       const filterValueLower = typeof value === "string" ? value.toLowerCase() : "";
  //       if (key === "contains") return translatedString.includes(filterValueLower);
  //       if (key === "endsWith") return translatedString.endsWith(filterValueLower);
  //       if (key === "startsWith") return translatedString.startsWith(filterValueLower);
  //       if (key === "equals") return translatedString === filterValueLower;
  //       // if (key === "neq") return translatedString !== filterValueLower;
  //       // if (key === "ncontains") return !translatedString.includes(filterValueLower);
  //     })
  //   )
  //     return idsForFilterValues.concat(item.key);
  //   return idsForFilterValues;
  // }, []);

  const idsForQuickValues = elems.data?.issueTypes?.items?.reduce<string[]>((idsForQuickValues, item) => {
    if (
      [quickValues?.join(" ")].some((value) =>
        getTranslatedIssueTypeString(item.key)
          .toLowerCase()
          .includes(value?.toLowerCase() ?? "undefined")
      )
    )
      return idsForQuickValues.concat(item.key);
    return idsForQuickValues;
  }, []);

  return { idsForQuickValues };
}
