import React, { useState } from "react";
import translations from "translations";
import { StyledAddButton } from "../styles";
import { AddNewSoftwareBridgeProps } from "./AddNewSoftwareBridge";
import CreateSoftwareModal from "./CreateSoftwareModal";
interface Props extends AddNewSoftwareBridgeProps {}

const CreateSoftware: React.FC<Props> = (props) => {
  const [openModal, setOpenModal] = useState(false);

  const openSoftwareModal = () => {
    setOpenModal(true);
  };

  return (
    <>
      <StyledAddButton onClick={openSoftwareModal}>{translations.globals.button.addSimulationSoftware}</StyledAddButton>
      <CreateSoftwareModal {...props} openModal={openModal} setOpenModal={setOpenModal} title="Add Software" />
    </>
  );
};

export default CreateSoftware;
