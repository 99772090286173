import styled from "@emotion/styled";
import { Box } from "@mui/system";
import React from "react";
interface IssueTypeNameIconProps {
  icon?: React.ReactNode;
  iconBackgroundColor: string;
}

export const IssueTypeNameIconBox = styled(Box)<{ iconBackgroundColor: string }>(({ iconBackgroundColor }) => ({
  display: "flex",
  padding: "4px 8px",
  alignItems: "center",
  justifyContent: "center",
  width: "2.25rem",
  height: "2.25rem",
  gap: "8px",
  borderRadius: "8px",
  //   border: `1px solid ${ordinaryColors.icon} `,
  backgroundColor: iconBackgroundColor,
}));

const IssueTypeNameIcon: React.FC<IssueTypeNameIconProps> = ({ icon, iconBackgroundColor }) => {
  return <IssueTypeNameIconBox iconBackgroundColor={iconBackgroundColor}>{icon}</IssueTypeNameIconBox>;
};

export default IssueTypeNameIcon;
