import { gql } from "@apollo/client";
import singleMultiNameMappings, { multiSingleNameMappings } from "graphqlBase/__utils__/singleMultiNameMappings";
import { create } from "lodash";
import { BridgeParams } from "./BridgeParamTypes";

export const upperCaseFirst = (text: string) => text.charAt(0).toUpperCase() + text.slice(1);

export const makeOuterEntityQuery = (bridgeParams: BridgeParams) => {
  const outerEntity = singleMultiNameMappings[bridgeParams.outerEntityName];
  const outerEntityUpper = upperCaseFirst(bridgeParams.outerEntityName);
  //   $skip: Int
  // $take: Int
  // $where: AssetTagFilterInput
  // $order: [AssetTagSortInput!]

  const query = gql`
    query getOuter($skip: Int
$take: Int
$where: ${outerEntityUpper}FilterInput
$order: [${outerEntityUpper}SortInput!]){
  outerEntity:${outerEntity}(skip: $skip, take: $take, where: $where, order: $order)
  {
    items{
      id
      ${bridgeParams.outerEntityColumns.map(({ column }) => column).join("\n")}
    }
  }
}
`;
  return query;
};

export const makeBridgeEntityQuery = ({ bridgeParams, id }: { bridgeParams: BridgeParams; id?: string }) => {
  const query = `
  query ${bridgeParams.bridgeEntityName}RaftBridgeQuery{
  bridgeEntity:${bridgeParams.bridgeEntityName} (where: {${bridgeParams.innerId}: {
    eq: \"${id}\"}}) {
      items{
        id
        innerId: ${bridgeParams.innerId}
          outerId: ${bridgeParams.outerId}
        }
    }
  }
`;

  return gql(query);
};

export const makeBridgeRaftMutation = ({ bridgeParams }: { bridgeParams: BridgeParams }) => {
  const bridgeEntity = bridgeParams.bridgeEntityName;
  const single = multiSingleNameMappings[bridgeEntity];
  // eslint-disable-next-line
  const fullMutationString = `mutation ${bridgeParams.bridgeEntityName}RaftMutation($creates: [${upperCaseFirst(
    single
  )}CreateInput!]!, $deletes: [UUID!]!) {
    create${upperCaseFirst(bridgeEntity)} (
    ${bridgeEntity}: $creates
    ) {
    id
    outerId:${bridgeParams.outerId}
  }
    delete${upperCaseFirst(bridgeEntity)} (ids: $deletes) {
    id
    outerId: ${bridgeParams.outerId}
  }
} `;

  const deleteMutationString = `mutation delete${upperCaseFirst(bridgeEntity)}($deletes: [UUID!]!) {
  delete${upperCaseFirst(bridgeEntity)}(ids: $deletes) {
    id
    outerId: ${bridgeParams.outerId}  
    }
    }
    `;
  const createMutationString = `mutation create${upperCaseFirst(bridgeEntity)}($creates: [${upperCaseFirst(
    single
  )}CreateInput!]!) {
      create${upperCaseFirst(bridgeEntity)}(${bridgeEntity}: $creates) {
        id
        outerId: ${bridgeParams.outerId}
      }
    }
    `;
  return {
    mutation: gql(fullMutationString),
    deleteMutation: gql(deleteMutationString),
    createMutation: gql(createMutationString),
  };
};
