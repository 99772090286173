import React from "react";
import switchStatus from "./switchStatus";
type StatusCellProps = {
  status: string | undefined;
};
const StatusCell: React.FC<StatusCellProps> = ({ status }) => {
  const showSwitchStatus = switchStatus({ status: status || "" });
  return <>{showSwitchStatus}</>;
};
export default StatusCell;
