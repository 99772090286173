import { FormHelperText, InputBase, MenuItem, Select, SelectChangeEvent, styled } from "@mui/material";
import { getTranslatedCategoryEnumString } from "lib/helper/getTranslatedRowDataStrings";
import * as React from "react";
import ordinaryColors from "themes/ordinaryColors";
import Props from "./index.d";
import { useGetCategoryOptions } from "./useGetCategoryOptions";
import { LabelArray, getSortedOptions } from "lib/helper/getSortedOptions";

const StyledInput = styled(InputBase)(({ error }) => ({
  maxHeight: "43px",
  display: "flex",
  padding: "8px 12px",
  alignContent: "center",
  rowGap: "100px",
  flex: "1 0 0",
  flexWrap: "wrap",
  borderRadius: "0px",
  border: "1px solid ",
  borderColor: error ? ordinaryColors.error : ordinaryColors.border,
  backgroundColor: error ? "rgba(211, 47, 47, 0.02)" : "transparent",
  marginBottom: "0px",
}));

const CertificateCategory: React.FC<Props> = (props) => {
  const {
    data: { state, setstate, setNull },
    disabled,
    testId,
    className,
    errorManager,
  } = props;

  const change = (e: SelectChangeEvent) => {
    const value = e.target.value;

    if (value === null) {
      if (setNull) {
        setNull();
      } else {
        setstate("");
      }
    } else {
      if (typeof value === "string") setstate(value);
    }
  };

  const categoryOptions = useGetCategoryOptions();

  const errorManagerRes = errorManager({
    errorJoiner: (errors) => {
      return errors.join(", ");
    },
  });

  return (
    <>
      <Select
        value={state ?? ""}
        onChange={change}
        input={<StyledInput error={errorManagerRes.error} />}
        disabled={disabled}
        className={className}
        data-testid={testId}
        {...errorManagerRes}
      >
        {categoryOptions &&
          (
            getSortedOptions({ labelArray: categoryOptions, sortFn: getTranslatedCategoryEnumString }) as LabelArray
          ).map((option, index) => (
            <MenuItem key={index} value={option.value} disabled={option.disabled}>
              {getTranslatedCategoryEnumString(option.value)}
            </MenuItem>
          ))}
      </Select>
      {errorManagerRes.error && (
        <FormHelperText sx={{ color: ordinaryColors.error, marginLeft: "0px", fontSize: "12px" }}>
          {errorManagerRes.helperText}
        </FormHelperText>
      )}
    </>
  );
};

export default CertificateCategory;
