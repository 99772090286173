import { useEffect, useState, useCallback } from "react";
import { StateEffects } from "../formGen.model";

export default ({
  valueIn,
  idPre,
  saveData,
  required,
  delayCommitIn,
  commitOnBlur,
  onDefocus,
}: {
  valueIn: any;
  idPre: string;
  saveData: (data: any) => void;
  required?: boolean;
  delayCommitIn?: number;
  commitOnBlur?: boolean;
  onDefocus: () => void;
}) => {
  const id = idPre,
    value = valueIn !== null ? valueIn : undefined,
    delayCommit = delayCommitIn ?? 500;

  const [state, setstateInner] = useState(value),
    [isBlured, setBlured] = useState(false);

  const setstate = (val: any) => {
    setstateInner(val === null ? undefined : val);
  };

  useEffect(() => {
    if (isBlured || (!commitOnBlur && !delayCommit)) {
      saveData({ [id]: state, commit: isBlured });
      setBlured(false);
    }
  }, [state, isBlured]);

  const onBlur = () => {
    onDefocus();
    setBlured(true);
  };

  useEffect(() => {
    if (delayCommit && !commitOnBlur) {
      const handler = setTimeout(() => {
        saveData({ [id]: state, commit: true });
      }, delayCommitIn);
      return () => {
        clearTimeout(handler);
      };
    }
  }, [state]);

  const updateValue = (event: React.ChangeEvent<HTMLSelectElement>) => {
    onDefocus();
    setstate(event.target.value);
  };
  const setAndSave = useCallback(
    (val: any) => {
      if (typeof val === "function") {
        const newVal = val(state);
        setstateInner(newVal);
        onDefocus();
        saveData({ [id]: newVal, commit: true });
      } else {
        setstateInner(val);
        onDefocus();
        saveData({ [id]: val, commit: true });
      }
    },
    [state, id, saveData, onDefocus, setstateInner]
  );

  const setNull = required
    ? undefined
    : () => {
        onDefocus();
        setstateInner(null);
        saveData({ [id]: null, commit: true });
      };

  // useEffect(() => {
  //   console.log(id, value);
  //   setstateInner(value);
  //   return () => {};
  // }, [value]);

  const stateEffects: StateEffects = {
    state,
    setstate,
    updateValue,
    onBlur,
    setAndSave,
    setNull,
  };
  return stateEffects;
};
