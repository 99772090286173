import * as React from "react";
import MuiTextField from "@mui/material/TextField";
import { useEffect, ChangeEventHandler } from "react";
import Props from "./index.d";

const ManufacturerId: React.FC<Props> = (props) => {
  const {
    title,

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    data: { state, setstate, onBlur, updateValue, setNull, setAndSave },
    disabled,
    testId,
    errors,
    isRequiredField,
    errorManager,
    className,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ...rest
  } = props;

  const change: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = (e) => {
    const value = e.target.value;

    if (value === null) {
      if (setNull) {
        setNull();
      } else {
        setstate("");
      }
    } else {
      if (typeof value === "string") setstate(value);
    }
  };
  useEffect(() => {
    if (!state) {
      setstate("e2648199-e8b4-474b-8f4f-48e004389e1b");
    }
  }, [state, setstate]);

  const errorManagerRes = errorManager({
    errorJoiner: (errors) => {
      console.log(errors);
      return errors.join(", ");
    },
  });
  return (
    <>
      <MuiTextField
        sx={{ height: "35px", marginBottom: errorManagerRes.error ? "27px" : "14px" }}
        onBlur={onBlur}
        value={state || ""}
        onChange={change}
        disabled={props.disabled}
        label={title}
        className={className}
        // showRemoveIcon={!isRequiredField}
        required={isRequiredField}
        InputProps={{ sx: { height: "40px" } }}
        {...errorManagerRes}
      />
    </>
  );
};

export default ManufacturerId;
