export default {
  notSpecified: "Keine Angabe",
  notAssigned: "nicht zugewiesen",
  globalErrorMessageHeader: "Fehler",
  globalErrorMessage:
    "Während Ihrer Anfrage ist ein Fehler aufgetreten und der IT-Support wurde informiert. Wir werden dieses Problem so schnell wie möglich beheben und entschuldigen uns für die Unannehmlichkeiten.",
  addModelName: "Modell Name hinzufügen",
  resetPassword:
    "Sie werden auf eine externe Website weitergeleitet, um Ihr Passwort zurückzusetzen. Möchten Sie fortfahren?",
};
