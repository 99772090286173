import {
  gridFilterModelSelector,
  gridPageSelector,
  gridPageSizeSelector,
  gridSortModelSelector,
} from "@mui/x-data-grid-pro";
import { EntityMappings } from "graphqlBase/__utils__/entityMappings";
import { SortEnumType } from "graphqlBase/types";
import * as React from "react";
import { useMemo } from "react";
import { BaseRow, EditableGridStateColDef, FilterParams, OnFilterOnSortCallBackProps } from "./types";

async function onQueryParmsChange<E extends keyof EntityMappings>({
  query,
  apiRef,
  variables = {},
}: OnFilterOnSortCallBackProps<E>) {
  if (!apiRef.current) return;
  const take = gridPageSizeSelector(apiRef);
  const skip = gridPageSelector(apiRef) * take;
  const filterModel = gridFilterModelSelector(apiRef);
  const filterPreProm = filterModel.items.reduce<Array<EntityMappings[E]["filter"]>>(
    (where, { columnField, operatorValue, value }) => {
      const column = apiRef.current.getColumn(columnField) as EditableGridStateColDef | undefined;
      if (!column?.remoteFilter?.filterPath || !operatorValue || !value) return where;
      const filterFunc = column?.remoteFilter?.filterPath as FilterParams<
        BaseRow,
        EntityMappings[E]["filter"]
      >["filterPath"];
      return where.concat(
        filterFunc({
          where,
          filterValue: { [operatorValue]: value },
          value,
        })
      );
    },
    []
  );

  const quickValues = (filterModel.quickFilterValues ?? []) as string[];

  const fuzzProm = (apiRef.current.getVisibleColumns() as EditableGridStateColDef[]).reduce<unknown[]>(
    (where, { type, remoteFilter }) => {
      if (!quickValues.length) return where;
      if (type === "string" && remoteFilter) {
        const filterFunc = remoteFilter?.filterPath ?? function () {};
        const condition = quickValues.map((value) => ({ contains: value }));
        return where.concat(
          filterFunc({
            where,
            filterValue: { or: condition },
            quickValues,
          }) as EntityMappings[E]["filter"]
        );
      }
      return where;
    },
    []
  );
  const [filterPre, fuzzy] = await Promise.all([Promise.all(filterPreProm), Promise.all(fuzzProm)]);
  const fuzzyFilter = { or: [...fuzzy] } as EntityMappings[E]["filter"];
  const filter = fuzzy.length ? (filterPre.length ? filterPre.concat(fuzzyFilter) : [fuzzyFilter]) : filterPre;

  const combinator = variables.where?.[filterModel.linkOperator ?? "and"];
  const nextWhere = filter.length ? [...(combinator ?? []), ...filter] : undefined;

  const where = nextWhere ? { ...variables.where, [filterModel.linkOperator ?? "and"]: nextWhere } : variables.where;

  const sort = gridSortModelSelector(apiRef).reduce<EntityMappings[E]["sort"]>((sorts, elem) => {
    const column = apiRef.current.getColumn(elem.field) as EditableGridStateColDef | undefined;
    if (!column?.remoteOrder || !elem.sort) return sorts;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return { ...sorts, ...(column.remoteOrder(elem.sort.toUpperCase() as SortEnumType) as any) };
  }, {});

  const sortIn = variables.order ?? {};
  const order = sortIn || Object.keys(sort).length ? (sortIn ? { ...sort, ...sortIn } : sort) : undefined;
  query({
    variables: { ...variables, take, skip, order, where },
  });
}
export function useExecuteQuery<E extends keyof EntityMappings>({
  apiRef,
  query,
  variables,
}: OnFilterOnSortCallBackProps<E>): () => void {
  const varsStr = JSON.stringify(variables);
  React.useEffect(() => {
    onQueryParmsChange<E>({ query, apiRef, variables });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiRef, varsStr, query]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const executeQuery = useMemo(() => () => onQueryParmsChange<E>({ query, apiRef, variables }), [apiRef]);
  return executeQuery;
}
