import React, { useEffect } from "react";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { Box, styled, SxProps, Theme, Typography } from "@mui/material";
import { MUIStyledCommonProps } from "@mui/system";
import { DefaultValue, SetterOrUpdater } from "recoil";

interface AccProps extends AccordionProps {
  accordionSx?: MUIStyledCommonProps["sx"];
  accordiondetailssx?: MUIStyledCommonProps["sx"];
  accordionId: string;
  accordionsummarysx?: MUIStyledCommonProps["sx"];
  boxSx?: SxProps<Theme>;
  expandIcon?: React.ReactNode;
  setValue: SetterOrUpdater<boolean | DefaultValue | undefined> | React.Dispatch<React.SetStateAction<boolean>>;
  sx?: SxProps<Theme>;
  title?: string;
  value?: boolean | DefaultValue | undefined;
}

export interface StyledAccordionSummaryProps {
  accordionsummarysx?: MUIStyledCommonProps["sx"];
}

export interface StyledAccordionDetailsProps {
  accordiondetailssx?: MUIStyledCommonProps["sx"];
}

const StyledAccordionSummary = styled(MuiAccordionSummary)(({ accordionsummarysx }: StyledAccordionSummaryProps) => ({
  ...accordionsummarysx,
}));

const StyledAccordionDetails = styled(MuiAccordionDetails)(({ accordiondetailssx }: StyledAccordionDetailsProps) => ({
  ...accordiondetailssx,
}));

const Accordion: React.FC<AccProps> = (props) => {
  const { accordiondetailssx, accordionsummarysx, setValue, value } = props;

  useEffect(() => {
    setValue(true);
  }, [setValue]);

  return (
    <MuiAccordion
      sx={props.accordionSx}
      expanded={(value as boolean | undefined) ?? true}
      id={props.id}
      onChange={() => setValue(!value)}
      disableGutters
      elevation={0}
      square
    >
      <StyledAccordionSummary
        accordionsummarysx={accordionsummarysx}
        aria-controls="panel-content"
        expandIcon={props.expandIcon}
        id="panel-header"
        sx={props.sx}
      >
        {props.title && <Typography>{props.title}</Typography>}
      </StyledAccordionSummary>
      <StyledAccordionDetails accordiondetailssx={accordiondetailssx}>
        <Box sx={props.boxSx}>{props.children}</Box>
      </StyledAccordionDetails>
    </MuiAccordion>
  );
};

export default Accordion;
