import { format, formatDistanceToNow } from "date-fns";
import { de, enGB } from "date-fns/locale";
import translations from ".";

export default () => {};

export function capitalizeString(input: string | undefined) {
  if (!input) return "";
  return `${input[0].toUpperCase()}${input.slice(1)}`;
}

const {
  hours: hoursTransl,
  minutes: minutesTransl,
  hour: hourTransl,
  minute: minuteTransl,
} = translations.globals.time;
const { hrs, mins, hr, min } = translations.globals.shortTerms;

export const getCurrency = (value: number, currency = "EUR") => {
  const currentLanguage = translations.getLanguage();
  return new Intl.NumberFormat(currentLanguage, { style: "currency", currency }).format(value);
};

export const formatTimeDistance = (date: Date) => {
  const currentLanguage = translations.getLanguage();
  const locale = currentLanguage === "en" ? enGB : de;

  return formatDistanceToNow(date, {
    addSuffix: true,
    locale: locale,
  });
};

export const formatDate = (date: Date) => {
  const currentLanguage = translations.getLanguage();
  const options: Intl.DateTimeFormatOptions = {
    day: "numeric",
    month: "short",
    year: "numeric",
  };
  // @ts-ignore
  return Intl.DateTimeFormat(currentLanguage, options).format(date);
};

export const formatISODateString = (date: string) => {
  return formatDate(new Date(date));
};

export const formatTime = (date: Date) => {
  const currentLanguage = translations.getLanguage();
  const options: Intl.DateTimeFormatOptions = {
    hour: "numeric",
    minute: "numeric",
  };
  // @ts-ignore
  return new Intl.DateTimeFormat(currentLanguage, options).format(date);
};

export const formatDateTime = (date: Date) => {
  const currentLanguage = translations.getLanguage();
  const options: Intl.DateTimeFormatOptions = {
    day: "numeric",
    month: "short",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
  };
  // @ts-ignore
  return Intl.DateTimeFormat(currentLanguage, options).format(date);
};

export const getFormatedDateTime = ({
  date,
  formatString = "MM/dd/yyyy, kk:mm",
}: {
  date: Date | undefined;
  formatString?: string;
}): string => {
  if (!date) return "unknown";
  return format(date, formatString);
};

// export const formatDateTime2 = (date: Date) => {
//   const currentLanguage = translations.getLanguage();
//   console.log(currentLanguage);

//   const options: Intl.DateTimeFormatOptions = {
//     day: "numeric",
//     month: "short",
//     year: "numeric",
//     hour: "numeric",
//     minute: "numeric",
//   };
//   // @ts-ignore
//   return Intl.DateTimeFormat(currentLanguage, options).format(date);
// };

export const formatDateForRange = (startDate: Date, endDate: Date) => {
  const currentLanguage = translations.getLanguage();
  const options: Intl.DateTimeFormatOptions = {
    day: "numeric",
    month: "numeric",
    year: "numeric",
  };

  return [
    // @ts-ignore
    Intl.DateTimeFormat(currentLanguage, options).format(startDate),
    // @ts-ignore
    Intl.DateTimeFormat(currentLanguage, options).format(endDate),
  ];
};

export const formatDateTimeForRange = (startDate: Date, endDate: Date) => {
  const currentLanguage = translations.getLanguage();
  const options: Intl.DateTimeFormatOptions = {
    day: "numeric",
    month: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
  };

  return [
    // @ts-ignore
    Intl.DateTimeFormat(currentLanguage, options).format(startDate),
    // @ts-ignore
    Intl.DateTimeFormat(currentLanguage, options).format(endDate),
  ];
};

export const getNumber = (number: number, minimumFractionDigits = 2) => {
  const currentLanguage = translations.getLanguage();
  return new Intl.NumberFormat(currentLanguage, { minimumFractionDigits }).format(number);
};

export const getDistance = (distance: number) => {
  const numericString = getNumber(distance);
  return `${numericString} km`;
};

export const getHoursAndMinutes = (timeInMinsIn: number) => {
  const timeInMins = Math.round(timeInMinsIn);
  const hours = Math.floor(timeInMins / 60);
  const minutes = timeInMins % 60;
  return { hours, minutes };
};

export const formatHoursAndMinutes = ({
  hours,
  minutes,
  short = false,
}: {
  hours: number;
  minutes: number;
  short?: boolean;
}) => {
  const hoursUnit = hours > 1 ? (short ? hrs : hoursTransl) : short ? hr : hourTransl;
  const minutesUnit = minutes > 1 ? (short ? mins : minutesTransl) : short ? min : minuteTransl;
  let hoursString = "";
  let minutesString = "";

  if (hours > 0) hoursString = `${hours} ${hoursUnit}`;
  if (minutes > 0) minutesString = `${minutes} ${minutesUnit}`;

  return `${hoursString} ${minutesString} ago`;
};

export const capitalizeFirstLetter = (str: string) => {
  const firstChar = str?.split(" ")?.shift()?.charAt(0).toUpperCase() ?? "";
  const lastChar = str?.split(" ")?.pop()?.charAt(0).toUpperCase() ?? "";
  return firstChar + lastChar;
};
