import { Box, styled, Typography } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import {
  GetCertificatesForRelacementQueryVariables,
  useGetCertificatesForRelacementQuery,
} from "graphqlBase/certificates/__generated__/getCertificatesForRelacement";
import { useGetUserInfo } from "lib/userInfo";
import { useDebounce } from "pages/CertificationManagement/CustomDataGridFilters/utils/useCustomFilterbar";
import switchStatus from "pages/CertificationManagement/UnitsOverview/StatusColumn/switchStatus";
import { useMemo, useState } from "react";
import { translations } from "translations";
interface ReplaceCertificateSelectProps {
  setCertificateId: React.Dispatch<React.SetStateAction<string | undefined>>;
}

export const OptionLabelSC = styled(Typography, {
  label: "OptionLabel",
  shouldForwardProp(propName) {
    return propName !== "status";
  },
})(() => ({
  width: "50%",
  marginRight: "1rem",
}));

const StyledTextField = styled(TextField, {
  shouldForwardProp: (prop) => prop !== "status",
})(() => ({
  "& .MuiAutocomplete-inputRoot": {
    borderRadius: "0px",
  },
}));

const ReplaceCertificateSelect: React.FC<ReplaceCertificateSelectProps> = ({ setCertificateId }) => {
  const userInfo = useGetUserInfo();
  const [search, setSearch] = useState("");

  const searchDebounced = useDebounce(search, 500);

  const variables: GetCertificatesForRelacementQueryVariables = useMemo(() => {
    const tenantFilter = {
      or: [
        {
          certificateAuthorityId: {
            eq: userInfo?.tenantId ?? "",
          },
        },
        {
          certificateHolderId: {
            eq: userInfo?.tenantId ?? "",
          },
        },
      ],
    };

    return {
      where: {
        certificateNumber: {
          contains: searchDebounced,
        },
        certificateBridgeCertificateNormIssueDates: {
          none: {
            certificateNormIssueDate: {
              certificateNormBridges: {
                some: {
                  certificateNorm: { certificateNormName: { contains: "4105" } },
                },
              },
            },
          },
        },
        ...tenantFilter,
      },
      take: 5,
      order: [
        {
          certificateNumber: "ASC",
          revision: "DESC",
        },
      ],
    };
  }, [searchDebounced, userInfo]);

  const { data } = useGetCertificatesForRelacementQuery({
    variables,
    skip: !searchDebounced || !searchDebounced.length,
    fetchPolicy: "network-only",
  });

  const options = useMemo(() => {
    return (data?.certificates?.items ?? []).map((item) => ({
      label: item.certificateNumber,
      revision: item.revision,
      value: item.id,
      status: item.certificateValidityStatus.certificateValidityStatusName,
    }));
  }, [data]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "2rem",
        flex: "1 0 0",
      }}
    >
      <Typography variant="body1">
        {translations.pages.certificateUpload.certificateUploadProcess.replaceCertificatePropmt}
      </Typography>
      <Autocomplete
        disablePortal
        // value={certificateToReplaceId}
        onChange={(event, newValue) => {
          if (newValue?.value) setCertificateId(newValue.value);
        }}
        inputValue={search}
        onInputChange={(event, newInputValue) => {
          setSearch(newInputValue);
        }}
        noOptionsText={translations.pages.certificateUpload.noOptionsText}
        id="combo-box-demo"
        options={options}
        sx={{ width: "100%", borderRadius: "0px" }}
        renderInput={(params) => <StyledTextField {...params} />}
        renderOption={(props, option) => (
          <li {...props}>
            <OptionLabelSC>{option.label}</OptionLabelSC>{" "}
            <OptionLabelSC>
              {translations.entities.certificate.revision}: {option.revision ?? 0}
            </OptionLabelSC>
            {switchStatus(option)}
          </li>
        )}
      />
    </Box>
  );
};
export default ReplaceCertificateSelect;
