import * as React from "react";

interface UploadedFileIconProps {
  fileType: string;
}

const UploadedFileIcon = (props: UploadedFileIconProps & JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => {
  if (props.fileType.toUpperCase() === "PDF")
    return (
      <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <g id="vscode-icons:file-type-pdf2">
          <path
            id="Vector"
            d="M24.0999 2.07227L29.6639 7.87227V29.9283H8.87891V30.0003H29.7349V7.94527L24.0999 2.07227Z"
            fill="#909090"
          />
          <path id="Vector_2" d="M24.0316 2H8.80859V29.928H29.6646V7.873L24.0316 2Z" fill="#F4F4F4" />
          <path id="Vector_3" d="M8.65562 3.5H2.26562V10.327H22.3656V3.5H8.65562Z" fill="#7A7B7C" />
          <path id="Vector_4" d="M22.4715 10.2109H2.39453V3.37891H22.4715V10.2109Z" fill="#DD2025" />
          <path
            id="Vector_5"
            d="M9.05114 4.5337H7.74414V9.3337H8.77214V7.7147L8.99914 7.7277C9.21971 7.7239 9.43821 7.68439 9.64614 7.6107C9.82844 7.54799 9.99615 7.449 10.1391 7.3197C10.2846 7.19652 10.3994 7.04105 10.4741 6.8657C10.5744 6.57419 10.6103 6.2644 10.5791 5.9577C10.5729 5.7386 10.5345 5.52162 10.4651 5.3137C10.402 5.1636 10.3083 5.0283 10.1901 4.91639C10.0718 4.80448 9.9315 4.71842 9.77814 4.6637C9.64555 4.61569 9.50856 4.58086 9.36914 4.5597C9.26357 4.54341 9.15696 4.53472 9.05014 4.5337M8.86114 6.8277H8.77214V5.3477H8.96514C9.05032 5.34155 9.13579 5.35463 9.21524 5.38596C9.29469 5.41729 9.36608 5.46607 9.42414 5.5287C9.54446 5.68971 9.60873 5.8857 9.60714 6.0867C9.60714 6.3327 9.60714 6.5557 9.38514 6.7127C9.22521 6.80066 9.04316 6.84127 8.86114 6.8277ZM12.5321 4.5207C12.4211 4.5207 12.3131 4.5287 12.2371 4.5317L11.9991 4.5377H11.2191V9.3377H12.1371C12.488 9.34731 12.8373 9.28785 13.1651 9.1627C13.4291 9.05802 13.6627 8.88928 13.8451 8.6717C14.0225 8.45215 14.1498 8.19653 14.2181 7.9227C14.2967 7.61256 14.335 7.29361 14.3321 6.9737C14.3515 6.59585 14.3223 6.21709 14.2451 5.8467C14.1719 5.57406 14.0348 5.32279 13.8451 5.1137C13.6963 4.94486 13.5142 4.80867 13.3101 4.7137C13.1349 4.63261 12.9506 4.57285 12.7611 4.5357C12.6858 4.52325 12.6095 4.51756 12.5331 4.5187M12.3511 8.4557H12.2511V5.3917H12.2641C12.4703 5.36798 12.6789 5.40517 12.8641 5.4987C12.9998 5.60702 13.1103 5.7435 13.1881 5.8987C13.2721 6.06203 13.3205 6.2413 13.3301 6.4247C13.3391 6.6447 13.3301 6.8247 13.3301 6.9737C13.3342 7.14533 13.3232 7.317 13.2971 7.4867C13.2663 7.66093 13.2093 7.8295 13.1281 7.9867C13.0362 8.13285 12.9121 8.256 12.7651 8.3467C12.6418 8.42649 12.4957 8.46372 12.3491 8.4527M17.4291 4.5377H14.9991V9.3377H16.0271V7.4337H17.3271V6.5417H16.0271V5.4297H17.4271V4.5377"
            fill="#464648"
          />
          <path
            id="Vector_6"
            d="M21.7807 20.2545C21.7807 20.2545 24.9687 19.6765 24.9687 20.7655C24.9687 21.8545 22.9937 21.4115 21.7807 20.2545ZM19.4237 20.3375C18.9172 20.4494 18.4236 20.6133 17.9507 20.8265L18.3507 19.9265C18.7507 19.0265 19.1657 17.7995 19.1657 17.7995C19.643 18.6029 20.1984 19.3572 20.8237 20.0515C20.3521 20.1218 19.8847 20.218 19.4237 20.3395V20.3375ZM18.1617 13.8375C18.1617 12.8885 18.4687 12.6295 18.7077 12.6295C18.9467 12.6295 19.2157 12.7445 19.2247 13.5685C19.1468 14.3971 18.9733 15.2138 18.7077 16.0025C18.3438 15.3404 18.1555 14.5961 18.1607 13.8405L18.1617 13.8375ZM13.5127 24.3535C12.5347 23.7685 15.5637 21.9675 16.1127 21.9095C16.1097 21.9105 14.5367 24.9655 13.5127 24.3535ZM25.8997 20.8945C25.8897 20.7945 25.7997 19.6875 23.8297 19.7345C23.0086 19.7213 22.1878 19.7792 21.3767 19.9075C20.591 19.1159 19.9143 18.223 19.3647 17.2525C19.711 16.2519 19.9205 15.2092 19.9877 14.1525C19.9587 12.9525 19.6717 12.2645 18.7517 12.2745C17.8317 12.2845 17.6977 13.0895 17.8187 14.2875C17.9372 15.0926 18.1608 15.8786 18.4837 16.6255C18.4837 16.6255 18.0587 17.9485 17.4967 19.2645C16.9347 20.5805 16.5507 21.2705 16.5507 21.2705C15.5734 21.5887 14.6534 22.0613 13.8257 22.6705C13.0017 23.4375 12.6667 24.0265 13.1007 24.6155C13.4747 25.1235 14.7837 25.2385 15.9537 23.7055C16.5753 22.9137 17.1433 22.0812 17.6537 21.2135C17.6537 21.2135 19.4377 20.7245 19.9927 20.5905C20.5477 20.4565 21.2187 20.3505 21.2187 20.3505C21.2187 20.3505 22.8477 21.9895 24.4187 21.9315C25.9897 21.8735 25.9137 20.9925 25.9037 20.8965"
            fill="#DD2025"
          />
          <path id="Vector_7" d="M23.9531 2.07715V7.95015H29.5861L23.9531 2.07715Z" fill="#909090" />
          <path id="Vector_8" d="M24.0312 2V7.873H29.6642L24.0312 2Z" fill="#F4F4F4" />
          <path
            id="Vector_9"
            d="M8.97497 4.45655H7.66797V9.25655H8.69997V7.63855L8.92797 7.65155C9.14854 7.64775 9.36704 7.60824 9.57497 7.53455C9.75726 7.47182 9.92496 7.37283 10.068 7.24355C10.2124 7.12004 10.326 6.9646 10.4 6.78955C10.5003 6.49805 10.5361 6.18825 10.505 5.88155C10.4987 5.66246 10.4603 5.44547 10.391 5.23755C10.3278 5.08745 10.2342 4.95215 10.1159 4.84024C9.9976 4.72833 9.85732 4.64228 9.70397 4.58755C9.57077 4.53908 9.4331 4.50391 9.29297 4.48255C9.18739 4.46626 9.08079 4.45757 8.97397 4.45655M8.78497 6.75055H8.69597V5.27055H8.88997C8.97515 5.26441 9.06062 5.27748 9.14007 5.30881C9.21952 5.34014 9.29091 5.38892 9.34897 5.45155C9.46929 5.61256 9.53356 5.80855 9.53197 6.00955C9.53197 6.25555 9.53197 6.47855 9.30997 6.63555C9.15004 6.72351 8.96799 6.76312 8.78597 6.74955M12.456 4.44355C12.345 4.44355 12.237 4.45155 12.161 4.45455L11.926 4.46055H11.146V9.26055H12.064C12.4148 9.27016 12.7641 9.2107 13.092 9.08555C13.3559 8.98087 13.5896 8.81213 13.772 8.59455C13.9493 8.375 14.0766 8.11939 14.145 7.84555C14.2235 7.53542 14.2618 7.21646 14.259 6.89655C14.2784 6.51871 14.2491 6.13994 14.172 5.76955C14.0988 5.49691 13.9616 5.24564 13.772 5.03655C13.6232 4.86771 13.441 4.73152 13.237 4.63655C13.0617 4.55546 12.8774 4.4957 12.688 4.45855C12.6126 4.4461 12.5363 4.44041 12.46 4.44155M12.278 8.37855H12.178V5.31455H12.191C12.3971 5.29083 12.6057 5.32803 12.791 5.42155C12.9266 5.52987 13.0372 5.66635 13.115 5.82155C13.1989 5.98488 13.2473 6.16415 13.257 6.34755C13.266 6.56755 13.257 6.74755 13.257 6.89655C13.261 7.06818 13.25 7.23985 13.224 7.40955C13.1931 7.58378 13.1362 7.75235 13.055 7.90955C12.9631 8.0557 12.8389 8.17886 12.692 8.26955C12.5686 8.34935 12.4225 8.38657 12.276 8.37555M17.353 4.46055H14.923V9.26055H15.951V7.35655H17.251V6.46455H15.951V5.35255H17.351V4.46055"
            fill="white"
          />
        </g>
      </svg>
    );
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g id="vscode-icons:file-type-excel">
        <path
          id="Vector"
          d="M19.5807 15.3504L8.51172 13.4004V27.8094C8.51185 27.9659 8.54281 28.1209 8.60284 28.2655C8.66286 28.41 8.75077 28.5414 8.86155 28.652C8.97233 28.7626 9.10381 28.8503 9.24848 28.91C9.39315 28.9698 9.54818 29.0005 9.70472 29.0004H28.8047C28.9614 29.0008 29.1167 28.9703 29.2616 28.9106C29.4065 28.8509 29.5382 28.7633 29.6492 28.6527C29.7602 28.5421 29.8483 28.4106 29.9084 28.2659C29.9686 28.1212 29.9996 27.9661 29.9997 27.8094V22.5004L19.5807 15.3504Z"
          fill="#185C37"
        />
        <path
          id="Vector_2"
          d="M19.5807 3H9.70472C9.54818 2.99987 9.39315 3.03057 9.24848 3.09035C9.10381 3.15014 8.97233 3.23783 8.86155 3.34842C8.75077 3.45902 8.66286 3.59035 8.60284 3.73492C8.54281 3.87949 8.51185 4.03446 8.51172 4.191V9.5L19.5807 16L25.4417 17.95L29.9997 16V9.5L19.5807 3Z"
          fill="#21A366"
        />
        <path id="Vector_3" d="M8.51172 9.5H19.5807V16H8.51172V9.5Z" fill="#107C41" />
        <path
          id="Vector_4"
          opacity="0.1"
          d="M16.4337 8.2002H8.51172V24.4502H16.4337C16.7494 24.4486 17.0517 24.3227 17.2752 24.0998C17.4987 23.8769 17.6254 23.5749 17.6277 23.2592V9.3912C17.6254 9.07553 17.4987 8.77352 17.2752 8.55059C17.0517 8.32765 16.7494 8.20176 16.4337 8.2002Z"
          fill="black"
        />
        <path
          id="Vector_5"
          opacity="0.2"
          d="M15.7827 8.84961H8.51172V25.0996H15.7827C16.0984 25.098 16.4007 24.9721 16.6242 24.7492C16.8477 24.5263 16.9744 24.2243 16.9767 23.9086V10.0406C16.9744 9.72495 16.8477 9.42293 16.6242 9.2C16.4007 8.97707 16.0984 8.85118 15.7827 8.84961Z"
          fill="black"
        />
        <path
          id="Vector_6"
          opacity="0.2"
          d="M15.7827 8.84961H8.51172V23.7996H15.7827C16.0984 23.798 16.4007 23.6722 16.6242 23.4492C16.8477 23.2263 16.9744 22.9243 16.9767 22.6086V10.0406C16.9744 9.72495 16.8477 9.42293 16.6242 9.2C16.4007 8.97707 16.0984 8.85118 15.7827 8.84961Z"
          fill="black"
        />
        <path
          id="Vector_7"
          opacity="0.2"
          d="M15.1317 8.84961H8.51172V23.7996H15.1317C15.4474 23.798 15.7497 23.6722 15.9732 23.4492C16.1967 23.2263 16.3234 22.9243 16.3257 22.6086V10.0406C16.3234 9.72495 16.1967 9.42293 15.9732 9.2C15.7497 8.97707 15.4474 8.85118 15.1317 8.84961Z"
          fill="black"
        />
        <path
          id="Vector_8"
          d="M3.194 8.84961H15.132C15.4482 8.84935 15.7516 8.97464 15.9755 9.19797C16.1994 9.4213 16.3255 9.72438 16.326 10.0406V21.9586C16.3255 22.2748 16.1994 22.5779 15.9755 22.8013C15.7516 23.0246 15.4482 23.1499 15.132 23.1496H3.194C3.03738 23.1499 2.88224 23.1193 2.73746 23.0595C2.59267 22.9998 2.46108 22.9122 2.35019 22.8015C2.2393 22.6909 2.1513 22.5596 2.09121 22.4149C2.03113 22.2703 2.00013 22.1152 2 21.9586V10.0406C2.00013 9.88399 2.03113 9.72893 2.09121 9.5843C2.1513 9.43966 2.2393 9.30829 2.35019 9.19768C2.46108 9.08707 2.59267 8.9994 2.73746 8.93968C2.88224 8.87995 3.03738 8.84935 3.194 8.84961Z"
          fill="url(#paint0_linear_831_50156)"
        />
        <path
          id="Vector_9"
          d="M5.69922 19.873L8.21022 15.989L5.91022 12.127H7.75722L9.01222 14.6C9.12822 14.834 9.21222 15.008 9.25022 15.124H9.26722C9.34922 14.936 9.43622 14.755 9.52722 14.578L10.8692 12.131H12.5692L10.2102 15.971L12.6292 19.876H10.8202L9.37022 17.165C9.30289 17.0484 9.24568 16.9263 9.19922 16.8H9.17522C9.13294 16.923 9.07656 17.0408 9.00722 17.151L7.51422 19.873H5.69922Z"
          fill="white"
        />
        <path
          id="Vector_10"
          d="M28.8051 3H19.5801V9.5H29.9991V4.191C29.9989 4.03438 29.968 3.87932 29.9079 3.73469C29.8478 3.59005 29.7598 3.45868 29.6489 3.34807C29.538 3.23746 29.4064 3.14979 29.2616 3.09007C29.1168 3.03034 28.9617 2.99974 28.8051 3Z"
          fill="#33C481"
        />
        <path id="Vector_11" d="M19.5801 16H29.9991V22.5H19.5801V16Z" fill="#107C41" />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_831_50156"
          x1="4.494"
          y1="7.91361"
          x2="13.832"
          y2="24.0856"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#18884F" />
          <stop offset="0.5" stop-color="#117E43" />
          <stop offset="1" stop-color="#0B6631" />
        </linearGradient>
      </defs>
    </svg>
  );
};
export default UploadedFileIcon;
