import { TextField } from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { formatISO, parseISO } from "date-fns";
import React, { useEffect, useState } from "react";
import Props, { RaftValueDateTime } from "./index.d";

export type DatePickerProps = Props;

const ensureInjectValue = (data: RaftValueDateTime[]) =>
  data.reduce((newItems: string[], item) => {
    if (item.state) {
      return newItems.concat(item.state);
    }
    return newItems;
  }, []);

const dateTimeToISO = (date: Date) => {
  let isoString = "";
  if (date) {
    try {
      isoString = `${formatISO(date as unknown as Date).split("T")[0]}T03:17:16.873`;
    } catch (e) {
      console.log(e, date);
      return undefined;
    }
  }
  return isoString;
};

const DatePicker: React.FC<Props> = (props) => {
  const {
    title,
    data: { state, setAndSave, setNull },
    datesAfter,
    datesBefore,
    minDate,
    maxDate,
    testId,
    className,
    errorManager,
    errors,
    raftLanguage,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ...rest
  } = props;
  const [stateInner, setstateInner] = useState(state ? parseISO(state) : null);
  const [minDateInner, setMinDateInner] = useState<Date | undefined>(undefined);
  const [maxDateInner, setMaxDateInner] = useState<Date | undefined>(undefined);
  const errorManagerRes = errorManager({
    errorJoiner: (errors) => {
      return errors.join(", ");
    },
  });
  useEffect(() => {
    if (datesBefore && datesBefore.length && !minDate) {
      setMinDateInner(parseISO(ensureInjectValue(datesBefore).sort((a, b) => b.localeCompare(a))[0]));
    }
  }, [datesBefore, minDate]);

  useEffect(() => {
    if (datesAfter && datesAfter.length && !maxDate) {
      setMaxDateInner(parseISO(ensureInjectValue(datesAfter).sort((a, b) => a.localeCompare(b))[0]));
    }
  }, [datesAfter, maxDate]);

  const setAndSaveInner = (date: Date | null) => {
    if (!!date) {
      const isoString = dateTimeToISO(date);
      if (isoString) {
        setAndSave(isoString);
      } else {
        setstateInner(null);
      }
    } else {
      if (setNull) setNull();
    }
  };

  const handleChange = (date: Date | null) => {
    if (date) setstateInner(date as unknown as Date);
  };
  const handleAccept = (date: Date | null) => {
    setAndSaveInner(date);
  };
  const onBlur = () => {
    setAndSaveInner(stateInner);
  };
  return (
    <>
      <DesktopDatePicker
        minDate={minDateInner}
        maxDate={maxDateInner}
        disableFuture={props.disableFuture}
        disablePast={props.disablePast}
        inputFormat={raftLanguage === "de" ? "dd.MM.yyyy" : "MM/dd/yyyy"}
        label=""
        onAccept={handleAccept}
        onChange={handleChange}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
              placeholder: "",
              sx: {
                height: "26px",
                // maxWidth: "120px",
                padding: "7px 14px",
              },
            }}
            onBlur={onBlur}
            sx={{
              "& .MuiFormHelperText-root": { marginLeft: "0px", fontSize: "12px" },
              "& .MuiOutlinedInput-root": {
                borderRadius: "0px",
                border: errorManagerRes.error ? "1px solid rgba(214, 40, 57, 1) " : "1px solid #9E9E9E66",
                backgroundColor: errorManagerRes.error ? "rgba(211, 47, 47, 0.02)" : "transparent",
                "& .fieldset": {
                  borderWidth: "0px",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderWidth: "0px",
                },
              },
            }}
            {...errorManagerRes}
          />
        )}
        value={stateInner}
      />
    </>
  );
};

export default DatePicker;
