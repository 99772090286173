import { ErrorResult, FormErrors } from "./types";

const getErrorInformation = ({
  combinedErrors,
  isSubmit,
  isInitialValidation = false,
  formErrorsPre = {},
}: {
  isSubmit: boolean;
  isInitialValidation?: boolean;
  combinedErrors: Partial<{
    [x: string]: ErrorResult;
  }>;
  formErrorsPre: { [key: string]: any };
}): GetErrorInformationResult => {
  const fieldsWithErrors = Object.keys(combinedErrors).reduce<
    Partial<{
      [x: string]: ErrorResult;
    }>
  >((fieldsWithErrors, field) => {
    if (combinedErrors[field]?.messages?.length) {
      return { ...fieldsWithErrors, [field]: combinedErrors[field] };
    }
    return fieldsWithErrors;
  }, {});
  const errorCount = Object.values(fieldsWithErrors).length;
  const formErrorsNew: FormErrors<any> = {
    meta: {
      errorCount,
      submitWasInvalid: (isSubmit && !!errorCount) || formErrorsPre.meta?.submitWasInvalid,
      isInitialValidation,
    },
    errors: fieldsWithErrors,
  };
  return { formErrorsNew, errorCount };
};
export default getErrorInformation;
export type GetErrorInformationResult = { formErrorsNew: FormErrors<any>; errorCount: number };
